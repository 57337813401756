/**
 * Created by ebondarev
 */
import { transport } from '../../services';

export const NOT_FETCHED = 'TRADE_INTERCHANGES_NOT_FETCHED';
export const FETCHING = 'TRADE_INTERCHANGES_FETCHING';
export const FETCHED = 'TRADE_INTERCHANGES_FETCHED';
export const ADD = 'TRADE_INTERCHANGES_ADD';
export const REMOVE = 'TRADE_INTERCHANGES_REMOVE';
export const UPDATE = 'TRADE_INTERCHANGES_UPDATE';
export const DECODE = 'TRADE_INTERCHANGES_DECODE';
export const EXECUTE = 'TRADE_INTERCHANGES_EXECUTE';
export const ACTIVATE = 'TRADE_INTERCHANGES_ACTIVATE';
export const REJECT = 'TRADE_INTERCHANGES_REJECT';
export const NOT_FOUND = 'TRADE_INTERCHANGES_NOT_FOUND';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function activate() {
    return {
        type: ACTIVATE,
    }
}

export function add(data) {
    return {
        type: ADD,
        payload: data,
    }
}

export function remove(data) {
    return {
        type: REMOVE,
        payload: data,
    }
}

export function decode(data) {
    return {
        type: DECODE,
        payload: data,
    }
}

export function execute(data) {
    return {
        type: EXECUTE,
        payload: data,
    }
}

export function reject(error) {
    return {
        type: REJECT,
        payload: error,
    }
}

export function notFound() {
    return {
        type: NOT_FOUND,
    }
}

export function doCreateInterchange(model) {
    const {CurrencyFrom, CurrencyTo, UnitVolumeFrom, UnitVolumeTo, TwoFACode} = model;
    transport.requestCreateInterchange(CurrencyFrom, CurrencyTo, UnitVolumeFrom, UnitVolumeTo, TwoFACode);
}

export function doRemoveInterchange(id) {
    transport.actionInterchange(id, 'Cancel');
}

export function doDecodeInterchange(id) {
    transport.actionInterchange(id, 'Decode');
}

export function doActivateInterchange(id, TwoFACode) {
    return dispatch => {
        dispatch(activate());
        transport.actionInterchange(id, 'Accept', TwoFACode);
    }
}

