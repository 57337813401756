/**
 * Created by ebondarev
 */
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import user from './user/index';
import app from './app';
import trade from './trade';
import transports from './transports';
import investing from './investing';
import tracking from './tracking/index';
import forms from './forms';
import messageBoxes from './message-boxes';
import sharing from './sharing';
import rss from './rss';


export default combineReducers({
    routing: routerReducer,
    app,
    user,
    trade,
    transports,
    investing,
    tracking,
    forms,
    messageBoxes,
    sharing,
    rss,
});