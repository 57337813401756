import AppConfig from '../AppConfig';

const { defines, features, languages } = AppConfig;

export const getDefaultLanguage = () => {
    if (!features.allowLanguageDetection) {
        return defines.DEFAULT_LANGUAGE;
    }

    const browserLang = (window.navigator.userLanguage || window.navigator.language || '').slice(0, 2);

    // fix for chinese language
    if(browserLang === 'zh') {
        return languages.includes('cn') ? 'cn' : defines.DEFAULT_LANGUAGE;
    }

    return languages.includes(browserLang) ? browserLang : defines.DEFAULT_LANGUAGE;
}