import React from 'react';
import _ from 'lodash';

export function classNameParse(className) {
    if (className) {
        return (_.isArray(className)) ? className : [className];
    } else {
        return [];
    }
};

export function renderChildren(children, props) {
    if (!children) return null;
    if (Array.isArray(children)) {
        return (
            children.map((child, index) => React.cloneElement(child, { key: index, ...props }))
        )
    } else if (!Array.isArray(children) && props !== undefined) {
        return (
            <>{React.cloneElement(children, props)}</>
        )
    } else if (!Array.isArray(children)) {
        return (
            <>{children}</>
        )
    }
    return null;
}

export function skipRenderByTickers(context, nextProps, nextState) {
    for(let p in nextProps) {
        if (nextProps[p] !== context.props[p] && p !== "tickers") {
            return true;
        }
    }
    for(let p in nextState) {
        if (nextState[p] !== context.state[p]) {
            return true;
        }
    }
    return false;
}

//test if message contain html tags
export function isMessageHtml(message) {
    return (/<\/?[a-z][\s\S]*>/i.test(message));
}