import { Record } from 'immutable';

import * as actions from '../../actions/rss/rss';

class Rss extends Record({
    news: null,
    status: actions.NOT_FETCHED,
}) {
}

const initialState = new Rss();

export default function rss(state = initialState, action) {
    switch (action.type) {
        case actions.FETCHING:
            return state.merge({
                status: actions.FETCHING,
            });

        case actions.FETCHED:
            return state.merge({
                news: action.payload,
                status: actions.FETCHED,
            });

        default:
            return state;
    }
}