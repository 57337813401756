/**
 * Created by ebondarev
 */
import { combineReducers } from 'redux';

import settings from './settings';
import model from './model';
import auth from './auth';
import info from './info';
import platformNotifications from './platformNotifications';


export default combineReducers({
    settings,
    model,
    auth,
    info,
    platformNotifications,
});