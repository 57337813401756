export const getDisplayMedia = () => {
    return navigator.mediaDevices.getDisplayMedia({ 
        video: {
            mediaSource: 'screen',
        },
        audio: false,
    });
};

export class Stream extends MediaStream {
    stop() {
        this.getTracks().forEach((track) => {
            track.stop();
        });
    }

    onended(cb) {
        if (typeof cb !== 'function') return;

        this.getTracks().forEach((track) => {
            track.onended = (...args) => {
                cb(...args);
            };
        });
    }
}