import BaseModel from './base';

class InterchangeModel extends BaseModel {
    Id;
    CurrencyFrom;
    CurrencyTo;
    UnitVolumeFrom;
    UnitVolumeTo;
    AdditionTime;
    CreateDateUnix;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}

export default InterchangeModel;