import { Record, List } from 'immutable';

import AppConfig from '../../AppConfig';
import { sort } from '../utils';
import Types from '../../classes/types';
import { TransactionHistoryModel } from '../../models/transaction-history';

import { actions } from '../../actions/trade/report';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as accountActions from '../../actions/trade/accounts';

const defaultDatePeriod = AppConfig.historyFilters.period;
const defaultRange = Types.datePeriod(defaultDatePeriod);

class TransactionHistory extends Record({
    list: new List([]),
    status: actions.TH_NOT_FETCHED,
    datePeriod: defaultDatePeriod,
    datePeriodFrom: defaultRange.from, // custom date period begin
    datePeriodTo: defaultRange.to, // custom date period end
    lastUpdateAt: Date.now(), // обновлены данные в модели
    lastUpdateListAt: Date.now(), // обновлены данные в списке логов
    accountNumber: null,
    sortAttribute: 'DATE',
    sortDirection: Types.SORT_DESC,
    downloadFormat: Types.DOWNLOAD_FORMAT_XLSX,
}) {
    isFetched() {
        return this.status === actions.TH_FETCHED
    }

    isFetching() {
        return this.status === actions.TH_FETCHING
    }
}

const initialState = new TransactionHistory();

export default function transactionHistory(state = initialState, action) {
    switch (action.type) {
        case actions.TH_FETCHING:
            return state.merge({
                status: actions.TH_FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actions.TH_FETCHED:
            if (state.status === actions.TH_SYNCED) {
                return state;
            }

            const data = action.payload.map(data => {
                return new TransactionHistoryModel(data);
            });
            
            const getLastUpdateDates = (data) => {
                let lastUpdateDatesMap = new Map();
                data.forEach( (item) => {
                    const lastUpdateDate = lastUpdateDatesMap.get(item.PAYMENT_ID);
                    if(!lastUpdateDate || item.LAST_UPDATE > lastUpdateDate){
                        lastUpdateDatesMap.set(item.PAYMENT_ID, item.LAST_UPDATE);
                    }
                });
                return lastUpdateDatesMap;
            }

            const lastUpdateDates = getLastUpdateDates(data);
            const filteredData = data.filter((item) => item.LAST_UPDATE === lastUpdateDates.get(item.PAYMENT_ID));

            return state.merge({
                list: sort(new List(filteredData), state.sortAttribute, state.sortDirection),
                status: actions.TH_FETCHED,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actions.TH_CLEAR:
            return state.merge({
                status: actions.TH_NOT_FETCHED,
                list: state.list.clear(),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actions.TH_CHANGE_DATE_PERIOD:
            return state.merge({
                datePeriod: action.payload
            });

        case actions.TH_CHANGE_DATE_PERIOD_RANGE:
            return state.merge({
                datePeriodFrom: action.payload.from,
                datePeriodTo: action.payload.to,
            });

        case actions.TH_CHANGE_SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, action.payload, state.sortDirection),
                sortAttribute: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actions.TH_CHANGE_SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, state.sortAttribute, action.payload),
                sortDirection: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actions.TH_CHANGE_DOWNLOAD_FORMAT:
            return state.merge({
                downloadFormat: action.payload,
            });

        case actions.TH_CHANGE_ACCOUNT_NUMBER:
            return state.merge({
                accountNumber: action.payload,
            });

        case accountActions.CHANGED_CURRENT_ACCOUNT:
            return state.merge({
                accountNumber: action.payload ? action.payload.AccountNumber : null,
                list: state.list.clear(),
                status: actions.TH_NOT_FETCHED,
            });

        case trackingTypes.GET_STORE:
            return (action.data.transactionHistory = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.transactionHistory) {
                const transactionHistoryData = action.payload.transactionHistory;
                const historyList = new List((transactionHistoryData.list).map((historyData) => { return new TransactionHistoryModel(historyData); }));

                transactionHistoryData.list = historyList;
                transactionHistoryData.status = (state.status === actions.TH_FETCHED || state.status === actions.TH_FETCHING) ? actions.TH_SYNCED : state.status;
                transactionHistoryData.datePeriodFrom = new Date(transactionHistoryData.datePeriodFrom);
                transactionHistoryData.datePeriodTo = new Date(transactionHistoryData.datePeriodTo);
                return state.merge(transactionHistoryData);
            }
            return state;

        default:
            return state;
    }
}
