import { Map } from 'immutable';

import * as actionTypes from '../../actions/trade/chart';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as tradeTabsActions from '../../actions/forms/trade-tabs';

const initialState = Map();

export default function tradeTabs(state = initialState, action) {
    switch (action.type) {
        case tradeTabsActions.CREATE: {
            const [tabKey, content] = action.payload;
            return state.setIn([tabKey], content);
        }
        case tradeTabsActions.UPDATE: {
            const [tabKey, content] = action.payload;
            const item = state.get(tabKey);
            return state.set(tabKey, { ...item, ...content });
        }
        case tradeTabsActions.TRADE_TABS__REMOVE_TAB:
        case actionTypes.REMOVE_TAB: {
            const needRemoveTab = action.payload;
            return state.remove(needRemoveTab);
        }
        case tradeTabsActions.TRADE_TABS__REMOVE_ALL: {
            return state.clear();
        }

        case trackingTypes.GET_STORE:
            return (action.data.tradeTabs = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE: {
            if (action.payload && action.payload.tradeTabs) {
                const newTabs = action.payload.tradeTabs;
                let newState = state.clear();

                for (var tabKey in newTabs) {
                    newState = newState.setIn([tabKey], newTabs[tabKey]);
                }
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
}
