import { reportApi } from "../../services";
import { date } from "../../utils";

export const NOT_FETCHED = 'INVESTING_INSTRUMENT_PROFITABLE_NOT_FETCHED';
export const FETCHING = 'INVESTING_INSTRUMENT_PROFITABLE_FETCHING';
export const FETCHED = 'INVESTING_INSTRUMENT_PROFITABLE_FETCHED';


function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}


export function doFetch() {
    return (dispatch) => {

        const endDate = new Date();
        endDate.setUTCHours(0);
        endDate.setUTCMinutes(0);
        endDate.setUTCSeconds(0);
        endDate.setUTCDate(endDate.getUTCDate() + 1);

        dispatch(fetching());

        return reportApi.get('', {
            data: {
                report: "InstrumentProfitableReport",
                begin: "2017-01-01 00:00:00",
                end: date.toServerDate(endDate),
                format: "json"
            }
        }).then((res) => {
            const { data } = res;

            dispatch(fetched(data));

            return data;
        });
    }
}

export function doFetchIfNeed(accounts = []) {
    return (dispatch, getState) => {
        const status = getState().investing.instrumentProfitable.status;
        if (status === FETCHING || status === FETCHED) {
            return;
        }

        dispatch(doFetch(accounts));
    }
}