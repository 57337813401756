/**
 * Created by ebondarev
 */
import { Record } from 'immutable';

import * as actions from '../../actions/transports/main';

class Transports extends Record({
    lastError: null,
    lastErrorAt: Date.now(),
    lastReject: null,
    lastRejectAt: Date.now(),
    lastOrderReject: null,
    lastOrderRejectAt: Date.now(),
}) {

}

const initialState = new Transports();

export default function activityLog(state = initialState, action) {
    switch (action.type) {
        case actions.ERROR:
            return state.merge({
                lastError: action.payload,
                lastErrorAt: Date.now(),
            });

        case actions.REJECT:
            return state.merge({
                lastReject: action.payload,
                lastRejectAt: Date.now(),
            });

        case actions.REJECT_ORDER:
            return state.merge({
                lastOrderReject: action.payload,
                lastOrderRejectAt: Date.now(),
            });

        default:
            return state;
    }
}