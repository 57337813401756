export default function throttle(throttleTime, func, ensure = false) {
    let lastTimeTrigger = Number.NEGATIVE_INFINITY;
    let wait;
    let handle;

    return (...args) => {
        wait = lastTimeTrigger + throttleTime - Date.now();
        clearTimeout(handle);
        if (wait <= 0 || ensure) {
            handle = setTimeout(() => {
                func(...args);
                lastTimeTrigger = Date.now();
            }, wait);
        }
    };
};