import { Record } from 'immutable';

import * as simpleExchangeActions from '../../actions/trade/simple-exchange';
import * as userActions from '../../actions/user/user';


class SimpleExchangeStore extends Record({
    symbolId: null,
    buySell: null,
    fromAccountNumber: null,
    toAccountNumber: null,
    sendAmount: null,
    receiveAmount: null,
}) {
}

const initialState = new SimpleExchangeStore();

export default function simpleExchange(state = initialState, action) {
    switch (action.type) {
        case simpleExchangeActions.SIMPLE_EXCHANGE_INIT_STORE: {
            return state.merge({
                ...action.payload,
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_SET_FROM_ACCOUNT: {
            return state.merge({
                fromAccountNumber: action.payload
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_CHANGE_FROM_ACCOUNT: {
            return state.merge({
                symbolId: action.payload.symbolId,
                buySell: action.payload.buySell,
                fromAccountNumber: action.payload.fromAccountNumber,
                toAccountNumber: action.payload.toAccountNumber,
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_SET_TO_ACCOUNT: {
            return state.merge({
                toAccountNumber: action.payload
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_CHANGE_TO_ACCOUNT: {
            return state.merge({
                symbolId: action.payload.symbolId,
                buySell: action.payload.buySell,
                toAccountNumber: action.payload.toAccountNumber
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_SWITCH_DIRECTION: {
            return state.merge({
                buySell: action.payload.buySell,
                fromAccountNumber: action.payload.fromAccountNumber,
                toAccountNumber: action.payload.toAccountNumber,
                sendAmount: action.payload.sendAmount,
                receiveAmount: action.payload.receiveAmount
            });
        }

        case simpleExchangeActions.SIMPLE_EXCHANGE_CHANGE_SEND_AMOUNT:
        case simpleExchangeActions.SIMPLE_EXCHANGE_CHANGE_RECEIVE_AMOUNT: {
            return state.merge({
                sendAmount: action.payload.sendAmount,
                receiveAmount: action.payload.receiveAmount,
            });
        }

        case userActions.LOG_OUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}