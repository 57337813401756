import AppConfig from '../AppConfig';

class math {
    static isBoolean(value) {
        return typeof value === 'boolean';
    }

    static countPrecision(value, asNumber = true) {
        let number = asNumber ? +value : value;
        let precision = 0;

        if (!isFinite(number)) {
            return precision;
        }

        number = number.toString();

        if (number.indexOf('e') !== -1) {
            return parseInt(number.split('e')[1], 10) * -1; 
        }

        const parts = number.split('.');
        if (parts[1]) {
            return parts[1].length;
        }

        return precision;
    }

    static countValuePrecision(valueType, value, currency) {
        const currencyPrecision = AppConfig.getPaymentCurrencyPrecision(currency);
        const configPrecision = AppConfig.valuePrecision[valueType] || AppConfig.valuePrecision.default;
        const countConfigPrecision = math.firstDigitPosition(value) + configPrecision;

        return Math.max(currencyPrecision, countConfigPrecision);
    }

    static firstDigitPosition(number) {
        let position = 0;
        number = +number;

        if (!isFinite(number)) {
            return position;
        }

        number = number.toString();

        const parts = number.split('.');

        if (+parts[0] !== 0) {
            return position;
        }

        if (parts[1]) {
            let len = parts[1].length;
            for (let i = 0; i < len; i++) {
                if (parts[1].charAt(i) !== "0") {
                    return i;
                }
            }
        }

        return position;
    }

    static splitNumber(number, asNumber = true) {
        const data = {
            integer: null,
            precision: null
        };

        if (!isFinite(+number)) {
            return data;
        }

        const parts = number.toString().split('.');
        if (parts) {
            data.integer = asNumber ? +parts[0] : parts[0];
            data.precision = asNumber ? +parts[1] : parts[1];
        }

        return data;
    }

    /**
     * Rounds with given currency precision
     * 
     * @param {number} number
     * @param {string} currency
     * @returns {number} number
     */
    static roundToCurrencyPrecision(number, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);
        return this.roundToPrecision(number, precision);
    }

    /**
     * Rounds with given precision
     *
     * @param {number} number
     * @param {number} precision
     * @returns {number} number
     */
    static roundToPrecision(number, precision) {
        return Math.round(+number * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    /**
     * Rounds down with given currency precision
     * 
     * @param {number} number
     * @param {string} currency
     * @returns {number} number
     */
    static floorToCurrencyPrecision(number, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);
        return this.floorToPrecision(number, precision);
    }

    /**
     * Rounds down with given precision
     * 
     * @param {number} number
     * @param {number} precision
     * @returns {number} number
     */
    static floorToPrecision(number, precision) {
        return Math.floor(+number * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    /**
     * Rounds up with given currency precision
     * 
     * @param {number} number
     * @param {string} currency
     * @returns {number} number
     */
    static ceilToCurrencyPrecision(number, currency) {
        const precision = AppConfig.getPaymentCurrencyPrecision(currency);
        return this.ceilToPrecision(number, precision);
    }

    /**
     * Rounds up with given precision
     * 
     * @param {number} number
     * @param {number} precision
     * @returns {number} number
     */
    static ceilToPrecision(number, precision) {
        return Math.ceil(+number * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    /**
     * Rounds down with given precision
     * 
     * @param {number} number
     * @param {number} precision
     * @returns {number} number
     */
    static floorToScale(number, scale) {
        const intVal = number / scale;
        return Math.floor(intVal) * scale;
    }

    /**
     * Get percent of value. If step != 0, floor to given step
     * @param {number} number 
     * @param {number} percent 
     * @param {number} step 
     */
    static getPercentValue(number, percent, step = 0) {
        let percentValue = number * percent / 100;

        if (step) {
            percentValue = step * Math.floor(percentValue / step);
        }

        return percentValue;
    }

    /**
     *  @param {number} number
     */
    static getInvertNumber(number) {
        return number - (number * 2);
    }

    /**
     * 
     * @param {number | string} value 
     * @returns {boolean}
     */
    static hasNumericValue(value) {
        if (typeof value === 'string') {
            return value.trim().length > 0 && !isNaN(Number(value));
        }
        
        return typeof value === 'number';
    }
}

export default math;