/**
 * Created by ebondarev
 */
import BaseModel from './base';
import { lang } from '../services';
import Types from '../classes/types';


export class ActivityLogModel extends BaseModel {
    static ERRORS_TO_MESSAGES = {
        "REASON_NO_PERMISSIONS_ACCOUNT_INSTRUMENT": "The selected instrument is not available for trading in this wallet",
        "REASON_TRADE_SESSION_CLOSED": "The trading session is closed",
    };

    Id;
    AccountNumber;
    ActionId;
    OrderReference;
    _UTCDateTime;

    get UTCDateTime() {
        return this._UTCDateTime;
    }

    set UTCDateTime(value) {
        this._UTCDateTime = value;
        this.dateTime = new Date(value * 1000);
    }

    Ticker;

    _EventText;
    get EventText() {
        return this._EventText;
    };

    set EventText(value) {
        let text = value;
        Object.keys(ActivityLogModel.ERRORS_TO_MESSAGES).forEach((code) => {
            const index = text.indexOf(code);
            if (index === -1) {
                return;
            }

            text = text.replace(code, lang.t(ActivityLogModel.ERRORS_TO_MESSAGES[code]));
        });
        this._EventText = value;
        this._text = text;
        this._updateKey();
    }

    // addition fields
    dateTime;

    get date() {
        return lang.date(this.dateTime);
    }

    _text;
    get text() {
        return this._text;
    }

    _key;
    get key() {
        return this._key;
    }

    _updateKey() {
        this._key = this.UTCDateTime + '_' + Math.floor(Math.random() * 1000000);
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
        this._updateKey();
    }

    get itemType() {
        switch (this.EventTypeCode) {
            case 608:
                return Types.REPORT_ITEM_TYPE_REJECTED;

            case 310:
            case 53:
            case 54:
                return Types.REPORT_ITEM_TYPE_MARGIN;

            case 500:
                return Types.REPORT_ITEM_TYPE_BALANCE;

            default:
                return Types.REPORT_ITEM_TYPE_NONE;
        }
    }
}
