/**
 * Created by svyshnov
 */
import { transport, transportUser, transportTracking, lang } from '../../services';
import authService from '../../services/auth-service';
import { store } from '../../store';
import Transport from '../../classes/transport';
import * as authActions from '../user/auth';
import * as trackingActions from '../tracking/tracking';
import AppConfig from '../../AppConfig';


export function doInit() {
    return new Promise((resolve, reject) => {
        if (transportTracking.connected) {
            resolve(transportTracking);
        }

        if (AppConfig.features.isEnableTracking)
            transportTracking.connect();

        transportTracking.onConnected = () => {
            const state = store.getState();
            const user = state.user;
            if (store.getState().user.auth.isLogged) {
                const baseRequest = {
                    lang: store.getState().user.settings.language,
                    channel: Transport.CHANNEL_TRACKING,
                    socketId: user.auth.socketUserId
                }
                if (AppConfig.features.isEnabledAuth2) {
                    transportTracking.requestAuth2({
                        ...baseRequest,
                        token: authService.getToken(),
                        tracking: localStorage.getItem('tracking')
                    });
                } else {
                    transportTracking.requestLogin({
                        ...baseRequest,
                        login: user.model.Login,
                        password: user.model.Password,
                        tracking: localStorage.getItem('tracking')
                    });
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    reject(msg);
                }, 20000);

                transportTracking.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    if (data.Login && data.Login === user.model.Login) {
                        resolve(data);
                        store.dispatch(authActions.loginSocketUserSuccess({ SocketId: data.SocketId }));
                    } else {
                        const msg = lang.t("Invalid Login or password");
                        reject(msg);
                    }
                };
            }

            resolve(transportTracking);
        };
        // send messages from slave client
        transportTracking.onDataMessage = (data) => {
            transport.processDataMessage(data);
            transportUser.processDataMessage(data);
        };

        transportTracking.onError = reject;
        transportTracking.onSetTracking = (data) => {
            if (store.getState().user.auth.isLogged && data.TrackState === trackingActions.TRACK_STATE_SLAVE) {
                store.dispatch(trackingActions.setTrackingState(data.TrackState));
                // Get base store by reducers 
                const getAppStoreResult = store.dispatch(trackingActions.getStore(data.TrackState));
                transportTracking.sendComponentStore('App', getAppStoreResult.data);
                // Get state by component
                const getStateResult = store.dispatch(trackingActions.getComponentState());
                const componentStateList = getStateResult.data;
                for (var cName in componentStateList) {
                    var cState = componentStateList[cName];
                    transportTracking.sendComponentState(cName, cState);
                }
            } else {
                store.dispatch(trackingActions.setTrackingState(trackingActions.TRACK_STATE_DISABLED));
            }
        };
        transportTracking.onAppMouseClick = (data) => {
            store.dispatch(trackingActions.doHandleMouseClick(data));
        };
        transportTracking.onAppMouseLocation = (data) => {
            store.dispatch(trackingActions.doHandleMouseLocation(data));
        };
        transportTracking.onAppActionData = (data) => {
            store.dispatch(trackingActions.updateAppData(data));
        };
        transportTracking.onAppActionState = (data) => {
            if (data.Action) {
                const fetchPayloadResult = store.dispatch(trackingActions.fetchActionPayload(data));
                let callbackPromise = store.dispatch(trackingActions.updateAppState(fetchPayloadResult.payload)).promise;
                if (callbackPromise) Promise.resolve(callbackPromise);
            }
        };
        transportTracking.onComponentState = (data) => {
            let callbackPromise = store.dispatch(trackingActions.updateComponentState(data)).promise;
            if (callbackPromise) Promise.resolve(callbackPromise);
        };
        transportTracking.onComponentStore = (data) => {
            store.dispatch(trackingActions.updateComponentStore(data));
        };
    })
}

export function doReconnect() {
    doInit().then(() => {
        if (AppConfig.features.isEnableTracking)
            transportTracking.reconnectStart(0);
    }, () => { })
}
