/**
 * Created by ebondarev
 */
export const NOT_FETCHED = 'TRADE_NEWS_NOT_FETCHED';
export const FETCHING = 'TRADE_NEWS_FETCHING';
export const FETCHED = 'TRADE_NEWS_FETCHED';
export const ADD = 'TRADE_NEWS_ADD';
export const CLEAR = 'TRADE_NEWS_CLEAR';
export const CHANGE_SORT_ATTRIBUTE = 'TRADE_NEWS_CHANGE_SORT_ATTRIBUTE';
export const CHANGE_SORT_DIRECTION = 'TRADE_NEWS_CHANGE_SORT_DIRECTION';


export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function add(data) {
    return {
        type: ADD,
        payload: data
    }
}

export function clear() {
    return {
        type: CLEAR,
    }
}

export function sortAttribute(data) {
    return {
        type: CHANGE_SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: CHANGE_SORT_DIRECTION,
        payload: data
    }
}

export function doClear() {
    return (dispatch, getState) => {
        dispatch(clear());
    }
}
