/**
 * Created by ebondarev
 */
import { Record, List } from 'immutable';

import Types from '../../classes/types';
import { sort } from '../utils';

import * as actionTypes from '../../actions/trade/activity-log';
import * as userTypes from '../../actions/user/user';
import * as accountTypes from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';
import { ActivityLogModel } from '../../models/activity-log';

const defaultDatePeriod = Types.DATE_PERIOD_TODAY;
const defaultRange = Types.datePeriod(defaultDatePeriod);

class ActivityLog extends Record({
    list: new List([]),
    listToShow: new List([]),
    status: actionTypes.NOT_FETCHED,
    initFetched: false,
    sortAttribute: 'UTCDateTime',
    sortDirection: Types.SORT_DESC,
    lastUpdateAt: Date.now(), // обновлены данные в модели
    lastUpdateListAt: Date.now(), // обновлены данные в списке логов
    datePeriod: defaultDatePeriod,
    datePeriodFrom: defaultRange.from, // custom date period begin
    datePeriodTo: defaultRange.to, // custom date period end
    downloadFormat: Types.DOWNLOAD_FORMAT_XLSX,
    accountNumber: null
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }

    isFetching() {
        return this.status === actionTypes.FETCHING
    }
}

function createModelFromData(data) {
    const model = new ActivityLogModel(data);
    return model;
}


function filterListToShow(list, accountNumber) {
    if (!accountNumber) {
        return list.clear();
    }

    return list.filter((activityLog) => {
        return (activityLog.AccountNumber === accountNumber);
    });
}

const initialState = new ActivityLog();

export default function activityLog(state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHED:
            if (state.status === actionTypes.SYNCED) {
                return state;
            }
            const data = action.payload.reverse().map((data) => {
                return createModelFromData(data);
            });
            const listFetched = sort(new List(data), state.sortAttribute, state.sortDirection);
            const listFetchedToShow = filterListToShow(listFetched, state.accountNumber);

            return state.merge({
                list: listFetched,
                listToShow: listFetchedToShow,
                status: actionTypes.FETCHED,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actionTypes.ADD:
            if (!action.payload) {
                return state;
            }
            const addModel = createModelFromData(action.payload);
            const updatedList = sort(state.list.unshift(addModel), state.sortAttribute, state.sortDirection);
            const updatedListToShow = filterListToShow(updatedList, state.accountNumber);

            return state.merge({
                list: updatedList,
                listToShow: updatedListToShow,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now()
            });

        case actionTypes.INIT_FETCHED: {
            return state.merge({
                initFetched: true,
            });
        }

        case actionTypes.SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            const sortedList = sort(state.list, action.payload, state.sortDirection);

            return state.merge({
                list: sortedList,
                listToShow: filterListToShow(sortedList, state.accountNumber),
                sortAttribute: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            const sortDirectionList = sort(state.list, state.sortAttribute, action.payload);

            return state.merge({
                list: sortDirectionList,
                listToShow: filterListToShow(sortDirectionList, state.accountNumber),
                sortDirection: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.CLEAR:

            const clearedList = state.list.clear();

            return state.merge({
                status: actionTypes.NOT_FETCHED,
                list: clearedList,
                listToShow: filterListToShow(clearedList, state.accountNumber),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actionTypes.DATE_PERIOD:
            return state.merge({
                datePeriod: action.payload
            });

        case actionTypes.DATE_PERIOD_RANGE:
            return state.merge({
                datePeriodFrom: action.payload.from,
                datePeriodTo: action.payload.to,
            });

        case accountTypes.CHANGED_CURRENT_ACCOUNT:
            const accountNumber = action.payload ? action.payload.AccountNumber : null;
            const changedAccountListToShow = filterListToShow(state.list, accountNumber);

            return state.merge({
                accountNumber,
                listToShow: changedAccountListToShow
            });

        case actionTypes.SET_ACCOUNT:
            return state.merge({
                accountNumber: action.payload,
                listToShow: filterListToShow(state.list, action.payload),
            });

        case actionTypes.CHANGE_DOWNLOAD_FORMAT:
            return state.merge({
                downloadFormat: action.payload,
            });

        case userTypes.LOG_OUT:
            return initialState;

        case trackingTypes.GET_STORE:
            return (action.data.activityLog = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.activityLog) {
                const activityLogData = action.payload.activityLog;
                const historyList = new List((activityLogData.list).map((historyData) => { return new ActivityLogModel().copyFrom(historyData); }));
                const historyListToShow = new List((activityLogData.listToShow).map((historyData) => { return new ActivityLogModel().copyFrom(historyData); }));

                activityLogData.list = historyList;
                activityLogData.listToShow = historyListToShow;
                activityLogData.status = (state.status === actionTypes.NOT_FETCHED || state.status === actionTypes.FETCHING) ? actionTypes.SYNCED : state.status;
                activityLogData.datePeriodFrom = new Date(activityLogData.datePeriodFrom);
                activityLogData.datePeriodTo = new Date(activityLogData.datePeriodTo);
                return state.merge(activityLogData);
            }
            return state;

        default:
            return state;
    }
}
