/**
 * Created by ebondarev
 */
import { Record, Set } from 'immutable';
import { store } from '../../store';

import Types from '../../classes/types';
import { sort } from '../utils';

import * as actionTypes from '../../actions/trade/account-history';
import * as accountTypes from '../../actions/trade/accounts';
import * as userTypes from '../../actions/user/user';
import * as trackingTypes from '../../actions/tracking/tracking';
import { AccountHistoryModel } from '../../models/account-history';

class SummaryHistory {
    unitVolume = 0;
    netPnl = 0;
    unitInLots = 0;
    commission = 0;
    swaps = 0;

    updateFromHistory(history) {
        this.swaps += history.Swap;
        this.commission += history.Commission;
        this.unitVolume += history.UnitVolume;
        this.unitInLots += history.unitInLots;
        this.netPnl += history.netPnl;
    }
}

const defaultDatePeriod = Types.DATE_PERIOD_TODAY;
const defaultRange = Types.datePeriod(defaultDatePeriod);

class AccountHistory extends Record({
    list: new Set(),
    listToShow: new Set(),
    status: actionTypes.NOT_FETCHED,
    initFetched: false,
    sortAttribute: 'CloseTime',
    sortDirection: Types.SORT_DESC,
    lastUpdateAt: Date.now(), // обновлены данные в модели
    lastUpdateListAt: Date.now(), // обновлены данные в списке логов
    datePeriod: defaultDatePeriod,
    datePeriodFrom: defaultRange.from, // custom date period begin
    datePeriodTo: defaultRange.to, // custom date period end
    downloadFormat: Types.DOWNLOAD_FORMAT_XLSX,
    summary: new SummaryHistory(),
    accountNumber: null
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }

    isFetching() {
        return this.status === actionTypes.FETCHING
    }
}

function createModelFromData(data, tickersList) {
    const model = new AccountHistoryModel(data);
    const ticker = tickersList.get(model.SymbolId);
    if (ticker) {
        model.updateAttributesFromQuoteOrTicker(ticker);
    }
    return model;
}

function getSummary(listToShow) {
    const summary = new SummaryHistory();

    listToShow.forEach((history) => {
        summary.updateFromHistory(history);
    });

    return summary;
}

function getListToShow(list, accountNumber) {
    if (!accountNumber) {
        return list.clear();
    }

    return list.filter((accountHistory) => {
        return accountHistory.AccountNumber === accountNumber;
    });
}

const initialState = new AccountHistory();

export default function accountHistory(state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHED:
            if (state.status === actionTypes.SYNCED) {
                return state;
            }
            const fetchedTickersList = store.getState().trade.tickers.list;
            const data = Object.values(action.payload).map((data) => {
                return createModelFromData(data, fetchedTickersList);
            });
            const listFetched = sort(new Set(data), state.sortAttribute, state.sortDirection);
            const listFetchedToShow = getListToShow(listFetched, state.accountNumber);

            return state.merge({
                list: listFetched,
                listToShow: listFetchedToShow,
                status: actionTypes.FETCHED,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
                summary: getSummary(listFetchedToShow)
            });

        case actionTypes.ADD:
            if (!action.payload) {
                return state;
            }
            const addModel = createModelFromData(action.payload, store.getState().trade.tickers.list);
            const updatedList = sort(state.list.add(addModel), state.sortAttribute, state.sortDirection);
            const updatedListToShow = getListToShow(updatedList, state.accountNumber);

            return state.merge({
                list: updatedList,
                listToShow: updatedListToShow,
                summary: getSummary(updatedListToShow),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now()
            });

        case actionTypes.INIT_FETCHED: {
            return state.merge({
                initFetched: true,
            });
        }

        case actionTypes.SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            const sortedList = sort(state.list, action.payload, state.sortDirection);

            return state.merge({
                list: sortedList,
                listToShow: getListToShow(sortedList, state.accountNumber),
                sortAttribute: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            const sortDirectionList = sort(state.list, state.sortAttribute, action.payload);

            return state.merge({
                list: sortDirectionList,
                listToShow: getListToShow(sortDirectionList, state.accountNumber),
                sortDirection: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.CLEAR:
            const clearedList = state.list.clear();

            return state.merge({
                status: actionTypes.NOT_FETCHED,
                list: clearedList,
                listToShow: getListToShow(clearedList, state.accountNumber),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actionTypes.DATE_PERIOD:
            return state.merge({
                datePeriod: action.payload
            });

        case actionTypes.DATE_PERIOD_RANGE:
            return state.merge({
                datePeriodFrom: action.payload.from,
                datePeriodTo: action.payload.to,
            });

        case actionTypes.CHANGE_DOWNLOAD_FORMAT:
            return state.merge({
                downloadFormat: action.payload,
            });

        case actionTypes.SET_ACCOUNT:
            const newListToShow = getListToShow(state.list, action.payload);

            return state.merge({
                accountNumber: action.payload,
                listToShow: newListToShow,
                summary: getSummary(newListToShow),
            });

        case accountTypes.CHANGED_CURRENT_ACCOUNT:
            const accountNumber = action.payload ? action.payload.AccountNumber : null;
            const changedAccountListToShow = getListToShow(state.list, accountNumber);

            return state.merge({
                accountNumber,
                listToShow: changedAccountListToShow,
                summary: getSummary(changedAccountListToShow),
            });

        case userTypes.LOG_OUT:
            return initialState;

        case trackingTypes.GET_STORE:
            return (action.data.accountHistory = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.accountHistory) {
                const accountHistoryData = action.payload.accountHistory;
                const historyList = new Set((accountHistoryData.list).map((historyData) => { return new AccountHistoryModel().copyFrom(historyData); }));
                const historyListToShow = new Set((accountHistoryData.listToShow).map((historyData) => { return new AccountHistoryModel().copyFrom(historyData); }));

                accountHistoryData.list = historyList;
                accountHistoryData.listToShow = historyListToShow;
                accountHistoryData.summary = getSummary(historyListToShow);
                accountHistoryData.status = (state.status === actionTypes.NOT_FETCHED || state.status === actionTypes.FETCHING) ? actionTypes.SYNCED : state.status;
                accountHistoryData.datePeriodFrom = new Date(accountHistoryData.datePeriodFrom);
                accountHistoryData.datePeriodTo = new Date(accountHistoryData.datePeriodTo);
                return state.merge(accountHistoryData);
            }
            return state;

        default:
            return state;
    }
}
