import Types from '../classes/types';

const oldDirTargets = [
    Types.TARGET_MOBILE,
    Types.TARGET_DESKTOP,
    Types.TARGET_CLASSIC_MOBILE,
    Types.TARGET_CLASSIC_DESKTOP,
];

const classicDesignTargets = [
    Types.TARGET_CLASSIC_MOBILE,
    Types.TARGET_CLASSIC_DESKTOP,
];

const mainDesignTargets = [
    Types.TARGET_MOBILE,
    Types.TARGET_DESKTOP,
];

export const isMobile = process.env.REACT_APP_TARGET.includes('mobile');
export const isDesktop = process.env.REACT_APP_TARGET.includes('desktop');

export const isMobileTarget = process.env.REACT_APP_TARGET === Types.TARGET_MOBILE;
export const isDesktopTarget = process.env.REACT_APP_TARGET === Types.TARGET_DESKTOP;
export const isClassicMobileTarget = process.env.REACT_APP_TARGET === Types.TARGET_CLASSIC_MOBILE;
export const isClassicDesktopTarget = process.env.REACT_APP_TARGET === Types.TARGET_CLASSIC_DESKTOP;
export const isCustom2MobileTarget = process.env.REACT_APP_TARGET === Types.TARGET_CUSTOM2_MOBILE;
export const isCustom2DesktopTarget = process.env.REACT_APP_TARGET === Types.TARGET_CUSTOM2_DESKTOP;
export const isVersion6MobileTarget = process.env.REACT_APP_TARGET === Types.TARGET_VERSION6_MOBILE;
export const isVersion6DesktopTarget = process.env.REACT_APP_TARGET === Types.TARGET_VERSION6_DESKTOP;

export const isCustomDesigns = !!['custom', 'classic'].filter(s => process.env.REACT_APP_TARGET.indexOf(s) === 0).length;

export const isCustomDesktopTargets = isDesktop && isCustomDesigns;
export const isCustomMobileTargets = isMobile && isCustomDesigns;

export const isClassicDesign = classicDesignTargets.includes(process.env.REACT_APP_TARGET);
export const isMainDesign = mainDesignTargets.includes(process.env.REACT_APP_TARGET);
export const isCustom2Design = process.env.REACT_APP_TARGET.indexOf('custom2') === 0;
export const isVersion6Design = process.env.REACT_APP_TARGET.indexOf('version6') === 0;

export const isElectron = process.env.REACT_APP_ENVIRONMENT === 'electron';
export const isBrowser = process.env.REACT_APP_ENVIRONMENT === 'browser';

export const isWindows = ['win32', 'win64', 'windows', 'wince'].includes(window.navigator.platform.toLowerCase());

export const getTargetDir = (target = process.env.REACT_APP_TARGET) => {
    if (oldDirTargets.includes(target)) {
        return target;
    }

    return `designs/${target.split('-', 2).join('/')}`;
}