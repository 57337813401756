/**
 * Created by ebondarev
 */
import Types from '../../classes/types';
import { transport, reportApi } from '../../services';
import * as transportTradeActions from '../transports/trade';
import * as trackingActions from '../tracking/tracking';
import AppConfig from '../../AppConfig';
import { accountHistoryModelAdapter } from '../../models/account-history';

export const NOT_FETCHED = 'TRADE_ACCOUNT_HISTORY_NOT_FETCHED';
export const FETCHING = 'TRADE_ACCOUNT_HISTORY_FETCHING';
export const FETCHED = 'TRADE_ACCOUNT_HISTORY_FETCHED';
export const INIT_FETCHED = 'TRADE_ACCOUNT_HISTORY_INIT_FETCHED';
export const SYNCED = 'TRADE_ACCOUNT_HISTORY_SYNCED';
export const ADD = 'TRADE_ACCOUNT_HISTORY_ADD';
export const CLEAR = 'TRADE_ACCOUNT_HISTORY_CLEAR';
export const SORT_ATTRIBUTE = 'TRADE_ACCOUNT_HISTORY_SORT_ATTRIBUTE';
export const SORT_DIRECTION = 'TRADE_ACCOUNT_HISTORY_SORT_DIRECTION';
export const DATE_PERIOD = 'TRADE_ACCOUNT_HISTORY_DATE_PERIOD';
export const DATE_PERIOD_RANGE = 'TRADE_ACCOUNT_HISTORY_DATE_PERIOD_RANGE';
export const SET_ACCOUNT = 'TRADE_ACCOUNT_HISTORY_ACCOUNT';
export const CHANGE_DOWNLOAD_FORMAT = 'TRADE_ACCOUNT_HISTORY_CHANGE_DOWNLOAD_FORMAT';


export function fetching() {
    return {
        type: FETCHING,
    }
}

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function initFetched() {
    return {
        type: INIT_FETCHED,
    }
}

export function add(data) {
    return {
        type: ADD,
        payload: data
    }
}

export function clear() {
    return {
        type: CLEAR,
    }
}

export function sortAttribute(data) {
    return {
        type: SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: SORT_DIRECTION,
        payload: data
    }
}

export function datePeriod(value) {
    return {
        type: DATE_PERIOD,
        payload: value
    }
}

export function datePeriodRange(from, to) {
    return {
        type: DATE_PERIOD_RANGE,
        payload: {
            from,
            to
        }
    }
}

export function setAccount(value) {
    return {
        type: SET_ACCOUNT,
        payload: value
    }
}

export function changeDownloadFormat(format) {
    return {
        type: CHANGE_DOWNLOAD_FORMAT,
        payload: format
    }
}

export function doFetchIfNeed() {
    return (dispatch, getState) => {
        const { accountHistory } = getState().trade;

        if (accountHistory.status === FETCHED
            || accountHistory.status === FETCHING
        ) {
            return;
        }

        dispatch(doFetch());
    }
}

export function doInitFetch(accounts = []) {
    return (dispatch, getState) => {
        dispatch(initFetched());
        dispatch(doFetch(accounts));
    };
}

export function doFetch(accounts = []) {
    return (dispatch, getState) => {
        const { accountHistory } = getState().trade;
        const datePeriod = Types.datePeriod(
            accountHistory.datePeriod,
            accountHistory.datePeriodFrom,
            accountHistory.datePeriodTo
        );
        const from = accountHistory.datePeriodFrom ? accountHistory.datePeriodFrom 
        : datePeriod.from;
        const to = accountHistory.datePeriodTo ? accountHistory.datePeriodTo
        : datePeriod.to;
        
        dispatch(fetching());
        (AppConfig.features.accountHistoryFromReport)
        ? dispatch(doFetchHTTP(accounts, from, to)) 
        : dispatch(doFetchWSS(accounts, from, to));
    }
}

function doFetchWSS(accounts, from, to) {
    return (dispatch, getState) => {
        transportTradeActions.doInit().then(() => {
            transport.requestAccountHistory({
                from: +(from / 1000).toFixed(0),
                to: +(to / 1000).toFixed(0),
                accounts: accounts,
            });
        });
    }
}

function doFetchHTTP(accounts, from, to) {
    return (dispatch, getState) => {
        return reportApi.get('', {
            data: {
                report: Types.REPORTS_ENDPOINTS['accountHistory'],
                accounts: accounts.join(),
                begin: new Date(from).toISOString(),
                end: new Date(to).toISOString(),
                format: 'json',
            }
        }).then(res => {
            const data = res ? res.data || [] : [];
            const tickersList = getState().trade.tickers.list;
            dispatch(fetched(accountHistoryModelAdapter(data, tickersList)));
        });
    }
}

export function doChangeDatePeriod(value) {
    return (dispatch, getState) => {
        const range = Types.datePeriod(value);

        dispatch(clear());
        dispatch(datePeriodRange(range.from, range.to));
        dispatch(datePeriod(value));
        dispatch(doFetchIfNeed());
    }
}

export function doChangePeriodRange(from, to) {
    return (dispatch, getState) => {
        dispatch(clear());
        dispatch(datePeriod(Types.DATE_PERIOD_CUSTOM));
        dispatch(datePeriodRange(from, to));
        dispatch(doFetchIfNeed());
    }
}

export function doFetched(data) {
    return (dispatch, getState) => {
        const { isReady } = getState().trade.tickers;
        if (isReady) {
            trackingActions.dispatchAction(fetched(data));
            return;
        }

        transportTradeActions.doInit().then(() => {
            setTimeout(() => {
                trackingActions.dispatchAction(fetched(data));
            }, 1000);
        });
    }
}
