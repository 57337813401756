/**
 * Created by ebondarev
 */
import Types from '../classes/types';

export function sort(map, attribute, direction = Types.SORT_ASC, handler = f => f) {
    return map.sort((a, b) => {
        if (handler(a[attribute]) === handler(b[attribute])) {
            return 0;
        } else if (direction === Types.SORT_DESC) {
            return (handler(a[attribute]) > handler(b[attribute])) ? -1 : 1;
        }

        return (handler(a[attribute]) > handler(b[attribute])) ? 1 : -1;
    });
}