/**
 * Created by ebondarev
 */
import BaseModel from './base';


export class NewsModel extends BaseModel {
    Caption;
    Content;
    Link;
    NewsID;
    Source;
    _UTCDateTime;

    get UTCDateTime() {
        return this._UTCDateTime;
    }

    set UTCDateTime(value) {
        this._UTCDateTime = value;
        this.dateTime = new Date(value * 1000);
    }

    WrappedContent;
    dateTime;

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }
}