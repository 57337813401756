import { Map, Record } from 'immutable';

import * as actionTypes from '../../actions/investing/strategy';
import * as userTypes from '../../actions/user/user';
import * as subscriptionTypes from '../../actions/investing/subscription';
import * as accountHistoryTypes from '../../actions/trade/account-history';
import { StrategyModel } from '../../models/strategy';
import { sort } from '../utils';
import Types from '../../classes/types';
import { store } from '../../store';
import { AccountHistoryModel } from "../../models/account-history";


class StrategyStore extends Record({
    sortAttribute: 'TotalGain',
    sortDirection: Types.SORT_DESC,
    list: new Map(),
    status: actionTypes.NOT_FETCHED,
    isReady: false,
    historyCache: [], // keep only last data because arrays is big
    loadingHistoryCache: false,
    conversionData: null,
    lastListUpdateAt: 0
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

const initialState = new StrategyStore();

function createModelFromData(data) {
    return new StrategyModel(data);
}

/**
 * @param list Map of StrategyModel
 * @param subscriptionData Object of SubscriptionsInfo key as strategyId value as SubscriptionsInfo
 * @returns {*}
 */
function updateCountSubscriptions(list, subscriptionData) {
    list.forEach((strategyItem) => {
        if (subscriptionData[strategyItem.Id]) {
            strategyItem.countSubscriptions = subscriptionData[strategyItem.Id].countSubscriptionsForStrategy;
        }
    });

    return list;
}

function createHistoryModelFromData(data, tickersList) {
    const model = new AccountHistoryModel(data);
    const ticker = tickersList.get(model.SymbolId);
    if (ticker) {
        model.updateAttributesFromQuoteOrTicker(ticker);
    }
    return model;
}

export default function strategy(state = initialState, action) {


    switch (action.type) {
        case actionTypes.FETCHED:
            const subscriptionListData = store.getState().investing.subscription.list
                .groupBy(subscription => subscription.SignalAccountNumber)
                .map((listSubscription, strategyId) => {
                    return new SubscriptionsInfo({countSubscriptionsForStrategy: listSubscription.size, strategyId});
                })
                .toObject();

            let list = new Map(action.payload).map((data) => {
                return createModelFromData(data);
            });

            return state.merge({
                status: actionTypes.FETCHED,
                list: sort(updateCountSubscriptions(list, subscriptionListData), state.sortAttribute, state.sortDirection),
                lastListUpdateAt: Date.now(),
                isReady: true
            });

        case subscriptionTypes.FETCHED:
            const subscriptionListInfo = {};
            Object.keys(action.payload).forEach((key) => {
                const subscriptionData = action.payload[key];
                if (!subscriptionListInfo[subscriptionData.SignalAccountNumber]) {
                    subscriptionListInfo[subscriptionData.SignalAccountNumber] = new SubscriptionsInfo({
                        countSubscriptionsForStrategy: 0, strategyId: subscriptionData.SignalAccountNumber
                    })
                }

                subscriptionListInfo[subscriptionData.SignalAccountNumber].countSubscriptionsForStrategy++;
            });
            updateCountSubscriptions(state.list, subscriptionListInfo);

            return state.merge({
                lastListUpdateAt: Date.now()
            });

        case actionTypes.FETCHED_HISTORY:
            let data = [];
            if (action.payload && action.payload.length > 0) {
                const fetchedTickersList = store.getState().trade.tickers.list;
                data = action.payload.map(data => createHistoryModelFromData(data, fetchedTickersList));
            }
            return state.merge({
                historyCache: data,
                loadingHistoryCache: false,
            });

        case accountHistoryTypes.FETCHED:
            let historyData = [];
            if (action.payload && action.payload.length > 0) {
                const fetchedTickersList = store.getState().trade.tickers.list;
                historyData = action.payload.map(data => createHistoryModelFromData(data, fetchedTickersList));
            }
            return state.merge({
                historyCache: historyData,
                loadingHistoryCache: false,
            });

        case actionTypes.FETCHING_HISTORY:
            return state.merge({loadingHistoryCache: true});

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}

class SubscriptionsInfo {
    strategyId;
    countSubscriptionsForStrategy = 0;

    constructor({strategyId, countSubscriptionsForStrategy}) {
        this.strategyId = strategyId;
        this.countSubscriptionsForStrategy = countSubscriptionsForStrategy;
    }
}