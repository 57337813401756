/**
 * Created by ebondarev
 */
import Types from '../../classes/types';
import { transport } from '../../services';
import * as transportTradeActions from '../transports/trade';

export const NOT_FETCHED = 'TRADE_ACTIVITY_LOG_NOT_FETCHED';
export const FETCHING = 'TRADE_ACTIVITY_LOG_FETCHING';
export const FETCHED = 'TRADE_ACTIVITY_LOG_FETCHED';
export const INIT_FETCHED = 'TRADE_ACTIVITY_LOG_INIT_FETCHED';
export const SYNCED = 'TRADE_ACTIVITY_LOG_SYNCED';
export const ADD = 'TRADE_ACTIVITY_LOG_ADD';
export const CLEAR = 'TRADE_ACTIVITY_LOG_CLEAR';
export const SORT_ATTRIBUTE = 'TRADE_ACTIVITY_LOG_SORT_ATTRIBUTE';
export const SORT_DIRECTION = 'TRADE_ACTIVITY_LOG_SORT_DIRECTION';
export const DATE_PERIOD = 'TRADE_ACTIVITY_LOG_DATE_PERIOD';
export const DATE_PERIOD_RANGE = 'TRADE_ACCOUNT_HISTORY_DATE_PERIOD_RANGE';
export const SET_ACCOUNT = 'TRADE_ACTIVITY_LOG_ACCOUNT';
export const CHANGE_DOWNLOAD_FORMAT = 'TRADE_ACTIVITY_LOG_CHANGE_DOWNLOAD_FORMAT';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function initFetched() {
    return {
        type: INIT_FETCHED,
    }
}

export function add(data) {
    return {
        type: ADD,
        payload: data
    }
}

export function clear() {
    return {
        type: CLEAR,
    }
}

export function sortAttribute(data) {
    return {
        type: SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: SORT_DIRECTION,
        payload: data
    }
}

export function datePeriod(value) {
    return {
        type: DATE_PERIOD,
        payload: value
    }
}

export function datePeriodRange(from, to) {
    return {
        type: DATE_PERIOD_RANGE,
        payload: {
            from,
            to
        }
    }
}

export function setAccount(value) {
    return {
        type: SET_ACCOUNT,
        payload: value
    }
}

export function changeDownloadFormat(format) {
    return {
        type: CHANGE_DOWNLOAD_FORMAT,
        payload: format
    }
}

export function doFetchIfNeed(accounts) {
    return (dispatch, getState) => {
        const { activityLog } = getState().trade;
        if (activityLog.status === FETCHED
            || activityLog.status === FETCHING
        ) {
            return;
        }

        dispatch(doFetch(accounts));
    }
}

export function doInitFetch(accounts = []) {
    return (dispatch, getState) => {
        dispatch(initFetched());
        dispatch(doFetch(accounts));
    };
}

export function doFetch(accounts) {
    return (dispatch, getState) => {
        dispatch(fetching());
        transportTradeActions.doInit().then(() => {
            const { activityLog } = getState().trade;
            const datePeriod = Types.datePeriod(
                activityLog.datePeriod,
                activityLog.datePeriodFrom,
                activityLog.datePeriodTo,
            );

            if (activityLog.datePeriodFrom) {
                transport.requestActivityLogHistory({
                    from: +(activityLog.datePeriodFrom / 1000).toFixed(0),
                    to: +(activityLog.datePeriodTo / 1000).toFixed(0),
                    accounts: accounts,
                });

            } else {
                transport.requestActivityLogHistory({
                    from: +(datePeriod.from / 1000).toFixed(0),
                    to: +(datePeriod.to / 1000).toFixed(0),
                    accounts: accounts,
                });
            }
        });

    }
}

export function doClear() {
    return (dispatch, getState) => {
        dispatch(clear());
    }
}

export function doChangeDatePeriod(value, accounts) {
    return (dispatch, getState) => {
        const range = Types.datePeriod(value);

        dispatch(clear());
        dispatch(datePeriodRange(range.from, range.to));
        dispatch(datePeriod(value));
        dispatch(doFetchIfNeed(accounts));
    }
}

export function doChangePeriodRange(from, to, accounts, period = Types.DATE_PERIOD_CUSTOM) {
    return (dispatch, getState) => {
        dispatch(clear());
        dispatch(datePeriod(period));
        dispatch(datePeriodRange(from, to));
        dispatch(doFetchIfNeed(accounts));
    }
}
