import {store} from "../store";
import soundGroupService from '../services/sound-group-service';

export function getSoundAction(soundId) {
    const soundGroup = soundGroupService.getSoundGroup(soundId);
    const { settings } = store.getState().user;

    if (!settings.enableSounds || (soundGroup && !settings[soundGroup])) return {};

    return {
        sound: {
            play: soundId,
        }
    };
}
