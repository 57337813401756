/**
 * Created by ebondarev
 */
import BaseModel from './base';
import Types from '../classes/types';
import countryList from "../catalogs/countries";
import AppConfig from '../AppConfig';

export class UserInfoModel extends BaseModel {
    Id;
    Login;
    FirstName;
    MiddleName;
    LastName;

    _DateOfBirth;
    get DateOfBirth() {
        return this._DateOfBirth;
    }

    set DateOfBirth(value) {
        if (value !== Types.DEFAULT_BIRTH_DATE) {
            this._DateOfBirth = value;
            this._dateOfBirth = new Date(value);
        }
    }

    get isUserPassedRegistration() {
        let requiredFields = [];

        AppConfig.registration.requiredFieldsInSteps.forEach((fields) => requiredFields = requiredFields.concat(fields));

        return requiredFields.every((field) => {
            if (this.hasOwnProperty(field) || this[field]) return !!this[field];
            return false;
        });
    }

    PhoneNumber;
    PhoneVerified;
    VerifiedPhoneGroup;

    get isPhoneVerified() {
        let phoneVerified = this.PhoneVerified;
        if (this.PhoneVerified && this.PhoneVerified.slice(-1) === "Z" ) {
            phoneVerified = this.PhoneVerified.slice(0, -1);
        }

        return this.PhoneVerified && phoneVerified !== Types.DEFAULT_DATA_TIME;
    }

    get isNeedPhoneVerification() {
        if (!this.isPhoneVerified && this.VerifiedPhoneGroup === 1) {
            return true;
        }

        return false;
    }

    Email;
    UserType;
    PromoCode;
    Comment;
    Company;

    _country;
    get Country() {
        return this._country;
    }

    set Country(value) {
        if (value) {
            const country = countryList.find(o => (o.name === value || o.value === value));
            this._country = country ? country.value : '';
        }
    }

    Tax;
    State;
    City;
    ZIPCode;
    Address1;
    Address2;
    RegDate;
    Accounts;
    Files;
    EmailVerified;

    get isEmailVerified() {
        return this.EmailVerified && this.EmailVerified !== Types.DEFAULT_DATA_TIME;
    }

    VerifiedEmailGroup;
    VerifiedKYCGroup;
    RiskStatus;
    BankInfos;
    TwoFAStatus;
    TwoFAMailStatus;

    get isTwoFaGoogle() {
        return this.TwoFAStatus === Types.VERIFICATION_METHOD.GOOGLE;
    }
    get isTwoFaEmail() {
        return this.TwoFAMailStatus === Types.VERIFICATION_METHOD.EMAIL;
    }

    TwoFACode;
    AntiPhishingCode;
    Verified2FAGroup;

    _dateOfBirth;
    get dateOfBirth() {
        return this._dateOfBirth;
    }

    set dateOfBirth(value) {
        if (value) {
            this._dateOfBirth = value;
            this._DateOfBirth = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())).toISOString();
        } else {
            this._DateOfBirth = Types.DEFAULT_BIRTH_DATE;
            this._dateOfBirth = undefined;
        }
    }

    get fullName() {
        return [this.FirstName, this.LastName].filter(Boolean).join(' ');
    }

    get isNeedInit2FA() {
        if (this.Verified2FAGroup === 1) {
            return !this.TwoFAStatus;
        }

        return false;
    }
}