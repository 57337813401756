import BaseModel from './base';
import Types from '../classes/types';
import * as algorithms from '../common/components/stock-chart/node_modules/react-stockcharts/lib/indicator';

export class IndicatorModel extends BaseModel {

    static DEFAULT_COLOR = "#000";
    static DEFAULT_PERIOD = 1;

    _key = null;
    _calculator = null;
    visible = true; // visible on chart

    setKey(key) {
        this._key = key;
    }

    getKey() {
        return this._key;
    }

    setCalc(opts, calculatorFunction) {
        this._calculator = calculatorFunction()
            .options({...opts})
            .merge((d, c) => {
                d[this.getKey()] = c
            })
            .accessor(d => {
                if (d)
                    return d[this.getKey()];
                return undefined;
            });
    }

    setMACalculator() {
        let props = Object.keys(this.settings.visual).map(prop => prop);
        let stroke = this.settings.visual[props[0]].stroke;
        stroke = stroke ? stroke : IndicatorModel.DEFAULT_COLOR;

        const {period, appliedPrice} = this.settings.parameters;
        const calculatorFunction = algorithms[this.algorithm];

        if(this.type === 'KAMA'){
            const {fast_ma_period, slow_ma_period} = this.settings.parameters;
            this._calculator = calculatorFunction()
                .options({
                    windowSize: period,
                    fast_ma_period: fast_ma_period,
                    slow_ma_period: slow_ma_period,
                    sourcePath: appliedPrice
                })
                .skipUndefined(true)
                .merge((d, c) => {
                    d[this.getKey()] = c;
                })
                .accessor(d => d[this.getKey()])
                .stroke(stroke);
        } else if (this.type === 'MAMA'){
            const {fastLimit, slowLimit} = this.settings.parameters;
            this._calculator = calculatorFunction()
                .options({
                    windowSize: period,
                    fastLimit: fastLimit,
                    slowLimit: slowLimit,
                    sourcePath: appliedPrice
                })
                .skipUndefined(true)
                .merge((d, c) => {
                    d[this.getKey()] = c;
                })
                .accessor(d => {
                    if (d)
                        return d[this.getKey()];
                    return undefined;
                })
                .stroke(stroke);
        } else {
                this._calculator = calculatorFunction()
                    .options({
                        windowSize: period,
                        sourcePath: appliedPrice
                    })
                    .skipUndefined(true)
                    .merge((d, c) => {
                        d[this.getKey()] = c;
                    })
                    .accessor(d => {
                        if (d)
                            return d[this.getKey()];
                        return undefined;
                    })
                    .stroke(stroke);
        }
    }

    setVolumeCalculator() {
        let {period} = this.settings.parameters;
        let props = Object.keys(this.settings.visual).map(prop => prop);
        let stroke = this.settings.visual[props[0]].stroke;
        stroke = stroke ? stroke : IndicatorModel.DEFAULT_COLOR;
        period = period ? period : IndicatorModel.DEFAULT_PERIOD;

        const calculatorFunction = algorithms[this.algorithm];

        if(this.type === Types.INDICATOR_VOLUME) {
            this._calculator = calculatorFunction()
                .options({
                    windowSize: period,
                    movingAverageType: this.settings.parameters.movingAverageType
                })
                .merge((d, c) => {
                    d[this.getKey()] = c;
                })
                .accessor(d => {
                    if (d)
                        return d[this.getKey()];
                    return undefined;
                })
                .stroke(stroke);
        } else{
            this._calculator = calculatorFunction()
                .options({
                    windowSize: period
                })
                .merge((d, c) => {
                    d[this.getKey()] = c;
                })
                .accessor(d => {
                    if (d)
                        return d[this.getKey()];
                    return undefined;
                })
                .stroke(stroke);
        }
    }

    setTrendAndChannelCalculator() {
        const calculatorFunction = algorithms[this.algorithm];

        switch (this.type) {
            case Types.INDICATOR_ADX: {
                this.setCalc({
                    label: `${this.label} ${this.settings.parameters.comments}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            }
            case Types.INDICATOR_BB:
                this.setCalc({
                    label: `${this.label} ${this.settings.parameters.comments}`,
                    multiplier: this.settings.parameters.multiplier,
                    sourcePath: this.settings.parameters.appliedPrice,
                    movingAverageType: this.settings.parameters.movingAverageType,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_VIDYA:
                this.setCalc({
                    sourcePath: this.settings.parameters.appliedPrice,
                    movingAverageType: this.settings.parameters.movingAverageType,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_ENVELOPES:
                this.setCalc({
                    sourcePath: this.settings.parameters.appliedPrice,
                    basisPoints: this.settings.parameters.basisPoints,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_TEMA:
                this.setCalc({
                    sourcePath: this.settings.parameters.appliedPrice,
                    movingAverageType: this.settings.parameters.movingAverageType,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_STANDARD_DEVIATION:
                this.setCalc({
                    sourcePath: this.settings.parameters.appliedPrice,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_ADMIW:
                this.setCalc({
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_SAR:
                this.setCalc({
                    accelerationFactor: this.settings.parameters.accelerationFactor,
                    maxAccelerationFactor: this.settings.parameters.maxAccelerationFactor
                }, calculatorFunction);
                break;
            case Types.INDICATOR_IC:
                this.setCalc({
                    label: `${this.label} ${this.settings.parameters.comments}`,
                    tenkanPeriod: this.settings.parameters.tenkanPeriod,
                    kijunPeriod: this.settings.parameters.kijunPeriod,
                    senkouPeriod: this.settings.parameters.senkouPeriod
                }, calculatorFunction);
                break;
            default:
                return;
        }
    }

    setOscillatorCalculator() {
        const calculatorFunction = algorithms[this.algorithm];

        switch (this.type) {
            case Types.INDICATOR_CCI:
                this.setCalc({
                    label: `${this.shortLabel} ${this.settings.parameters.comments}`,
                    windowSize: this.settings.parameters.period,
                    sourcePath: this.settings.parameters.appliedPrice,
                    upborder: this.settings.parameters.topLevel,
                    bottomborder: this.settings.parameters.bottomLevel
                }, calculatorFunction);
                break;
            case Types.INDICATOR_MACD:
                this.setCalc({
                    label: `${this.Label} ${this.settings.parameters.comments}`,
                    fast: this.settings.parameters.fast,
                    slow: this.settings.parameters.slow,
                    signal: this.settings.parameters.signal
                }, calculatorFunction);
                break;
            case Types.INDICATOR_MOM:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period,
                    sourcePath: this.settings.parameters.appliedPrice
                }, calculatorFunction);
                break;
            case Types.INDICATOR_RSI:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_RVI:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_STO:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period,
                    kWindowSize: this.settings.parameters.periodK,
                    movingAverageType: this.settings.parameters.movingAverageType,
                    dWindowSize: this.settings.parameters.periodD
                }, calculatorFunction);
                break;
            case Types.INDICATOR_BEARPOW:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_BULLPOW:
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            case Types.INDICATOR_CHO: {
                this.setCalc({
                    label: `${this.Label}`,
                }, calculatorFunction);
                break;
            }
            case Types.INDICATOR_DEMARKER: {
                this.setCalc({
                    label: `${this.Label}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            }
            case Types.INDICATOR_FORCE_INDEX: {
                this.setCalc({
                    label: `${this.Label}`,
                }, calculatorFunction);
                break;
            }
            default:
                return;
        }
    }

    setBillWilliamsCalculator() {
        const calculatorFunction = algorithms[this.algorithm];

        switch (this.type) {
            case Types.INDICATOR_AC:
                this.setCalc({
                    label: `${this.shortLabel} ${this.settings.parameters.comments}`,
                    windowSize: this.settings.parameters.period,
                    sourcePath: this.settings.parameters.appliedPrice,
                    FastMAPeriod: this.settings.parameters.fast,
                    SlowMAPeriod: this.settings.parameters.slow
                }, calculatorFunction);
                break;
            case Types.INDICATOR_BWMFI:
                this.setCalc({
                    label: `${this.shortLabel} ${this.settings.parameters.comments}`,
                    sourcePath: this.settings.parameters.appliedPrice
                }, calculatorFunction);
                break;
            case Types.INDICATOR_WPR: {
                this.setCalc({
                    label: `${this.shortLabel}`,
                    windowSize: this.settings.parameters.period
                }, calculatorFunction);
                break;
            }
            default:
                return;
        }
    }

    setVolatilityCalculator() {
        const calculatorFunction = algorithms[this.algorithm];

        switch (this.type) {
            case Types.INDICATOR_ATR:
                this.setCalc({
                    label: `${this.shortLabel} ${this.settings.parameters.comments}`,
                    windowSize: this.settings.parameters.period,
                    movingAverageType: this.settings.parameters.movingAverageType
                }, calculatorFunction);
                break;
            default:
                return;
        }
    }

    setCalculator() {
        switch (this.group) {
            case Types.MOVING_AVERAGE_GROUP:
                this.setMACalculator();
                break;
            case Types.VOLUMES_GROUP:
                this.setVolumeCalculator();
                break;
            case Types.TREND_AND_CHANEL_GROUP:
                this.setTrendAndChannelCalculator();
                break;
            case Types.OSCILLATOR_GROUP:
                this.setOscillatorCalculator();
                break;
            case Types.BILL_WILLIAMS_GROUP:
                this.setBillWilliamsCalculator();
                break;
            case Types.VOLATILITY_GROUP:
                this.setVolatilityCalculator();
                break;
            default:
                this._calculator = null;
        }
    }

    constructor(intiData, key) {
        super();
        this.copyFrom(intiData);
        this.setKey(key);
        this.setCalculator();
    }
}
