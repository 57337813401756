import MessageBox from "../common/components/message-box/MessageBox";

import Types from "../classes/types";
import { getSoundAction } from "../utils/sound";

export const SHOW_WINDOW = 'MESSAGE_BOXES_SHOW_WINDOW';
export const CLOSE_WINDOW = 'MESSAGE_BOXES_CLOSE_WINDOW';

export function showWindow(payload) {
    return {
        type: SHOW_WINDOW,
        payload,
    }
}

export function closeWindow(payload) {
    return {
        type: CLOSE_WINDOW,
        payload: {
            index: payload.index,
        }
    }
}

export function showConfirm(payload) {
    const meta = getSoundAction(Types.EVENT_SOUND_NOTICE_DEFAULT);

    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_CONFIRM,
        meta
    })
}

export function showInfo(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_INFO,
    })
}

export function showPlatformNotification(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_PLATFORM_NOTIFICATION,
    })
}

export function showInfo2Ok(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_INFO_2_OK,
    })
}

export function showSuccess(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_SUCCESS,
    })
}

export function showWarning(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_WARNING,
    })
}

export function showInfoBox(payload) {
    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_INFOBOX,
    })
}
export function showError(payload) {
    const meta = getSoundAction(Types.EVENT_SOUND_NOTICE_MINOR);

    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_ERROR,
        meta: meta
    })
}

export function showErrorWithProceed(payload) {
    const meta = getSoundAction(Types.EVENT_SOUND_NOTICE_MINOR);

    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_ERROR_WITH_PROCEED,
        meta
    })
}

export function showErrorWithActionOkButton(payload) {
    const meta = getSoundAction(Types.EVENT_SOUND_NOTICE_MINOR);

    return showWindow({
        iconName: '',
        ...payload,
        type: MessageBox.TYPE_ERROR,
        meta
    })
}

export function doShowError(payload) {
    return (dispatch, getState) => {
        const settings = getState().user.settings;

        if (!settings.confirmRejectionReports) return;

        return dispatch(showError(payload));
    };
}

export function doShowErrorWithProceed(payload) {
    return (dispatch, getState) => {
        const settings = getState().user.settings;

        if (!settings.confirmRejectionReports) return;

        return dispatch(showErrorWithProceed(payload));
    };
}

export function doShowErrorWithActionOkButton(payload) {
    return (dispatch, getState) => {
        const settings = getState().user.settings;

        if (!settings.confirmRejectionReports) return;

        return dispatch(showErrorWithActionOkButton(payload));
    };
}

