export const COMMISSION_PAYMENTS_NOT_FETCHED = 'COMMISSION_PAYMENTS_NOT_FETCHED';
export const COMMISSION_PAYMENTS_FETCHING = 'COMMISSION_PAYMENTS_FETCHING';
export const COMMISSION_PAYMENTS_FETCHED = 'COMMISSION_PAYMENTS_FETCHED';

export function fetching() {
    return {
        type: COMMISSION_PAYMENTS_FETCHING,
    }
}

export function fetched(data) {
    return {
        type: COMMISSION_PAYMENTS_FETCHED,
        payload: data
    }
}