/**
 * Created by ebondarev
 */
import BaseModel from './base';
import Types from '../classes/types';
import { lang } from '../services'
import {store} from "../store";

export class OrderModel extends BaseModel {

    static NEW_ADDED_VALID_TIME = 5000; // ms

    OrderNumber;
    Name;
    AccountNumber;
    Account2;
    SymbolId;
    Ticker;
    Amount;
    UnitVolume;
    Price;
    MarketPrice;
    SLOrderNumber;
    SLEnteredUnit;
    SLEnteredVolume;
    SLPrice;
    SLTPBasePrice;
    TPOrderNumber;
    TPEnteredUnit;
    TPEnteredVolume;
    TPPrice;
    BoundTo;
    _OrderType;
     
    set dateTime(value) {
        this._dateTime = value;
    }

    get dateTime() {
        return new Date(this._dateTime);
    }

    set OrderType(value) {
        this._OrderType = value;
        this.orderTypeText = Types.orderType(value);
    }

    get OrderType() {
        return this._OrderType;
    }

    TimeInForce;
    IsActive;
    _BuySell;
    set BuySell(value) {
        this._BuySell = value;
        this.sideText = Types.side(value);
    }

    get BuySell() {
        return this._BuySell;
    }

    Comment;
    FullName;
    _UTCDateTime;
    set UTCDateTime(value) {
        this._UTCDateTime = value;
        this._dateTime = new Date(value * 1000);
    }

    get UTCDateTime() {
        return this._UTCDateTime;
    }

    get usedMargin() {
        return this.getMargin()
    }

    get leverage() {
        const { accounts, tickers } = store.getState().trade;
        const account = accounts.list.get(this.AccountNumber);
        const tickerLeverage = tickers.getLeverage(this.SymbolId);

        return tickerLeverage > account.InitLeverage ? tickerLeverage : account.InitLeverage;
    }

    get quoteInvested() {
        return this.UnitVolume * this.Price * this.leverage;
    }

    get SLPercent() {
        return (this.possibleLoss * 100) / this.quoteInvested;
    }

    get TPPercent() {
        return (this.possibleProfit * 100) / this.quoteInvested;
    }

    get possibleLoss() {
        return this.SLPrice > 0 ? this.differentSL * this.UnitVolume : 0;
    }

    get possibleProfit() {
        return this.TPPrice > 0 ? this.differentTP * this.UnitVolume : 0;
    }

    get differentSL() {
        const stopLoss = isFinite(+this.SLPrice) ? +this.SLPrice : 0;

        return this.BuySell === Types.SIDE_BUY ? (this.Price - stopLoss) : (stopLoss - this.Price);
    }

    get differentTP() {
        const takeProfit = isFinite(+this.TPPrice) ? +this.TPPrice : 0;
        return this.BuySell === Types.SIDE_BUY ? takeProfit - this.Price : this.Price - takeProfit;
    }

    get isMargin() {
        return this.TradeMode === Types.INSTRUMENT_TRADE_MODE_MARGIN;
    }

    get isExchange() {
        return this.TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE;
    }

    //addition
    accountCurrency;
    exp1;
    exp2;
    bidDiff;
    precision = 5;
    distance = 0;
    unitInLots = 0;
    _dateTime;
    sideText = '';
    orderTypeText = '';
    lastUpdateAt = Date.now();

    //addition state
    isCanceling = false;
    _isAdded = false;
    _addedEndTimestamp = 0;

    get isAdded() {
        return this._isAdded;
    }

    set isAdded(value) {
        this._isAdded = value;
        if (value) {
            this._addedEndTimestamp = Date.now() + OrderModel.NEW_ADDED_VALID_TIME;
        }
    }

    get isNewAdded() {
        if (this.isAdded && this._addedEndTimestamp && this._addedEndTimestamp > Date.now()) {
            return true;
        } else if (this._addedEndTimestamp) {
            this._addedEndTimestamp = 0;
        }

        return false;
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);

        if (this.Price && this.MarketPrice) {
            this.distance = lang.numberDiff(this.Price, this.MarketPrice, this.precision);
        }

        if(isFinite(+this.TPPrice) && !this.TPEnteredUnit) {
            this.TPEnteredUnit = Types.SLTP_UNIT_PRICE;
            this.TPEnteredVolume = this.TPPrice;
        }

        if(isFinite(+this.SLPrice) && !this.SLEnteredUnit) {
            this.SLEnteredUnit = Types.SLTP_UNIT_PRICE;
            this.SLEnteredVolume = this.SLPrice;
        }
    }

    getMargin = () => {
        const tickersStore = store.getState().trade.tickers;
        const ticker = tickersStore.list.get(this.SymbolId);
        let sideExp = '';
        let orderVolume = 0;

        if (this.Side === 'Sell') {
            sideExp = ticker.Exp1;
            orderVolume = this.UnitVolume;
        } else {
            sideExp = ticker.Exp2;
            orderVolume = this.UnitVolume * this.Price;
        }

        let amount = orderVolume;

        if (sideExp !== this.accountCurrency) {
            amount *= tickersStore.getCrossRate(sideExp, this.accountCurrency);
        }

        return amount * this.leverage;
    }

    updateAttributesFromQuoteOrTicker(quote) {
        this.lastUpdateAt = Date.now();
        this.MarketPrice = this.BuySell === Types.SIDE_BUY ? quote.Ask : quote.Bid;

        this.distance = lang.numberDiff(this.Price, this.MarketPrice, this.precision);
    }
}