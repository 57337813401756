import { Record } from 'immutable';
import * as ScreenSharingActions from '../../actions/sharing/screen';

class ScreenStore extends Record({
    isSharing: false,
    connectionId: null,
    connectedUserName: null,
}){};

const initialState = new ScreenStore();

export default function store(state = initialState, action) {
    switch (action.type) {
        case ScreenSharingActions.REQUEST:
            const { connectionId, connectedUserName } = action.payload;

            return state.merge({
                connectionId,
                connectedUserName
            });

        case ScreenSharingActions.START:
            return state.set('isSharing', true);

        case ScreenSharingActions.END:
            return new ScreenStore();

        default:
            return state;
    }
}