/**
 * Created by Evgeniy Bondarev
 */
import { reportApi } from "../../services";
import { date } from "../../utils";

export const NOT_FETCHED = 'INVESTING_PERFORMANCE_CHART_NOT_FETCHED';
export const FETCHING = 'INVESTING_PERFORMANCE_CHART_FETCHING';
export const FETCHED = 'INVESTING_PERFORMANCE_CHART_FETCHED';


function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}


export function doFetch(accounts = []) {
    return (dispatch, getState) => {

        const endDate = new Date();
        endDate.setUTCHours(0);
        endDate.setUTCMinutes(0);
        endDate.setUTCSeconds(0);
        endDate.setUTCDate(endDate.getUTCDate() + 1);

        dispatch(fetching());

        return reportApi.get('', {
            data: {
                report: "AccountPerformanceChart",
                begin: "2017-01-01 00:00:00",
                end: date.toServerDate(endDate),
                accounts: accounts.join(','),
                format: "json"
            }
        }).then((res) => {
            const fetchedDataUnique = {};

            res.data.forEach((dataItem) => {
                if (!fetchedDataUnique[dataItem.ACCOUNT_ID]) {
                    fetchedDataUnique[dataItem.ACCOUNT_ID] = [];
                }

                fetchedDataUnique[dataItem.ACCOUNT_ID].push({
                    AccountId: dataItem.ACCOUNT_ID,
                    Balance: dataItem.BALANCE,
                    BalanceGain: dataItem.BALANCE_GAIN,
                    DateTimeTicks: dataItem.DATETIME,
                    Drawdown: dataItem.DRAWDOWN,
                    Equity: dataItem.EQUITY,
                    EquityGain: dataItem.EQUITY_GAIN,
                    Points: dataItem.POINTS,
                    Profit: dataItem.PROFIT
                });
            });
            dispatch(fetched(fetchedDataUnique));

            return fetchedDataUnique;
        });
    }
}

export function doFetchIfNeed(accounts = []) {
    return (dispatch, getState) => {
        const status = getState().investing.subscription.status;
        if (status === FETCHING || status === FETCHED) {
            return;
        }

        dispatch(doFetch(accounts));
    }
}