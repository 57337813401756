import { getPlatformNotificationsListToShowModal } from '../../selectors/user/platformNotifications';
import * as messageBoxAction from '../message-box';
import { reportApi, transport } from '../../services';
import Types from '../../classes/types';
import { date } from '../../utils';
import AppConfig from '../../AppConfig';

export const NOT_FETCHED = 'PLATFORM_NOTIFICATIONS_NOT_FETCHED';
export const FETCHING = 'PLATFORM_NOTIFICATIONS_FETCHING';
export const FETCHED = 'PLATFORM_NOTIFICATIONS_FETCHED';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function showPlatformNotificationsModals(skipIds = []) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState();
            const listToShownModals = getPlatformNotificationsListToShowModal(state);
            listToShownModals.forEach(n => {
                if (!skipIds.includes(n.NOTIFICATION_ID)) {
                    dispatch(messageBoxAction.showPlatformNotification({
                        title: n.HEADER,
                        message: n.NOTIFICATION_TEXT,
                        creationDate: date.getTimeFromTick(n.CREATION_DATE),
                        closeWithEsc: true,
                        closeCallback: () => {
                            dispatch(markPlatformNotificationAsRead({Id: n.NOTIFICATION_ID, STATUS: 1}));
                        }
                    }))
                }
            })
            resolve();
        })
    }
}

export function doFetchNotifications(ids, period, begin, end) {
    return (dispatch, getState) => {
        const datePeriod = Types.datePeriod(period, begin, end);
        return reportApi.get('', {
            data: {
                report: Types.REPORTS_ENDPOINTS['notifications'],
                ids: ids,
                begin: date.toServerDate(datePeriod.from),
                end: date.toServerDate(datePeriod.to),
                format: 'json',
            }
        }).then(res => {
            const data = res ? res.data || [] : [];
            dispatch(fetched(data));
        });
    }
}

export function globalFetchRequestNotifications(userId) {
    if (!AppConfig.settingsGeneral.platformNotifications) return;

    return (dispatch, getState) => {
        const {period, from, to} = getState().trade.filters.platformNotificationsFilters;
        dispatch(doFetchNotifications(userId, period, from, to));
    };
}

export function onNotificationAdd(data) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState();
            const platformNotifications = state.user.platformNotifications.list.toJS();
            platformNotifications.push({
                HEADER: data.Header,
                IS_MODAL: data.IsModal,
                STATUS: data.Status,
                NOTIFICATION_TEXT: data.Text,
                NOTIFICATION_ID: data.ID,
                CREATION_DATE: (data.CreationDate * 10000000) + Types.TICKS_TO_UNIX_EPOCH
            });

            const skipIds = platformNotifications.slice(0, -1).map(n => n.NOTIFICATION_ID);
            dispatch(fetched(platformNotifications));
            dispatch(showPlatformNotificationsModals(skipIds));
            resolve();
        });
    }
}

export function markPlatformNotificationAsRead(data) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (transport) {
                transport.updateNotification(data);
                const state = getState();
                const platformNotifications = state.user.platformNotifications.list.toJS();
                const notificationIndex = platformNotifications.findIndex(item => item.NOTIFICATION_ID === data.Id);

                platformNotifications[notificationIndex].STATUS = data.STATUS;
                
                dispatch(fetched(platformNotifications));
                resolve();
            } else {
                reject();
            }
        });
    }
}