/**
 * Created by ebondarev
 */
import md5 from 'md5';

import { lang, transport, transportTracking, transportUser, logger, wdApi, dispatcher, dispatchServers } from '../../services';
import * as appActions from '../app';
import * as userActions from './user';
import * as userInfoActions from './info';
import * as platformNotificationActions from '../user/platformNotifications';
import * as transportUserActions from '../transports/user';
import * as transportTradeActions from '../transports/trade';
import * as transportTrackingActions from '../transports/tracking';
import AppConfig from '../../AppConfig';
import Transport from '../../classes/transport';
import Types from '../../classes/types';
import Register from '../../classes/register';
import authService from '../../services/auth-service';
import { history, store } from '../../store';

//авторизация пользователя через форму
export const LOG_IN_REQUEST = 'USER_AUTH_LOG_IN_REQUEST';
export const LOG_IN_ERROR = 'USER_AUTH_LOG_IN_ERROR';
export const LOG_IN_SUCCESS = 'USER_AUTH_LOG_IN_SUCCESS';
// подключение по 2-му сокету
export const LOG_IN_SOCKET_USER_SUCCESS = 'USER_AUTH_LOG_IN_SOCKET_USER_SUCCESS';
export const LOGIN_AFTER_REGISTRATION = 'USER_AUTH_LOGIN_AFTER_REGISTRATION';

export const SIGN_UP = 'USER_AUTH_SIGN_UP';
export const USER_IS_FORBIDDEN = 'USER_IS_FORBIDDEN';
export const INITIAL_STATE = 'INITIAL_STATE';
export const USER_EMAIL_IS_NOT_VERIFIED = 'USER_EMAIL_IS_NOT_VERIFIED';
export const USER_PHONE_IS_NOT_VERIFIED = 'USER_PHONE_IS_NOT_VERIFIED';
export const IS_USER_COMPLETE_REGISTRATION_CLICK = "IS_USER_COMPLETE_REGISTRATION_CLICK";

const forbiddenEmailsHash = ['edefb1af4daf6c5facb8aba92e13f4b0', 'bc6113cfabb292c61771a37d10addb69',
    '92cfba441316fe5c75eac104bb2887ef', '434990c8a25d2be94863561ae98bd682',
    'e1e3a992fb80363e50f7763ff35ec2d1', '27183aacdcb689968f322032550ad33d',
    'ece6ba6c4fa12d3521456a6b4d63af96', '6e1a03da0a9d5a79324dca90ce518c81',
    '798ad51ddf6bccd866be0dbbf76e2cf0', '05e63e1868a5820921f8289ff471815e',
    'f63dab22c2eb85ecb6adf00af35cd688', '029ffd04dce2cdff4aa08e05191fbb1a',
    'f0da50a357e0c7a5864a0f025f8dc7f5', '424c8f8ad99e7bff267b6671c94c7f34',
    'c4f5dc8f7bd90494641b3e31be908ba7', '3adc6f3f56d3250a30a0aabbf1ded057',
    '8770e8336acc9ec0e36d188943790141', 'ece6ba6c4fa12d3521456a6b4d63af96',
    '02b35c6e600e58b5af04369134347196', '84fd4ab3ffa43ca14fa420ec658e2b78',
    '6343299a9c2d08fdcaf47267d23bf7b9', '0938ec46723c6c2a872605d6a4bae948',
    '282ccfad05cfb14279250072b7dce52b', 'a655897c7fc0590c2e7b5b7e51c1f457',
    '6d706aa71b74b94cca09dcd1270107ad', 'e44dd113138a9fb1f876473b5519d6c2',
    'c4f5dc8f7bd90494641b3e31be908ba7'];

export function userPhoneIsNotVerified() {
    return {
        type: USER_PHONE_IS_NOT_VERIFIED
    }
}

export function userEmailIsNotVerified(email) {
    return {
        type: USER_EMAIL_IS_NOT_VERIFIED,
        payload: email
    }
}

export function loginError(error) {
    return {
        type: LOG_IN_ERROR,
        payload: error
    }
}

export function initialState() {
    return {
        type: INITIAL_STATE
    }
}

export function userIsForbidden(error) {
    return {
        type: USER_IS_FORBIDDEN,
        payload: error
    }
}

export function loginRequest() {
    return {
        type: LOG_IN_REQUEST,
    }
}

export function loginSuccess({ SocketId, ServerTime }) {
    return {
        type: LOG_IN_SUCCESS,
        payload: {
            SocketId,
            ServerTime,
        }
    }
}

export function loginAfterRegistration(value) {
    return {
        type: LOGIN_AFTER_REGISTRATION,
        payload: value
    }
}

export function loginSocketUserSuccess({ SocketId }) {
    return {
        type: LOG_IN_SOCKET_USER_SUCCESS,
        payload: {
            SocketId
        }
    }
}

export function doUserCompleteRegistration(value) {
    return {
        type: IS_USER_COMPLETE_REGISTRATION_CLICK,
        payload:value
    }
}

export function doLogIn({ login, password, apiKey, remember, twoFACode }) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!apiKey && forbiddenEmailsHash.indexOf(md5(login.toLowerCase())) >= 0) {
                const msg = 'Login prohibited! Use the desktop terminal for this';
                dispatch(loginError(msg));
                return;
            }

            dispatch(loginRequest());

            const error = (msg = 'Server connection problem') => {
                reject(lang.t(msg));
                // дать отработать пердыдущему dispatch для визуального отображения прогресса
                window.setTimeout(() => {
                    dispatch(loginError(msg));
                });
            };

            const invalidAuthData = () => {
                const msg = lang.t('Invalid Login or password, try again');
                reject(msg);
                dispatch(loginError(msg));
            };

            const run = () => {
                if (!transport.connected) {
                    error();
                    return;
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    dispatch(loginError(msg));
                    reject(msg);
                }, 15000);

                transport.requestLogin({
                    login,
                    password,
                    lang: getState().user.settings.language,
                    twoFACode
                });

                transport.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    if (data.Login && data.Login === login) {

                        dispatcher.saveServers();

                        dispatch(loginSuccess({ ServerTime: data.ServerTime, SocketId: data.SocketId }));

                        const storePassword = getState().user.model.Password;
                        if (storePassword) {
                            password = storePassword;
                        }

                        dispatch(userActions.update({
                            Login: login,
                            Password: password,
                            Remember: remember,
                            Timestamp: Date.now()
                        }));
                        if (AppConfig.features.isEnableQuoteWSChanel) {
                            transportUserActions.doInit()
                            .then((transportUser) => {
                                if (transportUser.logInStatus === Transport.NOT_LOG_IN) {
                                    transportUser.requestLogin({
                                        login,
                                        password,
                                        lang: getState().user.settings.language,
                                        channel: Transport.CHANNEL_QUOTE
                                    });
                                    transportUser.onLogin = (data) => {
                                        resolve(data);
                                    }
                                } else {
                                    resolve(data);
                                }
                            })
                            .catch((e) => {
                                logger.error('Error connect to data socket', e);
                            });
                        } else {
                            resolve(data);
                        }

                        if (AppConfig.features.isEnableTracking) {
                            transportTrackingActions.doInit()
                                .then((transportTracking) => {
                                    if (transportTracking.logInStatus === Transport.NOT_LOG_IN) {
                                        transportTracking.requestLogin({
                                            login,
                                            password,
                                            lang: getState().user.settings.language,
                                            channel: Transport.CHANNEL_TRACKING,
                                            tracking: localStorage.getItem('tracking')
                                        });
                                    }
                                })
                                .catch((e) => {
                                    logger.error('Error connect to data socket', e);
                                });
                        }

                        if (getState().user.settings.logOutTime !== 0) {
                            dispatch(appActions.setRunningAutoLogOutTimer(true));
                        }

                        // send data for json token
                        if (apiKey) {
                            wdApi.setAuthData({
                                apiKey,
                            });
                        } else {
                            wdApi.setAuthData({
                                Login: login,
                                Password: md5(password),
                                PasswordHashed: true,
                            });
                        }

                        wdApi.getTokenAndReturnUserId().then(({ userId }) => {
                            if (userId) {
                                dispatch(userActions.update({ Id: userId }));
                                dispatch(userInfoActions.doFetch(userId, true));
                                dispatch(platformNotificationActions.globalFetchRequestNotifications(userId));
                            }
                        });
                    } else {
                        if (AppConfig.features.isEnabledNewAuth && data.Code && data.Code.toString() === Types.REJECT_LOGIN_NOT_FOUND.toString()) {
                            const msg = lang.t('Dear Madam/Dear Sir, Thank you for your application. Unfortunately, we are unable to process your application further and must decline your request accordingly. Please contact us if you have any further questions.');
                            reject(msg);
                            dispatch(userIsForbidden(msg));
                        } else if (AppConfig.features.isEnabledNewAuth && data.Code && data.Code.toString() === Types.REJECT_LOGIN_VERIFYEMAIL.toString()) {
                            reject('User email is not verified!');
                            dispatch(userEmailIsNotVerified(login));
                        } else {
                            invalidAuthData();
                        }
                    }
                };
                transport.onRejectLogin = rej => {
                    clearTimeout(timeOutId);
                    // Temporary get code from Reason while server send reject with empty Code
                    const errorCode = rej.Code || rej.Reason.substr(0, 4);

                    validateError({
                        errorCode, 
                        dispatch, 
                        reject, 
                        resolve,
                        login, 
                        password, 
                        remember,
                    });
                };
            };

            dispatchServers({login, apiKey}).then(() => {
                transportTradeActions.doInit().then(run, error).catch(err => {
                    if (err === 'TRANSPORT_ERROR') {
                        error();
                    } else {
                        invalidAuthData();
                    }
                });
            }).catch(() => {
                error('User is not found');
            });
        });
    }
}

export function doLogIn2({login, password, apiKey, remember, googleTwoFACode, emailTwoFACode}) { // copied and updated from old doLogin
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!apiKey && forbiddenEmailsHash.indexOf(md5(login.toLowerCase())) >= 0) {
                const msg = 'Login prohibited! Use the desktop terminal for this';
                dispatch(loginError(msg));
                return;
            }

            dispatch(loginRequest());
            authService.useRemember(remember);

            const error = (msg = 'Server connection problem') => {
                reject(lang.t(msg));
                // дать отработать пердыдущему dispatch для визуального отображения прогресса
                window.setTimeout(() => {
                    dispatch(loginError(msg));
                });
            };

            const invalidAuthData = () => {
                const msg = lang.t('Invalid Login or password, try again');
                reject(msg);
                dispatch(loginError(msg));
            };

            const run = () => {
                if (!transport.connected) {
                    error();
                    return;
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    dispatch(loginError(msg));
                    reject(msg);
                }, 15000);

                transport.requestAuth2({
                    token: authService.getToken(),
                    lang: getState().user.settings.language,
                    twoFACode: googleTwoFACode
                });
                transport.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    dispatcher.saveServers();

                    dispatch(loginSuccess({ ServerTime: data.ServerTime, SocketId: data.SocketId }));
                    dispatch(userActions.update({
                        Login: login,
                        Password: remember ? password : '',
                        EnteredPassword: password,
                        Remember: remember,
                        Timestamp: Date.now()
                    }));

                    if (getState().user.settings.logOutTime !== 0) {
                        dispatch(appActions.setRunningAutoLogOutTimer(true));
                    }

                    if (AppConfig.features.isEnableQuoteWSChanel) {
                        transportUserActions.doInit()
                        .then((transportUser) => {
                            if (transportUser.logInStatus === Transport.NOT_LOG_IN) {
                                transportUser.requestAuth2({
                                    token: authService.getToken(),
                                    lang: getState().user.settings.language,
                                    channel: Transport.CHANNEL_QUOTE
                                });
                                transportUser.onLogin = (data) => {
                                    resolve(data);
                                }
                            } else {
                                resolve(data);
                            }
                        })
                        .catch((e) => {
                            logger.error('Error connect to data socket', e);
                        });
                    } else {
                        resolve(data);
                    }

                    transportTrackingActions.doInit()
                        .then((transportTracking) => {
                            if (transportTracking.logInStatus === Transport.NOT_LOG_IN) {
                                transportTracking.requestAuth2({
                                    token: authService.getToken(),
                                    lang: getState().user.settings.language,
                                    channel: Transport.CHANNEL_TRACKING,
                                    tracking: localStorage.getItem('tracking')
                                });
                            }
                        })
                        .catch((e) => {
                            logger.error('Error connect to data socket', e);
                        });
                };
                transport.onRejectLogin = (rej) => {
                    clearTimeout(timeOutId);
                    // Temporary get code from Reason while server send reject with empty Code
                    const errorCode = rej.Code || rej.Reason.substr(0, 4);

                    validateError({ 
                        errorCode,
                        dispatch,
                        reject,
                        resolve,
                        login,
                        password,
                        remember,
                        status: 200,
                    });
                };
            };

            dispatchServers({login, apiKey}).then(() => {
                if (apiKey) {
                    wdApi.setAuthData({
                        apiKey,
                    });
                } else {
                    wdApi.setAuthData({
                        Login: login,
                        Password: md5(password),
                        PasswordHashed: true,
                        TwoFaCode: googleTwoFACode,
                        EmailCode: emailTwoFACode,
                    });
                }

                wdApi.getTokenAndReturnUserId().then((res) => {
                    const { userId, token, expiredIn, error: errorCode, status } = res;
                    if (userId) {
                        authService.saveToken(token, expiredIn);
                        dispatch(userActions.update({ Id: userId }));
                        dispatch(userInfoActions.doFetch(userId, true));
                        transportTradeActions.doInit().then(run, error);
                        dispatch(platformNotificationActions.globalFetchRequestNotifications(userId));
                    } else {
                        validateError({
                            errorCode, 
                            dispatch, 
                            reject, 
                            resolve,
                            login, 
                            password, 
                            remember, 
                            status
                        });
                    }
                }).catch(err => {
                    if (err === 'TRANSPORT_ERROR') {
                        error();
                    } else if (err && err.status === Types.HTTP_CODE_FORBIDDEN) {
                        validateError({
                            errorCode: null, 
                            dispatch, 
                            reject, 
                            resolve,
                            login, 
                            password, 
                            remember, 
                            status: err.status,
                        });
                    } else {
                        invalidAuthData();
                    }
                });
            }).catch(() => {
                error('User is not found');
            });
        });
    }
}

export function doAuth2() { // copied and updated from old doLogin
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(loginRequest());

            const error = (msg = 'Server connection problem') => {
                reject(lang.t(msg));
                // дать отработать пердыдущему dispatch для визуального отображения прогресса
                window.setTimeout(() => {
                    dispatch(loginError(msg));
                });
            };

            const invalidAuthData = () => {
                const msg = lang.t('Invalid Login or password, try again');
                reject(msg);
                authService.removeData();
                dispatch(loginError(msg));
            };

            const run = (login) => {
                if (!transport.connected) {
                    error();
                    return;
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    dispatch(loginError(msg));
                    reject(msg);
                }, 15000);

                transport.requestAuth2({
                    token: authService.getToken(),
                    lang: getState().user.settings.language,
                });
                transport.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    dispatcher.saveServers();

                    dispatch(loginSuccess({ ServerTime: data.ServerTime, SocketId: data.SocketId }));
                    dispatch(userActions.update({
                        Login: login,
                        // Password: password,
                        Remember: authService.isRemembered,
                        Timestamp: Date.now()
                    }));

                    if (getState().user.settings.logOutTime !== 0) {
                        dispatch(appActions.setRunningAutoLogOutTimer(true));
                    }

                    if (AppConfig.features.isEnableQuoteWSChanel) {
                        transportUserActions.doInit()
                        .then((transportUser) => {
                            if (transportUser.logInStatus === Transport.NOT_LOG_IN) {
                                transportUser.requestAuth2({
                                    token: authService.getToken(),
                                    lang: getState().user.settings.language,
                                    channel: Transport.CHANNEL_QUOTE
                                });
                                transportUser.onLogin = (data) => {
                                    resolve(data);
                                }
                            } else {
                                resolve(data);
                            }
                        })
                        .catch((e) => {
                            logger.error('Error connect to data socket', e);
                        });
                    } else {
                        resolve(data);
                    }

                    transportTrackingActions.doInit()
                        .then((transportTracking) => {
                            if (transportTracking.logInStatus === Transport.NOT_LOG_IN) {
                                transportTracking.requestAuth2({
                                    token: authService.getToken(),
                                    lang: getState().user.settings.language,
                                    channel: Transport.CHANNEL_TRACKING,
                                    tracking: localStorage.getItem('tracking')
                                });
                            }
                        })
                        .catch((e) => {
                            logger.error('Error connect to data socket', e);
                        });
                };
                transport.onRejectLogin = (rej) => {
                    clearTimeout(timeOutId);
                    // Temporary get code from Reason while server send reject with empty Code
                    const errorCode = rej.Code || rej.Reason.substr(0, 4);

                    validateError({
                        errorCode, 
                        dispatch, 
                        reject, 
                        resolve,
                        login, 
                        password: '', 
                        remember: null,
                        status: 200,
                    });
                };
            };

            const oldToken = authService.getToken();

            dispatchServers({isUseWss: true, token: oldToken}).then(() => {
                wdApi.updateToken(oldToken).then(({ userId, token, expiredIn }) => {
                    if (userId) {
                        //authService.saveToken(token, expiredIn);
                        dispatch(userActions.update({ Id: userId }));
                        dispatch(platformNotificationActions.globalFetchRequestNotifications(userId));
                        dispatch(userInfoActions.doFetch(userId, true)).then((data) => {
                            if(data.Login){
                                transportTradeActions.doInit().then(() => run(data.Login), error);
                            } else {
                                throw new Error('No email in server response');
                            }
                        });
                    } else {
                        throw new Error('No user id in server response');
                    }
                }).catch(err => {
                    if (err === 'TRANSPORT_ERROR') {
                        error();
                    } else if (err && err.status === Types.HTTP_CODE_FORBIDDEN) {
                        validateError({ 
                            code: null, 
                            dispatch, 
                            reject,
                            resolve, 
                            login: null, 
                            password: null, 
                            remember: null,
                            status: err.status, 
                        });
                    } else {
                        invalidAuthData();
                    }
                });
            }).catch(() => {
                error('User is not found');
            });
        })
    }
}

export function doAutoAuth2(token) {
    return (dispatch, getState) => {
        const genExpireTime = new Date().getTime() + 60000;
        authService.saveToken(token, genExpireTime, false);

        return dispatch(doAuth2());
    }
}

export function doReLogIn() {
    return (dispatch, getState) => {
        return new Promise(() => {
            const authUser = getState().user.auth;

            if (AppConfig.features.isEnabledAuth2) {
                return dispatch(doAuth2());
            } else {
                return dispatch(doLogIn({
                    login: authUser.Login,
                    password: authUser.Password,
                    remember: authUser.Remember,
                }));
            }
        })
    }
}

export function decodeLoginToken(token) {
    return wdApi.post('DecodeAutoLoginToken', {
        "AutoLoginToken": token
    }).then((response) => {
        if (response.data && response.data.Result) {
            const authData = { login: response.data.Result.Login, password: response.data.Result.Password, remember: true };
            if (authData) {
                localStorage.setItem("auth_info", JSON.stringify(authData));
            }
        }
    });
}

export function doEnableTwoFA(userId, verificationMethod = Types.VERIFICATION_METHOD.GOOGLE) {
    return (dispatch, getState) => {
        return wdApi.post('Enable2FA', {
            "UserId": userId,
            "VerificationMethod": verificationMethod,
            "Language": getState().user.settings.language
        });
    }
}

export function doAcceptTwoFA(userId, code, verificationMethod = Types.VERIFICATION_METHOD.GOOGLE) {
    return (dispatch, getState) => {
        return wdApi.post('Accept2FA', {
            "UserId": userId,
            "Code": code,
            "VerificationMethod": verificationMethod
        });
    }
}

export function doDisableTwoFA(userId, code, verificationMethod = Types.VERIFICATION_METHOD.GOOGLE) {
    return (dispatch, getState) => {
        return wdApi.post('Disable2FA', {
            "UserId": userId,
            "Code": code,
            "VerificationMethod": verificationMethod
        });
    }
}

export function doNotAuthUserCallback({data}) {
    return (dispatch, getState) => {
        data = JSON.parse(data);
        return wdApi
            .post('CreateGuestToken', {Email: data.email})
            .then((response) =>
                dispatch(
                    userActions.doSendUserCallBack({
                        login: data.email,
                        Key: response.data.Result,
                        data: JSON.stringify(data),
                    })
                )
            );
    };
}

export function reconnectWss() {
    transport.reconnectStart();
    transportUser.reconnectStart();
    if (transportTracking && transportTracking.reconnectStart) transportTracking.reconnectStart();
}

function validateError({ errorCode, dispatch, reject, resolve, login, password, remember, status = 200 }) {
    let msg;

    // logout when rejectLogin received after successful login
    if(store.getState().user.auth.isLogged && +errorCode !== Types.REJECT_LOGIN_VERIFYPHONE) {
        dispatch(userActions.doLogOut());
    }

    switch (+status) {
        case Types.HTTP_CODE_FORBIDDEN:
            msg = Types.REJECT_BY_COUNTRY;
            reject(msg);
            dispatch(loginError(msg));
            break;
        case Types.HTTP_CODE_UNAUTHORIZED:
            msg = lang.t("Check if you entered your username and password correctly and try again.");
            reject(msg);
            dispatch(loginError(msg));
            break;
        case Types.HTTP_CODE_NOCONTENT:
        default:
    }

    switch (+errorCode) {
        case Types.REJECT_LOGIN_2FA_INCORRECT_EMAIL_CODE:
        case Types.REJECT_LOGIN_2FA_NOT_ENABLED:
        case Types.REJECT_LOGIN_2FA_ALREADY_ENABLED:
        case Types.REJECT_LOGIN_2FA_INCORRECT_TOKEN:
        case Types.REJECT_LOGIN_2FA_INCORRECT_GOOGLE_CODE:
        case Types.REJECT_LOGIN_2FA_INCORRECT_CODE:
        case Types.REJECT_LOGIN_2FA_MISSING_EMAIL_CODE:
            msg = "2FA";
            reject(msg);
            msg += " " + errorCode;
            dispatch(loginError(msg));
            break;

        case Types.REJECT_LOGIN_SERVER_MAINTENANCE:
            msg = lang.t("Please be patient; login is not permitted during the maintenance period");
            reject(msg);
            dispatch(loginError(msg));
            break;

        case Types.REJECT_LOGIN_VERIFYPHONE: {
            if (AppConfig.features.isEnabledNotAuthPhoneVerification) {
                
                setTimeout(() => history.push('/not-auth-phone-verification'), 2);
                
                resolve();

                let updateUser = {
                    Login: login,
                    Timestamp: Date.now(),
                    Remember: password ? remember : authService.getToken(),
                };

                if (password) {
                    updateUser = { ...updateUser, Password: password };
                }

                dispatch(userActions.update(updateUser));
                dispatch(userPhoneIsNotVerified());
            } else {
                dispatch(appActions.redirectTo(AppConfig.getServerAddress(AppConfig.defines.CABINET_URL)));
            }
            break;
        }

        case Types.REJECT_LOGIN_VERIFYEMAIL:
            if (AppConfig.features.isEnabledNewAuth || AppConfig.features.isEnabledNotAuthEmailVerification) {
                if (AppConfig.features.isEnabledNotAuthEmailVerification) {
                    const notAuthEmailVerification = password ? { login, password } : { login };
                    Register.set('NotAuthEmailVerification', notAuthEmailVerification);
                    setTimeout(() => history.push('/not-auth-email-verification'), 2);
                }
                reject('User email is not verified!');
                let updateUser = {
                    Login: login,
                    Timestamp: Date.now(),
                    Remember: password ? remember : authService.getToken(),
                };

                if (password) {
                    updateUser = { ...updateUser, Password: password };
                }

                dispatch(userActions.update(updateUser));
                dispatch(userEmailIsNotVerified(login));
            } else {
                dispatch(appActions.redirectTo(AppConfig.getServerAddress(AppConfig.defines.CABINET_URL)));
            }
            break;

        default:
            reject(msg);
            dispatch(loginError(msg));
    }
}
