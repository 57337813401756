/**
 * Created by ebondarev
 */
import { store } from '../../store';
import { lang } from '../../services';
import * as tradeTransportsActions from '../transports/trade';
import * as userTransportsActions from '../transports/user';
import * as newsActions from '../trade/news';
import * as activityLogHistory from '../trade/activity-log';
import * as activityTradeTabs from '../forms/trade-tabs';
import * as appActions from '../app';
import AppConfig from '../../AppConfig';
import Types from '../../classes/types';

export const UPDATE = "USER_SETTINGS_UPDATE";

export function update(model) {
    return {
        type: UPDATE,
        payload: model
    }
}

export function doUpdate(model) {
    return (dispatch, getState) => {
        const changedLanguage = model.language !== lang.name;
        const changedContractSize = getState().user.settings.contractSize !== model.contractSize;
        const theme = getState().app.colorTheme;
        const changedCurrentTheme = theme === Types.THEMES.dark
            ? getState().user.settings.themeDark !== model.themeDark
            : getState().user.settings.themeLight !== model.themeLight;

        const run = () => {
            store.dispatch(update(model));
            if (changedLanguage) {
                dispatch(appActions.languageChanging());
                tradeTransportsActions.doReconnect();
                if (AppConfig.features.isEnableQuoteWSChanel) userTransportsActions.doReconnect();
                //trackingTransportsActions.doReconnect();
                dispatch(newsActions.doClear());
                dispatch(activityLogHistory.doClear());
            }

            if (changedContractSize) {
                dispatch(activityTradeTabs.removeAll());
            }

            if (changedCurrentTheme) {
                dispatch(appActions.doChangeColorTheme(theme));
            }
        };

        return (dispatch(appActions.setLanguage(model.language)).promise).then(run, run);
    }
}

export function doSetLanguage (language) {
    return (dispatch, getState) => {
        if (language && AppConfig.languages.includes(language)) {
            const userSettings = getState().user.settings;
            userSettings.language = language;

            dispatch(doUpdate(userSettings));
        }
    }
}