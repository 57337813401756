/**
 * Created by ebondarev
 */
import { transportUser, lang } from '../../services';
import authService from '../../services/auth-service';
import { store } from '../../store';
import Transport from '../../classes/transport';
import { getFieldMap } from '../../utils/object';
import * as chartAction from '../trade/chart';
import * as authActions from '../user/auth';
import * as platformNotifications from '../user/platformNotifications';
import * as activityActions from '../trade/activity-log';
import * as accountHistoryActions from '../trade/account-history';
import * as newsActions from '../trade/news';
import * as strategyAction from '../investing/strategy';
import * as interchangeActions from '../trade/interchanges';
import * as trackingActions from '../tracking/tracking';
import * as transportActions from './main';
import * as subscriptionActions from '../investing/subscription';
import * as conversionAction from '../investing/conversion';
import * as screenSharingActions from '../sharing/screen';
import AppConfig from '../../AppConfig';
import Types from '../../classes/types';
import * as appActions from '../../actions/app';

export function doInit() {
    return new Promise((resolve, reject) => {
        if (transportUser.connected) {
            resolve(transportUser);
        }

        transportUser.connect();
        transportUser.onConnected = () => {
            if (store.getState().user.auth.isLogged) {
                const user = store.getState().user;

                const baseRequest = {
                    lang: store.getState().user.settings.language,
                    channel: Transport.CHANNEL_QUOTE,
                    socketId: user.auth.socketUserId
                }
                if (AppConfig.features.isEnabledAuth2) {
                    transportUser.requestAuth2({
                        ...baseRequest,
                        token: authService.getToken(),
                    });
                } else {
                    transportUser.requestLogin({
                        ...baseRequest,
                        login: user.model.Login,
                        password: user.model.Password,
                    });
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    reject(msg);
                }, 20000);

                transportUser.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    if (data.Login && data.Login === user.model.Login) {
                        resolve(data);
                        store.dispatch(authActions.loginSocketUserSuccess({SocketId: data.SocketId}));
                        store.dispatch(appActions.languageChanged());
                    } else {
                        const msg = lang.t("Invalid Login or password");
                        reject(msg);
                    }
                };
            }

            resolve(transportUser);
        };
        transportUser.onError = reject;
        transportUser.onActivityLog = (data) => {
            trackingActions.dispatchAction(activityActions.fetched(data));
        };
        transportUser.onActivityLogUpdate = (data) => {
            const activity = data[0];
            if (activity) {
                switch (activity.EventTypeCode) {
                    case Types.EVENT_TYPE_CODE.MARGIN_CALL: {
                        store.dispatch(appActions.gotMarginCall(activity.ActionId));
                        break;
                    }
                    case Types.EVENT_TYPE_CODE.STOP_OUT: {
                        store.dispatch(appActions.gotStackStopOut({
                            actionId: activity.ActionId,
                            accountNumber: activity.AccountNumber,
                            realizedPnL: activity.RealizedPnL,
                        }));
                        break;
                    }
                    case Types.EVENT_TYPE_CODE.POSITION_CLOSED: {
                        const accauntNumber = data[0].AccountNumber;
                        const closedId = data[0].Id;
                        const state = store.getState();
                        const activePositionsInTools = state.trade.positions.list.filter(position => {
                            return position.OrderNumber !== closedId && position.AccountNumber === accauntNumber;
                        }).size;
                        const stopOutMessage = state.app.showStopOutModals.first(d => d.accauntNumber === accauntNumber);
                        if (!activePositionsInTools && stopOutMessage) {
                            store.dispatch(appActions.showStopOutModal(accauntNumber))
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
            if (activity && activity.EventText && activity.EventText.includes('Matched order is not found')) {
                trackingActions.dispatchAction(interchangeActions.notFound());
            } else {
                trackingActions.dispatchAction(activityActions.add(data[0]));
            }
        };
        transportUser.onNewsList = (data) => {
            store.dispatch(newsActions.fetched(data));
        };
        transportUser.onNewsAdd = (data) => {
            store.dispatch(newsActions.add(data[0]));
        };
        transportUser.onAccountHistory = (data) => {
            store.dispatch(accountHistoryActions.doFetched(data));
        };
        transportUser.onAccountHistoryUpdate = (data) => {
            trackingActions.dispatchAction(accountHistoryActions.add(data[0]));
        };
        transportUser.onHistoryQuotes = (data) => {
            store.dispatch(chartAction.doHistoryQuotes(data));
        };
        transportUser.onHistoryBars = (data) => {
            store.dispatch(chartAction.doHistoryBars(data));
        };
        transportUser.onSignalProviders = (data) => {
            store.dispatch(strategyAction.fetched(data));
        };
        transportUser.onSignalSubscriptions = (data) => {
            const oldSubscription = store.getState().investing.subscription.list;
            const newIdMap = getFieldMap(data, 'SetID')

            oldSubscription.forEach(s => {
                if (!newIdMap[s.SetID]) {
                    store.dispatch(subscriptionActions.doPostUnsubscribe(s.SetID));
                }
            });

            store.dispatch(subscriptionActions.doFetched(data));
        };
        transportUser.onSignalHistory = (data) => {
            store.dispatch(strategyAction.fetchedHistory(data));
        };
        transportUser.onConversion = (data) => {
            store.dispatch(conversionAction.fetched(data));
        };
        transportUser.onScreenSharingRequest = (data) => {
            store.dispatch(screenSharingActions.doRequest(data));    
        };
        transportUser.onScreenSharingAnswer = (data) => {
            store.dispatch(screenSharingActions.doAnswer(data));
        };
        transportUser.onScreenSharingEnd = () => {
            store.dispatch(screenSharingActions.doEnd(false));
        };
        transportUser.onScreenSharingCancel = () => {
            store.dispatch(screenSharingActions.doEnd(false));
        };
        transportUser.onScreenSharingStarted = () => {
            store.dispatch(screenSharingActions.started());
        };
        transportUser.onReject = (errorData) => {
            const error = errorData.Reason;
            store.dispatch(transportActions.reject(lang.t(error)));
        };
        transportUser.onRejectSignalSubscription = (errorData) => {
            const error = errorData.Reason || 'Subscription Error';

            store.dispatch(subscriptionActions.subscribingOnStrategy(''));
            store.dispatch(transportActions.reject(lang.t(error)));
        };
        transportUser.onNotificationAdd = (listData) => {
            store.dispatch(platformNotifications.onNotificationAdd(listData));
        };
    })
}


export function doReconnect() {
    doInit().then(() => {
        transportUser.reconnectStart(0);
    }, () => { })
}
