import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../../common/components/buttons/Button';

const OkButton = props => {
    return (
        <Button
            className={classNames('OkButton', props.className)}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
}

OkButton.PropsTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default OkButton;