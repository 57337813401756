import BaseModel from './base';

export class InstrumentProfitableModel extends BaseModel {
    ACCOUNT_ID;
    AVERAGE_LOSS;
    AVERAGE_LOSS_PERCENT;
    AVERAGE_PROFIT;
    AVERAGE_PROFIT_PERCENT;
    BEST_TRADE;
    BEST_TRADE_PERCENT;
    BUY_TRADES;
    INSTRUMENT_NAME;
    PROFIT_TRADES_PERCENT;
    SELL_TRADES;
    TOTAL_PROFIT;

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }

    get amountOfTrades() {
        return this.BUY_TRADES + this.SELL_TRADES;
    }
}