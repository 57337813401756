/**
 * Created by Evgeniy Bondarev
 */
import { Record, Map } from 'immutable';

import * as actionTypes from '../../actions/investing/accounts';
import * as userTypes from "../../actions/user/user";
import { AccountTradeInfo } from "../../models/account-trade-info";

class AccountsStore extends Record({
    list: new Map(),
    status: actionTypes.NOT_FETCHED,
    isReady: false,
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED;
    }
}

const initialState = new AccountsStore();

function createModelFromData(data) {
    return new AccountTradeInfo(data);
}

export default function subscription(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHED:
            return state.merge({
                list: new Map(action.payload).map((data) => createModelFromData(data)),
                status: actionTypes.FETCHED,
                isReady: true
            });

        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}