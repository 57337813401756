import {AppConfig} from "../AppConfig";

const loadGoogleMapsApi = (lang) => {

    const googleApiToken = AppConfig.googleMapApiToken;
    const useGoogleAutoComplete = AppConfig.features.isEnableKYCOnBoardingGoogleAutoComplete;

    if (useGoogleAutoComplete) {
        return new Promise((resolve, reject) => {

            let script = document.createElement('script');

            if (googleApiToken !== '') {
                script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiToken}&libraries=places&language=${lang}`;
            } else {
                script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&language=${lang}`;
            }

            script.addEventListener("load", () => {
                resolve()
            });
            script.addEventListener("error", (e) => {
                reject(e)
            });
            document.body.appendChild(script);
        }).catch(e => {
            console.error(e);
        })
    }
};


export default loadGoogleMapsApi;