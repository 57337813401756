/**
 * Created by ebondarev
 */
import md5 from 'md5';

import AppConfig from '../../AppConfig';
import Types from "../../classes/types";
import {dispatchServers, transport, transportUser, transportTracking, wdApi} from '../../services';
import authService from '../../services/auth-service';

import { clear as  clearUserInfo, doFetch} from './info';
import {setInitState} from '../app'
import {closeLiveSupport} from '../../utils/liveSupportActions';
import {clearFilters} from '../../actions/trade/filters';
import {closeSidePanel, setRunningAutoLogOutTimer} from '../../actions/app';
import {clearInvestingFilters} from "../investing/filters";

export const UPDATE = 'USER_UPDATE';
export const LOG_OUT = 'USER_LOG_OUT';
export const GO_TO_AUTH_PAGE = 'USER_GO_TO_AUTH_PAGE';
export const GO_TO_MAIN_PAGE = 'GO_TO_MAIN_PAGE';

function logOut() {
    return {
        type: LOG_OUT,
        redirect: AppConfig.getServerAddress(AppConfig.defines.AUTH_URL)
    }
}

function goToAuthPage() {
    return {
        type: GO_TO_AUTH_PAGE,
        redirect: AppConfig.getServerAddress(AppConfig.defines.AUTH_URL)
    }
}

function goToMainPage() {
    return {
        type: GO_TO_MAIN_PAGE,
        redirect: AppConfig.getServerAddress(AppConfig.defines.MAIN_URL)
    }
}

export function update({Login, Password, EnteredPassword, Remember, Timestamp}) {
    return {
        type: UPDATE,
        payload: {
            Login,
            Password,
            EnteredPassword,
            Remember,
            Timestamp
        }
    }
}

export function doLogOut() {
    return (dispatch, getState) => {
        const {socketId, socketUserId} = getState().user.auth;

        dispatch(setRunningAutoLogOutTimer(false));

        transport.requestLogOut(socketId);
        transportUser.requestLogOut(socketUserId);
        if (AppConfig.features.isEnableTracking) {
            transportTracking.requestLogOut(socketUserId);
        }
        authService.setAutoUpdateToken(null);
        wdApi.setAuthData(null);
        dispatch(logOut());
        dispatch(clearUserInfo());
        dispatch(setInitState());
        dispatch(clearFilters());
        dispatch(closeSidePanel());
        dispatch(clearInvestingFilters());
        closeLiveSupport();
    }
}

export function doGoMainPage() {
    return (dispatch) => {
        dispatch(goToMainPage())
    }
}

export function doGoToAuthPage() {
    return (dispatch) => {
        dispatch(goToAuthPage());
    }
}

export function doChangePassword({ newPassword, language }) {
    return (dispatch, getState) => {
        return wdApi.post('ChangePassword', {
            NewPassword: newPassword,
            Language: language || getState().user.settings.language,
        }).then(response => {
            if (!response || !response.data || (response.data && response.data.Error)) {
                return false;
            }

            dispatch(update({
                Password: newPassword,
                Timestamp: Date.now()
            }));

            const authData = wdApi.getAuthData();

            const newAuthData = {
                Password: md5(newPassword),
                PasswordHashed: true
            };

            if (authData && authData.Login) {
                newAuthData.Login = authData.Login
            }
            wdApi.setAuthData(newAuthData);

            return true;
        });
    }
}

export function doSendVerifySms(phoneNumber) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) =>
            wdApi.post('SendUserVerificationCode',
                {
                    PhoneNumber: phoneNumber,
                    RequestId: 0
                })
                .then(res => {
                    if (res && res.data && !res.data.Error)
                        resolve(true);
                    reject(res.data.Error);
                }).catch((e) => {
                reject(e);
            }));
    }
}

export function doConfirmPhone({ phoneNumber, verifyCode, language }) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            wdApi.post('ConfirmUserPhone',
                {
                    code: verifyCode,
                    PhoneNumber: phoneNumber,
                    Language: language || getState().user.settings.language,
                })
                .then(res => {
                    if (res && res.data && !res.data.Error && res.data.Result)
                        resolve(true);
                    reject(res.data.Error);
                }).catch((e) => {
                reject(e);
            });
        });
    }
}

export function doRemindPassword(login, language = '') {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatchServers({login, isUseWss: false}).then(() => {
                wdApi.post('ResetPassword', {
                    Login: login,
                    Language: language || getState().user.settings.language,
                }).then(res => {
                    if ((res && res.data && res.data.Result))
                        resolve();
                    if (res && res.data && res.data.Error && res.data.Error.toString() === Types.REJECT_PASSWORD_REMIND.toString())
                        reject('E-mail not found');
                    else
                        reject('Error');

                }).catch(() => {
                    reject('Something went wrong. Please try again later');
                });
            }).catch(() => {
                reject('User is not found');
            });
        });
    }
}

export function doGetKYCAuthToken({ ttlInSec, userId, levelName, externalActionId }) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            wdApi.post('GetKYCAuthToken', {
                UserVerificatonData: {
                    ttlInSec,
                    userId,
                    levelName,
                    externalActionId
                },
            }).then(result => {
                if (!result || !result.data) {
                    reject('Get access token request error');
                }

                if (result.data.Error) {
                    reject(result.data.Message);
                }

                resolve(result.data.Result);
            }).catch(() => {
                reject('Get access token request error');
            });
        });
    }
}

export function doSendUserCallBack({ login, data, Key }) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            wdApi
            .post('WebAction', {
                Action: 'UserCallback',
                Login: login,
                Data: data,
                Key
            })
            .then(result => {
                if (!result || !result.data) {
                    reject('Connect error');
                }

                if (result.data.Error) {
                    reject(result.data.Message);
                }

                resolve(result.data.Result);
            }).catch(() => {
                reject('Request error');
            });
        });
    }
}

export function doSetUserApiAccessKey() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const userInfo = getState().user.info.model;
            wdApi
                .post('SetApiKey', { UserID: userInfo.Id })
                .then((result) => {
                    if (!result || !result.data) {
                        reject('Request error');
                    }

                    if (result.data.Error) {
                        reject(result.data.Message);
                    }

                    const apiKey = result.data && result.data.Result;

                    if (apiKey) {
                        dispatch(doFetch(userInfo.Id, true));
                        resolve(apiKey);
                    }
                    reject('Error');
                })
                .catch(() => {
                    reject('Request error');
                });
        });
    };
}