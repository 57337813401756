import { Record } from 'immutable';

import * as userTypes from '../../actions/user/user';
import * as actionTypes from '../../actions/investing/conversion';


class ConversionStore extends Record({
    convertingBalance: 0,
    lastConvertingBalanceAt: Date.now(),
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

const initialState = new ConversionStore();

export default function conversion(state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCHED:
            return state.merge({
                convertingBalance: action.payload,
                lastConvertingBalanceAt: Date.now()
            });

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
