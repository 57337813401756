/**
 * Created by ebondarev
 */
import { SettingsModel } from '../../models/settings';
import Register from '../../classes/register';
import * as actionTypes from '../../actions/user/settings';
import * as trackingTypes from '../../actions/tracking/tracking';
import AppConfig from '../../AppConfig';


const REGISTER_NAME = 'settings';
const model = new SettingsModel();
model.copyFrom(Register.get(REGISTER_NAME));

if (model.desktopTheme === null || model.desktopTheme === "")
{
    model.desktopTheme = AppConfig.site.desktopTheme;
    Register.set(REGISTER_NAME, model);
}

if (model.mobileTheme === null || model.mobileTheme === "")
{
    model.mobileTheme = AppConfig.site.mobileTheme;
    Register.set(REGISTER_NAME, model);
}

if (model.language === null) {
    model.language = AppConfig.defines.APP_LANG;
}

if (!AppConfig.languages.includes(model.language)) {
    model.language = AppConfig.defines.APP_LANG;
}

const logOutTime = localStorage.getItem('logout_time');
if (logOutTime) {
    model.logOutTime = parseInt(logOutTime);
}

model.layout = 0;

export default function user(state = model, action) {
    switch (action.type) {
        case actionTypes.UPDATE:
            const updateModel = new SettingsModel(action.payload);
            Register.set(REGISTER_NAME, updateModel);
            return updateModel;
        
        case trackingTypes.GET_STORE:
            if (action.payload) {
                action.data.settings = state;
            }
            return state;

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.settings) {
                return new SettingsModel(action.payload.settings);
            }
            return state;

        default:
            return state;
    }
}