import { combineReducers } from 'redux';

import strategy from './strategy';
import subscription from './subscription';
import conversion from './conversion';
import accounts from './accounts';
import performanceChart from './performance-chart';
import instrumentProfitable from './instrument-profitable';
import filters from "./filters";

/**
 * ВНИМАНИЕ! Важен порядок редьюсеров, некоторые события должны обрабатываться
 * сначала основным редьюсером а потом редьюсером который тоже подписан на это событие
 */
export default combineReducers({
    strategy,
    instrumentProfitable,
    performanceChart,
    accounts,
    subscription,
    conversion,
    filters
});