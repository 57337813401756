import { Record } from 'immutable';
import AccountModel from '../../models/account';
import * as accountActions from '../../actions/trade/accounts';
import * as appActions from '../../actions/app';
import * as depositActions from '../../actions/trade/deposit';
import * as trackingActions from '../../actions/tracking/tracking';

class DepositStore extends Record({
    isRequestActive: false,
    account: null,
    paymentMethodId: null,
    demoAccountNumber: null,
}){};

const initialState = new DepositStore();

export default function deposit(state = initialState, action) {
    switch (action.type) {
        case depositActions.DEPOSIT_BEGIN_REQUEST:
            return state.merge({
                isRequestActive: true,
            });

        case depositActions.DEPOSIT_END_REQUEST:
            return state.merge({
                isRequestActive: false,
            });

        case accountActions.CHANGED_CURRENT_ACCOUNT:
        case depositActions.DEPOSIT_CHANGE_ACCOUNT:
            return state.merge({
                account: action.payload,
            });

        case depositActions.DEPOSIT_CHANGE_PAYMENT_METHOD:
            return state.merge({
                paymentMethodId: action.payload,
            });

        case depositActions.DEPOSIT_CHANGE_PAYMENT_METHOD_AND_ACCOUNT:
            return state.merge({
                paymentMethodId: action.payload.paymentMethodId,
                account: action.payload.account,
            });

        case depositActions.DEPOSIT_CHANGE_DEMO_ACCOUNT_NUMBER:
            return state.merge({
                demoAccountNumber: action.payload,
            });

        case appActions.SHOW_DEPOSIT_MODAL: {
            if (!action.payload) {
                return state.merge({
                    account: null,
                });
            }

            return state;
        }

        case trackingActions.FETCH_ACTION_PAYLOAD: {
            const actionData = action.payload;
            if (actionData.Action === depositActions.DEPOSIT_CHANGE_ACCOUNT) {
                actionData.ActionPayload = new AccountModel(actionData.ActionPayload);
            }
            return state;
        }
        case trackingActions.GET_STORE:
            return (action.data.deposit = (action.payload) ? state : initialState);

        case trackingActions.SET_STORE: {
            if (action.payload && action.payload.deposit) {
                const accountData = action.payload.deposit.account;
                action.payload.deposit.account = new AccountModel(accountData);
                return state.merge(action.payload.deposit);
            }
            return state;
        }

        default:
            return state;
    }
}