export const getFieldMap = (object, field, value = true) => {
    let map = {};

    if (!object) {
        return map;
    }

    Object.values(object).forEach(a => {
        map[a[field]] = value;
    });

    return map;
}