import React, { createElement, isValidElement } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Types from '../../../classes/types';
import { lang } from '../../../services';
import { blockOutsideClickClose } from '../../../actions/app';
import { isMainDesign, isDesktop } from '../../../utils/platform';
import { isMessageHtml } from '../../../utils/reactHelper';
import * as messageBoxActions from '../../../actions/message-box';

import Decollete from '../../../common/components/modal/Decollete';
import GeneralButton from '../buttons/GeneralButton';
import OkButton from '../buttons/OkButton';
import RoundCloseButton from '../../../common/components/RoundCloseButton';
import SvgIcon from '../../../common/components/SvgIcon';
import TrackedComponent from '../../../common/components/TrackedComponent';
import KeyPressInterceptor from '../KeyPressInterceptor';
import OutsideClickClose from '../OutsideClickClose';
import CommonScroll from '../CommonScroll';

class MessageBox extends TrackedComponent {

    static TYPE_INFO = 'info';
    static TYPE_SUCCESS = 'success';
    static TYPE_ERROR = 'error';
    static TYPE_CONFIRM = 'confirm';
    static TYPE_PROCEED = 'proceed';
    static TYPE_INFOBOX = 'infobox';
    static TYPE_WARNING = 'warning';
    static TYPE_ERROR_WITH_PROCEED = 'errorProceed';
    static TYPE_INFO_2_OK = 'info2Ok';
    static TYPE_PLATFORM_NOTIFICATION = 'platformNotification';

    constructor(props) {
        super(props);
        this.componentName = this.props.name;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.show || nextProps.show;
    }

    applyStateCallback() {
        this.forceUpdate();
    }

    isErrorType(type) {
        return type === MessageBox.TYPE_ERROR || type === MessageBox.TYPE_ERROR_WITH_PROCEED;
    }

    isShown() {
        return this.props.show;
    }

    _closeBox() {
        setTimeout(() => {
            this.props.blockOutsideClickClose();
        }, 250);

        const needToClose = this.props.closeCallback(this.props.index);
        if (needToClose === undefined) {
            this.props.closeModal(this.props.index);
        }
    }

    _onOk = callback => {
        callback();
        this._closeBox();
    };

    _onClose = () => {
        this.props.cancelCallback();
        this._closeBox();
    };

    get classStyle() {
        return this.props.colorStyle + '-style';
    }

    render() {
        const body = this.renderBody();

        if (!this.props.settings.confirmRejectionReports && this.isErrorType(this.props.type)) return null;

        if (this.props.inPortal) {
            return (
                ReactDOM.createPortal(body, document.body)
            )
        }

        return body;
    }

    renderBody() {
        if (!this.props.show) {
            return null;
        }

        const { type, title, showTitle, message, showIcoTitle, closeWithEsc } = this.props;

        return (
            <div
                className={classNames(
                    'MessageBox',
                    this.props.className,
                    this.classStyle,
                    type,
                    {
                        'MessageBox__error': this.isErrorType(type),
                        'MessageBox__showTitle': showTitle,
                        'MessageBox__hideTitle': !showTitle,
                    }
                )}
            >
                <div className="MessageBox__container">
                    <OutsideClickClose
                        onClose={() => closeWithEsc && this._onClose()}
                    >
                        <KeyPressInterceptor
                            keyCode={Types.KEY_CODES.ESC}
                            onKeyPressed={() => closeWithEsc && this._onClose()}
                        />
                        <div className="MessageBox__body">
                            {this._renderCloseButton()}
                            {(showTitle) ? (
                                <div className={classNames('MessageBox__title', this.classStyle, type)}>
                                    <div className={classNames('MessageBox__title__text', this.classStyle, type)}>
                                        {showIcoTitle &&
                                            <div className={classNames('MessageBox__title__icon', this.classStyle, type)}>
                                                {this._renderMessageBoxIcon()}
                                            </div>
                                        }
                                        {title}
                                    </div>
                                </div>
                            ) : null }
                            {(message && isMessageHtml(message)) ?
                                <div
                                    className={classNames('MessageBox__text', this.classStyle, type)}
                                    dangerouslySetInnerHTML={{ __html: message }}
                                />
                                :
                                <div className={classNames('MessageBox__text', this.classStyle, type)}>
                                    {this._renderMessage()}
                                </div>}
                            <div className={classNames("MessageBox__buttons", this.classStyle, type)}>
                                {this._renderButtons()}
                            </div>
                        </div>
                    </OutsideClickClose>
                </div>
            </div>
        );
    }

    _renderCloseButton() {
        const { colorStyle } = this.props;

        return (
            <React.Fragment>
                <div onClick={this._onClose}>
                    <RoundCloseButton />
                </div>
                <Decollete style={colorStyle} />
            </React.Fragment>
        );
    }

    _renderButtons() {
        const { type, buttonLabel, buttonSubmitLabel, buttonCancelLabel, lang, secondOkButtonLabel, okCallback, secondOkCallback, showOk, showSecondOk, okDisabled, secondOkDisabled } = this.props;
        let submitLabel;
        let cancelLabel = buttonCancelLabel || lang.t('Cancel');

        switch (type) {
            case MessageBox.TYPE_CONFIRM:
            case MessageBox.TYPE_PLATFORM_NOTIFICATION:
                submitLabel = buttonSubmitLabel || lang.t('Confirm');
                break;

            case MessageBox.TYPE_INFO:
            case MessageBox.TYPE_SUCCESS:
            case MessageBox.TYPE_ERROR:
                submitLabel = buttonSubmitLabel || lang.t('Ok');
                break;

            case MessageBox.TYPE_PROCEED:
            case MessageBox.TYPE_ERROR_WITH_PROCEED:
                submitLabel = buttonSubmitLabel || lang.t('Proceed');
                break;

            case MessageBox.TYPE_INFOBOX:
                submitLabel = buttonSubmitLabel || lang.t(buttonLabel);
                break;

            case MessageBox.TYPE_INFO_2_OK:
                submitLabel = buttonSubmitLabel || lang.t('Enable 2FA Google');
                break;

            default:
                submitLabel = lang.t('Proceed');
                break;
        }

        return (
            <>
                {type === MessageBox.TYPE_CONFIRM &&
                    <GeneralButton
                        onClick={this._onClose}
                        className="MessageBox__button cancel"
                    >
                        {cancelLabel}
                    </GeneralButton>}
                {showOk && (<OkButton
                    onClick={() => this._onOk(okCallback)}
                    disabled={okDisabled}
                    className={classNames("MessageBox__button", 
                        {'MessageBox__simple--green': (type === MessageBox.TYPE_SUCCESS || type === MessageBox.TYPE_ERROR)}
                    )}
                >
                    {submitLabel}
                </OkButton>)}
                {type === MessageBox.TYPE_INFO_2_OK && showSecondOk &&
                <div className='MessageBox__button-wrapper'>
                    <OkButton
                        onClick={() => this._onOk(secondOkCallback)}
                        disabled={secondOkDisabled}
                        className="MessageBox__button"
                    >
                        {secondOkButtonLabel}
                    </OkButton>
                </div>}
            </>
        )
    };

    _renderMessage() {
        const { reactElement, message, footerBodyText, type, updateValue, lang } = this.props;
        const element = reactElement && (isValidElement(reactElement) ? reactElement : createElement("div", reactElement.props, null));
        const text = typeof message === "object" ? isValidElement(message) ? message : createElement("div", message.props, null) : message;
        
        if (updateValue && updateValue.key) {
            return lang.t(message, { [updateValue.key]: updateValue.value });
        }

        if (type === MessageBox.TYPE_INFOBOX) {
            return (
                <div className="MessageBox__infobox__wrap">
                    <div>{message}</div>
                    <div>{footerBodyText}</div>
                </div>
            );
        }

        if (type === MessageBox.TYPE_SUCCESS || type === MessageBox.TYPE_ERROR) {
            return (
                <div className="MessageBox__wrap">
                    {isMainDesign &&
                    <div className="MessageBox__icon">{this._renderMessageBodyIcon()}</div>}
                    <div className="MessageBox__message">{message}</div>
                </div>
            )
        }

        if (type === MessageBox.TYPE_PLATFORM_NOTIFICATION) {
            const formatedDate = moment(this.props.creationDate).format('DD.MM.YYYY | HH:mm:ss');

            return (
                <div className="MessageBox__wrap platformNotification">
                    <CommonScroll
                        type={isDesktop ? 'custom' : 'native'}
                    >
                        <div className="MessageBox__date">{formatedDate}</div>
                        <div className="MessageBox__message">{message}</div>
                    </CommonScroll>
                </div>
            )
        }

        return element || text;
    }

    _renderMessageBoxIcon = () => {
        const { type, iconName } = this.props;

        switch (type) {
            case MessageBox.TYPE_PROCEED:
            case MessageBox.TYPE_INFO:
                return (
                    <SvgIcon icon={SvgIcon.I_DONE} />
                );

            case MessageBox.TYPE_CONFIRM:
            case MessageBox.TYPE_ERROR:
            case MessageBox.TYPE_WARNING:
                return (
                    <SvgIcon icon={SvgIcon.WARNING} />
                );

            case MessageBox.TYPE_INFOBOX:
                return (
                    <SvgIcon icon={iconName} />
                );

            default:
                return (
                    iconName ? <SvgIcon icon={iconName} /> : <></>
                );
        }
    }

    _renderMessageBodyIcon = () => {
        const { type } = this.props;

        switch (type) {
            case MessageBox.TYPE_SUCCESS:
                return (
                    <SvgIcon icon={SvgIcon.SUCCESS} />
                );
            case MessageBox.TYPE_ERROR:
                return (
                    <SvgIcon icon={SvgIcon.ERROR} />
                );
            default:
                return;
        }
    }
}

MessageBox.defaultProps = {
    className: '',
    colorStyle: 'base', // 'asphalt'
    updateValue: {},
    inPortal: false,
    // model props
    show: true,
    confirm: false,
    title: '',
    message: '',
    footerBodyText: '',
    buttonLabel: '',
    showTitle: true,
    iconName: '',
    showIcoTitle: isMainDesign,
    buttonSubmitLabel: undefined,
    buttonCancelLabel: undefined,
    okCallback: () => { },
    secondOkCallback: () => { },
    secondOkButtonLabel: '',
    cancelCallback: () => { },
    closeCallback: (index) => { }, // NOTE if this function return FALSE modal will not close,
    showOk: true,
    showSecondOk: true,
    closeWithEsc: false,
};

MessageBox.propTypes = {
    inPortal: PropTypes.bool,
    className: PropTypes.string,
    index: PropTypes.number,
    creationDate: PropTypes.number,
    updateValue: PropTypes.object, //for timer update value (rerender)
    closeWithEsc: PropTypes.bool,
};


const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.user.settings,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeModal: function (index) {
            dispatch(messageBoxActions.closeWindow({ index }));
        },
        lang,
        blockOutsideClickClose: function(value) {
            dispatch(blockOutsideClickClose(value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);