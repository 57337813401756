import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injector } from './lib/injector';
import classNames from 'classnames';

const HandySvg = ({
    src,
    loadTimeout,
    loadRetryCount,
    defaultIcon,
    className,
    srcClassName,
    svgProps,
    viewBox
}) => {
    const [hasError, setError] = useState(false);
    const [data, setData] = useState({});
    const isFirstRun = useRef(true);
    const id = src && injector.getId(src);

    useEffect(() => {
        if (!src) return;

        const loadParams = {
            timeout: loadTimeout,
            retryCount: loadRetryCount,
            className: srcClassName,
            flushImmediate: true,
            propsViewBox: viewBox
        };

        if (isFirstRun.current) {
            loadParams.flushImmediate = false;
            isFirstRun.current = false;
        }

        injector
            .load(src, loadParams)
            .then(() => {
                setError(false);
                const id = src && injector.getId(src);
                const data = injector.getData(id);

                setData(data);
            })
            .catch((e) => setError(e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    useEffect(() => {
        if (!id || !data.className) return;

        const symbol = document.getElementById(id);

        if (!symbol) return;

        const newClassName = classNames(data.className, srcClassName);

        symbol.setAttribute('class', newClassName);

        return () => {
            if (symbol) {
                symbol.setAttribute('class', data.className);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [srcClassName]);

    if (React.isValidElement(defaultIcon) && (hasError || !id)) {
        defaultIcon = React.cloneElement(defaultIcon, svgProps);
    }

    return !hasError && id ? (
        <svg
            viewBox={`0 0 ${data.width || 24} ${data.height || 24}`}
            className={classNames(id, className)}
            {...svgProps}
        >
            <use xlinkHref={`#${id}`} />
        </svg>
    ) : (
        defaultIcon || null
    );
};

HandySvg.defaultProps = {
    src: '',
    loadTimeout: 4000,
    loadRetryCount: 3,
    defaultIcon: null,
    viewBox: null,
    svgProps: {},
};

HandySvg.propTypes = {
    className: PropTypes.string,
    srcClassName: PropTypes.string,
    src: PropTypes.string,
    loadTimeout: PropTypes.number,
    loadRetryCount: PropTypes.number,
    defaultIcon: PropTypes.oneOfType([PropTypes.node]),
    viewBox: PropTypes.string,
    svgProps: PropTypes.object,
};

export default HandySvg;
