/**
 * Created by ebondarev
 */
import md5 from 'md5';
import { wdApi } from '../../services';
import { base64 } from '../../utils';
import { store } from '../../store';


export const NOT_FETCHED = 'USER_INFO_NOT_FETCHED';
export const FETCHING = 'USER_INFO_FETCHING';
export const FETCHED = 'USER_INFO_FETCHED';
export const UPDATE = "USER_INFO_UPDATE";
export const UPDATED = "USER_INFO_HAS_BEEN_UPDATED";
export const CLEAR = "USER_INFO_CLEAR";

export function doUpdate(model) {
    return function (dispatch) {
        dispatch({
            type: UPDATE,
            payload: model,
        });
        return dispatch({
            type: UPDATED,
        });
    }
}

export function clear() {
    return {
        type: CLEAR,
        payload: {}
    }
}

export function doFetch(userId, force = false) {
    return (dispatch, getState) => {
        const userInfo = getState().user.info;
        if (!force && userInfo.isFetched()) {
            return Promise.resolve(userInfo.model);
        }

        return wdApi.post('GetUserDataById', {
            UserId: userId,
        }).then((response) => {
            if (response.data && response.data.Result) {
                dispatch(doUpdate(response.data.Result));
            }

            return getState().user.info.model;
        }, () => {});
    };
}

export function doUpdateUser(userInfo) {
    return (dispatch, getState) => {
        return wdApi.post('UpdateUser', {
            UserId: userInfo.Id,
            Address1: userInfo.Address1,
            Address2: userInfo.Address2,
            Apartment: userInfo.Apartment,
            BirthCountry: userInfo.BirthCountry,
            BirthPlace: userInfo.BirthPlace,
            City: userInfo.City,
            Comment: userInfo.Comment,
            Company: userInfo.Company,
            Country: userInfo.Country,
            DateOfBirth: userInfo.DateOfBirth,
            District: userInfo.District,
            FirstName: userInfo.FirstName,
            Insurance: userInfo.Insurance,
            LastName: userInfo.LastName,
            MiddleName: userInfo.MiddleName,
            Nationality: userInfo.Nationality,
            Sex: userInfo.Sex,
            State: userInfo.State,
            Tax: userInfo.Tax,
            ZIPCode: userInfo.ZIPCode,
            AntiPhishingCode: userInfo.AntiPhishingCode,
            ApiKey: userInfo.ApiKey
        }).then(
            response => {
                const result = response.data && response.data.Result;
                if (result) {
                    setTimeout(() => dispatch(doFetch(userInfo.Id, true)), 1000);
                }

                return result;
            },
            () => { }
        );
    }
}

export function doUploadUserFile(fileName, docType, fileData, fileTitle="") {
    return (dispatch, getState) => {
        const { model } = getState().user.info;
        return wdApi.post('UploadUserFile', {
            UserId: model.Id,
            Name: fileName,
            Type: docType,
            Title: fileTitle,
            Data: base64.base64ArrayBuffer(fileData),
        }).then((response) => {
            const result = response.data && response.data.Result;

            if (result) {
                setTimeout(() => dispatch(doFetch(model.Id, true)), 1000);
            }

            return result;
        }, () => {});
    }
}

export function doRemoveUserFile(fileId) {
    return (dispatch, getState) => {
        const { model } = getState().user.info;

        return wdApi.post('RemoveUserFile', {
            UserId: model.Id,
            FileId: fileId,
        }).then((response) => {
            const result = response.data && response.data.Result;

            if (result) {
                dispatch(doFetch(model.Id, true));
            }

            return result;
        }, () => {});
    }
}

export function getUserFileUrl(fileId) {
    const authData = wdApi.getAuthData();
    const password = authData.PasswordHashed ? authData.Password : md5(authData.Password);
    const userId = store.getState().user.info.model.Id;

    return wdApi._baseUrl + "DownloadUserFile?Key=" + wdApi.token + "&UserId=" + userId + "&Password=" + password + "&PasswordHashed=true&FileId=" + fileId;
}

export function getUserFileUrlByToken(fileToken) {
    return wdApi._baseUrl + "DownloadUserFile?Key=" + wdApi.token + "&FileToken=" + fileToken;
}