/**
 * Created by ebondarev
 */
import { Record } from 'immutable';

import { UserInfoModel } from '../../models/user-info';
import * as actionTypes from '../../actions/user/info';
import * as trackingTypes from '../../actions/tracking/tracking';

class UserInfoStore extends Record({
    model: new UserInfoModel(),
    status: actionTypes.NOT_FETCHED,
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

const initialState = new UserInfoStore();

export default function user(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NOT_FETCHED:
        case actionTypes.FETCHING:
        case actionTypes.FETCHED:
            return state.merge({
                status: action.type
            });

        case actionTypes.UPDATE:
            return state.update('model', (model) => {
                return model.clone().copyFrom(action.payload);
            }).merge({
                status: actionTypes.FETCHED
            });

        case actionTypes.CLEAR:
            return state.update('model', () => new UserInfoModel()).merge({
                status: actionTypes.NOT_FETCHED
            });

        case trackingTypes.GET_STORE:
            return (action.data.user = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE: {
            if (action.payload && action.payload.user) {
                const userData = action.payload.user;
                userData.model.DateOfBirth = userData.model._DateOfBirth;

                return state.update('model', (model) => {
                    return new UserInfoModel().copyFrom(userData.model);
                }).merge({
                    status: userData.status
                });
            }
            return state;
        }

        default:
            return state;
    }
}
