/**
 * Created by ebondarev
 */
import 'es7-shim';
import find from 'array.prototype.find';

if (!global.Intl) {
    global.Intl = require('intl');
    require('intl/locale-data/jsonp/en');
    require('intl/locale-data/jsonp/ru');
    require('intl/locale-data/jsonp/fr');
}

if (!global.Symbol) {
    global.Symbol = require('es6-symbol');
}

if (!Array.prototype.find) {
    find.shim();
}