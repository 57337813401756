import { createSelector } from 'reselect';

import Types from "../../classes/types";
import { convertMoney } from "../../utils/trade";

const getFavouriteStatus = (id, list) => Boolean(list.find(i => i === id));

const getChildren = (id, list) => {
    const children = [];
    list.forEach(i => {
        if (i.OwnerId === id) children.push(i)
    });
    return children
};

const getSearchField = (children) => {
    const searchArr = [];
    children.forEach(child => {
        if (child.children.length) {
            searchArr.push(child.Name,...getSearchField(child.children))
        } else {
            searchArr.push(child.Name)
        }
    });
    return searchArr;
};

const filterByMinProfitability = (key, list) => {
    if (key === "") return list;
    return list.filter(i => i.TotalGain >= +key)
};

const filterByPeriod = (key, list) => {
    if (key === '' || key === Types.DATE_PERIOD_ALL) {
        return list;
    }

    return list.filter(i => +new Date(i.DateTimeFrom) >= +Types.datePeriod(key).from);
};

const filterByTradePerDay = (key, list) => {
    switch (key) {
        case "Any":
            return list;

        case "0-10":
            return list.filter(i => i.TradePerDay <= 10);

        case "10-20":
            return list.filter(i => i.TradePerDay >= 10 && i.TradePerDay <= 20);

        case "More than 20":
            return list.filter(i => i.TradePerDay > 20);

        default:
            return list;
    }
};

const sortStrategies = (key, list) => {
    switch (key) {
        case "Favourites":
            return list.sort((a, b) => b.Favourite < a.Favourite ? -1 : 1);

        case "Profitability":
            return list.sort((a, b) => b.TotalGain < a.TotalGain ? -1 : 1);

        case "Max. Drawdown":
            return list.sort((a, b) => b.Drawdown < a.Drawdown ? -1 : 1);

        case "Min. size of investment":
            return list.sort((a, b) => {
                const convertCurrency = 'USD';
                const valueA = convertMoney(a.MinimumInvestment, a.Currency, convertCurrency);
                const valueB = convertMoney(b.MinimumInvestment, b.Currency, convertCurrency);
                if (valueA > valueB) return 1;
                return -1;
            });

        default:
            return list;
    }
};

const filterByAssets = (key, list, tickers) => {
    if (key.has(0)) return list;

    return list.filter(s => {
        if (!s.PopularTickers || s.PopularTickers.length === 0) return false;

        const strategyPopTickersGroupList = s.PopularTickers.map(p => {
            const ticker = tickers.find(t => t.Ticker === p);
            return ticker ? ticker.InstrumentTypeId : 0;
        });

        let result = false;
        key.forEach(k => {
            if (strategyPopTickersGroupList.indexOf(k) !== -1) result = true;
        });
        return result;
    });
};


const getAccountsList = state => state.trade.accounts.list;
const getAssets = state => state.investing.filters.assets;
export const getAssetsSearchKey = state => state.investing.filters.assetsSearchKey;
const getFavouritesStrategies = state => state.investing.filters.favourites;
const getFilters = state => state.investing.filters;
const getHistory = state => state.investing.strategy.historyCache;
const getStrategies = state => state.investing.strategy.list;
const getSubscriptions = state => state.investing.subscription.list;
const getTickersList = state => state.trade.tickers.list;
const getTickersGroups = state => state.trade.tickersGroups.list.toArray();
const getAccountNumber = (_, AccountNumber) => AccountNumber; 

export const getExtendedTickersGroups = createSelector(
    [getTickersGroups],
    (groups) => {
        const extendedGroups = groups;
        extendedGroups.forEach(i => {
            i.children = getChildren(i.Id, groups);
        });
        extendedGroups.forEach(i => {
            if (i.children.length) {
                i.searchFiled = [i.Name, ...getSearchField(i.children)].join("");
            } else {
                i.searchFiled = i.Name
            }
        });
        return extendedGroups;
    }
);

export const getExtendedStrategies = createSelector(
    [getStrategies, getFavouritesStrategies],
    (strategies, favourites) => {
        const extendedStrategies = strategies;
        extendedStrategies.forEach(i => {
            i.Favourite = getFavouriteStatus(i.Id, favourites);
        });
        return extendedStrategies;
    }
);

export const filteredStrategies = createSelector(
    [getExtendedStrategies, getFilters, getTickersList],
    (strategies, filters, tickersList) => {
        const filteredByMinProfitability = filterByMinProfitability(filters.minProfitability, strategies);
        const filteredByPeriod = filterByPeriod(filters.period, filteredByMinProfitability);
        const filteredByTradePerDay = filterByTradePerDay(filters.tradePerDay, filteredByPeriod);
        const filteredByAssets = filterByAssets(filters.assets, filteredByTradePerDay, tickersList);
        return sortStrategies(filters.sortDirection, filteredByAssets);
    }
);

export const myStrategies = createSelector(
    [getStrategies, getAccountsList],
    (strategies, accountsList) => {
        return strategies.filter(s => accountsList.has(s.AccountId.toString()));
    },
);

export const isMyStrategySelector = createSelector(
    [myStrategies, getAccountNumber],
    (strategies, accountNumber) => {
        if (!accountNumber) {
            return false;
        }

        return strategies.has(accountNumber);
    }
);

export const photoProfileIdSelector = createSelector(
    [myStrategies],
    (myStrategies) => {
        const strategy = myStrategies.first();

        if (strategy) {
            return strategy.PhotoProfileId;
        }
    }
);

export const photoProfilePreviewIdSelector = createSelector(
    [myStrategies],
    (myStrategies) => {
        const strategy = myStrategies.first();

        if (strategy) {
            return strategy.PhotoProfilePreviewId;
        }
    }
);

export const userDescriptionSelector = createSelector(
    [myStrategies],
    (myStrategies) => {
        const strategy = myStrategies.first();

        if (strategy) {
            return strategy.UserDescription;
        }
    }
);

export const assetsSelectorTitle = createSelector(
    [getExtendedTickersGroups, getAssets],
    (groups, assets) => {
        const assetsArray = Array.from(assets).map(i => {
            const group = groups.find(g => g.Id === i);
            return group ? group.Name : ''
        });
        return assetsArray.join(', ')
    }
);

export const strategyHistoryArr = createSelector(
    [getHistory],
    (history) => {
        return Array.from(history);
    }
);

export const subscriptionsArr = createSelector(
    [getSubscriptions, getExtendedStrategies],
    (subscriptionsData, strategies) => {
         return subscriptionsData.toArray().map(sub => {
            const strategy = strategies.find(({Id}) => Id === sub.SignalAccountNumber) || {};
            return {...sub, ...strategy }
        });
    }
);
