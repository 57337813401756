import Register from "../../classes/register";
import Types from "../../classes/types";
import * as chartsTypes from "./chart";
import * as appActions from "../app";
import { AppConfig } from "../../AppConfig";
import { ChartTabModel } from "../../models/chart-tab";
import { wdApi, dispatchPost } from "../../services";
import { sort } from "../../reducers/utils";
import { getCookie } from "../../utils/cookies"
import { isVersion6MobileTarget } from "../../utils/platform";

/**
 * Created by ebondarev
 */
export const NOT_FETCHED = 'TRADE_ACCOUNTS_NOT_FETCHED';
export const FETCHING = 'TRADE_ACCOUNTS_FETCHING';
export const FETCHED = 'TRADE_ACCOUNTS_FETCHED';
export const ADD_BTC_ADDRESS = 'TRADE_ACCOUNTS_ADD_BTC_ADDRESS';
export const CHANGE_ACCOUNT_NUMBER = 'TRADE_ACCOUNTS_CHANGE_ACCOUNT_NUMBER';
export const CHANGED_CURRENT_ACCOUNT = 'TRADE_ACCOUNTS_CHANGED_CURRENT_ACCOUNT';
export const UPDATE_ACCOUNTS = 'TRADE_ACCOUNTS_UPDATE_ACCOUNTS';
export const SHOW_ZERO_BALANCE_ASSETS = 'EXCHANGE_SHOW_ZERO_BALANCE_ASSETS';
export const CHANGE_ASSET_SHOW_TYPE = 'CHANGE_ASSET_SHOW_TYPE';
export const CHANGE_SEARCH_CURRENCY_KEYWORD = 'EXCHANGE_CHANGE_SEARCH_CURRENCY_KEYWORD';
export const CHANGE_ASSET_SORT_ATTRIBUTE = 'CHANGE_ASSET_SORT_ATTRIBUTE';
export const CHANGE_ASSET_SORT_DIRECTION = 'CHANGE_ASSET_SORT_DIRECTION';
export const CHANGE_CREATE_ACCOUNT_DEALER_NAME = 'CHANGE_CREATE_ACCOUNT_DEALER_NAME';
export const CHANGE_EXCHANGE_CONVERT_CURRENCY = 'CHANGE_EXCHANGE_CONVERT_CURRENCY';

const STORAGE_SAVED_TABS = 'chart_tabs';
const STORAGE_MERGE_TABS_NAME = 'Charts';

// eslint-disable-next-line
function fetching() {
    return {
        type: FETCHING,
    }
}

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

function changeAccountNumber(accountNumber) {
    return {
        type: CHANGE_ACCOUNT_NUMBER,
        payload: accountNumber
    }
}

function changedCurrentAccount(account) {
    return {
        type: CHANGED_CURRENT_ACCOUNT,
        payload: account
    }
}

function addBtcAddress(accountId, btcAddress) {
    return {
        type: ADD_BTC_ADDRESS,
        payload: {
            accountId,
            btcAddress
        }
    }
}

export function showZeroBalanceAssets(show) {
    return {
        type: SHOW_ZERO_BALANCE_ASSETS,
        payload: show,
    }
}

export function changeAssetShowType(type) {
    return {
        type: CHANGE_ASSET_SHOW_TYPE,
        payload: type,
    }
}

export function changeSearchCurrencyKeyword(keyword) {
    return {
        type: CHANGE_SEARCH_CURRENCY_KEYWORD,
        payload: keyword,
    }
}

export function changeAssetSortAttribute(attribute) {
    return {
        type: CHANGE_ASSET_SORT_ATTRIBUTE,
        payload: attribute,
    }
}

export function changeAssetSortDirection(direction) {
    return {
        type: CHANGE_ASSET_SORT_DIRECTION,
        payload: direction,
    }
}

export function changeCreateAccountDealerName(dealerName) {
    return {
        type: CHANGE_CREATE_ACCOUNT_DEALER_NAME,
        payload: dealerName,
    }
}

export function changeExchangeConvertCurrency(currency) {
    return {
        type: CHANGE_EXCHANGE_CONVERT_CURRENCY,
        payload: currency,
    }
}

/**
 * @deprecated
 */
export function updateAccounts(data) {
    return {
        type: UPDATE_ACCOUNTS,
        payload: data
    }
}

export function doFetched(data) {
    return (dispatch, getState) => {
        dispatch(fetched(data));
        const getSavedTabs = () => {
            const tabs = Register.get(STORAGE_SAVED_TABS, null);

            if(tabs && tabs.filter(t => tickersList.has(t.symbolId)).length === 0) {
                return null
            }

           if (tabs === null)
               return null;

           let isTabListEmpty = false;
           for (let i=0; i < tabs.length; i++) {
               if (!Types.CHART_FRAMES.has(tabs[i].frame) || (!AppConfig.availableTimePeriods[Types.CHART_FRAMES.get(tabs[i].frame).timeInterval])) {
                   isTabListEmpty = true;
                   break;
               }
           }

           if (isTabListEmpty)
               return null;
           return tabs;
        };
        const tickersList = getState().trade.tickers.list;
        const findTickers = isVersion6MobileTarget ? [] :AppConfig.showTickerChartForNewUser ?
            [AppConfig.showTickerChartForNewUser]
            : AppConfig.showTickerChartsForNewUser.length ? AppConfig.showTickerChartsForNewUser : [];
        const savedTabs = getSavedTabs();

        const mergeTabs = Register.get(STORAGE_MERGE_TABS_NAME, null);
        Register.remove(STORAGE_MERGE_TABS_NAME); //remove old version

        // @deprecated move old structure
        if (mergeTabs) {
            const newTabs = [];

            for (let key in mergeTabs) {
                const ticker = tickersList.get(mergeTabs[key]);
                if (ticker) {
                    newTabs.push(ChartTabModel.createFromTicker(ticker));
                }
            }

        if (newTabs) {
                dispatch(chartsTypes.updateTabs(newTabs));
            }
        }

        if (findTickers.length && savedTabs === null) {
            let newTabsTickers = [];
    
            findTickers.forEach((findTicker) => {
                const findTickerText = findTicker.toLowerCase();
                const tickers = tickersList.filter((ticker) => {
                    return  ticker.Name.toLowerCase() === findTickerText
                    // const currencies = findTickerText.split('/');
                    //
                    // if (currencies.length === 2) {
                    //     return ticker.Exp1.toLowerCase().indexOf(currencies[0].trim()) !== -1
                    //         && ticker.Exp2.toLowerCase().indexOf(currencies[1].trim()) !== -1;
                    // }
                    //
                    // return ticker.Ticker.toLowerCase().indexOf(findTickerText) > -1;
                }).toArray();

                tickers.forEach((ticker) => {
                    newTabsTickers.push(ticker);
                });
            });

            if (newTabsTickers.length === 0) {
                const newTabsTickersHasLpNames = {};
                tickersList.forEach((ticker) => {
                    if (!newTabsTickersHasLpNames[ticker.LPName]) {
                        newTabsTickersHasLpNames[ticker.LPName] = ticker.LPName;
                        newTabsTickers.push(ticker);
                    }
                });
            }

            if (newTabsTickers.length > 0) {
                const tabs = newTabsTickers.map(ticker => ChartTabModel.createFromTicker(ticker, {showDefaultIndicators: true}));
                dispatch(chartsTypes.updateTabs(tabs));
            } else {
                dispatch(chartsTypes.updateTabs([]));
            }
        }

        // merge for new logic, added require attributes
        const tabs = getState().trade.chart.tabs;
        if (tabs.size > 0) {
            const firstTab = tabs.first();
            if (firstTab && firstTab.isRealTicker === null) {
                const updatedTabs = tabs.toArray().map(tab => {
                    const foundTicker = tickersList.get(tab.symbolId);
                    if (foundTicker) {
                        tab.isRealTicker = foundTicker.isReal;
                    }
                   
                    return tab;
                });

                dispatch(chartsTypes.updateTabs(updatedTabs));
            }
        }

        // need after other logic
        const { current } = getState().trade.accounts;
        if (current) {
            dispatch(changedCurrentAccount(current)); // dispatch other reducers selected default account

            // show deposit window if balance is zero
            dispatch(appActions.doShowDepositIfZeroBalance(current));
        }
    }
}

export function doChangeAccountNumber(accountNumber) {
    return (dispatch, getState) => {
        const currentAccountBefore = getState().trade.accounts.current;
        dispatch(changeAccountNumber(accountNumber));

        const currentAccount = getState().trade.accounts.current;
        if (currentAccountBefore !== currentAccount) {
            dispatch(changedCurrentAccount(currentAccount));

            // show deposit window if balance is zero
            dispatch(appActions.doShowDepositIfZeroBalance(currentAccount));
        }
    }
}

/**
 * Change current account to first with given dealerName
 * @param {string} dealerName 
 */
export function doChangeAccountByDealerName(dealerName) {
    return (dispatch, getState) => {
        const accounts = getState().trade.accounts.listToShow;
        const current = getState().trade.accounts.current || {};

        const account = accounts.find(account =>
            account.DealerName === dealerName
        );

        if (account && current.AccountNumber !== account.AccountNumber) {
            dispatch(doChangeAccountNumber(account.AccountNumber));
        }
    }
}

/**
 * Change current account to first account with currency from margin list
 */
export function doChangeToMarginAccount() {
    return (dispatch, getState) => {
        const currencies = AppConfig.marginAccountCurrencies || [];
        const current = getState().trade.accounts.current || {};
        const accounts = getState().trade.accounts.listToShow;

        const marginAccount = accounts.find(account =>
            account.isRealForTrade && currencies.indexOf(account.BaseCurrency) !== -1
        );

        if (marginAccount && current.AccountNumber !== marginAccount.AccountNumber) {
            dispatch(doChangeAccountNumber(marginAccount.AccountNumber));
        }
    }
}

export function doChangeAssetShowType(type) {
    return (dispatch, getState) => {
        dispatch(changeAssetShowType(type));

        const accountsList = sort(getState().trade.accounts.list, 'Balance', Types.SORT_DESC);
        const account = accountsList.find(account => {
            if (type === Types.ACCOUNT_DEALER_NAME_DEMO) {
                return account.isDemo;
            }

            return account.isRealForTrade;
        });

        if (account) {
            dispatch(doChangeAccountNumber(account.AccountNumber));
        }
    }
}

export function doCreateAccountCryptoAddress({ AccountId, CryptoAddressType, CryptoCurrency }) {
    return (dispatch, getState) => {
        return dispatchPost('CreateAccountCryptoAddress', {
                AccountId,
                CryptoAddressType,
                CryptoCurrency,
            }).then((response) => {
            if (response.data && response.data.Error) {
                throw new Error(response.data.Error);
            }

            dispatch(addBtcAddress(AccountId, response.data.Result));

            const dataArray = response.data.Result.split(';');

            return {
                cryptoAddress: dataArray[0],
                tag: dataArray[1]
            }
        });
    }
}

export function doCreateAccountByUserId({ userId, tradeMode = 0, accountType, currency, initLeverage, strLeverage, dealerName }) {
    return (dispatch, getState) => {
        const requestName = dealerName === Types.ACCOUNT_DEALER_NAME_DEMO ? 'CreateDemoAccountByUserId'
            : 'CreateLiveAccountByUserId';
        const cookieAffiliateCode = getCookie(AppConfig.referral.cookieAffiliateCodeName);
        const cookiePartnerLink = getCookie(AppConfig.referral.cookiePartnerLinkName);

        return wdApi.post(requestName, {
            UserId: userId,
            TradeMode: tradeMode,
            Currency: currency,
            InitLeverage: strLeverage || '1:' + (1 / initLeverage).toString(),
            AccountType: accountType,
            AffiliateCode: cookieAffiliateCode || '',
            PartnerLink: cookiePartnerLink || '',
        }).then(response => {
            if (!response || !response.data || (response.data && response.data.Error)) {
                return '';
            }

            return response.data.Result.toString();
        });
    }
}