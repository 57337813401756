import { store } from '../store';

const calculatePaymentCommission = (paymentMethod, currency, number, type) => {
    const commissionArray = store.getState().trade.commissionPayments.list.toArray().filter(el => {        
        return (el.PaymentSystem === paymentMethod.paymentGate || !el.PaymentSystem) 
            && (el.Currency === currency || !el.Currency)
            && (number >= el.MinAmount && (number <= el.MaxAmount || el.MaxAmount === 0))
            && (el.CommissionPaymentType === type || !el.CommissionPaymentType)
        }
    ).sort((a, b) => b.weight - a.weight);

    if ( commissionArray.length !== 0) {
        return commissionArray[0].FlateRate + number * commissionArray[0].PercentageRate;
    }

    return 0;
}

export default calculatePaymentCommission;