/**
 * Created by ebondarev
 */
import { UserModel } from '../../models/user';
import Register from '../../classes/register';
import RegisterSession from '../../classes/register-session';
import * as actionTypes from '../../actions/user/user';
import { AppConfig } from '../../AppConfig';
import Dispatcher from '../../classes/dispatcher';

const {isEnableDemoMode} = AppConfig.features;
const {demoModeAuthInfo} = AppConfig;
export const REGISTER_NAME = 'auth_info';
let model = new UserModel(RegisterSession.get(REGISTER_NAME)); // session store is required

if (!model.Login || !model.Password) {
    model = new UserModel(Register.get(REGISTER_NAME));
}

if (isEnableDemoMode && demoModeAuthInfo && demoModeAuthInfo.login && demoModeAuthInfo.password) {
    model = new UserModel({
        Login: demoModeAuthInfo.login,
        Password: demoModeAuthInfo.password,
        Remember: false
    })
}

export default function user(state = model, action) {
    switch (action.type) {
        case actionTypes.UPDATE:
            if (isEnableDemoMode) {
                return state;
            }

            state.feed(action.payload);
            if (state.Remember) {
                Register.set(REGISTER_NAME, state.toSave());
                RegisterSession.set(REGISTER_NAME, null); //возможно есть старая запись
            } else {
                Register.set(REGISTER_NAME, state.clone().reset().toSave()); //созранить некоторые значения, например Remember
                RegisterSession.set(REGISTER_NAME, state.toSave());
            }

            return state.clone();

        case actionTypes.LOG_OUT:
            const modelAfterLogOut = state.clone().reset();
            Register.set(REGISTER_NAME, modelAfterLogOut.toSave());
            RegisterSession.set(REGISTER_NAME, modelAfterLogOut.toSave());
            Dispatcher.clear();
            return modelAfterLogOut;

        default:
            return state;
    }
}