import { transport } from '../../services';


export const NOT_FETCHED = 'INVESTING_CONVERSION_NOT_FETCHED';
export const FETCHING = 'INVESTING_CONVERSION_FETCHING';
export const FETCHED = 'INVESTING_CONVERSION_FETCHED';

export function convertBalance(firstCurrency, secondCurrency, sum) {
    transport.requestConversionBalance(firstCurrency, secondCurrency, sum);
}

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}


