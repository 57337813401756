import { Record, Map } from 'immutable';

import * as swapsTypes from '../../actions/trade/swaps';
import * as userTypes from '../../actions/user/user';
import SwapModel from '../../models/swap';


class SwapsStore extends Record({
    bySymbolId: Map(),
    status: swapsTypes.NOT_FETCHED,
    lastUpdateAt: null,
    isReady: false
}) {
    isFetched() {
        return this.status === swapsTypes.FETCHED;
    }
}

function createModelFromData(data) {
    return new SwapModel(data);
}

const initialState = new SwapsStore();

export default function swaps(state = initialState, action) {
    switch (action.type) {
        case swapsTypes.NOT_FETCHED:
        case swapsTypes.FETCHING:
            return state
                .merge({
                    status: action.type
                });

        case swapsTypes.FETCHED:
            const listFetched = Map(action.payload).mapEntries(([ key, data ]) => 
                [ data.SymbolId, createModelFromData(data) ]
            );

            return state
                .merge({
                    bySymbolId: listFetched,
                    status: swapsTypes.FETCHED,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case userTypes.LOG_OUT:
            return initialState.merge({
                bySymbolId: Map()
            });

        default:
            return state;
    }
}