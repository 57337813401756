import { transportTracking } from '../../services';
import { store } from '../../store';
import AppConfig from '../../AppConfig';

export const TRACK_STATE_MASTER = 'TRACK_STATE_MASTER';
export const TRACK_STATE_SLAVE = 'TRACK_STATE_SLAVE';
export const TRACK_STATE_DISABLED = 'TRACK_STATE_DISABLED';

export const APP_MOUSE_CLICK = 'APP_MOUSE_CLICK';
export const APP_MOUSE_LOCATION = 'APP_MOUSE_LOCATION';
export const COMPONENT_GET_STATE = 'COMPONENT_GET_STATE';
export const COMPONENT_UPDATE_STATE = 'COMPONENT_UPDATE_STATE';
export const FETCH_ACTION_PAYLOAD = 'FETCH_ACTION_PAYLOAD';
export const GET_STORE = 'GET_STORE';
export const SET_STORE = 'SET_STORE';
export const SET_TRACKING_STATE = "SET_TRACKING_STATE";
export const SEND_COMPONENT_STATE = 'SEND_COMPONENT_STATE';
export const SEND_COMPONENT_STORE = 'SEND_COMPONENT_STORE';

export function isTrackMasterMode() {
    let trackingState = store.getState().app.trackingState;
    return trackingState === TRACK_STATE_MASTER;
}

export function isTrackSlaveMode() {
    let trackingState = store.getState().app.trackingState;
    return trackingState === TRACK_STATE_SLAVE;
}

export function getTrackState() {
    if (store) {
        return store.getState().app.trackingState;
    }
    return TRACK_STATE_DISABLED;
}


export let trackedComponents = new Map();
export function getTrackedComponent(name) {
    return trackedComponents.get(name);
}

export function addTrackedComponent(name, component) {
    if (name) {
        trackedComponents.set(name, component);
    } else {
        console.log('HandleUndefinedComponent: ' + component);
    }
    return trackedComponents;
}

export function removeTrackedComponent(name) {
    trackedComponents.delete(name);
    return trackedComponents;
}

export function doHandleMouseClick(data) {
    return {
        type: APP_MOUSE_CLICK,
        payload: data
    }
}

export function doHandleMouseLocation(data) {
    return {
        type: APP_MOUSE_LOCATION,
        payload: data
    }
}


export function setTrackingState(value) {
    return {
        type: SET_TRACKING_STATE,
        payload: value
    }
}

export function getStore(value) {
    return {
        type: GET_STORE,
        payload: value,
        data: {}
    }
}

export function getComponentState() {
    return {
        type: COMPONENT_GET_STATE,
        data: {
            detail: {}
        }
    }
}

export function fetchActionPayload(actionData) {
    return {
        type: FETCH_ACTION_PAYLOAD,
        payload: actionData
    }
}

export function updateAppData(actionData) {
    return {
        type: actionData.TypeInfo,
        payload: actionData.State
    }
}

export function updateAppState(actionData) {
    return {
        type: actionData.Action,
        payload: actionData.ActionPayload,
        promise: null
    }
}

export function updateComponentState(data) {
    return {
        type: COMPONENT_UPDATE_STATE,
        payload: data,
        promise: null
    }
}

export function updateComponentStore(data) {
    let appStore = data.State;
    return {
        type: SET_STORE,
        payload: appStore
    }
}

export function doSendAppMouseClick(cursor) {
    return (dispatch, getState) => {
        let state = store.getState();
        transportTracking.sendAppMouseClick(state.app, cursor);
    }
}

export function doSendMouseLocation(cursor) {
    return (dispatch, getState) => {
        let state = store.getState();
        transportTracking.sendAppMouseLocation(state.app, cursor);
    }
}

export function doSendAppActionState(action, state) {
    return (dispatch, getState) => {
        if (AppConfig.features.isEnableTracking && isTrackSlaveMode()) {
            transportTracking.sendAppActionState(action, state);
        }
    }
}

export function doSendComponentState(name, state) {
    return (dispatch, getState) => {
        if (AppConfig.features.isEnableTracking && isTrackSlaveMode()) {
            transportTracking.sendComponentState(name, state);
        }
    }
}

export function doSendComponentStore(typeInfo, state) {
    return (dispatch, getState) => {
        if (AppConfig.features.isEnableTracking && isTrackSlaveMode()) {
            transportTracking.sendComponentStore(typeInfo, state);
        }
    }
}

export function doSendAppActionData(actionType, state) {
    return (dispatch, getState) => {
        if (AppConfig.features.isEnableTracking && isTrackSlaveMode()) {
            transportTracking.sendAppActionData(actionType, state);
        }
    }
}

export function dispatchAction(action) {
    store.dispatch(action);
    store.dispatch(doSendAppActionData(action.type, action.payload));
}
