import { reportApi } from '../../services';
import Types from '../../classes/types';
import * as accountHistoryActions from './account-history';
import { date } from "../../utils";

const reportActionsPrefix = {
    exchangeHistory: 'EXCHANGE',
    transactionHistory: 'TH',
};

export const actions = {
    TH_NOT_FETCHED: 'TRADE_TRANSACTION_HISTORY_NOT_FETCHED',
    TH_FETCHING: 'TRADE_TRANSACTION_HISTORY_FETCHING',
    TH_FETCHED: 'TRADE_TRANSACTION_HISTORY_FETCHED',
    TH_SYNCED: 'TRADE_TRANSACTION_HISTORY_SYNCED',
    TH_CLEAR: 'TRADE_TRANSACTION_HISTORY_CLEAR',
    TH_CHANGE_ACCOUNT_NUMBER: 'TH_CHANGE_ACCOUNT_NUMBER',
    TH_CHANGE_DATE_PERIOD: 'TRADE_TRANSACTION_HISTORY_CHANGE_DATE_PERIOD',
    TH_CHANGE_DATE_PERIOD_RANGE: 'TRADE_TRANSACTION_HISTORY_CHANGE_DATE_PERIOD_RANGE',
    TH_CHANGE_SORT_ATTRIBUTE: 'TRADE_TRANSACTION_HISTORY_CHANGE_SORT_ATTRIBUTE',
    TH_CHANGE_SORT_DIRECTION: 'TRADE_TRANSACTION_HISTORY_CHANGE_SORT_DIRECTION',
    TH_CHANGE_DOWNLOAD_FORMAT: 'TRADE_TRANSACTION_HISTORY_CHANGE_DOWNLOAD_FORMAT',

    EXCHANGE_NOT_FETCHED: 'EXCHANGE_HISTORY_NOT_FETCHED',
    EXCHANGE_FETCHING: 'EXCHANGE_HISTORY_FETCHING',
    EXCHANGE_FETCHED: 'EXCHANGE_HISTORY_FETCHED',
    EXCHANGE_SYNCED: 'EXCHANGE_HISTORY_SYNCED',
    EXCHANGE_CLEAR: 'EXCHANGE_HISTORY_CLEAR',
    EXCHANGE_CHANGE_ACCOUNT_NUMBER: 'EXCHANGE_CHANGE_ACCOUNT_NUMBER',
    EXCHANGE_CHANGE_DATE_PERIOD: 'EXCHANGE_HISTORY_CHANGE_DATE_PERIOD',
    EXCHANGE_CHANGE_DATE_PERIOD_RANGE: 'EXCHANGE_HISTORY_CHANGE_DATE_PERIOD_RANGE',
    EXCHANGE_CHANGE_SORT_ATTRIBUTE: 'EXCHANGE_HISTORY_CHANGE_SORT_ATTRIBUTE',
    EXCHANGE_CHANGE_SORT_DIRECTION: 'EXCHANGE_HISTORY_CHANGE_SORT_DIRECTION',
    EXCHANGE_CHANGE_DOWNLOAD_FORMAT: 'EXCHANGE_HISTORY_HISTORY_CHANGE_DOWNLOAD_FORMAT',
};

export function fetching(report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_FETCHING`],
    }
}

export function fetched(data, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_FETCHED`],
        payload: data,
    }
}

export function clear(report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CLEAR`],
    }
}

export function changeAccountNumber(accountNumber, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_ACCOUNT_NUMBER`],
        payload: accountNumber,
    }
}

export function changeSortAttribute(attribute, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_SORT_ATTRIBUTE`],
        payload: attribute,
    }
}

export function changeSortDirection(direction, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_SORT_DIRECTION`],
        payload: direction,
    }
}

export function changeDatePeriod(value, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_DATE_PERIOD`],
        payload: value
    }
}

export function changeDatePeriodRange(from, to, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_DATE_PERIOD_RANGE`],
        payload: {
            from,
            to
        }
    }
}

export function changeDownloadFormat(format, report) {
    return {
        type: actions[`${reportActionsPrefix[report]}_CHANGE_DOWNLOAD_FORMAT`],
        payload: format
    }
}

export function doFetch(accountNumber, begin, end, report) {
    return (dispatch, getState) => {

        dispatch(fetching(report));

        return reportApi.get('', {
            data: {
                report: Types.REPORTS_ENDPOINTS[report],
                accounts: accountNumber,
                begin: begin,
                end: end,
                format: 'json',
            }
        }).then(res => {
            const data = res ? res.data || [] : [];
            dispatch(fetched(data, report));
        });
    }
}

export function doChangeAccountNumber(accountNumber, report) {
    return (dispatch, getState) => {
        dispatch(clear(report));
        dispatch(changeAccountNumber(accountNumber, report));
    }
}

export function doChangeDatePeriod(value, report) {
    return (dispatch, getState) => {
        const datePeriod = Types.datePeriod(value);

        dispatch(clear(report));
        dispatch(changeDatePeriodRange(datePeriod.from, datePeriod.to, report));
        dispatch(changeDatePeriod(value, report));
    }
}

export function doChangePeriodRange(from, to, report) {
    return (dispatch, getState) => {
        dispatch(clear(report));
        dispatch(changeDatePeriod(Types.DATE_PERIOD_CUSTOM, report));
        dispatch(changeDatePeriodRange(from, to, report));
    }
}

export function doFetchTransactionsHistory() {
    return (dispatch, getState) => {

        const statefilters = getState().trade.filters;
        const filters = {
            ...statefilters.historyFilters,
            walletsSearchKey: statefilters.walletsSearchKey,
            showZeroBalance: statefilters.showZeroBalance,
        };

        const isRange = filters.period === Types.DATE_PERIOD_CUSTOM;
        const datePeriod = Types.datePeriod(filters.period, isRange ? filters.from : null, isRange ? date.getOneDayMore(filters.to) : null);

        const accountsToShow = getState().trade.accounts.listToShow.toArray();
        const accounts = filters.wallets.all ? accountsToShow.filter(a => !a.isFake).map(a => a.AccountNumber)
            : Array.from(filters.wallets.list, i => i.id);
        const accountsString = accounts.join();

        dispatch(doFetch(accountsString, date.toServerDate(datePeriod.from), date.toServerDate(datePeriod.to), "transactionHistory"));
    };
}

export function doFetchAllHistory(value, from, to, accounts) {
    const accountsString = accounts.join();
    return (dispatch, getState) => {
        const datePeriod = Types.datePeriod(value, from, to);
            dispatch(clearAllHistory());
            dispatch(changeDatePeriodRange(datePeriod.from, datePeriod.to, "transactionHistory"));
            dispatch(changeDatePeriodRange(datePeriod.from, datePeriod.to, "exchangeHistory"));
            dispatch(accountHistoryActions.datePeriodRange(datePeriod.from, datePeriod.to));
            dispatch(changeDatePeriod(value, "transactionHistory"));
            dispatch(changeDatePeriod(value, "exchangeHistory"));
            dispatch(accountHistoryActions.datePeriod(value));
            dispatch(doFetch(accountsString, date.toServerDate(datePeriod.from), date.toServerDate(datePeriod.to), "transactionHistory"));
            dispatch(doFetch(accountsString, date.toServerDate(datePeriod.from), date.toServerDate(datePeriod.to), "exchangeHistory"));
            dispatch(accountHistoryActions.doFetch(accounts));
    }
}

export function clearAllHistory() {
    return (dispatch, getState) => {
        dispatch(clear("transactionHistory"));
        dispatch(clear("exchangeHistory"));
        dispatch(accountHistoryActions.clear());
    }
}
