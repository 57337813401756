/**
 * Created by ebondarev
 */
import BaseModel from './base';

export class UserModel extends BaseModel {
    Id = null;
    Timestamp = null;
    Login = "";
    Password = "";
    EnteredPassword = "";
    Remember = true;

    constructor(data) {
        super();

        if (data === null || typeof data !== 'object') {
            return;
        }

        this.Login = data['login'] || data['Login'] || this.Login;
        this.Password = data['password'] || data['Password'] || this.Password;
        if (typeof data['Remember'] !== 'undefined') {
            this.Remember = !!data['Remember'];
        } else  if (typeof data['remember'] !== 'undefined') {
            this.Remember = !!data['remember'];
        }
    }

    reset() {
        this.Timestamp = null;
        this.Login = "";
        this.Password = "";

        return this;
    }

    toSave() {
        return {
            login: this.Login,
            password: this.Password,
            remember: this.Remember
        }
    }
}