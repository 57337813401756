import React from 'react';

import AppConfig from '../../AppConfig';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { isMainDesign, isClassicDesign, isCustom2Design, isDesktop } from '../../utils/platform';

import '../assets/scss/components/app-loader-common.scss';
import "../assets/scss/components/loading-spinner.scss";

class AppLoader extends React.Component {  
    constructor(props) {
        super(props);

        this.importAssets();

        this.state = {
            AppMainComponent: null
        };
    }

    componentDidMount() {
        this.loadAppMainComponent();
    }

    async importAssets() {
        if (isMainDesign)  {
            await import('../../common/assets/scss/components/app-loader.scss');
        } else if (isClassicDesign) {
            await import('../../classic-common/assets/scss/components/app-loader.scss');
        } else if (isCustom2Design) {
            await import('../../designs/custom2/common/assets/scss/components/app-loader.scss');
        }
    }

    async loadAppMainComponent() {
        await import('../../AppMain')
        .then(module => module.default)
        .then(module => {this.setState({AppMainComponent: module})});
    }

    render() {
        const {AppMainComponent} = this.state;

        if (AppMainComponent) {
            return <AppMainComponent/>
        };

        return (
            <div className='AppLoader__wrapper'>
                    <LoadingSpinner
                        name='folding-cube'
                        className={isDesktop ? 'App__loading' : ''}
                        classNameText={isDesktop ? 'App__loading-text' : ''}
                        text={isDesktop ? AppConfig.name : ''}
                    />
            </div>)
    }
}

export default AppLoader;