import TagManager from 'react-gtm-module';
import {AppConfig} from '../AppConfig';
import _ from "lodash";
import {ga} from "./index";

class Ga {
    /**
     * If enable in config send Google Analytics event
     * @param {string} eventCategory category
     * @param {string} eventAction action
     * @param {string} eventLabel label
     */
    static event(eventCategory, eventAction = '', eventLabel = '') {
        const GTMEvent = AppConfig.googleAnalytics.GTMEvent;
        const event = eventAction !== GTMEvent ? eventAction : GTMEvent;

        if (AppConfig.features.isEnableGA && window.dataLayer) {
            TagManager.dataLayer({
                dataLayer: {
                    'event': event,
                    'event_category': eventCategory,
                    'event_label': eventLabel
                }
            });
        }
    }

    static  GTAChangeAccountStatus = (tierFlags, rejectFlags) => {
        const KYCStatusForGTM = localStorage.getItem('KYCStatusForGTM');

        if (KYCStatusForGTM === null)
            return;

        const approved = _.filter(tierFlags, (value) => value === true).length;
        const rejected = _.filter(rejectFlags, (value) => value === true).length;
        const result = (approved === 4) ? 'Approved' : (approved !== 4 && rejected > 0) ? 'Rejected' : '';
        if (result !== KYCStatusForGTM) {
            ga.event('step8_accountstatus', AppConfig.googleAnalytics.GTMEvent);
            localStorage.setItem('KYCStatusForGTM', result);
        }

    };
}

export default Ga;