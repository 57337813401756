import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import HandySvg from './handy-svg/HandySvg';

const SpriteSvg = (props) => {
    const { noWrap, tagName, tagClassName, className, defaultIcon, src, srcClassName, svgProps, viewBox } = props;
    const Tag = `${tagName}`;

    const renderSvg = () => {
        return (
            <HandySvg
                className={classNames('SpriteSvg__svg', className)}
                srcClassName={srcClassName}
                src={src}
                defaultIcon={defaultIcon}
                viewBox={viewBox}
                svgProps={svgProps}
            />
        );
    };

    if (noWrap) {
        return renderSvg();
    }

    return (
        <Tag className={classNames('SpriteSvg', tagClassName)}>
            {renderSvg()}
        </Tag>
    );
};

SpriteSvg.defaultProps = {
    tagName: 'i',
    tagClassName: '',
    className: '',
    srcClassName: '',
    noWrap: false,
    src: '',
    defaultIcon: '',
    svgProps: {},
};

SpriteSvg.propTypes = {
    tagName: PropTypes.string,
    tagClassName: PropTypes.string,
    className: PropTypes.string,
    srcClassName: PropTypes.string,
    noWrap: PropTypes.bool,
    src: PropTypes.string,
    defaultIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    svgProps: PropTypes.object,
};

export default SpriteSvg;
