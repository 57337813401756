import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RoundCloseButton = ({ className, onClose }) => {
    return (
        <button
            className={classNames('RoundCloseButton', className)}
            onClick={onClose}
        >
            ×
        </button>
    );
}

RoundCloseButton.defaultProps = {
    onClose: f => f,
};

RoundCloseButton.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default RoundCloseButton;