/**
 * Created by ebondarev
 */
import sha256 from 'hash.js/lib/hash/sha/256';

import AppConfig from '../AppConfig';
import { store } from '../store';
import * as userAction from '../actions/user/user';
import AuthDepository from '../classes/auth-depository';
import Api from '../classes/api';
import Logger from '../classes/logger';
import Lang from '../classes/lang';
import Transport, { TransportUrlManager } from '../classes/transport';
import TransportMedia from '../classes/transport-media';
import ApiJsonToken from '../classes/api-json-token';
import Dispatcher from '../classes/dispatcher';
import ReportApi from '../classes/report-api';
import TransactionApi from '../classes/transaction-api';
import ErrorHandler from '../classes/errorHandler';

export const api = new Api();
export const errorHandler = new ErrorHandler();

export const dispatcher = new Dispatcher(api);
const transportUrlManager = new TransportUrlManager(dispatcher.servers.wssServer);
const trackingUrlManager = AppConfig.features.isEnableTracking ? new TransportUrlManager(dispatcher.servers.trackingServer) : {};
export const reportApi = new ReportApi({ baseUrl: dispatcher.servers.reportServer, errorHandler });
export const quoteApi = new ReportApi({ baseUrl: dispatcher.servers.quoteServer });
export const logger = new Logger();
export const lang = new Lang(api);

export const wdApi = new ApiJsonToken({
    createTokenEndPoint: "CreateToken",
    baseUrl: dispatcher.servers.server,
    logger,
    errorHandler,
    tokenUpdateDiff: AppConfig.auth2TokenUpdateDiff,
    onLogOut: () => store.dispatch(userAction.doLogOut()),
    defaultData: {
        Key: sha256().update(AppConfig.defines.WD_API.KEY).digest('hex'),
    }
});

export const transactionApi = new TransactionApi({
    baseUrl: dispatcher.servers.transactionServer,
    logger,
    errorHandler,
    defaultData: {
        Key: sha256().update(AppConfig.defines.WD_API.KEY).digest('hex'),
    },
    headerTokenName: AuthDepository.HEADER_TOKEN_NAME,
    getWdApiToken: wdApi.getToken,
});

export const transport = new Transport(transportUrlManager, logger, Transport.TRANSPORT_NAME_USER);
export const transportUser = new Transport(transportUrlManager, logger, Transport.TRANSPORT_NAME_HISTORY);
export const transportTracking = AppConfig.features.isEnableTracking ? new Transport(trackingUrlManager, logger, Transport.TRANSPORT_NAME_TRACKING) : {};
export const transportMedia = new TransportMedia(AppConfig.simplePeer);

export function dispatchServers({login = undefined, apiKey = undefined, isUseWss = true, token = undefined, affiliateCode = undefined}) {
    return new Promise((resolve, reject) => {
        return dispatcher.getServers(login, apiKey, token, affiliateCode).then(res => {
            if (res.error) {
                reject();
            } else {
                const transportUrlManager = new TransportUrlManager(dispatcher.servers.wssServer);
                transport.setNewServer(transportUrlManager, Transport.TRANSPORT_NAME_USER);
                transportUser.setNewServer(transportUrlManager, Transport.TRANSPORT_NAME_HISTORY);

                if (AppConfig.features.isEnableTracking) {
                    transportTracking.setNewServer(trackingUrlManager, Transport.TRANSPORT_NAME_TRACKING);
                }

                transactionApi.constructor({
                    baseUrl: dispatcher.servers.transactionServer,
                    logger,
                    errorHandler,
                    defaultData: {
                        Key: sha256().update(AppConfig.defines.WD_API.KEY).digest('hex'),
                    },
                    headerTokenName: AuthDepository.HEADER_TOKEN_NAME,
                    getWdApiToken: wdApi.getToken,
                });

                wdApi.constructor({
                    createTokenEndPoint: "CreateToken",
                    baseUrl: dispatcher.servers.server,
                    logger,
                    errorHandler,
                    tokenUpdateDiff: AppConfig.auth2TokenUpdateDiff,
                    onLogOut: () => store.dispatch(userAction.doLogOut()),
                    defaultData: {
                        Key: sha256().update(AppConfig.defines.WD_API.KEY).digest('hex')
                    },
                });

                reportApi.constructor({ baseUrl: dispatcher.servers.reportServer, errorHandler });
                quoteApi.constructor({ baseUrl: dispatcher.servers.quoteServer, errorHandler });

                if ((!login || !apiKey) || !isUseWss) {
                    resolve();
                } else {
                    transport.onConnected = () => {
                        resolve();
                    };
                    transport.onErrorDispatcher = () => {
                        reject('TRANSPORT_ERROR');
                    };
                }
            }
        }).catch(() => {
            resolve();
        });
    });
}

export function dispatchPost(url, body = {}, params = {}) {
    if (transactionApi._baseUrl && AppConfig.transactionServerEndpoints[url]) {
        return transactionApi.post(url, body, params);
    }

    return wdApi.post(url, body, params);
}
