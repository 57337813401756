export class AutoOrder {

    static TYPE = {
        SL: 'SL', // Stop Loss
        TP: 'TP', // Take Profit
    }

    static ACT_TYPE = {
        SELL: 'Sell',
        BUY: 'Buy',
    }

    static UNIT = {
        PRICE: 'gavel',
        PERCENT: '%',
        MONEY: '$',
    }

    static replasePrecisionInUnitobj(u, precisions) {
        if (u.id in precisions) {
            return {
                ...u,
                precision: Number(precisions[u.id]),
            };
        } else {
            return {
                ...u,
            };
        }
    }

    static getTPUnitList(precisions, AppConfig) {
        return AppConfig.orderEdit.takeProfitUnitList.map(u => AutoOrder.replasePrecisionInUnitobj(u, precisions));
    }

    static getSLUnitList(precisions, AppConfig) {
        return AppConfig.orderEdit.stopLostUnitList.map(u => AutoOrder.replasePrecisionInUnitobj(u, precisions));
    }
    
    static getDefaultValue({price, autoCloseType, action, ticker, Amount, crossRate}, defaultUnit, defaultValue, currenttUnit = null) {
        if (currenttUnit && currenttUnit !== defaultUnit) {
            return this.positiveConvertValue ({
                price, 
                autoCloseType, 
                Amount, 
                fromUnit: defaultUnit, 
                toUnit: currenttUnit, 
                action, 
                leverage: ticker.leverage,
                crossRate
            }, Number(defaultValue));
        }

        const direction = action === AutoOrder.ACT_TYPE.BUY ? +1 : -1;
        const side = autoCloseType === AutoOrder.TYPE.TP ? +1 * direction : -1 * direction;
        let result;
        switch (defaultUnit) {
            case AutoOrder.UNIT.PERCENT:
            case AutoOrder.UNIT.MONEY:
                result = defaultValue;
                break;
            case AutoOrder.UNIT.PRICE:
                result = defaultValue * ticker.PriceStep * ticker.PointSize * side + price;
                break;
            default:
                throw new Error('Nonexistent unit', defaultUnit);
        }
        return result;
    }

    static getCost({ act, Bid, Ask }) {
        return act === AutoOrder.ACT_TYPE.SELL ? Bid : Ask;
    }

    static positiveConvertValue({ price, autoCloseType, Amount, fromUnit, toUnit, action, leverage, crossRate}, value) {
        const newValue = AutoOrder.convertValue({ price, autoCloseType, Amount, fromUnit, toUnit, action, leverage, crossRate}, value);
        return Math.max(0, newValue);
    }

    static convertValue({ price, autoCloseType, Amount, fromUnit, toUnit, action, leverage, crossRate}, value) {
        const gravel = AutoOrder.convertValueToPrice({ price, autoCloseType, Amount, action, leverage, crossRate}, fromUnit, value);
        return AutoOrder.convertGravelValueTo({ price, autoCloseType, Amount, action, leverage, crossRate}, toUnit, gravel);
    }

    static convertValueToPrice({ price, autoCloseType, Amount, action, leverage, crossRate}, unit, value) {
        const direction = action === AutoOrder.ACT_TYPE.BUY ? +1 : -1;
        const side = autoCloseType === AutoOrder.TYPE.TP ? +1 * direction : -1 * direction
        let result;
        value = isFinite(value)? value: 0;

        switch (unit) {
            case AutoOrder.UNIT.PRICE:            // gavel => gavel
                result = value;
                break;
            case AutoOrder.UNIT.PERCENT:         // % => gavel
                if (Amount === 0) {
                    result = 0;
                } else {
                    const invest = AutoOrder.margin({crossRate: 1, Amount, price, leverage});
                    const goal = invest / 100 * +value * side;
                    result = goal / Amount + price;
                }
                break;
            case AutoOrder.UNIT.MONEY:            // $ => gavel
                if (Amount === 0) {
                    result = 0;
                } else {
                    const goal = +value / crossRate * side;
                    result = Amount === 0 ? 0 : goal / Amount + price;
                }
                break;
            default:
                throw new Error('Nonexistent unit', unit);
        }
        return result;
    }

    static convertGravelValueTo({ price, autoCloseType, Amount, action, leverage, crossRate}, unit, value) {
        const direction = action === AutoOrder.ACT_TYPE.BUY ?  +1 : -1;
        const side = autoCloseType === AutoOrder.TYPE.TP ? +1 * direction : -1 * direction
        let result;
        switch (unit) {
            case AutoOrder.UNIT.PRICE:             // gavel => gavel
                result = value;
                break;
            case AutoOrder.UNIT.PERCENT:          // gavel => %
                const profitLoss = Amount * (value - price);
                const goal = profitLoss * side;
                const invest = AutoOrder.margin({crossRate: 1, Amount, price, leverage});
                if (invest === 0) {
                    result = 0;
                } else {
                    result = goal * 100 / invest;
                }
                break;
            case AutoOrder.UNIT.MONEY:            // gavel => $
                result = Amount * (value - price) * crossRate * side;
                break;
            default:
                result = 0;
        }
        return result;
    }

    static margin({crossRate, Amount, price, leverage}) {
        return crossRate * Amount * price * leverage;
    }

    constructor({ action, ticker, appConfig, leverage }) {
        this.update({ action, ticker, appConfig, leverage })
    }

    update({ action, ticker, appConfig, leverage, precision }) {
        this._action = action;
        this._ticker = ticker;
        this._appConfig = appConfig;
        this._leverage = leverage;
        this._precision = Number(precision);
    }

    _precision = 2;
    get precision() {
        return this._precision;
    }

    get defaultTP() {
        return this._appConfig.orderEdit.defaultTP;
    }

    get defaultSL() {
        return this._appConfig.orderEdit.defaultSL;
    }

    get cost() {
        return (this._action === AutoOrder.ACT_TYPE.SELL) ? this._ticker.Bid : this._ticker.Ask;
    }

    _price = 0;
    set price(value) {
        this._price = Number(value) > 0 ? Number(value) : 0;
    }

    get price() {
        return this._price;
    }

    _stopLoss = 0;
    set stopLoss(value) {
        this._stopLoss = Number(value);
    }

    get stopLoss() {
        return this._stopLoss;
    }

    _takeProfit = 0;
    set takeProfit(value) {
        this._takeProfit = Number(value);
    }

    get takeProfit() {
        return this._takeProfit;
    }

    get leverage() {
        return this._leverage
    }

} 