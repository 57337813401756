/**
 * Created by ebondarev
 */
import { Record } from 'immutable';
import * as authTypes from '../../actions/user/auth';
import * as userTypes from '../../actions/user/user';
import Register from '../../classes/register';
import { lang } from '../../services';
import authService from '../../services/auth-service';

const JUST_REGISTERED_STORE_NAME = 'just_registered';

const initialState = new Record({
    isAuthProcessing: false,
    isTwoFAError: false,
    twoFAErrorCode: '',
    error: null,
    errorAt: null,
    socketId: null,
    socketUserId: null,
    isLogged: false,
    isLoginAfterRegistration: false,
    userIsForbidden: false,
    notVerifiedEmail: null,
    isUserCompleteRegistrationClick:false
})();

function isJustRegistered() {
    return Register.has(JUST_REGISTERED_STORE_NAME);
}

export default function user(state = initialState, action) {
    switch (action.type) {
        case authTypes.LOG_IN_REQUEST:
            return initialState.merge({
                isAuthProcessing: true,
            });

        case authTypes.LOG_IN_SUCCESS:
            return state.merge({
                isAuthProcessing: false,
                socketId: action.payload.SocketId,
                error: null,
                errorAt: null,
                isLogged: true,
                userIsForbidden: false,
                notVerifiedEmail: null,
                isLoginAfterRegistration: isJustRegistered()
            });

        case authTypes.LOG_IN_SOCKET_USER_SUCCESS:
            return state.merge({
                socketUserId: action.payload.SocketId,
            });

        case authTypes.LOGIN_AFTER_REGISTRATION:
            if (!action.payload) {
                Register.remove(JUST_REGISTERED_STORE_NAME);
            }

            return state.merge({
                isLoginAfterRegistration: action.payload
            });

        case authTypes.LOG_IN_ERROR:
            const isTwoFAError = action.payload.includes('2FA')
            const errorCode = isTwoFAError ? +action.payload.replace('2FA ', '') : ''
            return state.merge({
                isAuthProcessing: false,
                isTwoFAError,
                twoFAErrorCode: errorCode,
                error: isTwoFAError ? null : action.payload,
                errorAt: Date.now(),
                userIsForbidden: false,
                notVerifiedEmail: null,
            });

        case authTypes.USER_IS_FORBIDDEN:
            return state.merge({
                isAuthProcessing: false,
                error: lang.t('User is forbidden!'),
                errorAt: Date.now(),
                userIsForbidden: true,
                notVerifiedEmail: null,
            });

        case authTypes.USER_PHONE_IS_NOT_VERIFIED:
            return state.merge({
                isAuthProcessing: false,
                error: lang.t('User phone is not verified!'),
                errorAt: Date.now(),
                userIsForbidden: false,
                notVerifiedEmail: null,
            });

        case authTypes.USER_EMAIL_IS_NOT_VERIFIED:
            return state.merge({
                isAuthProcessing: false,
                error: lang.t('User email is not verified!'),
                errorAt: Date.now(),
                userIsForbidden: false,
                notVerifiedEmail: action.payload,
            });

        case authTypes.IS_USER_COMPLETE_REGISTRATION_CLICK:
            return state.merge({
                isUserCompleteRegistrationClick:action.payload
            });

        case userTypes.LOG_OUT:
            authService.removeData();
            return initialState;

        case authTypes.INITIAL_STATE:
            return initialState;

        default:
            return state;
    }
}
