/**
 * Created by ebondarev
 */

export default class Logger {
    static LEVEL_INFO = 'INFO';
    static LEVEL_ERROR = 'ERROR';
    static LEVEL_WARNING = 'WARNING';
    static LEVEL_DEBUG = 'DEBUG';

    enable = true;
    _levels = [Logger.LEVEL_INFO, Logger.LEVEL_ERROR, Logger.LEVEL_WARNING, Logger.LEVEL_DEBUG];

    constructor() {
        if (typeof window.console === 'undefined') {
            this.enable = false;
        }
    }

    /**
     * @param levels {Array}
     */
    setLevels(levels) {
        this._levels = [];
        levels.forEach((level) => {
            switch (level) {
                case Logger.LEVEL_INFO:
                case Logger.LEVEL_ERROR:
                case Logger.LEVEL_WARNING:
                case Logger.LEVEL_DEBUG:
                    this._levels.push(level);
                    break;
                default:
                    break;
            }
        })
    }

    info(...value) {
        if (this.enable && this._isEnableLevel(Logger.LEVEL_INFO)) {
            window.console.log(...value);
        }
    }

    warn(...value) {
        if (this.enable && this._isEnableLevel(Logger.LEVEL_WARNING)) {
            window.console.warn(...value);
        }
    }

    error(...value) {
        if (this.enable && this._isEnableLevel(Logger.LEVEL_ERROR)) {
            window.console.error(...value);
        }
    }

    debug(...value) {
        if (this.enable && this._isEnableLevel(Logger.LEVEL_DEBUG)) {
            window.console.debug(...value);
        }
    }

    _isEnableLevel(level) {
        return this._levels.indexOf(level) >= 0;
    }
}
