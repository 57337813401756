/**
 * Created by ebondarev
 */
import BaseModel from './base';
import Types from '../classes/types';
import { lang } from '../services';
import { math, date } from '../utils';
import { store } from '../store';
import moment from "moment";


export class AccountHistoryModel extends BaseModel {
    TYPE = Types.HISTORY_TYPE_ACCOUNT;

    Id;
    AccountNumber;
    ClosePrice;
    CloseTime;
    set CloseTime(value) {
        this._CloseTime = value;
        this.closeDateTime = new Date(value * 1000);
    }

    get CloseTime() {
        return this._CloseTime;
    }

    _CloseTypeCode;
    set CloseTypeCode(value) {
        this._CloseTypeCode = value;
        this.closeType = Types.CLOSE_TYPES[value];
    }

    get CloseTypeCode() {
        return this._CloseTypeCode;
    }

    Comment;
    Commission;
    MagicNumber;
    OpenPrice;
    _OpenTime;
    set OpenTime(value) {
        this._OpenTime = value;
        this.openDateTime = new Date(value * 1000);
    }

    get OpenTime() {
        return this._OpenTime;
    }

    _OpenTypeCode;
    set OpenTypeCode(value) {
        this._OpenTypeCode = value;
        this.openType = Types.OP_CODES[value];
    }

    get OpenTypeCode() {
        return this._OpenTypeCode;
    }

    PositionNumber;
    ProfitAccountCurrency;
    Side;
    Swap;
    Ticker;
    UnitVolume;

    // addition fields
    netPnl = 0;

    get roundedNetPnl() {
        const account = store.getState().trade.accounts.list.get(this.AccountNumber);

        if (account) {
            return lang.number(this.netPnl, account.BaseCurrencyPrecision, true);
        }

        return this.netPnl;
    }

    unitInLots = 0;
    openDateTime;

    get openFormatedDateTime() {
        return moment(this.openDateTime).format("DD.MM.YYYY HH:mm");
    }

    closeDateTime;

    get closeFormatedDateTime() {
        return moment(this.closeDateTime).format("DD.MM.YYYY HH:mm");
    }

    get DateTime() {
        return moment(this.closeDateTime).format('DD.MM.YYYY HH:mm:ss');
    }

    get SortTime() {
        return new Date(this.closeDateTime).getTime();
    }

    openType = '';
    closeType = '';

    constructor(intiData) {
        super();
        this.copyFrom(intiData);

        this.updatePnl();
    }

    updatePnl() {
        this.netPnl = this.ProfitAccountCurrency + this.Swap + this.Commission;
    }

    updateAttributesFromQuoteOrTicker(quote) {
        if (quote.LotSize && quote.LotSize > 0) {
            this.unitInLots = +(this.UnitVolume / quote.LotSize).toFixed(math.countPrecision(quote.SizeMinimalChange));
        }
    }
}

export function accountHistoryModelAdapter(data, tickersList){
    return data.map((item) => ({
        AccountNumber: item.ACCOUNT.toString(),
        ClosePrice: item.CLOSE_PRICE,
        CloseTime: (date.getTimeFromTick(item.CLOSE_TIME) / 1000).toFixed(0),
        CloseTypeCode: item.CLOSE_TYPE,
        Comment : item.COMMENT,
        Commission: item.COMMISSION,
        Id: item.Id,
        InitMargin: item.INIT_MARGIN,
        MagicNumber: item.MAGIC_NUMBER.toString(),
        OpenPrice: item.OPEN_PRICE,
        OpenTime: (date.getTimeFromTick(item.OPEN_TIME) / 1000).toFixed(0),
        OpenTypeCode: item.OPEN_TYPE,
        PositionNumber: item.POSITION_NUMBER.toString(),
        ProfitAccountCurrency: item.ACCOUNT_PROFIT,
        ProfitNetAccountCurrency: item.ACCOUNT_PROFIT + item.COMMISSION,
        Side: Types.SIDES[item.SELL_BUY],
        Swap: item.SWAP,
        SymbolId: item.INSTRUMENT.toString(),
        Ticker: tickersList.has(item.INSTRUMENT.toString()) ? tickersList.get(item.INSTRUMENT.toString()).Ticker : undefined,
        UnitVolume: item.AMOUNT
    }));
}
