import BaseModel from "./base";

/**
 * Created by Evgeniy Bondarev
 */

export class AccountTradeInfo extends BaseModel {
    ACCOUNT_ID;
    BALANCE;
    CURRENCY;
    DRAWDOWN;
    FAVORITE_INSTRUMENTS;
    MONTHLY_GAIN;
    POINTS;
    POINTS_PERTRADE;
    PROFIT_TRADES;
    TOTAL_GAIN;
    TOTAL_PROFIT;
    USER_LOGIN;

    // addition attributes
    name; // like DEMO-1990

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }
}