export const NOT_FETCHED = 'TRADE_MARGIN_MODELS_NOT_FETCHED';
export const FETCHING = 'TRADE_MARGIN_MODELS_FETCHING';
export const FETCHED = 'TRADE_MARGIN_MODELS_FETCHED';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}