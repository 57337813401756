import { Record, Map } from 'immutable';

import * as marginModelTypes from '../../actions/trade/margin-models';
import * as userTypes from '../../actions/user/user';
import MarginModel from '../../models/margin-model';


class MarginModelsStore extends Record({
    list: Map(),
    status: marginModelTypes.NOT_FETCHED,
    lastUpdateAt: null,
    isReady: false
}) {
    isFetched() {
        return this.status === marginModelTypes.FETCHED;
    }
}

function createModelFromData(data) {
    return new MarginModel(data);
}

const initialState = new MarginModelsStore();

export default function marginModels(state = initialState, action) {
    switch (action.type) {
        case marginModelTypes.NOT_FETCHED:
        case marginModelTypes.FETCHING:
            return state
                .merge({
                    status: action.type
                });

        case marginModelTypes.FETCHED:
            const listFetched = Map(action.payload).mapEntries(([ key, data ]) => 
                [ Number(key), createModelFromData(data) ]
            );

            return state
                .merge({
                    list: listFetched,
                    status: marginModelTypes.FETCHED,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: Map()
            });

        default:
            return state;
    }
}