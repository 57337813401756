import { Record, Map } from 'immutable';

import * as commissionPaymentsTypes from '../../actions/trade/commission-payments';
import * as userTypes from '../../actions/user/user';
import CommissionPaymentModel from '../../models/commission-payment';


class CommissionPaymentsStore extends Record({
    list: Map(),
    status: commissionPaymentsTypes.COMMISSION_PAYMENTS_NOT_FETCHED,
    lastUpdateAt: null,
    isReady: false
}) {
    isFetched() {
        return this.status === commissionPaymentsTypes.COMMISSION_PAYMENTS_FETCHED;
    }
}

function createModelFromData(data) {
    return new CommissionPaymentModel(data);
}

const initialState = new CommissionPaymentsStore();

export default function commissionPayments(state = initialState, action) {
    switch (action.type) {
        case commissionPaymentsTypes.COMMISSION_PAYMENTS_NOT_FETCHED:
        case commissionPaymentsTypes.COMMISSION_PAYMENTS_FETCHING:
            return state
                .merge({
                    status: action.type
                });

        case commissionPaymentsTypes.COMMISSION_PAYMENTS_FETCHED:
            const fetchedList = Map(action.payload).mapEntries(([ key, data ]) => 
                [ data.Id, createModelFromData(data) ]
            );

            return state
                .merge({
                    list: fetchedList,
                    status: commissionPaymentsTypes.COMMISSION_PAYMENTS_FETCHED,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: Map()
            });

        default:
            return state;
    }
}