import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function Decollete({position, style}) {
    const classPosition = `decollete__${position}`;
    return (
        <div className={`decollete ${classPosition} ${style}-style`} />
    );
}

Decollete.POSITION = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom'
}
Decollete.STYLE = {
    DEFAULT: "",
    ASPHALT: "asphalt",
    LIGHT_GRAY: "lightGray",
    BASE: "base",
    MOBILE_BASE: "mobile-base",
    INSTRUMENT: "instrument",
}

Decollete.propTypes = {
    position: PropTypes.string,
    style: PropTypes.oneOf(_.values(Decollete.STYLE)),
}

Decollete.defaultProps = {
    position: Decollete.POSITION.TOP,
    style: "",
}

export default Decollete;