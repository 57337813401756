import en from './en.json';
import ru from './ru.json';
import ar from './ar.json';
import cn from './cn.json';
import es from './es.json';
import fr from './fr.json';
import pt from './pt.json';

export function getCustomLanguage(lang) {
    switch (lang) {
        case 'en':
            return en;
        case 'ru':
            return ru;
        case 'ar':
            return ar;
        case 'cn':
            return cn;
        case 'es':
            return es;
        case 'fr':
            return fr;
        case 'pt':
            return pt;
        default:
            return 'en';
    }
}