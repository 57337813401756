/**
 * Created by Evgeniy Bondarev
 */
import { Map, Record } from "immutable";

import * as actionTypes from "../../actions/investing/performance-chart";
import * as userTypes from "../../actions/user/user";


class PerformanceChartStore extends Record({
    list: new Map(),
    status: actionTypes.NOT_FETCHED,
    isReady: false,
    isAccountsReady: false
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED;
    }
}

const initialState = new PerformanceChartStore();

export default function subscription(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case actionTypes.FETCHED:
            return state.merge({
                list: new Map(action.payload),
                status: actionTypes.FETCHED,
                isReady: true
            });

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}