import Parser from "rss-parser";

import { logger } from "../../services";
import AppConfig from "../../AppConfig";

export const NOT_FETCHED = 'RSS_NOT_FETCHED';
export const FETCHING = 'RSS_FETCHING';
export const FETCHED = 'RSS_FETCHED';

export function fetching() {
    return {
        type: FETCHING,
    }
}

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function doFetch(lang) {
    return (dispatch, getState) => {
        dispatch(fetching());

        new Parser().parseURL(AppConfig.defines.RSS_NEWS_URL + `?lang=${lang}`)
            .then( ({items}) => dispatch(fetched(items)))
            .catch((e) => {
                logger.error('Error connect to RSS channel', e);
                dispatch(fetched());
            })
    }
}