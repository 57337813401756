/**
 * Created by Evgeniy Bondarev
 */
import BaseModel from './base';
import moment from "moment";

export class SubscriptionModel extends BaseModel {
    CopyAlreadyOpened;
    CopySideMode;
    _FinishDayTime;
    get FinishDayTime() {
        return this._FinishDayTime;
    }
    set FinishDayTime(value) {
        let date;
        if (typeof value === 'string') {
            date = new Date(value);
        } else if (value && value instanceof Date) {
            date = value;
        } else if (!value) {
            this._FinishDayTime = null;
        }

        if (date) {
            SubscriptionModel._correctDateToday(date);
            this._FinishDayTime = date.toISOString();
        }
    }
    FixedLot;
    IgnoreOrdersLargerThanLot;
    IgnoreOrdersSmallerThanLot;
    InvestorAccountNumber;
    InvestorNetPnL;
    MaxOpenOrdersToCopy;
    MaxSlippage;
    MaxTPPoints;
    MaxTradeSizeToMultiply;
    MaxTradesPerDay;
    MinTradeSizeToMultiply;
    MultiplyLot;
    Name;
    OwnSl;
    OwnTp;
    RetriesCount;
    RetriesDelay;
    ReverseTrades;
    SetID;
    SignalAccountNumber;
    SignalExpirationMSec;
    SignalLogin;
    _StartDayTime;
    get StartDayTime() {
        return this._StartDayTime;
    }
    set StartDayTime(value) {
        let date;
        if (typeof value === 'string') {
            date = new Date(value);
        } else if (value && value instanceof Date) {
            date = value;
        } else if (!value) {
            this._StartDayTime = null;
        }

        if (date) {
            SubscriptionModel._correctDateToday(date);
            this._StartDayTime = date.toISOString();
        }
    }
    Status;
    TickersNames;
    TradeCopyingMode;
    UseMagicFilter;
    UserName;

    // addition attributes
    signalAccountBalance = 0;
    accountName;
    accountTotalGain = 0; // in %
    accountMonthlyGain = 0; // in %
    accountDrawdown = 0; // in %
    accountTotalProfit = 0;
    accountCurrency;
    performanceChartReports = null; // not loaded

    _investedAmount = 0;

    set investedAmount(value) {
        this._investedAmount = +value;
    }

    isUnsubscribing = false;

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }

    getAttributesToSave() {
        const notSaveAttributes = ['accountName', 'accountTotalGain', 'accountMonthlyGain', 'accountDrawdown',
            'accountTotalProfit', 'accountCurrency', 'performanceChartReports', 'isUnsubscribing'];
        const normalizeAttribute = (attribute) => {
            if (attribute.indexOf('_') === 0) {
                return attribute.substr(1);
            }

            return attribute;
        };
        const attributesToSave = [];

        Object.keys(this).forEach((attribute) => {
            if (notSaveAttributes.indexOf(attribute) === -1) {
                attributesToSave.push(normalizeAttribute(attribute));
            }
        });

        return attributesToSave
    }

    setDefaultValues() {
        const FinishDayTime = new Date(),
            StartDayTime = new Date();
        FinishDayTime.setUTCHours(23, 59, 59, 0);
        StartDayTime.setUTCHours(0, 0, 0, 0);
        this.Comment = "";
        this.CopyAlreadyOpened = false;
        this.CopySideMode = 0;
        this.FinishDayTime = FinishDayTime.toISOString();
        this.FixedLot = 0;
        this.IgnoreOrdersLargerThanLot = 0;
        this.IgnoreOrdersSmallerThanLot = 0;
        this.InvestorNetPnL = 0;
        this.MaxOpenOrdersToCopy = 0;
        this.MaxSlippage = 0;
        this.MaxTPPoints = 0;
        this.MaxTradeSizeToMultiply = 0;
        this.MaxTradesPerDay = 0;
        this.MinTradeSizeToMultiply = 0;
        this.MultiplyLot = -1;
        this.OwnSl = 0;
        this.OwnTp = 0;
        this.RetriesCount = 0;
        this.RetriesDelay = 0;
        this.ReverseTrades = false;
        this.SetID = 0;
        this.SignalExpirationMSec = 0;
        this.SignalLogin = "";
        this.StartDayTime = StartDayTime.toISOString();
        this.Status = true;
        this.TickersNames = [];
        this.TradeCopyingMode = 1;
        this.UseMagicFilter = false;
        this.UserName = "";
    }

    updateAttributesFromAccounts(accountTradeInfoData) {
        this.accountName = accountTradeInfoData.name;
        this.accountCurrency = accountTradeInfoData.CURRENCY;
        this.accountTotalGain = accountTradeInfoData.TOTAL_GAIN !== null ? accountTradeInfoData.TOTAL_GAIN : this.accountTotalGain;
        this.accountMonthlyGain = accountTradeInfoData.MONTHLY_GAIN !== null ? accountTradeInfoData.MONTHLY_GAIN : this.accountMonthlyGain;
        this.accountDrawdown = accountTradeInfoData.DRAWDOWN !== null ? accountTradeInfoData.DRAWDOWN : this.accountDrawdown;
        this.accountTotalProfit = accountTradeInfoData.TOTAL_PROFIT !== null ? accountTradeInfoData.TOTAL_PROFIT : this.accountTotalProfit;
    }

    static _correctDateToday(date) {
        const currentDate = new Date();
        date.setUTCFullYear(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());

        return date;
    }

    getRules() {
        let rules = [];

        rules.push([
            'StartDayTime, FinishDayTime',
            (attr, value) => {
                if (!moment(value).isValid()) {
                    return "Enter the data in the valid format (HH:MM:SS)";
                }
            },
        ]);

        rules.push([
            'MultiplyLot',
            (attr, value) => {
                if (this.FixedLot === 0 && this.MultiplyLot !== -1 && value < 1) {
                    return "The value of the \"Multiplier\" field cannot be less than 1. Enter the valid value";
                }
            },
        ]);

        return rules;
    }
}