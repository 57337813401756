import React from 'react';
import PropTypes from 'prop-types';

class KeyPressInterceptor extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        const { keyCode } = this.props;

        if (event.keyCode === keyCode) {
            this.props.onKeyPressed();
        }
    }

    render() {
        return (
            <div
                className="KeyPressInterceptor"
            >
                {this.props.children}
            </div>
        );
    }
}

KeyPressInterceptor.defaultProps = {
    onKeyPressed: f => f,
};

KeyPressInterceptor.propTypes = {
    onKeyPressed: PropTypes.func.isRequired,
    keyCode: PropTypes.number.isRequired,
};

export default KeyPressInterceptor;