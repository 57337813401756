import Depository from './Depository';
import QS from 'query-string';


export default class AuthDepository extends Depository {

    static HEADER_TOKEN_NAME = 'Authorization';
    static URL_TOKEN_KEY = 'token';

    constructor(prefix) {
        super(prefix);
        this.keys = {
            ...this.keys,
            TOKEN: this.prefix + 'token',
            TOKEN_EXPIRED_IN: this.prefix + 'tokenExpiredIn',
        };
        this._choseStore();
    }

    saveToken(token, expiredIn, isServerTimeFormat = true) {
        this.store.setItem(this.keys.TOKEN, token || null);
        this.store.setItem(this.keys.TOKEN_EXPIRED_IN, isServerTimeFormat ? expiredIn * 1000 : expiredIn);
    }

    getToken() {
        return this._getToken();
    }

    getTokenData() {
        return {
            token: this._getToken(),
            expiredIn: this.store.getItem(this.keys.TOKEN_EXPIRED_IN),
        }
    }

    haveActiveToken() {
        const tokenData = this.getTokenData();
        return !!(tokenData.token && (tokenData.expiredIn > new Date().getTime()));
    }

    setAutoLogOutAfterTimeExpired(fn, runAfter) {
        let startTimestamp = Date.now();
        let seconds = 0;

        if (this.autoLogOutInterval) {
            clearInterval(this.autoLogOutInterval);
            startTimestamp = Date.now();
        }

        const incrementTime = () => {
            const difference = Math.round(
                (Date.now() - startTimestamp) / 1000
            );

            seconds = difference;
        };
        
        this.autoLogOutInterval = setInterval(() => {
            incrementTime();

            if (seconds >= runAfter) {
                seconds = 0;
                startTimestamp = Date.now();
                clearInterval(this.autoLogOutInterval);
                fn();
            }
        }, 1000);
    
        return this.autoLogOutInterval;
    }

    autoLogOutInterval = null;

    setAutoUpdateToken(fn, runAfter) {
        let startTimestamp = Date.now();
        let seconds = 0;

        if (fn) {
            
            const incrementTime = () => {
                const difference = Math.round(
                    (Date.now() - startTimestamp) / 1000
                );

                seconds = difference;
            };

            this.autoInterval = setInterval(() => {
                incrementTime();

                if (seconds >= runAfter) {
                    seconds = 0;
                    startTimestamp = Date.now();
                    clearInterval(this.autoInterval);
                    fn(this.getToken());
                }
            }, 1000);

        } else {
            seconds = 0;
            startTimestamp = Date.now();
            clearInterval(this.autoInterval);
        }
        return this.autoInterval;
    }

    store = null;
    autoInterval = null;

    _getToken() {
        const token = this.store.getItem(this.keys.TOKEN);
        return token === 'null' ? undefined : token;
    }


    extractAuth2Token(location) {
        const params = QS.parse(location.search);
        return  params.key;
    }

    extractAutoLoginToken(location) {
        const {pathname} = location;
        const autoLoginPath = "enter/";
        const indexPath = pathname.indexOf(autoLoginPath);
        if (indexPath > -1) {
            return pathname.substring(indexPath+autoLoginPath.length, pathname.length);
        }
        return;
    }

    extractForgotPasswordToken(location) {
        const {pathname} = location;
        const autoLoginPath = "change_password/";
        const indexPath = pathname.indexOf(autoLoginPath);
        if (indexPath > -1) {
            return pathname.substring(indexPath+autoLoginPath.length, pathname.length);
        }
        return;
    }

}
