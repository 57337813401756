/**
 * Created by ebondarev
 */
import BaseModel from './base';
import Types from '../classes/types';
import { store } from '../store';

export class PositionModel extends BaseModel {

    static NEW_ADDED_VALID_TIME = 5000; // ms

    AccountNumber;
    Amount;
    AmountExp1;
    AmountExp1Close;
    AmountExp2;
    AmountExp2Close;
    _BuySell;
    set BuySell(value) {
        this._BuySell = value;
        this.sideText = Types.side(value);
    }

    get BuySell() {
        return this._BuySell;
    }

    Comission;
    Comment;
    CrossInstrumentSymbolId;
    Exp1;
    Exp2;
    ExpositionAccount;
    FullName;
    HoldPnLValue;
    HoldPnLMaxValue;
    HoldPnLMinValue;
    HoldPnLType;
    IsActive;
    MarketPrice;
    Name;
    OrderNumber;
    OrderType;
    Price;
    SLOrderNumber;
    SLEnteredUnit;
    SLEnteredVolume;
    SLPrice;
    SLTPBasePrice;
    Swaps;
    SymbolId;
    TPOrderNumber;
    TPEnteredUnit;
    TPEnteredVolume;
    TPPrice;
    Ticker;
    TimeInForce;
    _UTCDateTime;
    InitMargin;
    set UTCDateTime(value) {
        this._UTCDateTime = value;
        const dateTime = new Date(value * 1000);
        const localTime = dateTime.getTime();
        const  currentTimeZoneOffset = dateTime.getTimezoneOffset() * 60000;
        this._dateTime = new Date(localTime + currentTimeZoneOffset);
    }

    get UTCDateTime() {
        return this._UTCDateTime;
    }

    UnitVolume;
    MarkUpPnL;

    //addition
    accountName;
    accountCurrency;
    precision = 5;
    profit = 0;
    accountPnl = 0;
    unitInLots = 0;
    _dateTime;
    sideText = '';
    lastUpdateAt = Date.now();
    bidDiff = 0;
    //addition state
    isClosing = false;
    _isAdded = false;
    _addedEndTimestamp = 0;
    //SLTP entered value type and volume
    TPEnteredUnit;
    TPEnteredVolume;
    SLEnteredUnit;
    SLEnteredVolume;
    
    set dateTime(value) {
        this._dateTime = value;
    }

    get dateTime() {
        return new Date(this._dateTime);
    }

    get isAdded() {
        return this._isAdded;
    }

    set isAdded(value) {
        this._isAdded = value;
        if (value) {
            this._addedEndTimestamp = Date.now() + PositionModel.NEW_ADDED_VALID_TIME;
        }
    }

    get isNewAdded() {
        if (this.isAdded && this._addedEndTimestamp && this._addedEndTimestamp > Date.now()) {
            return true;
        } else if (this._addedEndTimestamp) {
            this._addedEndTimestamp = 0;
        }

        return false;
    }

    get quoteInvested() {
        return Math.abs(this.AmountExp2) * this.leverage;
    }

    get leverage() {
        const { accounts, tickers } = store.getState().trade;
        const account = accounts.list.get(this.AccountNumber);
        const tickerLeverage = tickers.getLeverage(this.SymbolId);

        return tickerLeverage > account.InitLeverage ? tickerLeverage : account.InitLeverage;
    }

    get MTMargin() {
        return this._calculateMTMargin();
    }

    get SLPercent() {
        return (this.possibleLoss * 100) / this.quoteInvested;
    }

    get TPPercent() {
        return (this.possibleProfit * 100) / this.quoteInvested;
    }

    get possibleLoss() {
        return this.SLPrice > 0 ? this.differentSL * this.UnitVolume : 0;
    }

    get possibleProfit() {
        return this.TPPrice > 0 ? this.differentTP * this.UnitVolume : 0;
    }

    get differentSL() {
        const stopLoss = isFinite(+this.SLPrice) ? +this.SLPrice : 0;

        return this.BuySell === Types.SIDE_BUY ? (this.Price - stopLoss) : (stopLoss - this.Price);
    }

    get differentTP() {
        const takeProfit = isFinite(+this.TPPrice) ? +this.TPPrice : 0;
        return this.BuySell === Types.SIDE_BUY ? takeProfit - this.Price : this.Price - takeProfit;
    }

    get plPercent() {
        if (!this.InitMargin) {
            return 0;
        }

        return (this.accountPnl * 100 / this.InitMargin).toFixed(2);
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);

        if(isFinite(+this.TPPrice) && !this.TPEnteredUnit) {
            this.TPEnteredUnit = Types.SLTP_UNIT_PRICE;
            this.TPEnteredVolume = this.TPPrice;
        }

        if(isFinite(+this.SLPrice) && !this.SLEnteredUnit) {
            this.SLEnteredUnit = Types.SLTP_UNIT_PRICE;
            this.SLEnteredVolume = this.SLPrice;
        }
    }

    _calculateMTMargin() {
        const { accounts, tickers } = store.getState().trade;
        const account = accounts.list.get(this.AccountNumber);

        if (account) {
            if (account.MarginModel === Types.ACCOUNT_MODEL_FIXED) return this.InitMargin;

            const exp = (this.Side === 'Sell') ? this.Exp1 : this.Exp2;
            const amountExp = (this.Side === 'Sell') ? this.AmountExp1 : this.AmountExp2;

            const amountBaseCurrency = (exp !== account.BaseCurrency)
                ? amountExp * tickers.getCrossRate(exp, account.BaseCurrency)
                : amountExp;

            return Math.abs(amountBaseCurrency * this.leverage);
        }

        return 0;
    }

    updateAttributesFromQuoteOrTicker(tickerOrQuote) {
        this.lastUpdateAt = Date.now();
        this.MarketPrice = this.BuySell === Types.SIDE_BUY ? tickerOrQuote.Bid : tickerOrQuote.Ask;

        if ((this.BuySell === Types.SIDE_BUY && tickerOrQuote.Bid === 0) ||
            (this.BuySell === Types.SIDE_SELL && tickerOrQuote.Ask === 0)) {
            this.profit = 0;
        } else {
            this.profit = (this.BuySell === Types.SIDE_BUY ? tickerOrQuote.Bid - this.Price : this.Price - tickerOrQuote.Ask)
                * this.UnitVolume;
        }
    }
}