import BaseModel from './base';

export class PositionsGroupModel extends BaseModel {
    symbolId;
    amount;
    unitVolume;
    orderNumbers = [];
    lastOpenTime;
    buySell;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}