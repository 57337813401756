import AppConfig from '../../AppConfig';
import { transport } from '../../services';
import { doUpdateUser } from '../../actions/user/info';

export const NOT_FETCHED = 'INVESTING_STRATEGY_NOT_FETCHED';
export const FETCHING = 'INVESTING_STRATEGY_FETCHING';
export const FETCHED = 'INVESTING_STRATEGY_FETCHED';
export const FETCHED_HISTORY = 'INVESTING_STRATEGY_FETCHED_HISTORY';
export const FETCHING_HISTORY = 'INVESTING_STRATEGY_FETCHING_HISTORY';

export function getStrategyProvider() {
    transport.requestGetStrategyProviders();
}

export function getStrategyHistory(type, id, from, to) {
    transport.requestGetAccountHistory(type, id, from, to);
    return (dispatch, getState) => {
        dispatch(fetchingHistory());
    }
}

export function getStrategyProviderIfNeed(force = false) {
    return (dispatch, getState) => {
        const isReady = getState().investing.strategy.isReady;
        if ((!isReady || force) && AppConfig.features.isEnableInvestingTab) {
            dispatch(fetching());
            getStrategyProvider();
        }
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function fetchedHistory(data) {
    return {
        type: FETCHED_HISTORY,
        payload: data
    }
}

export function fetchingHistory() {
    return {
        type: FETCHING_HISTORY
    }
}

export function doCreateStrategy(model) {
    return (dispatch, getState) => {
        const userInfo = getState().user.info.model;

        if (userInfo && (userInfo.FirstName !== model.firstName || userInfo.LastName !== model.lastName)) {
            const newUserInfo = {
                ...userInfo,
                FirstName: model.firstName,
                LastName: model.lastName,
            };

            dispatch(doUpdateUser(newUserInfo));
        }

        return transport.requestSetSignalAccount(model);
    }
}

export function doRemoveStrategy(AccountNumber) {
    return () => {
        return transport.requestCancelSignalAccount(AccountNumber);
    }
}