import AppConfig from "../AppConfig";

/**
 * Created by ebondarev
 */

function fallback(context, node) {
    if(node) do {
        if (node === context) return true;
    } while ((node = node.parentNode));

    return false;
}

const canUseDOM = !!(typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

class Dom {
    static checkClickOnElement(el, targetEl) {
        if (el === targetEl) {
            return true;
        } else if (el.parentElement) {
            return Dom.checkClickOnElement(el.parentElement, targetEl);
        }

        return false;
    }    

    static isShallowEqual(source, target) {
        if (source && !target) return false;
        else if (!source && target) return false;
    
        if (source && target) {
            for (var key in source) {
                if (!target.hasOwnProperty(key) || source[key] !== target[key]) {
                    return false;
                }
            }
        }
    
        return true;
    }

    static getResponsiveRatio() {
        const responsiveSize = getComputedStyle(document.documentElement).getPropertyValue('--size');
        return (responsiveSize && AppConfig.features.isEnableResponsiveMode) ? +responsiveSize : 1;
    }

    static scriptLoader(url, name) {
        return new Promise((resolve, reject) => {
            try {
                const scriptDOM = document.createElement('script');
                scriptDOM.src = url;
                scriptDOM.async = true;
                scriptDOM.onload = () => resolve({[name]: window[name]});
                document.body.appendChild(scriptDOM);
            } catch(e) {
                reject(e);
            }
        })
    }

    static contains() {
        return canUseDOM ? function(context, node) {
            if (context.contains) {
                return context.contains(node);
            } else if (context.compareDocumentPosition) {
                return context === node || !!(context.compareDocumentPosition(node) & 16);
            } else {
                return fallback(context, node);
            }
        } : fallback;
    } 
}

export default Dom;
