import moment from "moment/moment";

import BaseModel from './base';
import { AppConfig } from '../AppConfig';
import { date } from '../utils';
import Types from '../classes/types';
import { lang } from '../services';

export class TransactionHistoryModel extends BaseModel {
    TYPE = Types.HISTORY_TYPE_TRANSACTION;
    ACCOUNT_ID;
    ADDITIONAL_INFO;
    BANK_ADDRESS;
    BANK_PHONE;
    CARD_HOLDER;
    CC_BANK_NAME;
    CC_COUNTRY;
    CC_TYPE;
    COMMENT;
    COMMISSION;
    CONVERSION_CURRENCY;
    CONVERSION_RATE;
    CURRENCY;
    DATE;
    FIRST_NAME;
    GATE_WAY;
    IBAN;
    IP;
    IP_LOCATION;
    ISSUE_REASON;
    LAST_NAME;
    LAST_STATUS_UPDATE;
    LAST_UPDATE;
    OPERATOR_ID;
    PAYMENT_ID;
    PAYMENT_METHOD;
    PAYMENT_TYPE;
    STATUS;
    SUM;
    SWIFT;
    TRANSACTION_ID;
    USER_ADDRESS;
    USER_ID;

    get DateTime() {
        return moment(date.getTimeFromTick(this.DATE)).format("DD.MM.YYYY HH:mm:ss");
    }

    get SortTime() {
        return new Date(date.getTimeFromTick(this.DATE)).getTime();
    }

    get OperationType() {
        const { showBonusCreditsInReport } = AppConfig.features;

        if (this.SUM > 0 && this.PAYMENT_TYPE === 0) {
            return Types.BALANCE_OPERATION_TYPES[0];
        }
        
        if (this.SUM > 0 && this.PAYMENT_TYPE === 1) {
            if(showBonusCreditsInReport) {
                return Types.BALANCE_OPERATION_TYPES[2];
            } else {
                return Types.BALANCE_OPERATION_TYPES[0];
            }
        }
        
        if (this.SUM < 0 && this.PAYMENT_TYPE === 0) {
            return Types.BALANCE_OPERATION_TYPES[1];
        } else {
            if(showBonusCreditsInReport) {
                return Types.BALANCE_OPERATION_TYPES[3];
            } else {
                return Types.BALANCE_OPERATION_TYPES[1]
            }
        }
    }

    get StatusName() {
        if(this.STATUS === 1 || this.STATUS === 4) {
            return Types.BALANCE_OPERATION_STATUS[0]
        }
        return Types.BALANCE_OPERATION_STATUS[this.STATUS];
    }

    get Quantity() {
        return lang.number(this.SUM, AppConfig.getPaymentCurrencyPrecision(this.CURRENCY)) + ' ' + this.CURRENCY;
    }

    get TotalAmount() {
        return this.sumSign + lang.number(Math.abs(this.SUM) - this.COMMISSION, AppConfig.getPaymentCurrencyPrecision(this.CURRENCY)) + ' ' + this.CURRENCY;
    }

    get sumSign() {
        if (this.SUM < 0) {
            return '-';
        }

        return '';
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }
}