import BaseModel from './base';
import date from '../utils/date';
import math from '../utils/math';
import { store } from '../store';

class TradeSessionModel extends BaseModel {
    Id;
    Comment;
    Name;

    _sessionSchedule;

    get SessionSchedule() {
        return this._sessionSchedule;
    }

    set SessionSchedule(data) {
        this._sessionSchedule = Object.values(data);
    }

    SummerSwitchSchema;

    _utcWinterOffset;

    set UTCWinterOffset(offset) {
        this._utcWinterOffset = -offset; // utc offset from server has wrong sign
    }

    get UTCWinterOffset() {
        return this._utcWinterOffset;
    }

    // session state
    isOpen; // true/false
    nextChangeTime;
    nextId;

    get openDelay() {
        return this.isOpen ? 0 : this.nextChangeTime - new Date();
    }

    constructor(initData) {
        super();
        this.copyFrom(initData);

        this.initState();
    }

    initState() {
        const offsetMS = store.getState().app.serverTimeOffset * 1000;
        const now = new Date(Date.now() + offsetMS);

        for (let nWeek = -1; nWeek < 2; nWeek++) {
            let beginWeek = date.getBeginWeek(now);
            beginWeek.setDate(beginWeek.getDate() + nWeek * 7);

            for (let i = 0; i < this._sessionSchedule.length; i++) {
                const schedule = this._sessionSchedule[i];
                const closeDay = this.getCloseDay(schedule.OpenDayOfWeek, schedule.CloseDayOfWeek);

                const openTime = this.getScheduleLocalTime(beginWeek, schedule.OpenDayOfWeek, schedule.OpenTimeSeconds);
                if (now < openTime) {
                    this.isOpen = false;
                    this.nextChangeTime = openTime;
                    this.nextId = schedule.Id;
                    return;
                }

                const closeTime = this.getScheduleLocalTime(beginWeek, closeDay, schedule.CloseTimeSeconds);
                if (now < closeTime) {
                    this.isOpen = true;
                    this.nextChangeTime = closeTime;
                    this.nextId = null;
                    return;
                }
            }
        }
    }

    changeState() {
        this.initState();
    }

    getLocalSchedule(beginWeek) {
        const localSchedule = [];

        for (let i = 0; i < this._sessionSchedule.length; i++) {
            const schedule = this._sessionSchedule[i];
            const closeDay = this.getCloseDay(schedule.OpenDayOfWeek, schedule.CloseDayOfWeek);
            const openTime = this.getScheduleLocalTime(beginWeek, schedule.OpenDayOfWeek, schedule.OpenTimeSeconds);
            const closeTime = this.getScheduleLocalTime(beginWeek, closeDay, schedule.CloseTimeSeconds);
            const isNext = this.isNextSession(schedule.Id);

            localSchedule.push({ openTime, closeTime, isNext });
        }

        return localSchedule;
    }

    getScheduleLocalTime(beginWeek, weekDay, seconds) {
        const zoneOffset = this.UTCWinterOffset - beginWeek.getTimezoneOffset();
        const serverOffset = store.getState().app.serverTimeOffset;
        let time = new Date(beginWeek.getFullYear(), beginWeek.getMonth(), beginWeek.getDate() + weekDay, 0, 0 + zoneOffset);

        time.setSeconds(time.getSeconds() + seconds + math.getInvertNumber(serverOffset));

        const scheduleOffset = date.getUTCOffset(time, this.UTCWinterOffset, this.SummerSwitchSchema);

        // correcting for DST
        if (scheduleOffset !== this.UTCWinterOffset) {
            time.setMinutes(time.getMinutes() + scheduleOffset - this.UTCWinterOffset);
        }

        return time;
    }

    getCloseDay(openDayOfWeek, closeDayOfWeek) {
        return closeDayOfWeek >= openDayOfWeek ? closeDayOfWeek : 7 + closeDayOfWeek;
    }

    isNextSession(id) {
        return !this.isOpen && id === this.nextId;
    }
}

export default TradeSessionModel;