import Api from './api';

class TransactionApi extends Api {
    constructor(config = {}) {
        const { errorHandler, getWdApiToken, headerTokenName, logger, baseUrl, defaultData } = config;
        super({ errorHandler, logger, baseUrl, defaultData });

        this._getWdApiToken = getWdApiToken;
        this._headerTokenName = headerTokenName;
    }

    post(url, body = {}, params = {}) {
        return this._getWdApiToken().then((token) => {
            if (token) {
                params.headers || (params.headers = {});
                params.headers[this._headerTokenName] = "Bearer " + token;
            }
            return super.post(url, body, params);
        });
    }
}

export default TransactionApi;
