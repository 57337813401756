import BaseModel from './base';

class CommissionPaymentModel extends BaseModel {
    AccountId;
    CommissionPaymentType;
    Currency;
    DateTimeFrom;
    DateTimeTo;
    Id;
    FlateRate;
    PaymentSystem;
    PercentageRate;
    RepeatType;
    MaxAmount;
    MinAmount;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }

    get weight() {
        return 4 * +!!this.PaymentSystem + 2 * +!!this.Currency + 1 * +!!this.MaxAmount
    }
}

export default CommissionPaymentModel;