import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import classNames from 'classnames';

import AppConfig from '../../AppConfig';

const { showPlatformName } = AppConfig.loadingSpinner;

class LoadingSpinner extends PureComponent
{
    static FADE_IN_FULL = 'full';
    static FADE_IN_HALF = 'half';
    static FADE_IN_QUARTER = 'quarter';
    static FADE_IN_NONE = 'none';

    render()
    {
        return (
            <div className={classNames("LoadingSpinner", this.props.className)}>
                {(this.props.isEnableSpinner &&
                    <Spinner className="LoadingSpinner-element" fadeIn={this.props.fadeIn} name={this.props.name}/>)}
                {(showPlatformName || this.props.showText) && <div className={this.props.classNameText}>{this.props.text}</div>}
            </div>
        );
    }
}

LoadingSpinner.defaultProps = {
    isEnableSpinner: true,
    showText: false,
    name: 'line-scale-pulse-out',
    className: '',
    classNameText: '',
    fadeIn: LoadingSpinner.FADE_IN_FULL
};

LoadingSpinner.propTypes = {
    text: PropTypes.string,
    showText: PropTypes.bool,
    isEnableSpinner: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    classNameText: PropTypes.string,
    fadeIn: PropTypes.string,
};

export default LoadingSpinner;