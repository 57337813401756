/**
 * Created by svishnov
 */
import { Record } from 'immutable';

import * as appActions from '../../actions/app';
import * as chartActions from '../../actions/trade/chart';
import * as depositActions from '../../actions/trade/deposit';
import * as withdrawActions from '../../actions/trade/withdraw';
import * as accountActions from '../../actions/trade/accounts';
import * as orderActions from '../../actions/trade/orders';
import * as positionActions from '../../actions/trade/positions';
import * as tickerActions from '../../actions/trade/tickers';
import * as tickerGroupActions from '../../actions/trade/tickers-groups';
import * as trackingActions from '../../actions/tracking/tracking';
import * as activityLogActions from '../../actions/trade/activity-log';
import * as accountHistoryActions from '../../actions/trade/account-history';
import * as exchange from '../../actions/trade/report';
import * as tradeTabsActions from '../../actions/forms/trade-tabs';
import * as settingsActions from '../../actions/user/settings';
import * as filterActions from '../../actions/trade/filters';
import * as messagesActions from '../../actions/message-box';

export const handledActions = () => {
    return [
        messagesActions.SHOW_WINDOW,
        messagesActions.CLOSE_WINDOW,

        appActions.ACTIVE_TAB,
        appActions.SET_LANGUAGE,
        appActions.ACTIVE_MOBILE_PANEL_MENU,
        appActions.ACTIVE_PANEL_MENU,
        appActions.OPEN_SIDE_PANEL,
        appActions.CLOSE_SIDE_PANEL,
        appActions.PAGE_NAME,
        appActions.SHOW_SETTINGS_GENERAL,
        appActions.SHOW_ACTIVITY_LOG_MODAL,
        appActions.SHOW_CONSOLIDATED_REPORT_MODAL,
        appActions.SHOW_HISTORY_MODAL,
        appActions.SHOW_MARGIN_HISTORY_MODAL,
        appActions.SHOW_INSTRUMENT_LIST,
        appActions.SHOW_WITHDRAW_MODAL,
        appActions.SHOW_DEPOSIT_MODAL,
        appActions.SHOW_DEPOSIT_DEMO_ACCOUNT,
        appActions.SHOW_DEPOSIT_BXC_MODAL,
        appActions.CHANGE_TRADE_MODE,
        appActions.SHOW_WALLETS_MODAL,
        appActions.SHOW_PROFILE_MODAL,
        appActions.SHOW_CALL_BACK_FORM,
        appActions.SHOW_ON_BOARDING_MODAL,
        appActions.SHOW_KYC_FINISH_NOTIFICATION,
        appActions.SHOW_CHART_SETTINGS_DIALOG,
        appActions.SHOW_ACCOUNT_REGISTER_MODAL,
        appActions.SHOW_CREATE_ACCOUNT_MODAL,
        appActions.SHOW_CHANGE_PASSWORD_MODAL,
        appActions.SET_INVESTING_ID,
        appActions.SET_INVESTING_TAB,
        appActions.SHOW_INTERCHANGE_MODAL,
        appActions.SHOW_INSTRUMENT_DETAILS,
        appActions.SHOW_TRANSACTION_HISTORY_MODAL,
        appActions.SHOW_EXCHANGE_HISTORY_MODAL,
        appActions.SHOW_TWOFA_MODAL,
        appActions.SHOW_SECURITY_MODAL,
        appActions.START_MT,
        appActions.HIDE_MT_MESSAGE,
        appActions.SET_MOBILE_ROUTE,

        accountActions.CHANGE_ACCOUNT_NUMBER,
        accountActions.CHANGED_CURRENT_ACCOUNT,
        accountActions.ADD_BTC_ADDRESS,
        accountActions.SHOW_ZERO_BALANCE_ASSETS,
        accountActions.CHANGE_ASSET_SHOW_TYPE,
        accountActions.CHANGE_ASSET_SORT_ATTRIBUTE,
        accountActions.CHANGE_ASSET_SORT_DIRECTION,
        accountActions.CHANGE_SEARCH_CURRENCY_KEYWORD,
        accountActions.CHANGE_CREATE_ACCOUNT_DEALER_NAME,
        accountActions.CHANGE_EXCHANGE_CONVERT_CURRENCY,

        depositActions.DEPOSIT_CHANGE_ACCOUNT,
        depositActions.DEPOSIT_CHANGE_PAYMENT_METHOD,
        depositActions.DEPOSIT_CHANGE_PAYMENT_METHOD_AND_ACCOUNT,
        depositActions.DEPOSIT_CHANGE_DEMO_ACCOUNT_NUMBER,

        withdrawActions.WITHDRAW_CHANGE_ACCOUNT,
        withdrawActions.WITHDRAW_CHANGE_PAYMENT_METHOD,
        withdrawActions.WITHDRAW_CHANGE_PAYMENT_METHOD_AND_ACCOUNT,

        orderActions.ADD,
        orderActions.UPDATE,
        orderActions.REMOVE,
        orderActions.CHANGE_SORT_ATTRIBUTE,
        orderActions.CHANGE_SORT_DIRECTION,
        orderActions.TOGGLE_DETAILS_OF_ORDER,
        orderActions.ACTIVE_ORDER_ON_CHART,
        orderActions.UNSET_ACTIVE_ORDER,

        positionActions.ADD,
        positionActions.UPDATE,
        positionActions.REMOVE,
        positionActions.CHANGE_SORT_ATTRIBUTE,
        positionActions.CHANGE_SORT_DIRECTION,
        positionActions.TOGGLE_DETAILS_OF_POSITION,
        positionActions.ACTIVE_POSITION_ON_CHART,
        positionActions.UNSET_ACTIVE_POSITION,
        positionActions.CHANGE_PNL_CURRENCY,

        chartActions.ADD_TAB,
        chartActions.UPDATE_TABS,
        chartActions.REMOVE_TAB,
        chartActions.CHANGE_ACTIVE_TAB,
        chartActions.CHANGE_CHART_TYPE,
        chartActions.CHANGE_CHART_FRAME,
        chartActions.CHANGE_CHART_SETTINGS,
        chartActions.RESET_CHART_SETTINGS,
        chartActions.CHANGE_CHART_FRAME_REQUEST,
        chartActions.CANCEL_CHART_FRAME_REQUEST,
        chartActions.CHANGE_CHART_AUTO_INTERVAL_SWITCH,
        chartActions.CHANGE_CHART_IS_MONOCHROME,
        chartActions.TOGGLE_CHART_TOOLS,
        chartActions.SET_STOCK_CHARTS,
        chartActions.CHART_DATA_UPDATE,
        chartActions.INDICATOR_ADD,
        chartActions.INDICATOR_REMOVE,
        chartActions.INDICATOR_REPLACE,
        chartActions.ALL_INDICATORS_REPLACE,
        chartActions.INDICATOR_UPDATE,
        chartActions.INDICATOR_TPL_ADD,
        chartActions.INDICATOR_TPL_REMOVE,
        chartActions.INDICATOR_TPL_UPDATE,

        activityLogActions.FETCHING,
        activityLogActions.FETCHED,
        activityLogActions.SORT_ATTRIBUTE,
        activityLogActions.SORT_DIRECTION,
        activityLogActions.DATE_PERIOD,
        activityLogActions.DATE_PERIOD_RANGE,
        activityLogActions.SET_ACCOUNT,
        activityLogActions.CLEAR,

        accountHistoryActions.FETCHING,
        accountHistoryActions.FETCHED,
        accountHistoryActions.SORT_ATTRIBUTE,
        accountHistoryActions.SORT_DIRECTION,
        accountHistoryActions.DATE_PERIOD,
        accountHistoryActions.DATE_PERIOD_RANGE,
        accountHistoryActions.SET_ACCOUNT,
        accountHistoryActions.CLEAR,

        exchange.actions.EXCHANGE_FETCHING,
        exchange.actions.EXCHANGE_FETCHED,
        exchange.actions.EXCHANGE_CHANGE_SORT_ATTRIBUTE,
        exchange.actions.EXCHANGE_CHANGE_SORT_DIRECTION,
        exchange.actions.EXCHANGE_CHANGE_DATE_PERIOD,
        exchange.actions.EXCHANGE_CHANGE_DATE_PERIOD_RANGE,
        exchange.actions.EXCHANGE_CHANGE_ACCOUNT_NUMBER,
        exchange.actions.EXCHANGE_CLEAR,

        exchange.actions.TH_FETCHING,
        exchange.actions.TH_FETCHED,
        exchange.actions.TH_CHANGE_SORT_ATTRIBUTE,
        exchange.actions.TH_CHANGE_SORT_DIRECTION,
        exchange.actions.TH_CHANGE_DATE_PERIOD,
        exchange.actions.TH_CHANGE_DATE_PERIOD_RANGE,
        exchange.actions.TH_CHANGE_ACCOUNT_NUMBER,
        exchange.actions.TH_CLEAR,

        tradeTabsActions.CREATE,
        tradeTabsActions.UPDATE,
        tradeTabsActions.TRADE_TABS__REMOVE_TAB,
        tradeTabsActions.TRADE_TABS__REMOVE_ALL,

        tickerActions.CHANGE_DETAIL_SORT_ATTRIBUTE,
        tickerActions.CHANGE_DETAIL_SORT_DIRECTION,
        tickerActions.CHANGE_DETAIL_SEARCH_TICKER,
        tickerActions.CHAGNE_TRADE_TICKER,
        tickerActions.CHANGE_TICKERS_SEARCH,
        tickerActions.CHAGNE_TRADE_SIDE,
        tickerActions.UPDATE_SEARCH,
        tickerActions.UPDATE_TICKER_GROUP_FILTER,
        tickerActions.MARK_TICKER,

        tickerGroupActions.CHANGE_TICKERS_GROUP,

        settingsActions.UPDATE,

        filterActions.UPDATE_ACTIVITY_LOG_FILTERS,
        filterActions.UPDATE_HISTORY_FILTERS,
        filterActions.CLEAR_FILTERS,
        filterActions.UPDATE_SHOW_ZERO_BALANCE,
        filterActions.UPDATE_WALLETS_SEARCH_KEY,
    ];
}


class TrackStore extends Record({
}) {

}

const initialState = new TrackStore();

export default function track(state = initialState, action) {
    switch (action.type) {

        case trackingActions.APP_MOUSE_CLICK:
            {
                let cursorData = action.payload.Data.Cursor;
                document.dispatchEvent(new MouseEvent("handleMouseClick", {
                    bubbles: true,
                    cancelable: true,
                    button: cursorData.Button,
                    clientX: cursorData.X * cursorData.InnerWidth,
                    clientY: cursorData.Y * cursorData.InnerHeight,
                    offsetX: cursorData.OffsetX,
                    offsetY: cursorData.OffsetY,
                }));
                return state;
            }

        case trackingActions.APP_MOUSE_LOCATION:
            {
                let cursorData = action.payload.Data.Cursor;
                document.dispatchEvent(new MouseEvent('handleMouseLocation', {
                    bubbles: true,
                    cancelable: true,
                    button: cursorData.Button,
                    clientX: cursorData.X * cursorData.InnerWidth,
                    clientY: cursorData.Y * cursorData.InnerHeight,
                    offsetX: cursorData.OffsetX,
                    offsetY: cursorData.OffsetY,
                }));
                return state;
            }

        case trackingActions.COMPONENT_GET_STATE:
            {
                trackingActions.trackedComponents.forEach((component) => component.fetchComponentState(action.data));
                return state;
            }

        case trackingActions.COMPONENT_UPDATE_STATE:
            {
                let trackComponent = trackingActions.getTrackedComponent(action.payload.Name);
                if (trackComponent) trackComponent.handleComponentState({
                    name: action.payload.Name,
                    state: action.payload.State,
                });
                return state;
            }

        default:
            return null;
    }
}