import Types from './types';
import * as messageBoxAction from '../actions/message-box';
import { store } from '../store';
import { lang } from '../services';

const closeModal = () =>
    store.dispatch(messageBoxAction.closeWindow({ index: 0 }));

class ErrorHandler {
    secondsLeft = 0;
    countDown = null;
    isShowTooManyRequestsWindow = false;
    lastErrorCode = null;
    userClosedErrorWindow = false;
    static blockRequests = false;

    onCatch(error) {
        if(error && error.name === 'TypeError' && error.message === 'Failed to fetch' ) {
            const message = "Please, check your internet connection";
            store.dispatch(
                messageBoxAction.doShowError({
                    message: lang.t(message),
                    title: lang.t('Error')
            }));
        }

        if (error && error.cause && error.cause.status) {
            this.lastErrorCode = error.cause.status;
        }

        if (
            (error && error.cause && error.cause.status === Types.HTTP_CODE_TOO_MANY_REQUESTS) ||
            this.lastErrorCode === Types.HTTP_CODE_TOO_MANY_REQUESTS
        ) {
            this.blockRequests = true;
            this.isShowTooManyRequestsWindow = true;

            if (error && error.cause && error.cause.status) {
                this.secondsLeft = 60;
            }

            this.startTimer(
                () => this.showTooManyRequestsWindow(),
                () => (this.isShowTooManyRequestsWindow = false)
            );
        }
    }

    startTimer(startCallback, stopCallback) {
        if (!this.countDown) {
            this.countDown = setInterval(() => {
                if (this.secondsLeft === 0) return this.stopTimer(stopCallback);

                this.secondsLeft = this.secondsLeft - 1;
                closeModal();
                startCallback();
            }, 1000);
        } else {
            startCallback();
        }
    }

    stopTimer(callback) {
        clearInterval(this.countDown);
        this.blockRequests = false;
        this.countDown = null;
        this.lastErrorCode = null;
        callback();
        closeModal();
    }

    showTooManyRequestsWindow() {
        if (!this.isShowTooManyRequestsWindow) return;

        store.dispatch(
            messageBoxAction.doShowError({
                message: lang.t(
                    'Too many requests. Please wait {secondsLeft} seconds before trying again',
                    { secondsLeft: this.secondsLeft + 1 }
                ),
                title: lang.t('Too many requests'),
                closeCallback: () => {
                    this.isShowTooManyRequestsWindow = false;
                },
            })
        );
    }
}

export default ErrorHandler;
