import moment from 'moment/moment';
import BaseModel from './base';
import { date } from '../utils';
import { math } from '../utils';
import Types from '../classes/types';

export class ExchangeHistoryModel extends BaseModel {
    TYPE = Types.HISTORY_TYPE_EXCHANGE;
    ORDER_ID;
    EXEC_DATETIME;
    SPENT_AMOUNT;
    SPENT_CURRENCY;
    COMMISSION_AMOUNT;
    COMMISSION_CURRENCY;
    RECEIVED_AMOUNT;
    RECEIVED_CURRENCY;
    EXCHANGE_RATE;

    get Spent() {
        return `${math.roundToPrecision(this.SPENT_AMOUNT, Types.DEFAULT_REPORTS_PRECISION)} ${this.SPENT_CURRENCY}`;
    }

    get Commission() {
        return `${math.roundToPrecision(this.COMMISSION_AMOUNT, Types.DEFAULT_REPORTS_PRECISION)} ${this.COMMISSION_CURRENCY}`;
    }

    get Received() {
        return `${math.roundToPrecision(this.RECEIVED_AMOUNT, Types.DEFAULT_REPORTS_PRECISION)} ${this.RECEIVED_CURRENCY}`;
    }

    get ExchangeRate() {
        return math.roundToPrecision(this.EXCHANGE_RATE, Types.DEFAULT_REPORTS_PRECISION);
    }

    get DateTime() {
        return moment(date.getTimeFromTick(this.EXEC_DATETIME)).format('DD.MM.YYYY HH:mm:ss');
    }

    get SortTime() {
        return new Date(date.getTimeFromTick(this.EXEC_DATETIME)).getTime();
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }
}
