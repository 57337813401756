import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import SvgIcon from '../../common/components/SvgIcon';

class VerticalScrollButton extends React.Component {
    defaultStep = 20;
    scrollDelay = 100;
    scrollTimerId;

    constructor(props) {
        super(props);

        this.state = {
            showUp: false,
            showDown: false,
        }
    }

    componentDidMount() {
        const values = this.refs.scrollbar.getValues();

        if (values.scrollHeight > values.clientHeight) {
            this.updateButtonVisible(values);
        }
    }

    componentWillUnmount() {
        this.stopScroll();
    }

    componentDidUpdate(prevProps) {
        this.updateButtonVisible(this.refs.scrollbar.getValues());
    }

    handleScroll = () => {
        this.updateButtonVisible(this.refs.scrollbar.getValues());
    }

    updateButtonVisible = scrollValues => {
        const showUp = scrollValues.scrollTop > 0;
        const showDown = Math.ceil(scrollValues.scrollTop) + scrollValues.clientHeight < scrollValues.scrollHeight;

        if (this.state.showUp !== showUp || this.state.showDown !== showDown) {
            this.setState({ showUp, showDown });
        }
    }

    getStep = () => {
        const scrollHeight = this.refs.scrollbar.getScrollHeight();
        const elementCount = this.props.elementCount;

        if (elementCount) {
            return Math.ceil(scrollHeight / elementCount);
        }

        return this.defaultStep;
    }

    startScroll = direction => {
        const scrollFunc = direction === 'up' ? this.scrollUp : this.scrollDown;
        const step = this.getStep();

        this.stopScroll();

        scrollFunc(step);

        this.scrollTimerId = setInterval(() => {
            const scroll = direction === 'up' ? this.state.showUp : this.state.showDown;

            scroll ? scrollFunc(step) : this.stopScroll();
        }, this.scrollDelay);
    }

    stopScroll = () => {
        clearInterval(this.scrollTimerId);
    }

    scrollUp = step => {
        this.refs.scrollbar.scrollTop(this.refs.scrollbar.getScrollTop() - step);
    }

    scrollDown = step => {
        this.refs.scrollbar.scrollTop(this.refs.scrollbar.getScrollTop() + step);
    }

    render() {
        const { showUp, showDown } = this.state;
        const hideBars = {style: {display: 'none'}};

        return (
            <div className={classNames('VerticalScrollButton', this.props.className)}>
                {showUp &&
                <React.Fragment>
                    <div
                        className="VerticalScrollButton__btn VerticalScrollButton__btn__up"
                        onMouseDown={() => this.startScroll('up')}
                        onMouseUp={this.stopScroll}
                    >
                        <SvgIcon
                            className="VerticalScrollButton__btn__icon"
                            icon="ARROW_UP"
                        />
                    </div>
                    <div className="VerticalScrollButton__btn__bottomShadow background-gradient" />
                </React.Fragment>}
                <Scrollbars
                    ref="scrollbar"
                    onScroll={this.handleScroll}
                    renderTrackHorizontal={props => <div {...props} {...hideBars} />}
                    renderTrackVertical={props => <div {...props} {...hideBars} />}
                >
                    {this.props.children}
                </Scrollbars>
                {showDown &&
                <React.Fragment>
                    <div
                        className="VerticalScrollButton__btn VerticalScrollButton__btn__down"
                        onMouseDown={() => this.startScroll('down')}
                        onMouseUp={this.stopScroll}
                    >
                        <SvgIcon
                            className="VerticalScrollButton__btn__icon"
                            icon="ARROW_UP"
                        />
                    </div>
                    <div className="VerticalScrollButton__btn__topShadow background-gradient bottom-location" />
                </React.Fragment>}
            </div>
        );
    }
}

VerticalScrollButton.defaultProps = {
    className: '',
};

VerticalScrollButton.propTypes = {
    className: PropTypes.string,
    elementCount: PropTypes.number.isRequired,
};

export default VerticalScrollButton;