/**
 * Created by Evgeniy Bondarev
 */
import Api from "./api";
import authService from '../services/auth-service';
import AuthDepository from './auth-depository';


class ApiJsonToken extends Api {
    static MODE_WITHOUT_TOKEN = 'MODE_WITHOUT_TOKEN';
    static MODE_WITH_TOKEN = 'MODE_WITH_TOKEN';

    _currentMode = ApiJsonToken.MODE_WITH_TOKEN;
    _authData = null;
    _tokenUpdateDiff = 0;

    onLogOut = f => f;

    setAuthData(data) {
        if (this._authData && data && this._authData.Login !== data.Login) {
            this._clearToken();
        }

        this._authData = data;
        if (!data) {
            this._clearToken();
            return;
        }

        if (this._currentMode !== ApiJsonToken.MODE_WITH_TOKEN) {
            this._currentMode = ApiJsonToken.MODE_WITH_TOKEN;
        }
    }

    getAuthData() {
        return this._authData;
    }

    get token() {
        return authService.getToken();
    }

    get tokenExpire() {
        return this._tokenExpire;
    }

    _createTokenEndPoint;
    get createTokenEndPoint() {
        return this._createTokenEndPoint;
    }

    _clearToken() {
        authService.removeData();
    }

    isExpiredToken() {
        return !authService.haveActiveToken();
    }

    /**
     * @see super class for all params of config
     * @param config
     */
    constructor(config = {}) {
        super(config);
        const { createTokenEndPoint, onLogOut, tokenUpdateDiff } = config;

        this._createTokenEndPoint = createTokenEndPoint;
        this._tokenUpdateDiff = tokenUpdateDiff;
        this.onLogOut = onLogOut || (f => f);
    }

    get(url, params = {}) {
        return this.getToken().then((token) => {
            if (token) {
                params.headers || (params.headers = {});
                params.headers[AuthDepository.HEADER_TOKEN_NAME] = "Bearer " + token;
            }

            return super.get(url, params);
        });
    }

    post(url, body = {}, params = {}) {
        return this.getToken().then((token) => {
            if (token) {
                params.headers || (params.headers = {});
                params.headers[AuthDepository.HEADER_TOKEN_NAME] = "Bearer " + token;
            }

            return super.post(url, body, params);
        });
    }

    getToken() {
        return new Promise((resolve, reject) => {
            if (authService.haveActiveToken()) {
                resolve(authService.getToken());
                return;
            }

            if (this._currentMode === ApiJsonToken.MODE_WITHOUT_TOKEN || !this.getAuthData()) {
                resolve(null);
                return;
            }

            let authData = this.getAuthData();

            super.post(this._createTokenEndPoint, authData)
                .then((response) => {
                    const {AccessToken, ExpiredIn} = response.data.Result;

                    authService.saveToken(AccessToken, ExpiredIn);
                    resolve(AccessToken);
                })
                .catch((e) => {
                    this._currentMode = ApiJsonToken.MODE_WITHOUT_TOKEN;
                    resolve(null);
                });
        });
    }

    getTokenAndReturnUserId() {
        return new Promise((resolve, reject) => {

            let authData = this.getAuthData();
            super.post(this._createTokenEndPoint, authData)
                .then((response) => {
                    if (!response) {
                        reject('TRANSPORT_ERROR');
                    }
                    const { AccessToken, UserId, Lifetime } = (response.data && response.data.Result) || {};
                    if (AccessToken) {
                        const lifetime = Lifetime || 1440; // TODO remove this temporary solution for old server version
                        const expiredIn = this._getExpiredIn(lifetime);
                        authService.saveToken(AccessToken, expiredIn);
                        this._autoUpdateToken(lifetime);

                        resolve({
                            userId: UserId,
                            token: AccessToken,
                            expiredIn: expiredIn,
                            lifetime: lifetime,
                        });
                    } else {
                        resolve({
                            error: response.data && response.data.Error,
                            status: response.status,
                        })
                    }

                })
                .catch(() => {
                    this._currentMode = ApiJsonToken.MODE_WITHOUT_TOKEN;
                    resolve({});
                });
        });
    }

    updateToken(token, params = {}) {
        if (token) {
            params.headers || (params.headers = {});
            params.headers[AuthDepository.HEADER_TOKEN_NAME] = "Bearer " + token;
        }

        return new Promise((resolve, reject) => {
            super.post('UpdateToken', {}, params)
                .then((response) => {
                    const {AccessToken, UserId, Lifetime} = response.data.Result;
                    const lifetime = Lifetime || 1440; // TODO remove this temporary solution for old server version
                    const expiredIn = this._getExpiredIn(lifetime);

                    authService.saveToken(AccessToken, expiredIn);
                    this._autoUpdateToken(lifetime);

                    resolve({
                        userId: UserId,
                        token: AccessToken,
                        expiredIn: expiredIn,
                        lifetime: lifetime,
                    });
                })
                .catch(() => {
                    this._currentMode = ApiJsonToken.MODE_WITHOUT_TOKEN;
                    reject(null);
                });
        })
    }

    _getExpiredIn(lifetime){
        return new Date().getTime()/1000 + lifetime;
    }

    _autoUpdateToken(lifetime) {
        const tokenUpdateDiff = this._tokenUpdateDiff || 60; // In case if this._tokenUpdateDiff is undefined use 60 by default
        const repeatTime = (lifetime - tokenUpdateDiff);

        authService.setAutoUpdateToken((token) => { // it need for do only ONE LISTENER time to update
            this.updateToken(token)
            .then()
            .catch(e => {
                authService.setAutoUpdateToken(null);
                authService.removeData();
                this.onLogOut();
            })
        }, repeatTime);
        this.doLogOutAfterTimeExpired();
    }

    doLogOutAfterTimeExpired(){
        const { expiredIn } = authService.getTokenData();
        const logOutTimeout = (expiredIn - new Date().getTime()) / 1000;

        authService.setAutoLogOutAfterTimeExpired(() => {
            if (this.isExpiredToken()) this.onLogOut();
            }, logOutTimeout );
    }
}

export default ApiJsonToken;
