export const UPDATE_SORT_DIRECTION = 'UPDATE_SORT_DIRECTION';
export const UPDATE_TRADE_PER_DAY = 'UPDATE_TRADE_PER_DAY';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const UPDATE_MIN_PROFITABILITY = 'UPDATE_MIN_PROFITABILITY';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const CLEAR_INVESTING_FILTERS = 'CLEAR_INVESTING_FILTERS';
export const CHANGE_ASSETS_SEARCH_KEY = 'CHANGE_ASSETS_SEARCH_KEY';
export const CLEAR_ASSETS_SEARCH_KEY = 'CLEAR_ASSETS_SEARCH_KEY';
export const ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES';
export const REMOVE_FROM_FAVOURITES = 'REMOVE_FROM_FAVOURITES';
export const UPDATE_INVESTING_HISTORY = 'UPDATE_INVESTING_HISTORY';

export function updateSortDirection(data) {
    return {
        type: UPDATE_SORT_DIRECTION,
        payload: data
    }
}

export function updateTradePerDay(data) {
    return {
        type: UPDATE_TRADE_PER_DAY,
        payload: data
    }
}

export function updateAssets(data) {
    return {
        type: UPDATE_ASSETS,
        payload: data
    }
}

export function updateMinProfitability(data) {
    return {
        type: UPDATE_MIN_PROFITABILITY,
        payload: data
    }
}

export function updatePeriod(data) {
    return {
        type: UPDATE_PERIOD,
        payload: data
    }
}

export function changeAssetsSearchKey(data) {
    return {
        type: CHANGE_ASSETS_SEARCH_KEY,
        payload: data
    }
}

export function clearAssetsSearchKey() {
    return {
        type: CLEAR_ASSETS_SEARCH_KEY
    }
}

export function clearInvestingFilters() {
    return {
        type: CLEAR_INVESTING_FILTERS
    }
}

export function addToFavourites(data) {
    return {
        type: ADD_TO_FAVOURITES,
        payload: data
    }
}

export function removeFromFavourites(data) {
    return {
        type: REMOVE_FROM_FAVOURITES,
        payload: data
    }
}

export function updateInvestingHistory(data) {
    return {
        type: UPDATE_INVESTING_HISTORY,
        payload: data
    }
}