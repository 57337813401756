import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {lang} from "../../services";
import AppConfig from '../../AppConfig';
import {clearNotificationMessage} from '../../actions/app';
import { isVersion6DesktopTarget } from '../../utils/platform';
import SvgIcon from "./SvgIcon";

const notificationMessageHideDelay = AppConfig.notificationMessageHideDelay;
const hideAnimationDelay = 400;

class Message extends PureComponent {
    static TYPE_SUCCESS = 'success';
    static TYPE_FAIL = 'fail';
    static TYPE_INFORMATION = 'information';
    static TYPE_WARNING = 'warning';
    _timerId = null;    

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            isHidingActive: false,
        }
    }

    titleValues = {
        [Message.TYPE_SUCCESS]: "Successfull",
        [Message.TYPE_FAIL]: "Error",
        [Message.TYPE_INFORMATION]: "Info",
        [Message.TYPE_WARNING]: "Warning"
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isNotificationMessageShow !== prevProps.isNotificationMessageShow)
            this.updateMessageStatus();

        if ((this.props.message !== prevProps.message && this.props.isNotificationMessageShow) || (this.props.type !== prevProps.type && this.props.isNotificationMessageShow))
            this.setState({isVisible:false}, this.showMessage)

    }

    clearTimer = () => {
        if (this._timerId)
            clearTimeout(this._timerId);
    };

    updateTimer = () => {
        this.clearTimer();
        this._timerId = setTimeout(this.hideMessage, notificationMessageHideDelay);
    };

    updateMessageStatus = () => {
        const {isNotificationMessageShow} = this.props;

        if (isNotificationMessageShow)
            this.showMessage();
        else
            this.setState({isVisible: false});
    };

    showMessage = () => {
        this.setState({isVisible: true}, this.updateTimer);
    };

    hideMessage = () => {
        setTimeout(()=>{
            this.props.dispatch(clearNotificationMessage());
            this.setState({isHidingActive:false});
        }, hideAnimationDelay);

        this.setState({isHidingActive:true});
    };

    onClick = () => {
        const {callback} = this.props;

        if (callback)
            callback();
        this.hideMessage();
    };

    

    _renderMessageIcon () {
        const {type} = this.props;

        switch (type) {
            case Message.TYPE_SUCCESS:
                return (
                    <SvgIcon icon={SvgIcon.MESSAGE_SUCCESS}/>
                );
            case Message.TYPE_FAIL:
                return (
                    <SvgIcon icon={SvgIcon.MESSAGE_ERROR}/>
                );
            case Message.TYPE_INFORMATION:
                return (
                    isVersion6DesktopTarget ? <SvgIcon icon={SvgIcon.INFO2}/> : null
                );
            case Message.TYPE_WARNING:
                return (
                    isVersion6DesktopTarget ? <SvgIcon icon={SvgIcon.WARNING3}/> : null 
                );
            default:
                return (<></>)
        }
    }

    _renderTitle () {
        const {type} = this.props;

        return (
            <div className='NotificationMessage__title'>
                {`${lang.t(this.titleValues[type])}!`}
            </div>
        );
    }

    _renderMessage () {
        const {message, type} = this.props;

        switch (type) {
            case Message.TYPE_SUCCESS:
                return (message ? message : lang.t('Successful action'));
            case Message.TYPE_FAIL:
                return (message ? message : lang.t('Action failed'));
            case Message.TYPE_INFORMATION:
            case Message.TYPE_WARNING:
            default:
                return (message)
        }
    }

    _renderNotificationMessage () {
        const {type, callback, description, showTitle, showCloseButton} = this.props;
        const {isVisible, isHidingActive} = this.state;

        return (
            <CSSTransition
                in={isVisible}
                timeout={400}
                classNames="notification-message"
                unmountOnExit
                appear>
                <div
                    className={classNames('NotificationMessage', type, {'is-callback-exist': callback ? 1 : 0 }, {'is-hiding-active': isHidingActive})}
                    onClick={this.onClick}
                    onMouseEnter={this.clearTimer}
                    onMouseLeave={this.updateTimer}>
                    <div className='NotificationMessage__inner'>
                        {this._renderMessageIcon()}
                        <span className='NotificationMessage__text-wrapper'>
                            {showTitle && this._renderTitle()}
                            {this._renderMessage()}
                            {description && this._renderDescription()}
                        </span>
                        {showCloseButton && 
                        <div
                            className='NotificationMessage__close-button'
                            onClick={this.hideMessage}>
                            +
                        </div>}
                    </div>
                </div>
            </CSSTransition>
        )
    }

    _renderDescription() {

        const { description } = this.props;

        return (
            <span className='NotificationMessage__description'>
                {lang.t(description)}
            </span>
        )
    }

    render() {
        if (!this.state.isVisible)
            return null;
        return (
            this._renderNotificationMessage()
        )
    }
}

Message.propTypes = {
    showTitle: PropTypes.bool,
    showCloseButton: PropTypes.bool
}

Message.defaultProps = {
    showTitle: false,
    showCloseButton: false
}

const mapStateToProps = (state) => {
    const {notificationMessage} = state.app;
    const {message, isNotificationMessageShow, type, callback, description} = notificationMessage;
    return {
        message,
        isNotificationMessageShow,
        type,
        callback,
        description
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);