import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import * as trackingActions from '../../actions/tracking/tracking';
import { store } from "../../store";

class StateArgs {
    constructor(state) {
        this.newState = state;
    }

    newState;
    cancelBubble = false;

    preventDefault() {
        this.cancelBubble = true;
    }
}

export default class TrackedComponent extends React.Component {
    _mount = false;

    constructor(props) {
        super(props);

        this.componentName = undefined;
        this.isPureComponent = false;
    }

    componentDidMount() {
        trackingActions.addTrackedComponent(this.componentName, this);
        this._mount = true;
    }

    componentWillUnmount() {
        trackingActions.removeTrackedComponent(this.componentName);
        this._mount = false;
    }

    isTrackMasterMode() {
        return trackingActions.isTrackMasterMode();
    }

    isTrackSlaveMode() {
        return trackingActions.isTrackSlaveMode();
    }

    getStateClone() {
        return Object.assign({}, this.state);
    }

    onGetComponentState(e) {
    }

    fetchComponentState = (payload) => {
        if (this.isTrackSlaveMode()) {
            console.log('GetComponentState ' + this.componentName);

            const stateArgs = new StateArgs(this.state);
            this.onGetComponentState(stateArgs);
            if (!stateArgs.cancelBubble && stateArgs.newState) {
                payload[this.componentName] = stateArgs.newState;
            }
        }
    }


    onHandleComponentState(e) {
    }

    handleComponentState = (payload) => {
        if (trackingActions.isTrackMasterMode() && payload.name === this.componentName) {
            console.log('HandleComponentState ' + this.componentName);

            const stateArgs = new StateArgs(payload.state);
            this.onHandleComponentState(stateArgs);
            if (!stateArgs.cancelBubble) {
                const prevState = this.getStateClone();
                const mergedState = this.mergeState(this.state, stateArgs.newState);
                this.applyState(prevState, mergedState);
            }
        }
    }

    mergeState(currentState, newState) {
        const mergedState = Object.assign(currentState, newState);
        return mergedState;
    }

    applyStateCallback(prevState) {
    }

    applyState(prevState, newState) {
        setTimeout(() => { super.setState(newState, () => this.applyStateCallback(prevState)); });
    }


    setState(newState, callback, trackChangeState = true) {
        super.setState(newState, () => {
            if (this.componentName && trackChangeState && this.isTrackSlaveMode())
                this.dispatchTrackState();
            if (callback) callback();
        });
    }

    dispatchTrackState() {
        const stateArgs = new StateArgs(this.state);
        this.onGetComponentState(stateArgs);
        if (!stateArgs.cancelBubble && stateArgs.newState) {
            //setTimeout(() => {
            store.dispatch(trackingActions.doSendComponentState(this.componentName, stateArgs.newState));
            //});
        }
    }

    setNoTrackingState(newState, callback) {
        this.setState(newState, callback, false);
    }


    shouldComponentUpdate(nextProps, nextState) {
        return (!this.isPureComponent || shallowCompare(this, nextProps, nextState));
    }
}
