/**
 * Created by ebondarev
 */
export const NOT_FETCHED = 'TRADE_TICKERS_GROUPS_NOT_FETCHED';
export const FETCHING = 'TRADE_TICKERS_GROUPS_FETCHING';
export const FETCHED = 'TRADE_TICKERS_GROUPS_FETCHED';
export const CHANGE_TICKERS_GROUP = 'TRADE_TICKERS_GROUPS_CHANGE';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function changeActiveGroup(data) {
    return {
        type: CHANGE_TICKERS_GROUP,
        payload: data,
    }
}