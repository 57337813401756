import { Record, List } from 'immutable';

import { sort } from '../utils';
import Types from '../../classes/types';
import { ExchangeHistoryModel } from '../../models/exchange-history';

import { actions } from '../../actions/trade/report';

import * as accountActions from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';

const defaultDatePeriod = Types.DATE_PERIOD_TODAY;
const defaultRange = Types.datePeriod(defaultDatePeriod);

class ExchangeHistory extends Record({
    list: new List([]),
    status: actions.EXCHANGE_NOT_FETCHED,
    datePeriod: defaultDatePeriod,
    datePeriodFrom: defaultRange.from, // custom date period begin
    datePeriodTo: defaultRange.to, // custom date period end
    lastUpdateAt: Date.now(), // обновлены данные в модели
    lastUpdateListAt: Date.now(), // обновлены данные в списке логов
    accountNumber: null,
    sortAttribute: 'EXEC_DATETIME',
    sortDirection: Types.SORT_DESC,
    downloadFormat: Types.DOWNLOAD_FORMAT_XLSX,
}) {
    isFetched() {
        return this.status === actions.EXCHANGE_FETCHED
    }

    isFetching() {
        return this.status === actions.EXCHANGE_FETCHING
    }
}

const initialState = new ExchangeHistory();

export default function exchangeHistory(state = initialState, action) {
    switch (action.type) {
        case actions.EXCHANGE_FETCHING:
            return state.merge({
                status: actions.EXCHANGE_FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actions.EXCHANGE_FETCHED:
            if (state.status === actions.EXCHANGE_SYNCED) {
                return state;
            }
            const data = action.payload.map(data => {
                return new ExchangeHistoryModel(data);
            });

            return state.merge({
                list: sort(new List(data), state.sortAttribute, state.sortDirection),
                status: actions.EXCHANGE_FETCHED,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actions.EXCHANGE_CLEAR:
            return state.merge({
                status: actions.EXCHANGE_NOT_FETCHED,
                list: state.list.clear(),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actions.EXCHANGE_CHANGE_DATE_PERIOD:
            return state.merge({
                datePeriod: action.payload
            });

        case actions.EXCHANGE_CHANGE_DATE_PERIOD_RANGE:
            return state.merge({
                datePeriodFrom: action.payload.from,
                datePeriodTo: action.payload.to,
            });

        case actions.EXCHANGE_CHANGE_SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, action.payload, state.sortDirection),
                sortAttribute: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actions.EXCHANGE_CHANGE_SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, state.sortAttribute, action.payload),
                sortDirection: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actions.EXCHANGE_CHANGE_DOWNLOAD_FORMAT:
            return state.merge({
                downloadFormat: action.payload,
            });

        case actions.EXCHANGE_CHANGE_ACCOUNT_NUMBER:
            return state.merge({
                accountNumber: action.payload,
            });

        case accountActions.CHANGED_CURRENT_ACCOUNT:
            return state.merge({
                accountNumber: action.payload ? action.payload.AccountNumber : null,
                list: state.list.clear(),
                status: actions.EXCHANGE_NOT_FETCHED,
            });

        case trackingTypes.GET_STORE:
            return (action.data.exchangeHistory = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.exchangeHistory) {
                const exchangeHistoryData = action.payload.exchangeHistory;
                const historyList = new List((exchangeHistoryData.list).map((historyData) => { return new ExchangeHistoryModel(historyData); }));

                exchangeHistoryData.list = historyList;
                exchangeHistoryData.status = (state.status === actions.EXCHANGE_NOT_FETCHED || state.status === actions.EXCHANGE_FETCHING) ? actions.EXCHANGE_SYNCED : state.status;
                return state.merge(exchangeHistoryData);
            }
            return state;

        default:
            return state;
    }
}
