export class Depository {

    constructor(prefix, storage = null) {
        this.prefix = prefix;
        this.keys = {
            REMEMBER: this.prefix + 'remember',
        };
        if (storage) {
            this.store = storage;
        } else {
            this._choseStore();
        }
    }

    useRemember(newValue) {
        this._remember = newValue;
    }

    get isRemembered() {
        return this._remember;
    }

    get _remember() {
        return this.store.getItem(this.keys.REMEMBER) === 'true' ? true : false;
    }

    set _remember(value) {
        this.store.setItem(this.keys.REMEMBER, value);
    }

    getItem(key) {
        const val = this.store.getItem(key);
        return (val === 'undefined') ? undefined : JSON.parse(val);
    }

    setItem(key, value) {
        return this.store.setItem(key, JSON.stringify(value));
    }

    removeData() {
        for (let key in this.keys) {
            this.store.removeItem(this.keys[key]);
        }
    }

    _choseStore() {
        this.store = window.localStorage;
    }

    _switchStore(oldStore, newStore) {
        for (let key in this.keys) {
            const val = oldStore.getItem(this.keys[key]);
            newStore.setItem(this.keys[key], val);
            oldStore.removeItem(this.keys[key]);
        }
        this.store = newStore;
    }

}

export default Depository;