import { createSelector } from "reselect";
import { customerFilterByDateTime } from "../../utils/filters";
import * as _ from 'lodash';
import Types from "../../classes/types";

export const platformNotificationsList = state => state.user.platformNotifications.list
export const platformNotificationsFilters = state => state.trade.filters.platformNotificationsFilters;

export const getPlatformNotificationsList = createSelector([
    platformNotificationsList
], (list) => {
    const jsData = list.toJS();
    const res = _.map(jsData, v => {
        v.dateTime = new Date(((v.CREATION_DATE - Types.TICKS_TO_UNIX_EPOCH) / 10000));
        return v;
    });
    return res;
});

export const getPlatformNotificationsUnread = createSelector([
    getPlatformNotificationsList
], (list) => {
    const res = list.filter(item => item.STATUS === 0);
    return res;
});


export const getPlatformNotificationsListToShow = createSelector([
    getPlatformNotificationsList, platformNotificationsFilters
], (list, filterProps) => {
    const res = list.filter(customerFilterByDateTime(filterProps));
    return res;
});

export const getPlatformNotificationsListToShowModal = createSelector([
    getPlatformNotificationsListToShow
], (list) => {
    return list.filter((n) => n.IS_MODAL && n.STATUS === 0);
});