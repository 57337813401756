import BaseModel from './base';
import AppConfig from '../AppConfig';
import Types from '../classes/types';

import { getDefaultLanguage } from '../utils/lang';

const { orderEdit, settingsGeneral } = AppConfig;

export class SettingsModel extends BaseModel {
    /* GENERAL */
    language = getDefaultLanguage();
    logOutTime = AppConfig.autoLogOutTimeOut;
    enableSounds = true;
    saveChartFrame = settingsGeneral.saveChartFrame;
    desktopTheme = null;
    mobileTheme = null;
    _themeDark = null;
    _themeLight = null;
    layout = 0;
    contractSize = settingsGeneral.defaultContractSize;
    marketModel = settingsGeneral.defaultMarketModel;
    platformNotifications = settingsGeneral.platformNotifications;
    _tradeSize = settingsGeneral.tradeSize;
    _tradeSizeInPercents = 0;
    _closeWithProfitType = orderEdit.defaultTPType;
    _closeWithProfitValue = orderEdit.defaultTP;

    _closeWithLossType = orderEdit.defaultSLType;
    _closeWithLossValue = orderEdit.defaultSL;
    resetTradeParameters = true;
    maxTradeSizeInPercents = settingsGeneral.maxTradeSizeInPercents;

    get tradeSize() {
        return this._tradeSize;
    }

    set tradeSize(value) {
        this._tradeSize = +value;
    }

    get tradeSizeInPercents() {
        return this._tradeSizeInPercents;
    }

    set tradeSizeInPercents(value) {
        if(value > this.maxTradeSizeInPercents) {
            this._tradeSizeInPercents = this.maxTradeSizeInPercents;
        } else {
            this._tradeSizeInPercents = +value;
        }
    }

    get closeWithProfitType() {
        switch(this._closeWithProfitType) {
            case Types.SLTP_UNIT_PRICE:
            case Types.SLTP_UNIT_PERCENT:
            case Types.SLTP_UNIT_MONEY:
                return this._closeWithProfitType;
            default:
                return orderEdit.defaultTPType;
        }
    }

    set closeWithProfitType(value) {
        this._closeWithProfitType = value;
    }

    get closeWithProfitValue() {
        return this._closeWithProfitValue;
    }

    set closeWithProfitValue(value) {
        this._closeWithProfitValue = +value;
    }

    get closeWithLossType() {
        switch(this._closeWithLossType){
            case Types.SLTP_UNIT_PRICE:
            case Types.SLTP_UNIT_PERCENT:
            case Types.SLTP_UNIT_MONEY:
                return this._closeWithLossType;
            default:
                return orderEdit.defaultSLType;

        }
    }

    set closeWithLossType(value) {
        this._closeWithLossType = value;
    }

    get closeWithLossValue() {
        return this._closeWithLossValue;
    }

    set closeWithLossValue(value) {
        this._closeWithLossValue = +value;
    }

    reconnect = 1; // not available
    ticker = "EUR/USD"; // not available
    defaultAccount = null; // not available
    defaultPeriod = 1; // not available
    showMicroseconds = 1; // not available

    /* CONFIRMATIONS */
    allConfirmations = this._confirmClosePosition ||
        this._confirmSubmitPendingOrder ||
        this._confirmSubmitMarketOrder ||
        this._confirmModifyOrder ||
        this._confirmCancelOrder ||
        this._confirmPositionsNetting ||
        this.platformNotifications ||
        this._confirmRejectionReports;

    _confirmOpenPosition = 1; // not available
    get confirmOpenPosition() {
        return this._confirmOpenPosition;
    }

    set confirmOpenPosition(value) {
        this._confirmOpenPosition = +value;
    }

    _confirmClosePosition = 1;
    get confirmClosePosition() {
        return this._confirmClosePosition;
    }

    set confirmClosePosition(value) {
        this._confirmClosePosition = +value;
    }

    _confirmSubmitMarketOrder = 1;
    get confirmSubmitMarketOrder() {
        return this._confirmSubmitMarketOrder;
    }

    set confirmSubmitMarketOrder(value) {
        this._confirmSubmitMarketOrder = +value;
    }

    _confirmSubmitPendingOrder = 1;
    get confirmSubmitPendingOrder() {
        return this._confirmSubmitPendingOrder;
    }

    set confirmSubmitPendingOrder(value) {
        this._confirmSubmitPendingOrder = +value;
    }

    _confirmModifyOrder = 1;
    get confirmModifyOrder() {
        return this._confirmModifyOrder;
    }

    set confirmModifyOrder(value) {
        this._confirmModifyOrder = +value;
    }

    _confirmCancelOrder = 1;
    get confirmCancelOrder() {
        return this._confirmCancelOrder;
    }

    set confirmCancelOrder(value) {
        this._confirmCancelOrder = +value;
    }

    _confirmPositionsNetting = 1;
    get confirmPositionsNetting() {
        return this._confirmPositionsNetting;
    }

    set confirmPositionsNetting(value) {
        this._confirmPositionsNetting = +value;
    }

    /* SOUND GROUP */
    closePositionSoundGroup = 1;
    submitPendingOrderSoundGroup = 1;
    submitMarketOrderSoundGroup = 1;
    cancelOrderSoundGroup = 1;

    // show error notification on reject or reject order
    _confirmRejectionReports = 1;
    get confirmRejectionReports() {
        return this._confirmRejectionReports;
    }

    set confirmRejectionReports(value) {
        this._confirmRejectionReports = +value;
    }

    /* TRADING */
    tradingEnabled = 1; // not available
    tradingMode = 0; // not available

    /* PIPS */
    _pipsLimit = 10;
    get pipsLimit() {
        return this._pipsLimit;
    }

    set pipsLimit(value) {
        this._pipsLimit = +value;
    }

    get themeDark() {
        const themes = AppConfig.site.themeColors[Types.THEMES.dark];

        return (themes.find(theme => theme === this._themeDark))
        ? this._themeDark
        : themes[0];
    }

    set themeDark(value) {
        this._themeDark = value;
    }

    get themeLight() {
        const themes = AppConfig.site.themeColors[Types.THEMES.light];

        return (themes.find(theme => theme === this._themeLight))
        ? this._themeLight
        : themes[0];
    }

    set themeLight(value) {
        this._themeLight = value;
    }

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}
