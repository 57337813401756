/**
 * Created by ebondarev
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { replace } from 'react-router-redux';
import thunk from 'redux-thunk';
import {createHashHistory} from 'history';
import { routerMiddleware } from 'react-router-redux';
import soundsMiddleware from 'redux-sounds';
import Immutable from 'immutable';

import reducers from './reducers';
import * as tickersActions from './actions/trade/tickers';

import AppConfig from './AppConfig';

const history = createHashHistory();

/**
 * Перенаправление на другую страницу если есть в action аттрибут 'redirect'
 * @param store
 */
const redirectMiddleware = store => next => action => {
    if (action.redirect) {
        store.dispatch(replace(action.redirect));
    }

    return next(action);
};

let listMiddleware = [
    thunk,
    redirectMiddleware,
    routerMiddleware(history),
];

// trigger sound effects on actions
const loadedSoundsMiddleware = soundsMiddleware(AppConfig.eventSounds || {});

if (AppConfig.features.isEnableEventSound) {
    listMiddleware.push(loadedSoundsMiddleware);
}

const composeEnhancer = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
            immutable: Immutable,
        },
        maxAge: 30,
        latency: 1000,
        shouldHotReload: false,
        actionsBlacklist: [tickersActions.UPDATE_QUOTE],
    })
    : compose;


const middleware = applyMiddleware(...listMiddleware);
const store = createStore(reducers, undefined, composeEnhancer(middleware));

export {
    store,
    history
}