/**
 * Created by ebondarev
 */
import BaseModel from './base';
import AppConfig from '../AppConfig';

const {disableInstrumentsFilter} = AppConfig;

export class ChartTabModel extends BaseModel
{
    symbolId;
    name;
    tradeMode;
    isRealTicker = null; // ticker for real account
    _isShown = true;
    frame = AppConfig.stockChart.chartSettings.frame;
    chartType = 'CANDLES';
    isAutoTimeFrame = true;
    isAutoUpdateFrameByZoom = AppConfig.stockChart.chartSettings.isAutoUpdateFrameByZoom;
    synced = true;
    isMonochrome = AppConfig.stockChart.chartSettings.isMonochrome;
    showDefaultIndicators = false;

    get isShown() {
        return this._isShown;
    }

    set isShown(value) {
        this._isShown = value;
    }

    constructor(initData) {
        super();
        this.copyFrom(initData);
        this._updateKey();
    }

    _key;
    get key() {
        return this._key;
    }

    _updateKey() {
        this._key = this.symbolId + '_' + Math.floor(Math.random() * 10000000);
    }

    updateIsShownFromAccount(account) {
        if (!account || this.isRealTicker === null) {
            this._isShown = true;
            return;
        }

        // this._isShown = (account.isReal && this.isRealTicker) || (account.isDemo && (disableInstrumentsFilter ? this.isRealTicker : !this.isRealTicker));
        this._isShown = disableInstrumentsFilter ? true : (account.isReal && this.isRealTicker) || (account.isDemo && !this.isRealTicker);
    }

    toJSON() {
        return this.toSave();
    }

    static createFromTicker(tickerModel, initData = {}) {
        return new ChartTabModel({
            symbolId: tickerModel.SymbolId,
            name: tickerModel.Ticker,
            isRealTicker: tickerModel.isReal,
            tradeMode: tickerModel.TradeMode,
            ...initData,
        });
    }
}
