import { transport } from '../../services';

import { doTransferMoney } from '../../actions/trade/deposit';
import { doCancelAllOrders } from '../../actions/trade/orders';
import { doCloseAllPositions } from '../../actions/trade/positions';

export const NOT_FETCHED = 'INVESTING_SUBSCRIPTION_NOT_FETCHED';
export const FETCHING = 'INVESTING_SUBSCRIPTION_FETCHING';
export const FETCHED = 'INVESTING_SUBSCRIPTION_FETCHED';
export const SORT_ATTRIBUTE = 'INVESTING_SUBSCRIPTION_SORT_ATTRIBUTE';
export const SORT_DIRECTION = 'INVESTING_SUBSCRIPTION_SORT_DIRECTION';
export const SUBSCRIBING_ON_STRATEGY = 'INVESTING_SUBSCRIPTION_SUBSCRIBING_ON_STRATEGY';
export const SUBSCRIBED_ON_STRATEGY = 'INVESTING_SUBSCRIPTION_SUBSCRIBED_ON_STRATEGY';
export const RESET_UNSUBSCRIBED = 'INVESTING_SUBSCRIPTION_RESET_UNSUBSCRIBED';
export const UNSUBSCRIBING_ADD = 'UNSUBSCRIBING_ADD';
export const UNSUBSCRIBING_REMOVE = 'UNSUBSCRIBING_REMOVE';

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function sortAttribute(data) {
    return {
        type: SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: SORT_DIRECTION,
        payload: data
    }
}

export function subscribingOnStrategy(strategyId) {
    return {
        type: SUBSCRIBING_ON_STRATEGY,
        payload: strategyId
    }
}

export function subscribedOnStrategy(strategyId) {
    return {
        type: SUBSCRIBED_ON_STRATEGY,
        payload: strategyId
    }
}

export function resetUnsubscribed() {
    return {
        type: RESET_UNSUBSCRIBED,
    }
}

export function unsubscribingAdd(subscription, toAccountNumber) {
    return {
        type: UNSUBSCRIBING_ADD,
        payload: {
            subscription,
            toAccountNumber
        }
    }
}

export function unsubscribingRemove(id) {
    return {
        type: UNSUBSCRIBING_REMOVE,
        payload: id,
    }
}

export function doFetched(data) {
    return (dispatch, getState) => {
        dispatch(fetched(data));
    }
}

export function doCreate(data) {
    return (dispatch, getState) => {
        dispatch(subscribingOnStrategy(data.SignalAccountNumber));
        transport.requestCreateSubscription(data);
    }
}

export function doRemove(id) {
    return (dispatch, getState) => {
        transport.requestRemoveSubscription(id);
    }
}

export function doUnsubscribe({ subscription, toAccountNumber }) {
    return (dispatch, getState) => {
        dispatch(unsubscribingAdd(subscription, toAccountNumber));
        dispatch(doRemove(subscription.SetID));
    }
}

export function doPostUnsubscribe(id) {
    return (dispatch, getState) => {
        const postData = getState().investing.subscription.unsubscribing.get(id);

        if (!postData) {
            return;
        }

        dispatch(doCloseAllPositions({ accountNumber: postData.investorAccountNumber }));
        dispatch(doCancelAllOrders({ accountNumber: postData.investorAccountNumber }));

        setTimeout(() => {
            const investorAccount = getState().trade.accounts.list.get(postData.investorAccountNumber);

            if (!investorAccount) {
                return;
            }

            dispatch(doTransferMoney({
                fromAccountNumber: postData.investorAccountNumber,
                toAccountNumber: postData.toAccountNumber,
                amount: investorAccount.Balance
            }));
        }, 4000);

        dispatch(unsubscribingRemove(id));
    }
}