import React from 'react';
import PropTypes from 'prop-types';

import CustomScrollBars from '../../common-desktop/components/CustomScrollBars';
import VerticalScrollButton from '../../desktop/components/VerticalScrollButton';
import classNames from 'classnames';

const CommonScroll = ({ className, type, scrollProps, children }) => {
    let ScrollComponent = null;
    let props = {
        className: classNames('CommonScroll', className)
    };

    switch (type) {
        case 'custom':
            ScrollComponent = CustomScrollBars;
            break;

        case 'button':
            ScrollComponent = VerticalScrollButton;
            break;

        case 'native':
            ScrollComponent = 'div';
            props['className'] = "CommonScroll__native";
            break;

        default:
            props = {};
            ScrollComponent = React.Fragment;
    }

    return (
        <ScrollComponent
            {...props}
            {...scrollProps}
        >
            {children}
        </ScrollComponent>
    );
}

CommonScroll.defaultProps = {
    scrollProps: {},
    className: ''
};

CommonScroll.propTypes = {
    type: PropTypes.oneOf(['none', 'custom', 'native', 'button']),
    scrollProps: PropTypes.object,
};

export default CommonScroll;