import { Record } from 'immutable';
import AccountModel from '../../models/account';
import * as accountActions from '../../actions/trade/accounts';
import * as withdrawActions from '../../actions/trade/withdraw';
import * as trackingActions from '../../actions/tracking/tracking';

class WithdrawStore extends Record({
    isRequestActive: false,
    accountNumber: null,
    paymentMethodId: null,
}) {
}

const initialState = new WithdrawStore();

export default function withdraw(state = initialState, action) {
    switch (action.type) {
        case withdrawActions.WITHDRAW_BEGIN_REQUEST:
            return state.merge({
                isRequestActive: true,
            });

        case withdrawActions.WITHDRAW_END_REQUEST:
            return state.merge({
                isRequestActive: false,
            });

        case accountActions.CHANGED_CURRENT_ACCOUNT:
        case withdrawActions.WITHDRAW_CHANGE_ACCOUNT:
            return state.merge({
                accountNumber: action.payload.AccountNumber,
            });

        case withdrawActions.WITHDRAW_CHANGE_PAYMENT_METHOD:
            return state.merge({
                paymentMethodId: action.payload,
            });

        case withdrawActions.WITHDRAW_CHANGE_PAYMENT_METHOD_AND_ACCOUNT:
            return state.merge({
                paymentMethodId: action.payload.paymentMethodId,
                accountNumber: action.payload.account.AccountNumber,
            });

        case trackingActions.FETCH_ACTION_PAYLOAD: {
            const actionData = action.payload;
            if (actionData.Action === withdrawActions.WITHDRAW_CHANGE_ACCOUNT) {
                actionData.ActionPayload = new AccountModel(actionData.ActionPayload);
            }
            return state;
        }

        case trackingActions.GET_STORE:
            return (action.data.withdraw = (action.payload) ? state : initialState);

        case trackingActions.SET_STORE: {
            if (action.payload && action.payload.withdraw) {
                return state.merge(action.payload.withdraw);
            }
            return state;
        }

        default:
            return state;
    }
}