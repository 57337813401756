import Types from "../classes/types";

export function customerFilter(filterProps) {
    const timeRange = Types.datePeriod(filterProps.period, filterProps.from, filterProps.to);
    return function(item) {
        return (timeRange.from <= item.dateTime && item.dateTime < timeRange.to)
            && (filterProps.wallets.all
                || filterProps.wallets.list.find(i => i.id === item.AccountNumber)
            )
    }
}

export function customerFilterByDateTime(filterProps) {
    const timeRange = Types.datePeriod(filterProps.period, filterProps.from, filterProps.to);
    return function(item) {
        return (timeRange.from <= item.dateTime && item.dateTime < timeRange.to)
    }
}
