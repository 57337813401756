import * as actionTypes from '../../actions/investing/filters';
import Types from "../../classes/types";
import Register from "../../classes/register";

const REGISTER_NAME = 'favouritesStrategies';
const favourites = Register.get(REGISTER_NAME);

export const initialState = {
    sortDirection: "Favourites",
    tradePerDay: "Any",
    assets: new Set(),
    assetsSearchKey: '',
    minProfitability: '',
    period: Types.DATE_PERIOD_ALL,
    favourites: favourites ? favourites : [],
    investingHistory: {
        period: Types.DATE_PERIOD_TODAY,
        from: null,
        to: null,
    }
};

export default function filters(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_SORT_DIRECTION:
            return {...state, sortDirection: action.payload};

        case actionTypes.UPDATE_TRADE_PER_DAY:
            return {...state, tradePerDay: action.payload};

        case actionTypes.UPDATE_ASSETS:
            return {...state, assets: action.payload};

        case actionTypes.UPDATE_MIN_PROFITABILITY:
            return {...state, minProfitability: action.payload};

        case actionTypes.UPDATE_PERIOD:
            return {...state, period: action.payload};

        case actionTypes.CHANGE_ASSETS_SEARCH_KEY:
            return {...state, assetsSearchKey: action.payload};

        case actionTypes.CLEAR_ASSETS_SEARCH_KEY:
            return {...state, assetsSearchKey: initialState.assetsSearchKey};

        case actionTypes.ADD_TO_FAVOURITES:
            Register.set(REGISTER_NAME, [...state.favourites, action.payload]);
            return {...state, favourites: [...state.favourites, action.payload]};

        case actionTypes.REMOVE_FROM_FAVOURITES:
            const delFavourites = state.favourites.filter(i => i !== action.payload);
            Register.set(REGISTER_NAME, delFavourites);
            return {...state, favourites: delFavourites};

        case actionTypes.UPDATE_INVESTING_HISTORY:
            return {...state, investingHistory: action.payload};

        case actionTypes.CLEAR_INVESTING_FILTERS:
            return initialState;

        default:
            return state;
    }
}