/**
 * Created by ebondarev
 */
import { combineReducers } from 'redux';

import tickers from './tickers';
import accounts from './accounts';
import orders from './orders';
import positions from './posittions';
import activityLog from './activity-log';
import accountHistory from './account-history';
import transactionHistory from './transaction-history';
import news from './news';
import chart from './chart';
import tickersGroups from './tickers-groups';
import deposit from './deposit';
import commissions from './commissions';
import swaps from './swaps';
import commissionPayments from './commission-payments';
import marginModels from './margin-models';
import withdraw from './withdraw';
import exchangeHistory from './exchange-history';
import simpleExchange from './simple-exchange';
import tradeSessions from './trade-sessions';
import interchanges from './interchanges';
import filters from './filters';

/**
 * ВНИМАНИЕ! Важен порядок редьюсеров, некоторые события должны обрабатываться
 * сначала основным редьюсером а потом редьюсером который тоже подписан на это событие
 */
export default combineReducers({
    accounts,
    tickers,
    orders,
    positions,
    accountHistory,
    activityLog,
    transactionHistory,
    news,
    chart,
    tickersGroups,
    deposit,
    commissions,
    swaps,
    marginModels,
    withdraw,
    exchangeHistory,
    tradeSessions,
    interchanges,
    filters,
    simpleExchange,
    commissionPayments
});