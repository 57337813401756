export const SIMPLE_EXCHANGE_INIT_STORE = 'SIMPLE_EXCHANGE_INIT_STORE';
export const SIMPLE_EXCHANGE_SET_FROM_ACCOUNT = 'SIMPLE_EXCHANGE_SET_FROM_ACCOUNT';
export const SIMPLE_EXCHANGE_CHANGE_FROM_ACCOUNT = 'SIMPLE_EXCHANGE_CHANGE_FROM_ACCOUNT';
export const SIMPLE_EXCHANGE_SET_TO_ACCOUNT = 'SIMPLE_EXCHANGE_SET_TO_ACCOUNT';
export const SIMPLE_EXCHANGE_CHANGE_TO_ACCOUNT = 'SIMPLE_EXCHANGE_CHANGE_TO_ACCOUNT';
export const SIMPLE_EXCHANGE_SWITCH_DIRECTION = 'SIMPLE_EXCHANGE_SWITCH_DIRECTION';
export const SIMPLE_EXCHANGE_CHANGE_SEND_AMOUNT = 'SIMPLE_EXCHANGE_CHANGE_SEND_AMOUNT';
export const SIMPLE_EXCHANGE_CHANGE_RECEIVE_AMOUNT = 'SIMPLE_EXCHANGE_CHANGE_RECEIVE_AMOUNT';

export function initStore(initData) {
    return {
        type: SIMPLE_EXCHANGE_INIT_STORE,
        payload: initData
    }
}

export function setFromAccount(accountNumber) {
    return {
        type: SIMPLE_EXCHANGE_SET_FROM_ACCOUNT,
        payload: accountNumber
    }
}

export function changeFromAccount({ symbolId, buySell, fromAccountNumber, toAccountNumber }) {
    return {
        type: SIMPLE_EXCHANGE_CHANGE_FROM_ACCOUNT,
        payload: {
            symbolId,
            buySell,
            fromAccountNumber,
            toAccountNumber
        }
    }
}

export function setToAccount(accountNumber) {
    return {
        type: SIMPLE_EXCHANGE_SET_TO_ACCOUNT,
        payload: accountNumber
    }
}

export function changeToAccount({ symbolId, buySell, toAccountNumber }) {
    return {
        type: SIMPLE_EXCHANGE_CHANGE_TO_ACCOUNT,
        payload: {
            symbolId,
            buySell,
            toAccountNumber
        }
    }
}

export function switchDirection({ buySell, fromAccountNumber, toAccountNumber, sendAmount, receiveAmount }) {
    return {
        type: SIMPLE_EXCHANGE_SWITCH_DIRECTION,
        payload: {
            buySell,
            fromAccountNumber,
            toAccountNumber,
            sendAmount,
            receiveAmount
        }
    }
}

export function changeSendAmount({ sendAmount, receiveAmount }) {
    return {
        type: SIMPLE_EXCHANGE_CHANGE_SEND_AMOUNT,
        payload: {
            sendAmount,
            receiveAmount
        }
    }
}

export function changeReceiveAmount({ sendAmount, receiveAmount }) {
    return {
        type: SIMPLE_EXCHANGE_CHANGE_RECEIVE_AMOUNT,
        payload: {
            sendAmount,
            receiveAmount
        }
    }
}