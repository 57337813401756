(function defineAppInfo() {
    // add global info
    window.App || (window.App = {});
    window.App.version = process.env.REACT_APP_VERSION;
    window.App.buildHash = process.env.BUILD_HASH;
    window.App.buildBranch = process.env.BUILD_BRANCH;
    window.App.buildTimeUTC = process.env.BUILD_UTC_TIME;
    window.App.buildTimeServer = process.env.BUILD_SERVER_TIME;
    window.App.env = process.env.NODE_ENV;
    window.App.buildAuthor = process.env.BUILD_AUTHOR;
})();