const MarketTypes = {
    CLASSIC: 'CLASSIC',
    LOTS: 'LOTS',
    INVESTMENT: 'INVESTMENT',
    EXCHANGE: 'EXCHANGE',
}

const MarketDirection = {
    BUY: 'Buy',
    SELL: 'Sell',
    MIDDLE: 'MIDDLE',
}

export {
    MarketTypes,
    MarketDirection,
}