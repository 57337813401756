import Peer from 'simple-peer';
import { Stream } from '../utils/mediaStream';

const noop = function () {};

export default class TransportMedia {
    connected = false;
    connecting = false;
    peer = null;
    config = null;
    dataReceived = false;
    streams = [];

    onConnected = noop;
    onClose = noop;
    onError = noop;
    onReject = noop;
    onSignal = noop;
    onDataMessage = noop;
    onStopStream = noop;

    constructor(config) {
        this.config = config;
    }

    connect() {
        if (this.connected || this.connecting) {
            return;
        }

        this.connecting = true;

        try {
            this.peer = new Peer({ 
                initiator: true,
                config: this.config, 
            });

            this.peer.on('connect', () => {
                this.connected = true;
                this.connecting = false;
                this.onConnected();
                this.dataReceived = true;
            });

            this.peer.on('close', () => {
                this.connecting = false;
                this.connected = false;
                this.onClose();
            });

            this.peer.on('signal', (data) => {
                this.onSignal(data);
                this.dataReceived = true;
            });
            
            this.peer.on('data', (data) => {
                this.message(data);
                this.dataReceived = true;
            });

            this.peer.on('error', (err) => {
                this.onError(err);
            });

        } catch (err) {
            this.onError(err);
        }
    }

    disconnect() {
        this.connected = false;
        this.connecting = false;

        if (this.peer) {
            this.peer.removeAllListeners();
            this.removeStreams();
            this.peer.destroy();
            this.peer = null;
        }
    }

    send(data) {
        if (!this.connected) {
            this.onReject('No connection to the peer');
        }

        try {
            if (this.isStream(data)) {
                this.peer.addStream(data);
            } else {
                this.peer.send(data);
            }

        } catch (err) {

        }
    }

    addSignal(data) {
        if (this.peer) {
            this.peer.signal(data);
        }
    }

    message(data) {
        const obj = JSON.parse(data);
        this.processMessage(obj);
    }

    processDataMessage(jsonData) {
        this.onDataMessage(jsonData);
    }

    removeStreams() {
        this.streams.forEach((stream) => this.removeStream(stream));
    }

    removeStream(stream) {
        this.streams = this.streams.filter(s => s !== stream);
        stream.stop();
    }

    addStream(stream) {
        this.streams.push(stream);
        
        stream.onended(() => {
            this.streams = this.streams.filter(s => s !== stream);
            this.onStopStream(stream);
        });
    }

    sendStream(stream) {
        this.addStream(stream);
        this.send(stream);
    }

    isStream(stream) {
        return Object.getPrototypeOf(stream).constructor === Stream;
    }

    sendScreenSharingStream(stream) {
        if (this.isStream(stream)) {
            this.sendStream(stream);
        }
    }
}
