/**
 * Created by ebondarev
 */
import { lang } from '../services/';
import currencySymbols from './currencySymbols';
import date from './date';

export default class Format {
    /**
     * Format card number with given delimiter
     * @param s{string}
     * @param delimiter{string}
     * @returns {string}
     */
    static cardNumber(s, delimiter = '-') {
        let result = '';

        if (!s) {
            return result;
        }

        for (let i = 0; i < s.length; i++) {
            if (i > 0 && i % 4 === 0) {
                result += delimiter + s[i];
            } else {
                result += s[i];
            }
        }

        return result;
    }

    static parseExpireDate(str, delimiter = '/') {
        if (str.length < 5 || !str.includes(delimiter)) {
            return false;
        }

        const subStr = str.split(delimiter);

        return {
            month: subStr[0],
            year: subStr[1]
        };
    }

    /**
     *
     * @param s{string}
     * @param separator{string}
     * @returns {string}
     */
    static dateNumber(s, separator) {
        let result = "";
        for (let i = 0; i < s.length; i++) {
            if (i === 4 || i === 6) {
                result += "-" + s[i];
            } else {
                result += s[i];
            }
        }
        return result;
    }

    static validDigitsRegex = new RegExp("^\\d*$");

    /**
     * @param value{string}
     * @param oldValue{string}
     * @param maxLen{number}
     * @returns {string}
     */
    static validDigits(value, oldValue, maxLen) {
        if (value.length > maxLen) {
            return oldValue
        }

        if (!Format.validDigitsRegex.exec(value)) {
            return oldValue;
        }

        return value;
    }

    static validPhoneRegex = new RegExp("^\\+\\d*$");

    /**
     *
     * @param phone{string}
     * @param oldValue{string}
     * @returns {string}
     */
    static validPhone(phone, oldValue) {
        if (!phone) {
            return "+";
        }

        if (!Format.validPhoneRegex.exec(phone)) {
            return oldValue;
        }

        return phone;
    }

    /**
     * Formatting leverage with a given format. Default format is "1:5"
     * @param leverage{number}
     * @param formatId{number|1}
     * @returns {string}
     */
    static leverage(leverage, formatId = 1) {
        const leverageValue = (1 / leverage).toFixed(0);

        switch(formatId) {
            // '1:5'
            case 1:
                return leverage ? "1:" + leverageValue : "";

            // '5x'
            case 2:
                return leverage ? leverageValue + "x" : "";
            
            // x 5
            case 3:
                return leverage ? "x " + leverageValue : "";

            // x5
            case 4:
                return leverage ? "x" + leverageValue : "";

            // '5'
            default:
                return leverageValue;
        }
    }

    /**
     * Formatting instrument's commission for display
     * @param commission {object}
     * @returns {string}
     */
    static commission(commission) {
        if (!commission) {
            return '0';
        }

        switch(commission.PaymentType) {
            case 0:
                return `${commission.ComissionRate} ${commission.Currency}`;

            case 1:
                return `${commission.ComissionRate * 100}%`;

            case 2:
                return `${commission.ComissionRate} ${commission.Currency}`;

            default:
                return commission.ComissionRate;
        }
    }

    /**
     * Formating swaps value for display
     * @param {object} swap swap object
     * @param {object} ticker ticker object
     * @param {string} swapType long or short
     * @returns {number}
     */
    static swap(swap, ticker, swapType = 'long') {
        if (!swap) {
            return 0;
        }

        const swapValue = swapType === 'long' ? swap.Long : swap.Short;

        return +(swapValue * ticker.PriceStep * ticker.PointSize * 100).toFixed(12);
    }

    static string(str, maxLength = 20) {
        if (!str) {
            return '';
        }

        if (str.length > maxLength) {
            return `${str.substr(0, 20 - 3)}...`;
        }

        return str;
    }

    /**
     * Parse filter name
     * @param {string} filter filter name
     * @returns {object}
     */
    static parseFilter(filter) {
        if (filter[0] === '!') {
            return {
                filter: filter.slice(1).toLowerCase(),
                except: true,
            }
        }
    
        return {
            filter: filter.toLowerCase(),
            except: false,
        }
    }

    /**
     * Add sign to number
     * @param {number} number 
     * @param {boolean} format
     * @param {number} precision
     * @returns {string}
     */
    static signNumber(number, format = true, precision = 0) {
        const sign = +number > 0 ? '+' : '';
        return `${sign}${format ? (precision ? lang.number(number, precision, true) : this.thousandsNumber(number)) : number}`;
    }

    static moneyAmount(amount, currency) {
        const symbol = currencySymbols[currency];
        return symbol ? `${symbol}${amount}` : `${amount} ${currency}`;
    }

    static thousandsNumber(number, separator = " ") {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    /**
     * If value less than ten add lead zero
     * @param {number} value
     * @returns {string}
     */
    static timeItem(value) {
        if (value < 10) {
            return '0' + value;
        }

        return +value.toFixed(0);
    }

    /**
     * Format UTC offset
     * @param {Date} date
     * @returns {string}
     */
    static utcOffset(date = new Date()) {
        const offset = Math.abs(date.getTimezoneOffset() / 60);
        const sign = date.getTimezoneOffset() <= 0 ? '+' : '-';

        return `UTC${sign}${offset}`;
    }

    static wholePartOfDuration(period) {
        let value = Math.trunc(period.asHours())
        let unit = 'hours'

        if (Math.trunc(period.asYears())) {
            value = Math.trunc(period.asYears());
            unit = 'years';
        } else if (Math.trunc(period.asMonths())) {
            value = Math.trunc(period.asMonths());
            unit = 'months';
        } else if (Math.trunc(period.asWeeks())) {
            value = Math.trunc(period.asWeeks());
            unit = 'weeks';
        } else if (Math.trunc(period.asDays())) {
            value = Math.trunc(period.asDays());
            unit = 'days';
        }

        return {
            value: value,
            unit: unit,
            label: date.declinationOfNumber(value, unit)
        }
    }

    static parseShortNumber(shortNumber) {
        if (shortNumber === 0 || Number.isInteger(shortNumber)) {
            return shortNumber;
        }
        if(shortNumber.slice(-1) === 'k') {
            return parseFloat(shortNumber)*1000;
        } else if (shortNumber.slice(-1) === 'M') {
            return parseFloat(shortNumber)*1000000;
        }
    }

    static hexToNumber(value) {
        return parseInt(value.substr(1), 16)
    }

    static numberToHex(number) {
        if (number <= 1048575 && number > 65535 ) {
            return `#0${number.toString(16)}`;
        } else if (number <= 65535 && number > 4095 ) {
            return `#00${number.toString(16)}`;
        } else if (number <= 4095 && number > 255 ) {
            return `#000${number.toString(16)}`;
        } else if (number <= 255 && number > 15 ) {
            return `#0000${number.toString(16)}`;
        } else if (number <= 15) {
            return `#00000${number.toString(16)}`;
        } else {
            return `#${number.toString(16)}`;
        }
    }
}