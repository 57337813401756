import AppConfig from '../AppConfig';

export class SoundGroup {

    constructor(soundGroup = AppConfig.soundGroup) {
        this._soundGroupMap = new Map();

        for (let key in soundGroup) {
            soundGroup[key].forEach(item => {
                this._soundGroupMap.set(item, key);
            });
        };
    }

    getSoundGroup(soundId) {
        return this._soundGroupMap.get(soundId);
    }
}

export default SoundGroup;