/**
 * Created by ebondarev
 */
import { Record, Map } from 'immutable';

import AccountModel from '../../models/account';
import Register from '../../classes/register';
import { sort } from '../utils';
import * as actionTypes from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as userTypes from '../../actions/user/user';
import * as transportTradeTypes from '../../actions/transports/trade';
import AppConfig from '../../AppConfig';
import Types from '../../classes/types';
import { store } from '../../store';

const precision = 5;
const {
    addFakeExchangeCurrencyAccount,
    filterMarginModeAccounts,
    sortCustomAccountsHeaderSelect,
    showNonCreatedWallets } = AppConfig.features;

const REGISTER_ACCOUNT_NAME = 'account_name';
const REGISTER_SHOW_ZERO_ASSETS = 'show_zero_assets';

const fakeAccountData = {
    Balance: 0,
    Equity: 0,
    Credit: 0,
    PnL: 0,
    AvailableBalance: 0,
    OwnerUser: '',
};

class AccountsStore extends Record({
    list: new Map(),
    listToShow: new Map(),
    current: null,
    isReady: false, // были получены аккаунты, статус может быть FETCHING (обновление)
    lastUpdateAt: Date.now(),
    precision,
    // exchange assets (wallets)
    showZeroAssets: Register.get(REGISTER_SHOW_ZERO_ASSETS, null) === null ? true : Register.get(REGISTER_SHOW_ZERO_ASSETS),
    showType: Types.ACCOUNT_DEALER_NAME_REAL,
    searchCurrency: '',
    exchangeConvertCurrency: AppConfig.exchangeMode.convertCurrencies[0],
    assetSortAttribute: 'Balance',
    assetSortDirection: Types.SORT_DESC,
    createAccountDealerName: Types.ACCOUNT_DEALER_NAME_REAL,
}) {
    isFetched() {
        return !!this.list.size;
    }
}

const initialState = new AccountsStore();

function convertDataToAccountsMap(data, sortAttribute = true) {
    const list = new Map(data).map((accountData) => {
        return new AccountModel(accountData);
    });

    if (list && list._root && list._root.entries) {
        list._root.entries = customSort(list._root.entries);
    }

    if (sortCustomAccountsHeaderSelect) {
        return list
    } else {
        return sortAttribute ? sort(list, sortAttribute) : list;
    }
}

function addFakeAccount(list) {
    if (!addFakeExchangeCurrencyAccount) {
        return list;
    }

    const excCurrencies = store.getState().trade.tickers.getExchangeCurrencies();
    const listCurrencies = getRealTradeCurrenciesMap(list);
    let newList = list;

    excCurrencies.forEach(currency => {
        if (listCurrencies[currency]) {
            return;
        }

        const accountNumber = currency;
        const data = {
            AccountNumber: accountNumber,
            BaseCurrency: currency,
            Name: `FAKE-${currency}`,
            ...fakeAccountData,
        }

        newList = newList.set(accountNumber, new AccountModel(data));
    });

    return newList;
}

function removeFakeAccount(list, addList) {
    if (!addFakeExchangeCurrencyAccount) {
        return list;
    }

    let newList = list;

    addList.filter(a => a.isTrade).forEach(account => {
        if (list.has(account.BaseCurrency)) {
            newList = newList.delete(account.BaseCurrency);
        }
    });

    return newList;
}

function getRealTradeCurrenciesMap(list) {
    let map = {};

    list.filter(a => a.isRealForTrade).forEach(account => {
        map[account.BaseCurrency] = true;
    });

    return map;
}

export const getTradeAccountNumbers = () => {
    const { list } = store.getState().trade.accounts;
    return list.filter(account => account.isTrade).map(account => account.AccountNumber).toArray();
}

export const getAccountsByType = demo => {
    const { list } = store.getState().trade.accounts;

    if (demo) {
        return list.filter(account => account.isDemo);
    }

    return list.filter(account => account.isRealForTrade || account.isInvestor);
}

function filterListToShow(list) {
    const { visibleAccountCurrencies } = AppConfig.marginMode;
    const listToShow = list.filter(account => account.isVisible);

    if (filterMarginModeAccounts) {
        return filterByCurrency(listToShow, visibleAccountCurrencies);
    }

    return listToShow;
}

function filterByCurrency(list, currencies) {
    return list.filter(account => currencies.indexOf(account.BaseCurrency) !== -1);
}

function customSort(list) {
    list = list.sort(function (a, b) {

        const arrCustom = ["BTC", "ETH", "USD", "USDT", "DEMO"];

        let aIndex = arrCustom.indexOf(a[1].DealerName);
        let bIndex = arrCustom.indexOf(b[1].DealerName);

        if (aIndex === -1) {
            aIndex = arrCustom.indexOf(a[1].BaseCurrency);
        }
        if (bIndex === -1) {
            bIndex = arrCustom.indexOf(b[1].BaseCurrency);
        }

        if (aIndex === 4) {
            aIndex = 1000
        }
        if (bIndex === 4) {
            bIndex = 1000
        }

        if (aIndex === -1) {
            aIndex = 100
        }

        if (bIndex === -1) {
            bIndex = 100
        }
        return aIndex - bIndex;
    });
    return list;
}

const calcDayChange = (account, currency, tickers) => {
    let dayChange = { value: 0, percent: 0 };
    const beginCrossRate = tickers.getCrossRate(account.BaseCurrency, currency, true);
    const crossRate = tickers.getCrossRate(account.BaseCurrency, currency);

    if (!beginCrossRate || crossRate === 1 || (account.Balance - account.Credit) <= 0) {
        return dayChange;
    }

    dayChange.value = crossRate - beginCrossRate;
    dayChange.percent = dayChange.value / beginCrossRate * 100;

    return dayChange;
}

export default function accounts(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHED:
            const list = addFakeAccount(convertDataToAccountsMap(action.payload, 'Name'));
            let listToShow = filterListToShow(list);

            const savedAccountName = Register.get(REGISTER_ACCOUNT_NAME);
            let showType = state.showType;
            let savedAccount = null;
            let configAccount = null;

            if (savedAccountName) {
                savedAccount = listToShow.find((account) => {
                    return account.isRealForTrade && account.Name.toUpperCase().indexOf(savedAccountName) >= 0;
                });
            }

            const {autoCurrentAccountCurrency, currentPriorityAccountCurrencies} = AppConfig.features;
            const sortedList = listToShow.sort((a, b) => b.AccountBalance - a.AccountBalance);

            if (autoCurrentAccountCurrency) {
                configAccount = sortedList
                    .find((account) => {
                        return account.isRealForTrade && account.BaseCurrency === autoCurrentAccountCurrency;
                    });
            } else {
                for (const currency of currentPriorityAccountCurrencies) {
                    const account = sortedList
                        .find((account) => account.isRealForTrade && account.BaseCurrency === currency);

                    if (account && account.AccountBalance) {
                        configAccount = account;
                        break;
                    }
                }
            }

            const field = AppConfig.walletsSort.field || 'RegDate';
            const direction = AppConfig.walletsSort.direction;

            let current =
                savedAccount ||
                state.current ||
                configAccount ||
                sort(
                    listToShow.filter((account) => account.isRealForTrade),
                    field,
                    direction
                ).first() ||
                listToShow.first();

            if (current) {
                if (current.isDemo) {
                    showType = Types.ACCOUNT_DEALER_NAME_DEMO;
                } else {
                    showType = Types.ACCOUNT_DEALER_NAME_REAL;
                }
            }

            return state
                .update('current', () => {
                    // обновление через update чтобы не происходила конвертация в Map (immutable)
                    return current;
                })
                .merge({
                    list,
                    listToShow,
                    showType,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case actionTypes.UPDATE_ACCOUNTS:
        case transportTradeTypes.UPDATE_DATA:
            const updatedData = action.type === transportTradeTypes.UPDATE_DATA
                ? action.payload.accounts : action.payload;

            if (!updatedData) {
                return state.merge({
                    lastUpdateAt: Date.now(),
                });
            }

            const updateAccounts = convertDataToAccountsMap(updatedData);
            const updateList = removeFakeAccount(state.list, updateAccounts);
            const updateListToShow = removeFakeAccount(state.listToShow, updateAccounts);

            return state
                .update('current', (current) => {
                    // обновление через update чтобы не происходила конвертация в Map (immutable)
                    return current && updateAccounts.has(current.AccountNumber)
                        ? updateAccounts.get(current.AccountNumber) : current;
                })
                .merge({
                    list: updateList.merge(updateAccounts),
                    listToShow: updateListToShow.merge(filterListToShow(updateAccounts)),
                    lastUpdateAt: Date.now(),
                });

        case actionTypes.CHANGE_ACCOUNT_NUMBER:
            const changedAccount = state.listToShow.find((account) => {
                return account.AccountNumber === action.payload;
            });

            if (changedAccount) {
                if (changedAccount.isRealForTrade) {
                    Register.set(REGISTER_ACCOUNT_NAME, changedAccount.Name);
                }

                if (changedAccount.isDemo) {
                    showType = Types.ACCOUNT_DEALER_NAME_DEMO;
                } else {
                    showType = Types.ACCOUNT_DEALER_NAME_REAL;
                }

                return state.update('current', () => changedAccount).merge({ showType });
            }

            return state;

        case actionTypes.SHOW_ZERO_BALANCE_ASSETS: {
            Register.set(REGISTER_SHOW_ZERO_ASSETS, action.payload);
            return state.merge({
                showZeroAssets: action.payload,
            });
        }

        case actionTypes.CHANGE_ASSET_SHOW_TYPE: {
            return state.merge({
                showType: action.payload,
            });
        }

        case actionTypes.CHANGE_SEARCH_CURRENCY_KEYWORD: {
            return state.merge({
                searchCurrency: action.payload,
            });
        }

        case actionTypes.CHANGE_ASSET_SORT_ATTRIBUTE:
            return state.merge({
                assetSortAttribute: action.payload,
            });

        case actionTypes.CHANGE_ASSET_SORT_DIRECTION:
            return state.merge({
                assetSortDirection: action.payload,
            });

        case actionTypes.CHANGE_CREATE_ACCOUNT_DEALER_NAME: {
            return state.merge({
                createAccountDealerName: action.payload,
            })
        }

        case actionTypes.CHANGE_EXCHANGE_CONVERT_CURRENCY: {
            return state.merge({
                exchangeConvertCurrency: action.payload,
            });
        }

        case trackingTypes.FETCH_ACTION_PAYLOAD: {
            const actionData = action.payload;
            if (actionData.Action === actionTypes.CHANGED_CURRENT_ACCOUNT) {
                actionData.ActionPayload = new AccountModel(actionData.ActionPayload);
            }
            return state;
        }

        case trackingTypes.GET_STORE:
            return (action.data.accounts = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE: {
            if (action.payload && action.payload.accounts) {
                const accountsData = action.payload.accounts;
                const accountsMap = new Map(accountsData.list).map((accountData) => { return new AccountModel(accountData); });
                const dataListToShow = new Map(accountsData.listToShow);
                const accountsMapToShow = accountsMap.filter(account => dataListToShow.has(account.AccountNumber));
                const selectedAccount = (accountsData.current && accountsMap.has(accountsData.current.AccountNumber))
                    ? accountsMap.get(accountsData.current.AccountNumber) : state.current;

                accountsData.list = accountsMap;
                accountsData.listToShow = accountsMapToShow;
                return state.merge(accountsData).update('current', () => selectedAccount);
            }
            return state;
        }

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}

export const accountDayChangeSelector = (account, currency, tickers) => {
    const dayChange = calcDayChange(account, currency, tickers);

    return {
        DayChange: dayChange.value,
        DayChangePercent: dayChange.percent,
    }
}

export const summaryDayChangeSelector = (accounts, tickers) => {
    let beginTotal = 0;
    let total = 0;

    accounts.listToShow.forEach(account => {
        const beginRate = tickers.getCrossRate(account.BaseCurrency, accounts.exchangeConvertCurrency, true);
        if (beginRate !== 1 && beginRate && (account.Balance - account.Credit) > 0) {
            beginTotal += beginRate;
            total += tickers.getCrossRate(account.BaseCurrency, accounts.exchangeConvertCurrency);
        }
    });

    return {
        value: beginTotal ? total - beginTotal : 0,
        percent: beginTotal ? (total - beginTotal) / beginTotal * 100 : 0,
    }
}

export const summaryExchangeBalanceSelector = (accounts, tickers, exchangeConvertCurrency = null) => {
    const currency = exchangeConvertCurrency ? exchangeConvertCurrency : accounts.exchangeConvertCurrency;
    let totalBalance = 0;

    accounts.listToShow.forEach(account => {
        totalBalance += (account.Balance - account.Credit) * tickers.getCrossRate(account.BaseCurrency, currency);
    });

    return totalBalance;
}

export const totalMarginAccountsBalance = (accounts, tickers, convertCurrency = 'USD') => {
    let totalBalance = 0;

    accounts.listToShow.toArray().filter(acc => acc.isReal).forEach(account => {
        totalBalance += account.Balance * tickers.getCrossRate(account.BaseCurrency, convertCurrency);
    });

    return totalBalance;
}

export const assetsSelector = (accounts, tickers) => {
    const current = accounts.current || {};
    const showType = accounts.showType;
    const currentIsDemo = showType === Types.ACCOUNT_DEALER_NAME_DEMO;
    const field = AppConfig.walletsSort.field || 'RegDate';
    const direction = AppConfig.walletsSort.direction;

    const accountsList = accounts.listToShow.filter(
        account => account.AccountNumber !== current.AccountNumber).toArray();
    const currentList = sort(accounts.listToShow.filter(
        account => account.AccountNumber === current.AccountNumber && current.isDemo === currentIsDemo).toArray(), field, direction);

    let assets = accountsList;

    const currencies = AppConfig.createAccount.currencyList[Types.ACCOUNT_DEALER_NAME_REAL] || AppConfig.createAccount.currencyList.default || [];

    if (showNonCreatedWallets && accounts.showZeroAssets) {
        currencies.forEach(currency => {
            if (!accounts.listToShow.find(account => account.BaseCurrency === currency && (account.isTrade || account.isFake))) {
                assets.push({
                    BaseCurrency: currency,
                    BaseCurrencyPrecision: 0,
                    ExchangeBalance: 0,
                    DayChange: 0,
                    DayChangePercent: 0,
                });
            }
        });
    }

    const filteredAssets = assets
        .filter(account =>
            (accounts.showZeroAssets || account.Balance > 0)
            && (accounts.searchCurrency === '' || account.BaseCurrency.indexOf(accounts.searchCurrency.toUpperCase()) !== -1 || (account.AccountNumber && account.AccountNumber.includes(accounts.searchCurrency)))
        ).map(account => {
            if (account.AccountNumber) {
                const dayChange = calcDayChange(account, accounts.exchangeConvertCurrency, tickers);
                account.DayChange = dayChange.value;
                account.DayChangePercent = dayChange.percent;
            }

            return account;
        });

    const demoAssets = showType === Types.ACCOUNT_DEALER_NAME_DEMO ?
        filteredAssets.filter(account => account.isDemo) : [];
    const realAssets = showType === Types.ACCOUNT_DEALER_NAME_REAL ?
        filteredAssets.filter(account => !account.isDemo && account.BaseCurrency !== current.BaseCurrency) : [];
    const currentCerrencyAssets = showType === Types.ACCOUNT_DEALER_NAME_REAL ?
        filteredAssets.filter(account => !account.isDemo && account.BaseCurrency === current.BaseCurrency) : [];

    return currentList.concat(sort(demoAssets, accounts.assetSortAttribute, accounts.assetSortDirection))
        .concat(sort(currentCerrencyAssets, accounts.assetSortAttribute, accounts.assetSortDirection))
        .concat(sort(realAssets, accounts.assetSortAttribute, accounts.assetSortDirection));
}
