import md5 from 'md5';

// This is Bad injection it need move to class implementation 
import AuthDepository from './auth-depository';
import authDepository from '../services/auth-service';
import AppConfig from '../AppConfig';
import Api from './api';
import {store} from '../store';

class ReportApi extends Api {

    get(url, params = {}, auth = true) {
        const requestData = {...params.data};

        if (AppConfig.features.isEnabledAuth2 && auth) {
            const auth2Data = this._auth2Data();
            for (let key in auth2Data) {
                requestData[key] = auth2Data[key];
            }
        } else if (auth) {
            const authData = this._authData();
            for (let key in authData) {
                requestData[key] = authData[key];
            }
        }
        return super.get(url, {
            ...params,
            data: {
                ...requestData,
                compress: true
            },
        })

    }

    _authData() {
        const user = store.getState().user.model;
        return {
            login: user.Login,
            password: md5(user.Password),
        }
    }

    _auth2Data() {
        return {
            [AuthDepository.URL_TOKEN_KEY]: authDepository.getToken(),
        }
    }
}

export default ReportApi;
