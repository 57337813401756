/**
 * Created by Evgeniy Bondarev
 */
import { List, Record } from "immutable";

import * as actionTypes from "../../actions/investing/instrument-profitable";
import * as userTypes from "../../actions/user/user";
import { InstrumentProfitableModel } from '../../models/instrument-profitable';

class InstrumentProfitableStore extends Record({
    list: new List(),
    status: actionTypes.NOT_FETCHED,
    isReady: false,
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED;
    }
}

const initialState = new InstrumentProfitableStore();

export default function subscription(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case actionTypes.FETCHED:
            return state.merge({
                list: new List(action.payload ? action.payload.map(item => new InstrumentProfitableModel(item)) : []),
                status: actionTypes.FETCHED,
                isReady: true
            });

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}