import Types from "../../classes/types";
import { transport } from "../../services";
import { convertTimeToServerTime } from "../../utils/time";

export const UPDATE_HISTORY_FILTERS = 'UPDATE_HISTORY_FILTERS';
export const UPDATE_ACTIVITY_LOG_FILTERS = 'UPDATE_ACTIVITY_LOG_FILTERS';
export const UPDATE_WALLETS_SEARCH_KEY = 'UPDATE_WALLETS_SEARCH_KEY';
export const UPDATE_SHOW_ZERO_BALANCE = 'UPDATE_SHOW_ZERO_BALANCE';
export const UPDATE_POSITIONS_FILTERS = 'UPDATE_POSITIONS_FILTERS';
export const UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS';
export const UPDATE_PLATFORM_NOTIFICATIONS_FILTERS = 'UPDATE_PLATFORM_NOTIFICATIONS_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';
export const SET_SEARCH_ACTIVITY_LOG = 'SET_SEARCH_ACTIVITY_LOG';

export function updateHistoryFilters(data) {
    return {
        type: UPDATE_HISTORY_FILTERS,
        payload: data
    }
}

export function doUpdateHistoryFilters(data) {
    return (dispatch, getState) => {
    const currentWallets = data.wallets;
    
    if (currentWallets.currentWallet) {
        const prevWallets = getState().trade.filters.historyFilters.wallets;
        const areWalletsFiltersEqual = JSON.stringify(currentWallets) === JSON.stringify(prevWallets);

        if (!areWalletsFiltersEqual) {
            data.wallets.currentWallet = false;
        }
    }

    dispatch(updateHistoryFilters(data));
    }
}

export function updateWalletsSearchKey(data) {
    return {
        type: UPDATE_WALLETS_SEARCH_KEY,
        payload: data
    }
}

export function updateShowZeroBalance() {
    return {
        type: UPDATE_SHOW_ZERO_BALANCE,
    }
}

export function updateActivityLogFilters(data) {
    return {
        type: UPDATE_ACTIVITY_LOG_FILTERS,
        payload: data
    }
}

export function doUpdateActivityLogFilters(data) {
    return (dispatch, getState) => {
    const currentWallets = data.wallets;
    
    if (currentWallets.currentWallet) {
        const prevWallets = getState().trade.filters.activityLogFilters.wallets;
        const areWalletsFiltersEqual = JSON.stringify(currentWallets) === JSON.stringify(prevWallets);

        if (!areWalletsFiltersEqual) {
            data.wallets.currentWallet = false;
        }
    }

    dispatch(updateActivityLogFilters(data));
    }
}

export function updatePositionsFilters(data) {
    return {
        type: UPDATE_POSITIONS_FILTERS,
        payload: data
    }
}

export function asynUpdatePositionsFilters(data) {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_POSITIONS_FILTERS,
            payload: data,
        });
        return Promise.resolve();
    }
}

export function updateOrdersFilters(data) {
    return {
        type: UPDATE_ORDERS_FILTERS,
        payload: data
    }
}

export function updatePlatformNotificationsFilters(data) {
    return (dispatch, getState) => {
        if (data.period !== Types.DATE_PERIOD_ALL) {
            const datePeriod = Types.datePeriod(
                data.period,
                data.from,
                data.to
            );
            transport.notificationRequest({
                fromDate: convertTimeToServerTime(datePeriod.from),
                toDate: convertTimeToServerTime(datePeriod.to),
            })
        }

        return dispatch({
            type: UPDATE_PLATFORM_NOTIFICATIONS_FILTERS,
            payload: data
        });
    }
}

export function asynUpdateOrdersFilters(data) {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_ORDERS_FILTERS,
            payload: data,
        });
        return Promise.resolve();
    }
}


export function clearFilters() {
    return {
        type: CLEAR_FILTERS
    }
}

export function setSearchHistory(searchString) {
    return {
        type: SET_SEARCH_HISTORY,
        payload: searchString
    }
}

export function setSearchActivityLog(searchString) {
    return {
        type: SET_SEARCH_ACTIVITY_LOG,
        payload: searchString
    }
}