/**
 * Created by ebondarev
 */
export const ERROR = "TRANSPORTS_MAIN_ERROR";
export const REJECT = "TRANSPORTS_MAIN_REJECT";
export const REJECT_ORDER = "TRANSPORTS_MAIN_REJECT_ORDER";

export function error(text) {
    return {
        type: ERROR,
        payload: text
    }
}

export function reject(text) {
    return {
        type: REJECT,
        payload: text
    }
}

export function rejectOrder(text) {
    return {
        type: REJECT_ORDER,
        payload: text
    }
}