import BaseModel from './base';

class MarginModel extends BaseModel {
    Id;
    Name;
    InitMargin;
    HoldMargin;
    StopOut;
    Comment;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}

export default MarginModel;