import { Record } from 'immutable';
import * as formTradeActions from '../../actions/forms/trade';

class FormTradeStore extends Record({
    isEnoughMoney: true,
    amountIsValid: true,
    takeProfit: undefined,
    tpUnit: undefined,
    tpValue: undefined,
    stopLoss: undefined,
    slUnit: undefined,
    slValue: undefined,
    price: undefined,
    mouseIsAboveTradeButton: false,
    trailingStop: false,
    action: undefined,
    amount: undefined,
    leverage: undefined,
    accountCurrency: undefined,
    isActiveExchangePanel: false,
}){};

const initialState = new FormTradeStore();

export default function trade(state = initialState, action) {
    switch (action.type) {
        case formTradeActions.UPDATE:
            return state.merge({
                ...action.payload,
            });

        default:
            return state;
    }
}