/**
 * Created by ebondarev
 */

import { addTab, changeActiveTab } from '../../actions/trade/chart';
import { ChartTabModel } from '../../models/chart-tab';

export const UPDATE_QUOTE = 'TRADE_TICKERS_UPDATE_QUOTE';
export const UPDATE_QUOTES = 'TRADE_TICKERS_UPDATE_QUOTES';
export const NOT_FETCHED = 'TRADE_TICKERS_NOT_FETCHED';
export const FETCHING = 'TRADE_TICKERS_FETCHING';
export const FETCHED = 'TRADE_TICKERS_FETCHED';
export const UPDATE_SEARCH = 'TRADE_TICKERS_UPDATE_SEARCH';
export const UPDATE_TICKER_GROUP_FILTER = 'UPDATE_TICKER_GROUP_FILTER';
export const UPDATE_DISABLED_LIST = 'TRADE_TICKERS_UPDATE_DISABLED_LIST';
export const CHANGE_DETAIL_SORT_ATTRIBUTE = 'TRADE_TICKERS_DETAIL_CHANGE_SORT_ATTRIBUTE';
export const CHANGE_DETAIL_SORT_DIRECTION = 'TRADE_TICKERS_DETAIL_CHANGE_SORT_DIRECTION';
export const CHANGE_DETAIL_SEARCH_TICKER = 'TRADE_TICKERS_DETAIL_CHANGE_SEARCH_TICKER';
export const CHAGNE_TRADE_TICKER = 'TRADE_TICKERS_CHAGE_TICKER';
export const CHAGNE_TRADE_SIDE = 'TRADE_TICKERS_CHAGE_SIDE';
export const CHANGE_TICKERS_SORT_ATTRIBUTE = 'TRADE_TICKERS_CHANGE_TICKERS_SORT_ATTRIBUTE';
export const CHANGE_TICKERS_SORT_DIRECTION = 'TRADE_TICKERS_CHANGE_TICKERS_SORT_DIRECTION';
export const CHANGE_TICKERS_SEARCH = 'TRADE_TICKERS_CHANGE_TICKERS_SEARCH';

export const MARK_TICKER = 'MARK_TICKER';

export function fetching() {
    return {
        type: FETCHING,
    }
}

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

/**
 * @deprecated
 */
export function updateQuote(quote) {
    return {
        type: UPDATE_QUOTE,
        payload: quote
    }
}

/**
 * @deprecated
 */
export function updateQuotes(quotes) {
    return {
        type: UPDATE_QUOTES,
        payload: quotes
    }
}

export function updateSearch(value) {
    return {
        type: UPDATE_SEARCH,
        payload: value
    }
}

export function updateTickerGroupFilter(value) {
    return {
        type: UPDATE_TICKER_GROUP_FILTER,
        payload: value
    }
}

export function updateDisabledList(value) {
    return {
        type: UPDATE_DISABLED_LIST,
        payload: value
    }
}

export function doFetched(data) {
    return (dispatch, getState) => {
        dispatch(fetched(data));
    }
}

export function detailSortAttribute(value) {
    return {
        type: CHANGE_DETAIL_SORT_ATTRIBUTE,
        payload: value
    }
}

export function detailSortDirection(value) {
    return {
        type: CHANGE_DETAIL_SORT_DIRECTION,
        payload: value
    }
}

export function detailSearchTicker(value) {
    return {
        type: CHANGE_DETAIL_SEARCH_TICKER,
        payload: value
    }
}

export function tickersSortAttribute(value) {
    return {
        type: CHANGE_TICKERS_SORT_ATTRIBUTE,
        payload: value
    }
}

export function tickersSortDirection(value) {
    return {
        type: CHANGE_TICKERS_SORT_DIRECTION,
        payload: value
    }
}

export function tickersSearch(value) {
    return {
        type: CHANGE_TICKERS_SEARCH,
        payload: value
    }
}

export function markTicker(symbolId) {
    return {
        type: MARK_TICKER,
        payload: symbolId
    }
}

export function changeTradeTicker(value) {
    return {
        type: CHAGNE_TRADE_TICKER,
        payload: value,
    }
}

export function changeTadeSide(side) {
    return {
        type: CHAGNE_TRADE_SIDE,
        payload: side,
    }
}

export function doChangeTradeTicker(data) {
    return (dispatch, getState) => {
        dispatch(changeTradeTicker(data));

        const { activeTab, tabs } = getState().trade.chart;

        if (activeTab && activeTab.symbolId === data.symbolId) {
            return;
        }

        const tab = tabs.filter(t => t.symbolId === data.symbolId).first();

        if (tab) {
            dispatch(changeActiveTab(tab));
            return;
        }

        const ticker = getState().trade.tickers.list.get(data.symbolId);
        dispatch(addTab(ChartTabModel.createFromTicker(ticker)));
    }
}