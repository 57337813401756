import { dispatchPost, wdApi } from '../../services';
import { doFetchTransactionsHistory } from './report';

export const WITHDRAW_BEGIN_REQUEST = 'WITHDRAW_BEGIN_REQUEST';
export const WITHDRAW_END_REQUEST = 'WITHDRAW_END_REQUEST';
export const WITHDRAW_CHANGE_ACCOUNT = 'WITHDRAW_CHANGE_ACCOUNT';
export const WITHDRAW_CHANGE_PAYMENT_METHOD = 'WITHDRAW_CHANGE_PAYMENT_METHOD';
export const WITHDRAW_CHANGE_PAYMENT_METHOD_AND_ACCOUNT = 'WITHDRAW_CHANGE_PAYMENT_METHOD_AND_ACCOUNT';

export function beginRequest() {
    return {
        type: WITHDRAW_BEGIN_REQUEST,
    }
}

export function endRequest(result) {
    return {
        type: WITHDRAW_END_REQUEST,
        payload: result,
    }
}

export function changeAccount(account) {
    return {
        type: WITHDRAW_CHANGE_ACCOUNT,
        payload: account,
    }
}


export function changePaymentMethod(paymentMehodId) {
    return {
        type: WITHDRAW_CHANGE_PAYMENT_METHOD,
        payload: paymentMehodId,
    }
}

export function changePaymentMethodAndAccount(data) {
    return {
        type: WITHDRAW_CHANGE_PAYMENT_METHOD_AND_ACCOUNT,
        payload: data,
    }
}

export function doWithdraw({
    userId,
    accountId,
    paymentMethodNumber,
    paymentSystemNumber,
    amount,
    currency,
    SWIFT,
    IBAN,
    IBAN2,
    holderFirstName,
    holderLastName,
    holderCountry,
    holderAddress,
    holderCity,
    holderZip,
    bankAddress,
    bankPhone,
    bankName,
    phone,
    bailAccountId,
    twoFACode,
    emailCode,
    network}) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return dispatchPost('WithdrawAccount', {
            UserId: +userId,
            AccountId: +accountId,
            PaymentMethod: paymentMethodNumber,
            PaymentSystem: paymentSystemNumber,
            Sum: amount,
            Currency: currency,
            SWIFT,
            IBAN,
            IBAN2,
            HolderFirstName: holderFirstName,
            HolderLastName: holderLastName,
            HolderCountry: holderCountry,
            HolderAddress: holderAddress,
            HolderCity: holderCity,
            HolderZip: holderZip,
            BankAddress: bankAddress,
            BankPhone: bankPhone,
            BankName: bankName,
            Phone: phone,
            BailAccountId: bailAccountId,
            TwoFACode: twoFACode,
            EmailCode: emailCode,
            Network: network
        }).then((response) => {
            let result = response.data && response.data.Result;

            if(response.data && response.data.Error) {
                result = response.data.Error
            }

            dispatch(endRequest(true));
            return result;
        }).catch(() => dispatch(endRequest(false)));
    }
}

export function doCancelWithdraw(paymentId) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return dispatchPost('CancelPayment', {
            PaymentId: paymentId,
        }).then((response) => {
            const result = response.data && response.data.Result;

            dispatch(endRequest(true));

            if (result) {
                dispatch(doFetchTransactionsHistory());
            }

            return result;
        }).catch(() => dispatch(endRequest(false)));
    };
};

export function doSendWithdrawError({login, reason}) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            wdApi
            .post('WebAction', {
                Action: 'WithdrawError',
                Login: login,
                Data: JSON.stringify({reason}),
            })
            .then(result => {
                if (!result || !result.data) {
                    reject('Connect error');
                }

                if (result.data.Error) {
                    reject(result.data.Message);
                }

                resolve(result.data.Result);
            }).catch(() => {
                reject('Request error');
            });
        });
    }
}