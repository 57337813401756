/**
 * Created by Evgeniy Bondarev
 */
import { reportApi } from "../../services";


export const NOT_FETCHED = 'INVESTING_ACCOUNTS_NOT_FETCHED';
export const FETCHING = 'INVESTING_ACCOUNTS_FETCHING';
export const FETCHED = 'INVESTING_ACCOUNTS_FETCHED';


export function fetching() {
    return {
        type: FETCHING,
    }
}

function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function doFetch() {
    return (dispatch, getState) => {
        dispatch(fetching());

        return reportApi.get('', {
            data: {
                report: "UserAccounts",
                format: "json"
            }
        }).then((res) => {
            const fetchedDataUnique = {};
            const accountsList = getState().trade.accounts.list;
            res.data.forEach((rawData) => {
                const account = accountsList.get(rawData.ACCOUNT_ID.toString());
                if (account) {
                    rawData.name = account.Name;
                }

                fetchedDataUnique[rawData.ACCOUNT_ID] = rawData;
            });

            dispatch(fetched(fetchedDataUnique));
            return fetchedDataUnique;
        });
    }
}

export function doFetchIfNeed() {
    return (dispatch, getState) => {
        const status = getState().investing.accounts.status;
        if (status === FETCHED || status === FETCHING) {
            return;
        }

        dispatch(doFetch());
    }
}