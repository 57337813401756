import { Record, Map } from 'immutable';

import * as actionTypes from '../../actions/investing/subscription';
import * as actionAccountsTypes from '../../actions/investing/accounts';
import * as actionPerformanceChartTypes from '../../actions/investing/performance-chart';
import * as userTypes from '../../actions/user/user';
import { SubscriptionModel } from '../../models/subscription';
import { sort } from '../utils';
import Types from '../../classes/types';
import { store } from '../../store';
import { lang } from '../../services';


class SubscriptionStore extends Record({
    list: new Map(),
    status: actionTypes.NOT_FETCHED,
    sortAttribute: 'SetID',
    sortDirection: Types.SORT_DESC,
    isReady: false,
    isAccountsReady: false,
    subscribingStrategy: '',
    subscribedStrategy: '',
    doneStrategy: '',
    unsubscribed: false,
    unsubscribing: new Map(),
    listLastUpdatedAt: Date.now()
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED;
    }

    isReadyToShow() {
        return this.isReady && this.isAccountsReady;
    }
}

const initialState = new SubscriptionStore();

function convertDataToSubscriptionsMap(data) {
    return new Map(data).map((subscribeData) => {
        const model = new SubscriptionModel(subscribeData);
        model.FixedLot = +lang.ceilFloatNumber(model.FixedLot);
        model.MultiplyLot = +lang.ceilFloatNumber(model.MultiplyLot);

        return model;
    });
}

export default function subscription(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHED:
            const investingAccountsList = store.getState().investing.accounts.list;
            const performanceChartStore = store.getState().investing.performanceChart;
            const fetchedList = convertDataToSubscriptionsMap(action.payload);

            let subscribedStrategy = state.subscribedStrategy;
            let subscribingStrategy = state.subscribingStrategy;
            const unsubscribed = fetchedList.size < state.list.size;

            fetchedList.forEach((subscriptionModel) => {
                const investingAccount = investingAccountsList.get(subscriptionModel.InvestorAccountNumber);

                if (investingAccount) {
                    subscriptionModel.updateAttributesFromAccounts(investingAccount);
                }

                if (performanceChartStore.isReady) {
                    const performanceChartsData = performanceChartStore.list.get(subscriptionModel.InvestorAccountNumber);
                    if (performanceChartsData) {
                        subscriptionModel.performanceChartReports = performanceChartsData;
                    } else {
                        subscriptionModel.performanceChartReports = [];
                    }
                }

                if (subscribingStrategy === subscriptionModel.SignalAccountNumber.toString()) {
                    subscribedStrategy = subscriptionModel.SignalAccountNumber;
                    subscribingStrategy = '';
                }
            });

            return state.merge({
                list: sort(fetchedList, state.sortAttribute, state.sortDirection),
                listLastUpdatedAt: Date.now(),
                isReady: true,
                status: actionTypes.FETCHED,
                subscribingStrategy,
                subscribedStrategy,
                unsubscribed,
            });

        case actionAccountsTypes.FETCHED:
            state.list.forEach((subscriptionModel) => {
                if (action.payload[subscriptionModel.InvestorAccountNumber]) {
                    subscriptionModel.updateAttributesFromAccounts(action.payload[subscriptionModel.InvestorAccountNumber]);
                }
            });

            const mergedDataAfterStrategyFetched = {
                list: state.list,
                listLastUpdatedAt: Date.now(),
                isAccountsReady: true
            };

            if (!state.isAccountsReady) {
                mergedDataAfterStrategyFetched.list = sort(mergedDataAfterStrategyFetched.list, state.sortAttribute, state.sortDirection)
            }

            return state.merge(mergedDataAfterStrategyFetched);

        case  actionPerformanceChartTypes.FETCHED:
            state.list.forEach((subscriptionModel) => {
                if (action.payload[subscriptionModel.InvestorAccountNumber]) {
                    subscriptionModel.performanceChartReports = action.payload[subscriptionModel.InvestorAccountNumber];
                    return;
                }

                subscriptionModel.performanceChartReports = [];
            });

            return state.merge({
                listLastUpdatedAt: Date.now()
            });

        case actionTypes.SUBSCRIBING_ON_STRATEGY: {
            return state.merge({
                subscribingStrategy: action.payload.toString(),
            });
        }

        case actionTypes.SUBSCRIBED_ON_STRATEGY: {
            return state.merge({
                subscribedStrategy: action.payload.toString(),
            });
        }

        case actionTypes.RESET_UNSUBSCRIBED: {
            return state.merge({
                unsubscribed: false,
            });
        }

        case actionTypes.UNSUBSCRIBING_ADD: {
            return state.merge({
                unsubscribing: state.unsubscribing.set(action.payload.subscription.SetID, {
                    toAccountNumber: action.payload.toAccountNumber,
                    investorAccountNumber: action.payload.subscription.InvestorAccountNumber
                }),
            });
        }

        case actionTypes.UNSUBSCRIBING_REMOVE: {
            return state.merge({
                unsubscribing: state.unsubscribing.delete(action.payload),
            });
        }

        case actionTypes.SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            const sortedList = sort(state.list, action.payload, state.sortDirection);

            return state.merge({
                list: sortedList,
                sortAttribute: action.payload,
            });

        case actionTypes.SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            const sortDirectionList = sort(state.list, state.sortAttribute, action.payload);

            return state.merge({
                list: sortDirectionList,
                sortDirection: action.payload,
            });

        case userTypes.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
