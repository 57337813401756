import Register from './register';
import AppConfig from '../AppConfig';
import { getCookie } from '../utils/cookies';

export default class Dispatcher {
    api;
    servers;
    _isActualServers = false;

    constructor(api) {
        this.api = api;
        const dispatcherServers = Register.get('dispatcherServers');
        Dispatcher.clear();

        if (AppConfig.features.isDispatcherEnabled && dispatcherServers) {
            this._isActualServers = true;
            this.servers = {
                server: dispatcherServers.server,
                wssServer: dispatcherServers.wssServer,
                reportServer: dispatcherServers.reportServer,
                quoteServer: AppConfig.getServerAddress(AppConfig.defines.QUOTE_URL),
                trackingServer: dispatcherServers.trackingServer || AppConfig.getServerAddress(AppConfig.defines.TRACKER_URL),
                transactionServer: dispatcherServers.transactionServer,
            };
        } else {
            this.servers = {
                server: AppConfig.getServerAddress(AppConfig.defines.WD_API.SERVER),
                wssServer: AppConfig.getServerAddress(AppConfig.defines.SERVERS_URL),
                reportServer: AppConfig.getServerAddress(AppConfig.defines.REPORT_URL),
                quoteServer: AppConfig.getServerAddress(AppConfig.defines.QUOTE_URL),
                trackingServer: AppConfig.getServerAddress(AppConfig.defines.TRACKER_URL),
                transactionServer: AppConfig.getServerAddress(AppConfig.defines.TRANSACTION_URL),
            };
        }
    }

    // remove servers addresses from Local storage
    static clear() {
        Register.remove('dispatcherServers');
    }

    saveServers() {
        if (AppConfig.features.isDispatcherEnabled) {
            Register.set('dispatcherServers', this.servers);
        }
    }

    static isDispatcherAnswerValid(answer) {
        if (answer && answer.Error === null
            && Array.isArray(answer.WDApi) && answer.WDApi[0]
            && Array.isArray(answer.Report) && answer.Report[0]
            && Array.isArray(answer.WSS) && answer.WSS[0]
            && Array.isArray(answer.Transaction) && answer.Transaction[0]
        ) {
            return true;
        }
        return false;
    }

    replaceHostnameWildcard(url) {
        if (typeof url !== 'string') return;

        return url.replace('*', document.domain);
    }

    getServers(login = undefined, apiKey, token = undefined, affiliateCode = undefined) {
        return new Promise((resolve, reject) => {
            if (AppConfig.features.isDispatcherEnabled && AppConfig.getServerAddress(AppConfig.defines.DISPATCHER_URL)) {
                if (this._isActualServers) { // if we have actual servers
                    this._isActualServers = false;
                    reject(this.servers);
                } else {
                    const message = apiKey ? {
                        Type: 'dispatchlogin',
                        ApiKey: apiKey
                    } : login || token ? {
                        Type: 'dispatchlogin',
                        Login: login, 
                        Token: token
                    } : {
                        Type: 'dispatchregister',
                        AffiliateCode: affiliateCode || getCookie(AppConfig.referral.cookieAffiliateCodeName)
                    };

                    this.api.post(AppConfig.getServerAddress(AppConfig.defines.DISPATCHER_URL), message).then(res => {
                        const r = res && res.data && res.data.Result;
                        if (Dispatcher.isDispatcherAnswerValid(r)) {
                            this.servers = {
                                server: this.replaceHostnameWildcard(r.WDApi[0]),
                                wssServer: r.WSS.map(u => this.replaceHostnameWildcard(u)),
                                reportServer: this.replaceHostnameWildcard(r.Report[0]),
                                quoteServer: AppConfig.getServerAddress(AppConfig.defines.QUOTE_URL),
                                trackingServer: (r.Tracking && r.Tracking.length) ?
                                    r.Tracking.map(u => this.replaceHostnameWildcard(u))
                                    : AppConfig.getServerAddress(AppConfig.defines.TRACKER_URL),
                                transactionServer: this.replaceHostnameWildcard(r.Transaction[0])
                            };

                            resolve({
                                error: false,
                                servers: this.servers
                            });
                        } else if (r && r.Error) {
                            resolve({ error: true, servers: {} });
                        } else { //dispatcher not return correct answer
                            reject(this.servers);
                        }
                    });
                }
            } else {
                reject(this.servers);
            }
        });
    }
}
