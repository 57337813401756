import { Record, Map } from 'immutable';

import { store } from '../../store';
import { AppConfig } from '../../AppConfig';

import * as commissionsTypes from '../../actions/trade/commissions';
import * as userTypes from '../../actions/user/user';
import CommissionModel from '../../models/commission';


class CommissionsStore extends Record({
    list: Map(),
    status: commissionsTypes.NOT_FETCHED,
    lastUpdateAt: null,
    isReady: false
}) {
    isFetched() {
        return this.status === commissionsTypes.FETCHED;
    }

    getCommissionAmount(ticker, size, currency) {
        let response = 0;
        const commission = this.list.get(ticker.ComissionModelId);

        if (commission === undefined
            || (commission.PaymentType !== 0 && commission.PaymentType !== 1)) {
            return response;
        }

        if (commission.PaymentType === 0) {
            response = (size * commission.ComissionRate) / ticker.LotSize;
            response *= store.getState().trade.tickers.getCrossRate(commission.Currency, currency);
        }

        if (commission.PaymentType === 1) {
            response = (size * (commission.ComissionRate * 100000)) / 100000;
            response *= store.getState().trade.tickers.getCrossRate(
                ticker.Exp1 === currency ? ticker.Exp2 : ticker.Exp1,
                commission.Currency
            );
        }

        return response < 0.00001 ? 0 : AppConfig.filterAmount(currency, response);
    }
}

function createModelFromData(data) {
    return new CommissionModel(data);
}

const initialState = new CommissionsStore();

export default function commissions(state = initialState, action) {
    switch (action.type) {
        case commissionsTypes.NOT_FETCHED:
        case commissionsTypes.FETCHING:
            return state
                .merge({
                    status: action.type
                });

        case commissionsTypes.FETCHED:
            const listFetched = Map(action.payload).mapEntries(([ key, data ]) => 
                [ Number(key), createModelFromData(data) ]
            );

            return state
                .merge({
                    list: listFetched,
                    status: commissionsTypes.FETCHED,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: Map()
            });

        default:
            return state;
    }
}