/**
 * Created by ebondarev
 */

import BaseModel from './base';
import AppConfig from '../AppConfig';
import Types from '../classes/types';
import { store } from '../store';
import { filterListByAccount as positionListFilter } from '../reducers/trade/posittions';
import date from '../utils/date';
import { calculateSumInitMargin, calculateAccountMTMargin } from '../utils/trade';
import { subscriptionsArr } from '../selectors/trade/investing';

export default class AccountModel extends BaseModel {

    AccountNumber;
    _Name;
    get Name() {
        return this._Name;
    }

    set Name(value) {
        const { accountPrefix } = AppConfig;
        let accountType = '';

        this._Name = value;

        const isStrPrefix = (arr, str) => {
            return arr.map(item => str.indexOf(item))
                .filter(item => item === 0)
                .length > 0;
        }
        
        if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_DEMO], value)) {
            this._isDemo = true;
            accountType = Types.ACCOUNT_TYPE_DEMO;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_REAL], value)) {
            this._isTrade = true;
            accountType = Types.ACCOUNT_TYPE_REAL;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_PAMM], value)) {
            this._isPamm = true;
            accountType = Types.ACCOUNT_TYPE_PAMM;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_FAKE], value)) {
            this._isFake = true;
            accountType = Types.ACCOUNT_TYPE_FAKE;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_MINING], value)) {
            this._isMining = true;
            accountType = Types.ACCOUNT_TYPE_MINING;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_PAYMENT], value)) {
            this._isPayment = true;
            accountType = Types.ACCOUNT_TYPE_PAYMENT;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_PROFIT], value)) {
            this._isProfit = true;
            accountType = Types.ACCOUNT_TYPE_PROFIT;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_MPF], value)) {
            this._isPower = true;
            accountType = Types.ACCOUNT_TYPE_MPF;
        } else if (isStrPrefix(accountPrefix[Types.ACCOUNT_TYPE_AFFILIATE], value)) {
            this._isAffiliate = true;
            accountType = Types.ACCOUNT_TYPE_AFFILIATE;
        }

        this._isReal = !this._isDemo && !this._isFake && this._isAffiliate;
        this._isRealForTrade = this._isTrade;
        this.accountType = accountType;

        this.namePrefix = value.substring(0, value.indexOf(`-${this.AccountNumber}`));
    }

    AccType;
    AvailableBalance;
    Balance;
    BaseCurrency;
    BaseCurrencyPrecision = 2;
    ComissionLevel;
    Credit;
    CurrentMargin;
    DealerName;
    Deposit;
    EnableTrading;
    Equity;
    FullName;
    InitLeverage;
    LPName;
    MarginSupport;
    OwnerUser;
    Permissions;
    PnL;
    RealizedPnL;
    RealizedPnLCurrency;

    _RegDate;
    get RegDate() {
        return this._RegDate;
    }

    set RegDate(value) {
        this._RegDate = value;

        const days = AppConfig.demoAccountExpirationDays;

        if (!days || !this.isDemo) {
            return;
        }

        this.expirationDays = days - date.diffDays(new Date(), new Date(value * 1000));
    }

    State;
    SupportLeverage;
    TotalExposition;
    TotalExpositionOrders;
    TotalMargin;
    UsedMargin;

    get AccountBalance() {
        return this.Balance - this.Credit;
    }

    get MTPL() {
        const { positions } = store.getState().trade;

        if (positions && positions.list) {
            return this._calculateMTPL(positionListFilter(positions.list, this.AccountNumber));
        }

        return 0;
    }

    get MTEquity() {
        return this.Balance + this.MTPL;
    }

    get MTAvailableWithdrawBalance() {
        const { positions } = store.getState().trade;
        const { allowWithdrawBonus } = AppConfig.withdraw;
        const calculatedStopOut = Math.pow(Math.abs(this.StopOut / 100), 2);

        let availableWithdrawBalance = allowWithdrawBonus ? this.Balance : this.Balance - this.Credit;

        if (positionListFilter(positions.list, this.AccountNumber).size > 0) {
            availableWithdrawBalance = allowWithdrawBonus ? 
                this.MTEquity * calculatedStopOut : 
                (this.MTEquity - this.Credit) * calculatedStopOut;
        };

        return availableWithdrawBalance;
    }

    get MTMargin() {
        return calculateAccountMTMargin(this);
    }

    get MTMarginLevel() {
        const MTMargin = this.MTMargin;

        if (MTMargin > 0) {
            return (this.MTEquity / MTMargin) * 100;
        }

        return 0;
    }

    get MTFreeMargin() {
        return this.MTEquity - this.MTMargin;
    }

    get totalLoss() {
        const { positions } = store.getState().trade;

        if (positions && positions.list) {
            return this._calculateTotalLoss(positionListFilter(positions.list, this.AccountNumber));
        }

        return 0;
    }

    get totalProfit() {
        const { positions } = store.getState().trade;

        if (positions && positions.list) {
            return this._calculateTotalProfit(positionListFilter(positions.list, this.AccountNumber));
        }

        return 0;
    }

    get sumInitMargin() {
        const { positions } = store.getState().trade;

        if (positions && positions.list) {
            return calculateSumInitMargin(positionListFilter(positions.list, this.AccountNumber));
        }

        return 0;
    }

    // additions attributes
    accountName;
    accountType;
    expirationDays;
    namePrefix;

    get exchangeBalance() {
        return this.isMining ? this.Balance - this.Credit : this.MTEquity - this.Credit - this.MTMargin;
    }

    get isVisible() {
        return !!AppConfig.accountVisible[this.accountType];
    }

    _isReal;
    get isReal() {
        return this._isReal;
    }

    _isRealForTrade;
    get isRealForTrade() {
        return this._isRealForTrade;
    }

    _isPamm = false;
    get isPamm() {
        return this._isPamm;
    }

    _isPayment = false;
    get isPayment() {
        return this._isPayment;
    }

    _isPower = false;
    get isPower() {
        return this._isPower;
    }

    _isProfit = false;
    get isProfit() {
        return this._isProfit;
    }

    _isTrade = false;
    get isTrade() {
        return this._isTrade;
    }

    _isDemo = false;
    get isDemo() {
        return this._isDemo;
    }

    _isMining;
    get isMining() {
        return this._isMining;
    }

    _isFake = false;
    get isFake() {
        return this._isFake;
    }

    _isAffiliate = true;
    get isAffiliate() {
        return this._isAffiliate;
    }

    get isInvestor() {
        return this.AccType === Types.ACCOUNT_TYPE_ID_INVESTOR;
    }

    _calculateMTPL(positionsList) {
        let accountPnl = 0;

        if (positionsList && positionsList.size) {
            accountPnl = positionsList.toArray().reduce((pnl, position) => pnl + position.accountPnl, 0);
        }

        return accountPnl;
    }

    _calculateTotalLoss(positionsList) {
        let loss = 0;

        if (positionsList && positionsList.size) {
            loss = positionsList.toArray().reduce(
                (pnl, position) => pnl + (position.accountPnl < 0 ? position.accountPnl : 0), 0);
        }

        return Math.abs(loss);
    }

    _calculateTotalProfit(positionsList) {
        let profit = 0;

        if (positionsList && positionsList.size) {
            profit = positionsList.toArray().reduce(
                (pnl, position) => pnl + (position.accountPnl > 0 ? position.accountPnl : 0), 0);
        }

        return profit;
    }

    _setBaseCurrencyPrecision() {
        this.BaseCurrencyPrecision = AppConfig.getPaymentCurrencyPrecision(this.BaseCurrency);
    }

    get UnsubscriptionFromInvestingStrategy() {
        const initialResult = {
            enableUnsubscription: true,
            accountName: this.FullName,
            strategyName: null,
            minimumDays: null,
            leftDays: null,
        };

        if (this.DealerName !== "PAMM") return initialResult;

        const subscriptions = subscriptionsArr(store.getState());
        const currentSubscription = subscriptions.find(s => s.InvestorAccountNumber === this.AccountNumber);

        if (!currentSubscription) return initialResult;

        const expectedDate = new Date(currentSubscription.CreationTime);
        expectedDate.setDate(expectedDate.getDate() + currentSubscription.MinSubscribtionDays);
        const currentDate = new Date();

        const leftDays = currentDate < expectedDate ? date.diffDays(currentDate, expectedDate) : 0;

        return {
            enableUnsubscription: currentDate >= expectedDate || currentSubscription.MinSubscribtionDays === 0,
            accountName: this.FullName,
            strategyName: currentSubscription.Name,
            minimumDays: currentSubscription.MinSubscribtionDays,
            leftDays: leftDays,
        }
    }

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
        this._setBaseCurrencyPrecision();
    }
}