/**
 * Created by ebondarev
 */
import { Record, Map, List } from 'immutable';

import { sort } from '../utils';
import * as actionTypes from '../../actions/trade/tickers-groups';
import * as accountsTypes from '../../actions/trade/accounts';
import * as userTypes from '../../actions/user/user';
import { TickerGroupModel } from '../../models/ticker-group';
import AppConfig from '../../AppConfig';
import { store } from "../../store";

const { disableInstrumentsFilter } = AppConfig;


class TickersGroupsStore extends Record({
    list: new Map(),
    listToShow: new Map(),
    childGroupIds: new Map(), // child ids by group id
    activeGroups: new List([0]),
    status: actionTypes.NOT_FETCHED,
    lastUpdateAt: null,
    currentAccountDealerName: null,
    isReady: false, // были получены группы, статус может быть FETCHING (обновление)
    isReadyToShow: false // были получены группы и выбран текущий акаунт
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED;
    }
}

function createModelFromData(data) {
    return new TickerGroupModel(data);
}

function getAllChildGroupIds(groupId, childGroupIds) {
    const childIds = childGroupIds[groupId] || [];
    let result = [];

    childIds.forEach(childId => {
        result.push(childId);
        result = result.concat(getAllChildGroupIds(childId, childGroupIds));
    });

    return result;
}

function createChildGroupIds(groups) {
    let childGroupIds = {};
    let result = {};

    groups.forEach(group => {
        if (group.OwnerId === 0) {
            return;
        }

        if (!childGroupIds[group.OwnerId]) {
            childGroupIds[group.OwnerId] = [group.Id];
        } else {
            childGroupIds[group.OwnerId].push(group.Id);
        }
    });

    for (const groupId in childGroupIds) {
        result[groupId] = getAllChildGroupIds(groupId, childGroupIds);
    }

    return new Map(result);
}

function filterListGroupByEmptyTickers(group) {
    const tickersStore = store.getState().trade.tickers.list.toArray();
    const ticker = tickersStore.find((ticker) => {return ticker.OwnerId === group.Id.toString()});

    if(ticker) {
        return group;
    }
}

function filterListToShow(list, dealerName) {
    const tickersStore = store.getState().trade.tickers.list;
    // delete empty group of tickers
    if(tickersStore.size !== 0) {
        list = list.filter((group) => {
            return filterListGroupByEmptyTickers(group);
        });
    }

    if (disableInstrumentsFilter) {
        return list;
    }

    if (!dealerName) {
        return list.clear();
    }

    return list.filter((group) => {
        return group.LPName.toLowerCase().indexOf(dealerName) >= 0;
    });
}

const initialState = new TickersGroupsStore();

export default function tickersGroups(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NOT_FETCHED:
        case actionTypes.FETCHING:
            return state
                .merge({
                    status: action.type,
                });

        case actionTypes.FETCHED:
            const listFetched = sort((new Map(action.payload)).map((data) => {
                return createModelFromData(data);
            }), 'Name');

            return state
                .merge({
                    list: listFetched,
                    listToShow: filterListToShow(listFetched, state.currentAccountDealerName),
                    childGroupIds: createChildGroupIds(listFetched),
                    status: actionTypes.FETCHED,
                    isReady: true,
                    lastUpdateAt: Date.now()
                });

        case actionTypes.CHANGE_TICKERS_GROUP:
            let activeGroups = state.activeGroups.toArray().slice(0, action.payload.level);

            activeGroups[action.payload.level] = action.payload.groupId;

            return state.merge({
                activeGroups: new List(activeGroups),
            });

        case accountsTypes.CHANGED_CURRENT_ACCOUNT:
            // let accountDealerName = action.payload ? action.payload.DealerName.toLowerCase() : null;
            // accountDealerName = disableInstrumentsFilter ? Types.LP_NAME_REAL.toLowerCase() : accountDealerName;
            let accountDealerName = disableInstrumentsFilter ? null : action.payload ? action.payload.DealerName.toLowerCase() : null;

            return state.merge({
                currentAccountDealerName: accountDealerName,
                listToShow: filterListToShow(state.list, accountDealerName),
                isReadyToShow: state.isReady,
                lastUpdateAt: Date.now()
            });

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: new Map(),
                listToShow: new Map(),
                childGroupIds: new Map(),
                activeGroups: new List([0]),
            });

        default:
            return state;
    }
}