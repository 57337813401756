/**
 * Created by ebondarev
 */
import Types from '../classes/types';
import { lang } from '../services';

export default class date {
    static PERIOD_MS = 1;
    static PERIOD_SECOND = date.PERIOD_MS * 1000;
    static PERIOD_MINUTE = date.PERIOD_SECOND * 60;
    static PERIOD_HOUR = date.PERIOD_MINUTE * 60;
    static PERIOD_DAY = date.PERIOD_HOUR * 24;

    static DST_OFFSET = 60; // minutes

    static dstSchedule = {
        [Types.DST_SCHEME_STANDARD]: {
            begin: {
                month: 2,
                hours: 2,
                minutes: 0,
                day: 0,
                dayNumber: 1,
                fromBegin: false,
            },
            end: {
                month: 9,
                hours: 3,
                minutes: 0,
                day: 0,
                dayNumber: 1,
                fromBegin: false,
            }
        },
        [Types.DST_SCHEME_AMERICAN]: {
            begin: {
                month: 2,
                hours: 2,
                minutes: 0,
                day: 0,
                dayNumber: 2,
                fromBegin: true,
            },
            end: {
                month: 10,
                hours: 2,
                minutes: 0,
                day: 0,
                dayNumber: 1,
                fromBegin: true,
            }
        },
    };

    /**
     * @param date{Date}
     */
    static toServerDate(date) {
        return date.toISOString().replace('T', ' ').replace(/\..*/, '');
    }

    static msToTime(ms) {
        const hours = Math.floor(ms / date.PERIOD_HOUR);
        const minutes = Math.floor((ms - hours * date.PERIOD_HOUR) / date.PERIOD_MINUTE);
        const seconds = Math.floor((ms - hours * date.PERIOD_HOUR - minutes * date.PERIOD_MINUTE) / date.PERIOD_SECOND);

        return {
            hours,
            minutes,
            seconds
        }
    }

    static msToDays(ms) {
        const HOURS_IN_DAYS = 24;
        const time = date.msToTime(ms);
        const days = Math.floor(time.hours / HOURS_IN_DAYS);
        const hours = Math.floor(((time.hours / HOURS_IN_DAYS) - days) * HOURS_IN_DAYS);

        return {
            days,
            hours,
        }
    }

    static diff(date1, date2, fixed = 0) {
        const diff = Math.abs(date1 - date2);

        return {
            diff,
            ms: +(diff / date.PERIOD_MS).toFixed(fixed),
            s: +(diff / date.PERIOD_SECOND).toFixed(fixed),
            m: +(diff / date.PERIOD_MINUTE).toFixed(fixed),
            h: +(diff / date.PERIOD_HOUR).toFixed(fixed),
            d: +(diff / date.PERIOD_DAY).toFixed(fixed),
        }
    }

    /**
     * Calculate difference of date in days
     * @param {Date} date1
     * @param {Date} date2
     * @returns {number}
     */
    static diffDays(date1, date2) {
        return date.diff(date1, date2).d;
    }

    /**
     * Calculate difference of date in hours
     * @param {Date} date1
     * @param {Date} date2
     * @returns {number}
     */
    static diffHours(date1, date2) {
        return date.diff(date1, date2).h;
    }

    static getTimeFromTick(tick) {
        return (tick - Types.TICKS_TO_UNIX_EPOCH) / 10000;
    }

    static getFullYear(shortYear) {
        const now = new Date();
        const year = now.getFullYear().toString();

        return `${year.substr(0,2)}${shortYear}`;
    }

    /**
     * Get begin of given day (or today if todayTime not set)
     * @param {Date} todayTime 
     * @returns {Date} begin day date
     */
    static getTodayBeginDate(todayTime = Date.now()) {
        const todayBeginDay = new Date(todayTime);

        todayBeginDay.setDate(todayBeginDay.getDate() - 1);
        todayBeginDay.setHours(0);
        todayBeginDay.setMinutes(0);
        todayBeginDay.setSeconds(0);
        todayBeginDay.setMilliseconds(0);

        return todayBeginDay;
    }

    /**
     * Get end of given day (or today if todayTime not set)
     * @param {Date} todayTime 
     * @returns {Date} end day date
     */
    static getTodayEndDate(todayTime = Date.now()) {
        const todayEndDay = new Date(todayTime);

        todayEndDay.setDate(todayEndDay.getDate() + 1);
        todayEndDay.setHours(0);
        todayEndDay.setMinutes(0);
        todayEndDay.setSeconds(0);
        todayEndDay.setMilliseconds(0);

        return todayEndDay;
    }

    static getFormatTime(time) {
        const m = Math.floor(time % 3600 / 60);
        const h = Math.floor(time / 3600);
        return `${('0' + h).slice(-2)}${lang.t('h ')}${('0' + m).slice(-2)}${lang.t('m ')}`;
    }

    static getOneDayMore(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    }

    static getBeginWeek(dateTime = new Date()) {
        return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate() - dateTime.getDay());
    }

    static getEndWeek(dateTime = new Date()) {
        return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate() + 7 - dateTime.getDay());
    }

    static getUTCOffset(time, winterOffset, dstSchema) {
        if (winterOffset && date.isDST(time, dstSchema, winterOffset - time.getTimezoneOffset())) {
            return winterOffset - date.DST_OFFSET;
        }

        return winterOffset;
    }

    static isDST(checkTime, schema, zoneOffset) {
        if (!date.dstSchedule[schema]) return false;

        const dstPeriod = date.getDSTPeriod(checkTime.getFullYear(), schema, zoneOffset);

        return checkTime >= dstPeriod.begin && checkTime < dstPeriod.end;
    }

    static getDSTPeriod(year, schema, zoneOffset) {
        const begin = date.getDayDate(year, date.dstSchedule[schema].begin, zoneOffset);
        const end = date.getDayDate(year, date.dstSchedule[schema].end, zoneOffset);

        return { begin, end };
    }

    static getDayDate(year, dayParam, zoneOffset) {
        const monthDay = date.calcMonthDay(year, dayParam);

        return new Date(year, dayParam.month, monthDay, dayParam.hours, dayParam.minutes + zoneOffset);
    }

    static calcMonthDay(year, dayParam) {
        if (dayParam.fromBegin) {
            return date.calcMonthDayFromBegin(year, dayParam.month, dayParam.day, dayParam.dayNumber);
        }

        return date.calcMonthDayFromEnd(year, dayParam.month, dayParam.day, dayParam.dayNumber);
    }

    static calcMonthDayFromBegin(year, month, day, dayNumber) {
        const beginMonth = new Date(year, month, 1);

        return 1 + day - beginMonth.getDay() + (dayNumber - 1) * 7 + 7 * (beginMonth.getDay() > day)
    }

    static calcMonthDayFromEnd(year, month, day, dayNumber) {
        const endMonth = new Date(year, month + 1, 0);

        return endMonth.getDate() - endMonth.getDay() - day - (dayNumber - 1) * 7 - 7 * (day > endMonth.getDay());
    }

    static declinationOfNumber(number, word) {
        const n = Math.abs(number) % 100;
        const n1 = n % 10;

        if (n > 10 && n < 20) {
            return `${word}2`;
        }

        if (n1 > 1 && n1 <5) {
            return `${word}1`;
        }

        if (n1 === 1) {
            return `${word}0`;
        }

        return `${word}2`;
    }
}