import merge from 'deepmerge';

import AppConfig from '../../AppConfig';
import * as actionTypes from '../../actions/trade/filters';
import * as trackingTypes from '../../actions/tracking/tracking';
import Types from "../../classes/types";

const {historyFilters} = AppConfig;

export const initialState = merge({
    historyFilters: {
        period: Types.DATE_PERIOD_TODAY,
        from: null,
        to: null,
        search: '',
        type: {
            all: true,
            marginal: false,
            exchange: false,
            balance: false,
        },
        side: {
            all: true,
            buy: false,
            sell: false,
            deposit: false,
            withdraw: false,
        },
        wallets: {
            currentWallet: true,
            list: [],
            all: true,
        }
    },
    activityLogFilters: {
        period: Types.DATE_PERIOD_TODAY,
        from: null,
        to: null,
        search: "",
        wallets: {
            currentWallet: true,
            list: [],
            all: true,
        }
    },
    positionsFilters: {
        period: Types.DATE_PERIOD_ALL,
        from: null,
        to: null,
        wallets: {
            list: [],
            all: true,
        }
    },
    ordersFilters: {
        period: Types.DATE_PERIOD_ALL,
        from: null,
        to: null,
        wallets: {
            list: [],
            all: true,
        }
    },
    platformNotificationsFilters: {
        period: Types.DATE_PERIOD_ALL,
        from: null,
        to: null,
    },
    showZeroBalance: true,
    walletsSearchKey: ''
}, {historyFilters});

export default function filters(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_HISTORY_FILTERS:
            return {...state, historyFilters: action.payload};

        case actionTypes.UPDATE_ACTIVITY_LOG_FILTERS:
            return {...state, activityLogFilters: action.payload};

        case actionTypes.UPDATE_POSITIONS_FILTERS:
            return {...state, positionsFilters: action.payload};

        case actionTypes.UPDATE_PLATFORM_NOTIFICATIONS_FILTERS: {
            return {...state, platformNotificationsFilters: action.payload};
        }

        case actionTypes.UPDATE_ORDERS_FILTERS:
            return {...state, ordersFilters: action.payload};

        case actionTypes.UPDATE_WALLETS_SEARCH_KEY:
            return {...state, walletsSearchKey: action.payload};

        case actionTypes.UPDATE_SHOW_ZERO_BALANCE:
            return {...state, showZeroBalance: !state.showZeroBalance};

        case actionTypes.CLEAR_FILTERS:
            return initialState;

        case actionTypes.SET_SEARCH_HISTORY:
            return {...state, historyFilters: {...state.historyFilters, search: action.payload}};

        case actionTypes.SET_SEARCH_ACTIVITY_LOG:
            return {...state, activityLogFilters: {...state.activityLogFilters, search: action.payload}};

        case trackingTypes.GET_STORE:
            return (action.data.filters = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.filters) {
                const filtersData = action.payload.filters;
                return {...state, activityLogFilters: filtersData.activityLogFilters, historyFilters: filtersData.historyFilters};
            }
            return state;

        default:
            return state;
    }
}