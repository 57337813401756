import './utils/buildInfo';
import './polyfills';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import registerServiceWorker from './registerServiceWorker';
import { store, history } from './store';
import { logger } from './services/index';

import App from './common/components/AppLoader';
import AppConfig from './AppConfig';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_CONFIG !== 'development') {
    logger.enable = false;
}

registerServiceWorker(!AppConfig.isDisableServiceWorker);

const start = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
        , document.getElementById('app')
    );
}

if (!window.cordova) {
    start();
} else {
    document.addEventListener('deviceready', start, false);
}