/**
 * Created by ebondarev
 */
import AppConfig from '../../AppConfig';
import { transport, quoteApi } from '../../services';

export const TOGGLE_CHART_TOOLS = 'TRADE_CHART__TOGGLE_CHART_TOOLS';
export const UPDATE_TABS = 'TRADE_CHART_UPDATE_TABS';
export const ADD_TAB = 'TRADE_CHART_ADD_TAB';
export const REMOVE_TAB = 'TRADE_CHART_REMOVE_TAB';
export const REMOVE_NON_FAVORITE_TABS = 'TRADE_CHART_REMOVE_NON_FAVORITE_TABS';
export const REMOVE_SHOWN_TABS = 'TRADE_CHART_REMOVE_SHOWN_TABS';
export const CHANGE_ACTIVE_TAB = 'TRADE_CHART_CHANGE_ACTIVE_TAB';
export const CHANGE_POSITIONS = 'TRADE_CHART_CHANGE_POSITIONS';
export const CHANGE_CHART_SETTINGS = 'TRADE_CHART_CHANGE_SETTINGS';
export const CHANGE_CHART_FRAME = 'CHANGE_CHART_FRAME';
export const CHANGE_CHART_FRAME_REQUEST = 'CHANGE_CHART_FRAME_REQUEST';
export const CANCEL_CHART_FRAME_REQUEST = 'CANCEL_CHART_FRAME_REQUEST';
export const SET_STOCK_CHARTS = 'TRADE_SET_STOCK_CHARTS';
export const CHART_DATA_UPDATE = 'TRADE_CHART_DATA_UPDATE';
export const INDICATOR_ADD = 'TRADE_CHART_INDICATOR_ADD';
export const INDICATOR_REMOVE = 'TRADE_CHART_INDICATOR_REMOVE';
export const INDICATOR_REPLACE = 'TRADE_CHART_INDICATOR_REPLACE';
export const ALL_INDICATORS_REPLACE = 'TRADE_CHART_ALL_INDICATORS_REPLACE';
export const INDICATOR_UPDATE = 'TRADE_CHART_INDICATOR_UPDATE';
export const INDICATOR_TPL_ADD = 'TRADE_CHART_INDICATOR_TPL_ADD';
export const INDICATOR_TPL_UPDATE = 'TRADE_CHART_INDICATOR_TPL_UPDATE';
export const INDICATOR_TPL_REMOVE = 'TRADE_CHART_INDICATOR_TPL_REMOVE';
export const SET_TAB_SYNC_STATE = 'SET_TAB_SYNC_STATE';
export const CHANGE_CHART_TYPE = 'CHANGE_CHART_TYPE';
export const CHANGE_CHART_IS_MONOCHROME = 'CHANGE_CHART_IS_MONOCHROME';
export const CHANGE_SHOW_DEFAULT_INDICATORS = 'CHANGE_SHOW_DEFAULT_INDICATORS';
export const RESET_CHART_SETTINGS = 'TRADE_CHART_RESET_SETTINGS';
export const RESET_SLTP_DRAG_COMPONENT = 'RESET_SLTP_DRAG_COMPONENT';
export const ACTIVE_ELEMENT_BY_CLICK = 'ACTIVE_ELEMENT_BY_CLICK';
export const CHANGE_CHART_AUTO_INTERVAL_SWITCH = 'CHANGE_CHART_AUTO_INTERVAL_SWITCH';
export const CHANGE_CHART_AUTO_UPDATE_FRAME_BY_ZOOM = 'CHANGE_CHART_AUTO_UPDATE_FRAME_BY_ZOOM';
export const TOGGLE_MAGNET_MODE = 'TOGGLE_MAGNET_MODE';

export function toggleChartTools(isShowing) {
    return {
        type: TOGGLE_CHART_TOOLS,
        payload: isShowing,
    }
}

export function updateTabs(tabsList) {
    return {
        type: UPDATE_TABS,
        payload: tabsList
    }
}

export function addTab(tabModel) {
    return (dispatch, getState) => {
        const state = getState();
        const { settings } = state.user;
        const { activeTab } = state.trade.chart;
        const frame = settings.saveChartFrame ? (activeTab ? activeTab.frame : null) : null;

        dispatch({
            type: ADD_TAB,
            payload: { tabModel, frame }
        });
    }
}

export function removeTab(tabModel) {
    return {
        type: REMOVE_TAB,
        payload: tabModel
    }
}

export function removeNonFavoriteTabs() {
    return {
        type: REMOVE_NON_FAVORITE_TABS,
    }
}

export function changeActiveTab(tabModel) {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: tabModel
    }
}

export function changeChartSettings(chartSettings) {
    return {
        type: CHANGE_CHART_SETTINGS,
        payload: chartSettings
    }
}

export function changeChartFrameRequest(tabKey, frame) {
    return {
        type: CHANGE_CHART_FRAME_REQUEST,
        payload: {frame, tabKey}
    }
}

export function cancelChartFrameRequest() {
    return {
        type: CANCEL_CHART_FRAME_REQUEST,
    }
}

export function changeChartFrame(tabModel, frame, isByRequest = false) {
    return {
        type: CHANGE_CHART_FRAME,
        payload: { 'tab': tabModel, 'frame': frame, isByRequest }
    }
}

export function changeAutoSwitchChartInterval(tab, isAutoSwitch) {
    return {
        type: CHANGE_CHART_AUTO_INTERVAL_SWITCH,
        payload: {tab, isAutoSwitch}
    }
}

export function changeAutoUpdateFrameByZoom(tab, isAutoUpdateFrameByZoom) {
    return {
        type: CHANGE_CHART_AUTO_UPDATE_FRAME_BY_ZOOM,
        payload: {tab, isAutoUpdateFrameByZoom}
    }
}

export function toggleMagnetMode(magnetMode) {
    return {
        type: TOGGLE_MAGNET_MODE,
        payload: magnetMode
    }
}

export function changeChartType(tabModel, chartType) {
    return {
        type: CHANGE_CHART_TYPE,
        payload: { 'tab': tabModel, 'chartType': chartType }
    }
}

export function changeChartIsMonochrome(tabModel, isMonochrome) {
    return {
        type: CHANGE_CHART_IS_MONOCHROME,
        payload: { 'tab': tabModel, 'isMonochrome': isMonochrome }
    }
}

export function changeShowDefaultIndicators(tabKey, showDefaultIndicators) {
    return {
        type: CHANGE_SHOW_DEFAULT_INDICATORS,
        payload: {
            tabKey,
            showDefaultIndicators
        }
    }
}

export function setTabSyncState(tabModel, syncState) {
    return {
        type: SET_TAB_SYNC_STATE,
        payload: { 'tab': tabModel, 'syncState': syncState }
    }
}

export function resetChartSettings() {
    return {
        type: RESET_CHART_SETTINGS,
        payload: null
    }
}

export function resetSLTPDragPosition(reset) {
    return {
        type: RESET_SLTP_DRAG_COMPONENT,
        payload: reset
    }
}

export function changePositions(fromTab, toTab) {
    return {
        type: CHANGE_POSITIONS,
        payload: {
            fromTab,
            toTab
        }
    }
}

export function removeShownTabs() {
    return {
        type: REMOVE_SHOWN_TABS,
    }
}

export function activeElementByClick(data) {
    return {
        type: ACTIVE_ELEMENT_BY_CLICK,
        payload: data
    }
}

export function setStockCharts({ chartKey, stockCharts }) {
    return {
        type: SET_STOCK_CHARTS,
        payload: {
            chartKey,
            stockCharts
        }
    }
}

export function chartDataUpdate({ chartKey, data }) {
    return {
        type: CHART_DATA_UPDATE,
        payload: {
            chartKey,
            data
        }
    }
}

export function indicatorAdd({ chartKey, key, indicator }) {
    return {
        type: INDICATOR_ADD,
        payload: {
            chartKey,
            key, 
            indicator
        }
    }
}

export function indicatorRemove({ chartKey, key }) {
    return {
        type: INDICATOR_REMOVE,
        payload: {
            chartKey,
            key, 
        }
    }
}

export function indicatorReplace({ chartKey, removeKey, key, indicator }) {
    return {
        type: INDICATOR_REPLACE,
        payload: {
            chartKey,
            removeKey,
            key, 
            indicator
        }
    }
}

export function replaceAllIndicators({ chartKey, indicators }) {
    return {
        type: ALL_INDICATORS_REPLACE,
        payload: {
            chartKey,
            indicators
        }
    }
}

export function indicatorUpdate({ chartKey, key, indicator }) {
    return {
        type: INDICATOR_UPDATE,
        payload: {
            chartKey,
            key, 
            indicator
        }
    }
}

export function indicatorTplAdd({ key, indicatorTemplate }) {
    return {
        type: INDICATOR_TPL_ADD,
        payload: {
            key, 
            indicatorTemplate
        }
    }
}

export function indicatorTplUpdate({ key, indicatorTemplate }) {
    return {
        type: INDICATOR_TPL_UPDATE,
        payload: {
            key, 
            indicatorTemplate
        }
    }
}

export function indicatorTplRemove(key) {
    return {
        type: INDICATOR_TPL_REMOVE,
        payload: key
    }
}

export function doActiveElementByClick({ SymbolId, OrderNumber }) {
    return (dispatch, getState) => {
        const { activeTab } = getState().trade.chart;

        if (activeTab && activeTab.symbolId === SymbolId) {
            dispatch(activeElementByClick({
                activeChartElementByClick: true,
                chartActiveElementId: OrderNumber,
            }));
        } else {
            dispatch(activeElementByClick({
                activeChartElementByClick: true,
            }));
        }
    }
}

export function doQuoteHistory({ reqId, symbolId, periodM, periodB, from, to }) {
    return (dispatch, getState) => {
        const doQuoteHistoryAction = AppConfig.features.quoteHistoryFromQuote ? doQuoteHistoryHTTP : doQuoteHistoryWSS;
        dispatch(doQuoteHistoryAction({ reqId, symbolId, periodM, periodB, from, to }));
    }
}

export function doQuoteHistoryWSS({ reqId, symbolId, periodM, periodB, from, to }) {
    return (dispatch, getState) => {
        transport.requestQuotesHistory({
            requestId: reqId,
            symbolId,
            periodM,
            periodB,
            from,
            to
        });
    }
}

export function doQuoteHistoryHTTP({ reqId, symbolId, periodM, periodB, from, to }) {
    return (dispatch, getState) => {
        quoteApi.get('/history/', {
            data: {
                s: symbolId,
                p: periodB,
                from,
                to,
                format: 'json'
            }
        }, ).then(res => {
            if (!res || !res.data) return;

            const data = res.data;

            data.ListQuotes = getState().trade.chart.tmxDataFeed.convertQuotesToPeriod(data, periodM);
            data.RequestId = reqId;
            dispatch(doHistoryBars(data));
        });
    }
}

export function doHistoryBars(data) {
    return (dispatch, getState) => {
        getState().trade.chart.tmxDataFeed.gotBars({
            reqId: data.RequestId,
            reqTime: data.StartTime,
            list: data.ListQuotes,
            symbolId: data.SymbolId,
        });
    }
}

export function doHistoryQuotes(data) {
    const FilteredListQuotes = data.ListQuotes.map((q) => {
        return {
            time: q.QuoteDateTime,
            ask: q.Ask,
            bid: q.Bid
        };
    });
    return (dispatch, getState) => {
        getState().trade.chart.tmxDataFeed.gotBars({
            reqId: data.RequestId,
            reqTime: data.StartTime,
            list: FilteredListQuotes,
            symbolId: data.SymbolId,
        });
    }
}