/**
 * Created by ebondarev
 */
import BaseModel from './base';

export class TickerGroupModel extends BaseModel {
    Id;
    Name;
    OwnerId;

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }
}