import { List } from 'immutable';
import * as userTypes from '../actions/user/user';
import * as messageBoxesActions from '../actions/message-box';

const initState = new List();

export default function messageBoxes(state = initState, { type, payload }) {
    switch (type) {

        case messageBoxesActions.SHOW_WINDOW: {
            return state.push({
                // type: MessageBox.TYPE_INFO,          // NOTE this type is required
                show: true,
                confirm: null,
                title: '',
                message: '',
                footerBodyText: '',
                buttonLabel: '',
                iconName: '',
                okDisabled: false,
                secondOkDisabled: false,
                okCallback: () => { },
                cancelCallback: () => { },
                closeCallback: () => { },
                ...payload,
                //             NOTE if type is INFOBOX also need set next props as required:
                //             footerBodyText,
                //             buttonLabel,
                //             iconName
            });
        }

        case messageBoxesActions.CLOSE_WINDOW: {
            return state.delete(payload.index);
        }

        case userTypes.LOG_OUT: {
            return initState;
        }

        default:
            return state;
    }
}