import { Record, Map } from 'immutable';

import * as actions from '../../actions/trade/trade-sessions';
import * as userActions from '../../actions/user/user';
import { store } from '../../store';
import TradeSessionModel from '../../models/trade-session';

const MIN_DELAY = 2000;

class TradeSessionsStore extends Record({
    list: new Map(),
    status: actions.NOT_FETCHED,
    isReady: false,

    // change state timer
    timerId: null,
    runTime: null,
}) {
    isFetched() {
        return this.status === actions.FETCHED;
    }
}

function createModelFromData(data) {
    return new TradeSessionModel(data);
}

function initChangeStateTimer(list, timerId = null, runTime = null) {
    let timerParam = { timerId, runTime };
    const changeTime = getSoonStateChangeTime(list);

    if (!changeTime) {
        return timerParam;
    }

    if (timerId) {
        if (runTime <= changeTime) {
            return timerParam;
        } else {
            clearTimeout(timerId);
        }
    }

    const delay = changeTime - new Date();
    timerParam.timerId = setTimeout(() => changeStateAction(changeTime), delay > MIN_DELAY ? delay : MIN_DELAY);
    timerParam.runTime = changeTime;

    return timerParam;
}

function getSoonStateChangeTime(list) {
    let changeTime = 0;

    list.forEach(session => {
        if (!changeTime || session.nextChangeTime < changeTime) {
            changeTime = session.nextChangeTime;
        }
    });

    return changeTime;
}

function changeStateAction(changeTime) {
    store.dispatch(actions.changeStates(changeTime));
}

function changeState(list, changeTime) {
    return list.map(session => {
        if (session.nextChangeTime <= changeTime) {
            session.changeState();
        }

        return session;
    });
}

const initialState = new TradeSessionsStore();

export default function tradeSessions(state = initialState, action) {
    switch (action.type) {
        case actions.NOT_FETCHED:
        case actions.FETCHING:
            return state.merge({
                status: action.type
            });

        case actions.FETCHED:
        case actions.UPDATE_TRADE_SESSIONS:
            const listFetched = Map(action.payload).mapEntries(([ key, data ]) =>
                [ Number(key), createModelFromData(data) ]
            );
            const timer = initChangeStateTimer(listFetched, state.timerId, state.runTime);

            return state.merge({
                list: listFetched,
                status: actions.FETCHED,
                isReady: true,
                ...timer,
            });

        case actions.REMOVE_TRADE_SESSIONS:
            const listRest = state.list.deleteAll(action.payload);
            const timerRest = initChangeStateTimer(listRest, state.timerId, state.runTime);

            return state.merge({
                list: listRest,
                ...timerRest,
            });

        case actions.CHANGE_STATES:
            const listNewState = changeState(state.list, action.payload);
            const timerNewState = initChangeStateTimer(listNewState);

            return state.merge({
                list: listNewState,
                ...timerNewState,
            });

        case userActions.LOG_OUT:
            state.timerId && clearTimeout(state.timerId);

            return initialState;

        default:
            return state;
    }
}