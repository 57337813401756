/**
 * Created by ebondarev
 */
import BaseModel from './base';
import Types from '../classes/types';
import AppConfig from '../AppConfig';
import { store } from '../store';

export class TickerModel extends BaseModel {
    SymbolId;
    Ticker;
    Ask;
    AskSize;
    AskLevels = [];
    AskSizeLevels = [];
    Bid;
    BidSize;
    Spread;
    Change;
    ChangePr;
    quoteDateTime;
    ComissionModelId;
    _DateTime;
    get DateTime() {
        return this._DateTime;
    }

    set DateTime(value) {
        if (typeof value === 'string' && value.indexOf('GMT') === -1) {
            value += ' GMT'; // time in UTC format
        }

        this._DateTime = value;
        this._date = new Date(value);
    }

    EnableTrading;
    Exp1;
    Exp2;
    High;
    InstrumentTypeName;
    InstrumentTypeId;
    _LPName;
    get LPName() {
        return this._LPName;
    }

    set LPName(value) {
        this._LPName = value;
        this._isReal = value.toUpperCase().indexOf('REAL') >= 0;
        if (this._displayLpName === null) {
            this._displayLpName = value;
        }
    }

    get TodayChangePr() {
        return this.LastMTBid ? (this.Bid - this.LastMTBid) / this.LastMTBid * 100 : 0;
    }

    LastMTAsk;
    LastMTBid;
    LastPrice;
    LastSize;
    LastTradeDateTime;
    LotSize;
    Low;
    MarginModel;
    MarginModelId;
    MarkUpAsk;
    MarkUpBid;
    MarketDepth;
    OwnerId;
    PriceStep;
    PricePrecision;
    MinTickerSize;
    MaxTickerSize;
    SizeMinimalChange;
    TradeSessionId;

    _PointSize = Types.POINT_IN_PIPS;
    get PointSize() {
        return this._PointSize;
    }
    set PointSize(value) {
        if (value >= 1) {
            this._PointSize = value;
        }
    }

    _date = null;
    get date() {
        return this._date;
    }

    // addition attributes
    get leverage() {
        return store.getState().trade.tickers.getLeverage(this.SymbolId);
    }

    get tradeLeverage() {
        return store.getState().trade.tickers.getLeverage(this.SymbolId, true);
    }

    spread = 0;
    sizeStep = 1;

    _isReal;
    get isReal() {
        return this._isReal;
    }

    get isExchange() {
        return this.TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE;
    }

    get isMargin() {
        return this.TradeMode === Types.INSTRUMENT_TRADE_MODE_MARGIN;
    }

    // addition state
    visible = true;
    disableBuy = false;
    disableSell = false;
    bidDiff = 0;
    lastUpdateAt = Date.now();
    isMark = false; // ticker is favorite

    // temporary for instrument details table
    maxTradeNumbers = 0;
    contractLength = "";
    positionLimit = "";

    MinPrice = 0;
    MaxPrice = 0;

    constructor(intiData) {
        const { maxTradeNumbers, contractLength, positionLimit } = AppConfig.instrumentDetails.staticFields;

        super();
        this.copyFrom(intiData);

        this.sizeStep = this.LotSize * this.MinTickerSize;
        this.spread = (this.Ask - this.Bid) / this.PriceStep / this.PointSize;

        // temporary for instrument details table
        this.maxTradeNumbers = maxTradeNumbers ? maxTradeNumbers.value[this.SymbolId] || maxTradeNumbers.value['default'] : 0;
        this.contractLength = contractLength ? contractLength.value[this.SymbolId] || contractLength.value['default'] : "";
        this.positionLimit = positionLimit ? positionLimit.value[this.SymbolId] || positionLimit.value['default'] : "";
    }

    updateFromQuote(quote) {
        this.bidDiff = quote.Bid - this.Bid;
        this.Ask = quote.Ask;
        this.Bid = quote.Bid;
        this.AskLevels = quote.AskLevels;
        this.AskSizeLevels = quote.AskSizeLevels;
        this.AskSize = quote.AskSize;
        this.BidLevels = quote.BidLevels;
        this.BidSizeLevels = quote.BidSizeLevels;
        this.BidSize = quote.BidSize;

        this.LotSize = quote.LotSize;
        this.DateTime = quote.DateTime * 1000;
        this.quoteDateTime = quote.DateTime;
        this.lastUpdateAt = Date.now();
        this.spread = (this.Ask - this.Bid) / this.PriceStep / this.PointSize;

        return this;
    }
}