import { Record, Map } from 'immutable';

import * as actionTypes from '../../actions/trade/interchanges';
import InterchangeModel from '../../models/interchange';

class InterchangeStore extends Record({
    list: new Map(),
    decodedInterchange: {},
    executeInterchange: {},
    status: actionTypes.NOT_FETCHED,
    error: '',
    lastCreatedAt: Date.now(),
    lastErrorTime: Date.now(),
    lastUpdateAt: Date.now(),
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

function createModelFromData(data) {
    return new InterchangeModel(data);
}

const initialState = new InterchangeStore();

export default function interchanges(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHED:
            let data = {};
            const AdditionTime = Date.now();
            Object.keys(action.payload).forEach(key => {
                data[key] = createModelFromData({...action.payload[key], Id: key, AdditionTime });
            });

            const list = new Map(data);
            state.concat({
                list,
            });

            return state.merge({
                status: actionTypes.FETCHED,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.ADD:
            const addKey = Object.keys(action.payload)[0];
            const addModel = createModelFromData({...action.payload[addKey], Id: addKey, AdditionTime: Date.now()});

            return state.merge({
                status: actionTypes.ADD,
                list: state.list.set(addKey, addModel),
                lastCreatedAt: Date.now(),
                lastUpdateAt: Date.now(),
            });

        case actionTypes.REMOVE:
            let listAfterRemove = state.list;
            listAfterRemove = listAfterRemove.remove(Object.keys(action.payload)[0]);

            return state.merge({
                status: actionTypes.REMOVE,
                list: listAfterRemove,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.EXECUTE:
            const { status } = state;
            if (status !== actionTypes.ACTIVATE) {
                return state;
            }
            let listAfterExecute = state.list;
            listAfterExecute = listAfterExecute.remove(Object.keys(action.payload)[0]);
            const executeKey = Object.keys(action.payload)[0];
            const executeInterchange = createModelFromData({...action.payload[executeKey], Id: executeKey, AdditionTime: Date.now()});

            return state.merge({
                status: actionTypes.EXECUTE,
                executeInterchange,
                list: listAfterExecute,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.DECODE:
            const decodeKey = Object.keys(action.payload)[0];
            const decodedInterchange = createModelFromData({...action.payload[decodeKey], Id: decodeKey, AdditionTime: Date.now()});

            return state.merge({
                status: actionTypes.DECODE,
                decodedInterchange,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.ACTIVATE:
            return state.merge({
                status: actionTypes.ACTIVATE,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.REJECT:
            return state.merge({
                error: action.payload,
                lastErrorTime: Date.now(),
                lastUpdateAt: Date.now(),
            });

        case actionTypes.NOT_FOUND:
            return state.merge({
                status: actionTypes.NOT_FOUND,
                decodedInterchange: {},
                lastUpdateAt: Date.now(),
            });

        default:
            return state;
    }
}