export const NOT_FETCHED = 'TRADE_SESSIONS_NOT_FETCHED';
export const FETCHING = 'TRADE_SESSIONS_FETCHING';
export const FETCHED = 'TRADE_SESSIONS_FETCHED';
export const CHANGE_STATES = 'TRADE_SESSIONS_CHANGE_STATES';
export const REMOVE_TRADE_SESSIONS = 'TRADE_SESSIONS_REMOVE';
export const UPDATE_TRADE_SESSIONS = 'TRADE_SESSIONS_UPDATE';

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function remove(data) {
    return {
        type: REMOVE_TRADE_SESSIONS,
        payload: data
    }
}

export function update(data) {
    return {
        type: UPDATE_TRADE_SESSIONS,
        payload: data
    }
}

export function changeStates(changeTime) {
    return {
        type: CHANGE_STATES,
        payload: changeTime,
    }
}