/**
 * Created by ebondarev
 */
import BaseModel from './base';

/**
 * SignalProviders
 */
export class StrategyModel extends BaseModel {
    AccountId;
    AveragePointPerTrade;
    Balance;
    Currency;

    _description;

    get Description() {
        return this._description;
    }

    set Description(value) {
        try {
            const description = JSON.parse(value);
            this._description = description.description;
            this.strategyName = description.strategyName;
        } catch(e) {
            this._description = value;
        }
    }

    DateTimeFrom;
    DateTimeTo;
    Drawdown;
    Id;
    MinSubscribtionDays;
    MounthlyGain;
    Name;
    PerformanceChartReports;
    PopularTickers;
    ProfitLoss;
    ProfitLossPoints;
    ProfitTrades;
    TotalGain;
    TotalTrades;
    get TradePerDay() {
        if (!this.TotalTrades) return 0;
        const daysCount = (this.DateTimeTo - this.DateTimeFrom) / 86400000;
        return this.TotalTrades / daysCount;
    };

    // addition attributes
    countSubscriptions = 0;

    constructor(intiData) {
        super();
        this.copyFrom(intiData);
    }

    getPointsArray(type) {
        return this.PerformanceChartReports.map((data) => {
            return data[type];
        });
    }
}