import BaseModel from './base';

class CommissionModel extends BaseModel {
    Id;
    Name;
    ComissionRate;
    Currency;
    PaymentType;
    Period;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}

export default CommissionModel;