/**
 * Created by ZERG on 08.11.2016.
 */
import Logger from './logger';
import { math, base64 } from '../utils';
import { lostWSConnection, ready } from '../actions/app';
import { store } from '../store';

const noop = function () {
};

class TransportConnect {
    static BLOCKED_TIME_ON_ERROR = 2 * 60000; //ms

    serverName;
    _activeConnections = 0;
    _countErrors = 0;
    _availableNextConnectTime;

    constructor(serverName) {
        this.serverName = serverName;
        this._availableNextConnectTime = Date.now() - 1;
    }

    addConnect() {
        this._activeConnections++;
    }

    removeConnect() {
        this._activeConnections--;
        if (this._activeConnections < 0) {
            this._activeConnections = 0;
        }
    }

    clearConnects() {
        this._activeConnections = 0;
    }

    hadError() {
        this._countErrors++;
        this.removeConnect();
        if (this._activeConnections === 0) {
            this._availableNextConnectTime = Date.now() + TransportConnect.BLOCKED_TIME_ON_ERROR;
        }
    }

    isAvailableConnect() {
        return Date.now() > this._availableNextConnectTime;
    }

    clearErrors() {
        this._countErrors = 0;
        this._availableNextConnectTime = Date.now() - 1;
    }
}

export class TransportUrlManager {
    _urls = [];
    _currentConnection = null;

    constructor(urls) {
        if (typeof urls === 'string') {
            urls = [urls];
        }

        this._urls = urls.map((endPoint) => {
            return new TransportConnect(endPoint);
        });

        if (!this._urls.length) {
            throw new Error('There is not valid wss servers');
        }

        this._currentConnection = this._getRandomServer();
    }

    _getRandomServer() {
        let urls = [...this._urls];

        if (this._currentConnection && urls.length > 1) {
            const currentServerIndex = urls.indexOf(this._currentConnection);
            if (currentServerIndex >= 0) {
                urls.splice(currentServerIndex, 1);
            }
        }

        urls = urls.filter((connect) => {
            return connect.isAvailableConnect();
        });

        // not available servers, clear all results
        if (urls.length === 0) {
            urls = this._urls.map((connect) => {
                connect.clearErrors();
                return connect;
            });
        }

        const randomServer = urls[Math.floor(Math.random() * urls.length)];
        if (randomServer && this._currentConnection !== randomServer) {
            return randomServer;
        }

        return this._currentConnection;
    }

    getCurrentConnection() {
        return this._currentConnection;
    }

    closedConnection(connection) {
        if (this._currentConnection !== connection) {
            connection && connection.clearConnects();
            return;
        } else if (!this._currentConnection) {
            this._currentConnection = this._getRandomServer();
            return
        }

        this._currentConnection.hadError();

        if (!this._currentConnection.isAvailableConnect()) {
            this._currentConnection = this._getRandomServer();
        }
    }

    openedConnection(connection) {
        if (this._currentConnection !== connection) {
            connection && connection.clearConnects();
            return;
        }

        this._currentConnection.addConnect();
    }
}

export default class Transport {
    static CHANNEL_TRADE = 'Trade';
    static CHANNEL_QUOTE = 'Quote';
    static CHANNEL_TRACKING = 'Track';

    static TRANSPORT_NAME_USER = "USER";
    static TRANSPORT_NAME_HISTORY = "HISTORY";
    static TRANSPORT_NAME_TRACKING = "TRACKING";

    // log in statuses
    static NOT_LOG_IN = "NOT_LOG_IN";
    static LOGGING_IN = "LOGGING_IN";
    static LOGGED_IN = "LOGGED_IN";

    logInStatus = Transport.NOT_LOG_IN;
    connected = false;
    connecting = false;
    socket = null;
    errorMessage = "";
    reconnectTimer = null;
    watchdog = null;
    dataReceived = false;
    throwRejectWhenNoConnectedSend = true;
    isWSClosedWithError = false;
    isCheckConnectionRunning = false;
    working = false;

    onLogin = noop;
    onQuote = noop;
    onDataMessage = noop;
    onAccountsList = noop;
    onInstrumentsList = noop;
    onOrdersList = noop;
    onPositionsList = noop;
    onNewsList = noop;
    onNewsAdd = noop;
    onAccountUpdate = noop;
    onAccountAdd = noop;
    onPositionAdd = noop;
    onPositionUpdate = noop;
    onPositionRemove = noop;
    onOrderAdd = noop;
    onOrderUpdate = noop;
    onOrderRemove = noop;
    onHistoryBars = noop;
    onHistoryQuotes = noop;
    onActivityLog = noop;
    onActivityLogUpdate = noop;
    onAccountHistory = noop;
    onAccountHistoryUpdate = noop;
    onCommissionsList = noop;
    onSignalSubscriptions = noop;
    onMarginModelList = noop;
    onSwapsList = noop;
    onCommissionPaymentList = noop;
    onInstrumentGroupList = noop;
    onError = noop;
    onErrorDispatcher = noop;
    onConnected = noop;
    onClose = noop;
    onReject = noop;
    onRejectOrder = noop;
    onSignalProviders = noop;
    onSignalHistory = noop;
    onConversion = noop;
    onRejectPosition = noop;
    onRejectLogin = noop;
    onRejectSignalSubscription = noop;
    onTechBreak = noop;
    onTradeSessionList = noop;
    onTradeSessionAddUpdate = noop;
    onTradeSessionRemove = noop;
    onAppMouseClick = noop;
    onAppMouseLocation = noop;
    onSetTracking = noop;
    onAppActionData = noop;
    onAppActionState = noop;
    onComponentState = noop;
    onComponentStore = noop;
    onInterchangeList = noop;
    onInterchangeAdd = noop;
    onInterchangeRemove = noop;
    onInterchangeDecode = noop;
    onInterchangeExecute = noop;
    onScreenSharingRequest = noop;
    onScreenSharingAnswer = noop;
    onScreenSharingEnd = noop;
    onScreenSharingCancel = noop;
    onScreenSharingStarted = noop;
    onScreenSharingReject = noop;
    onNotificationAdd = noop;

    _urlManager = null;
    _logger = null;
    _transportName = 'noname';
    _currentServerConnection = null;

    /**
     * @param urlManager {TransportUrlManager}
     * @param logger {Logger}
     * @param transportName
     */
    constructor(urlManager, logger, transportName = Transport.TRANSPORT_NAME_USER) {
        this._urlManager = urlManager;
        this._transportName = transportName;

        if (logger instanceof Logger) {
            this._logger = logger;
        }

        if (!(urlManager instanceof TransportUrlManager)) {
            throw new Error('urlManager must be instance of TransportUrlManager');
        }
    }

    setNewServer(urlManager, transportName = Transport.TRANSPORT_NAME_USER) {
        if (!(urlManager instanceof TransportUrlManager)) {
            throw new Error('urlManager must be instance of TransportUrlManager');
        }

        this._urlManager = urlManager;
        this._transportName = transportName;

        if (this.connected) {
            this.disconnect();
        }

        this.connect();
    }

    listener = this.checkConnectionOnFocus.bind(this);

    updatePageListeners() {
        window.removeEventListener("focus", this.listener);
        window.removeEventListener("pageshow", this.listener);
        window.removeEventListener("visibilitychange", this.listener);
        window.removeEventListener("popstate", this.listener);

        window.addEventListener("focus", this.listener);
        window.addEventListener("pageshow", this.listener);
        window.addEventListener("visibilitychange", this.listener);
        window.addEventListener("popstate", this.listener);
    }

    connect({ timeout = 10000, reconnectTimeout = 3000 } = {}) {
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer);
            this.reconnectTimer = null;
        }

        if (this.connected || this.connecting) {
            return;
        }

        this.isWSClosedWithError = false;
        this.connecting = true;
        const currentServerConnection = this._urlManager.getCurrentConnection();
        this._currentServerConnection = currentServerConnection;
        const scope = this;

        try {
            this.socket = new WebSocket(currentServerConnection.serverName);
            this.socket.onopen = function () {
                scope._logger && scope._logger.debug('WS Opened!!![' + currentServerConnection.serverName + ']');
                scope.connected = true;
                scope.connecting = false;
                scope.onConnected();
                store.dispatch(ready());
                scope.dataReceived = true;
                scope.startWatchDog();
                scope.updatePageListeners();

                scope._urlManager.openedConnection(currentServerConnection);
                if (currentServerConnection !== scope._urlManager.getCurrentConnection()) {
                    scope.reconnectStart(reconnectTimeout);
                }
            };
            this.socket.onclose = function (e) {
                scope.connecting = false;
                scope.connected = false;
                scope.logInStatus = Transport.NOT_LOG_IN;
                scope.onClose();
                scope.error('WS Closed!!!');
                scope._urlManager.closedConnection(currentServerConnection);

                if (e.code === 1006 && scope.working) {
                    scope.isWSClosedWithError = true;
                    scope.checkConnectionOnFocus();
                }
            };
            this.socket.onmessage = function (e) {
                if (!scope.working) {
                    scope.working = true;
                }
                scope.message(e.data);
                scope.dataReceived = true;
            };
            this.socket.onerror = function (e) {
                scope.onErrorDispatcher();
                scope._urlManager.closedConnection(currentServerConnection);
                scope._logger && scope._logger.debug('Unable connect to the server ' + currentServerConnection.serverName + '!');
                scope._logger && scope._logger.error(e);
                scope.onError(e);
            };

            window.setTimeout(() => {
                if (scope.connected) {
                    return;
                }

                scope._logger && scope._logger.debug('WS time out');
                scope._urlManager.closedConnection(currentServerConnection);
                scope.reconnectStart(reconnectTimeout);
            }, timeout)
        } catch (err) {
            scope.onErrorDispatcher();
            scope._logger && scope._logger.debug('WS Exception!!!', err);
            scope._urlManager.closedConnection(currentServerConnection);
            this.reconnectStart(reconnectTimeout);
        }
    }


    disconnect() {
        if (this._currentServerConnection) {
            this._urlManager.closedConnection(this._currentServerConnection);
            this._currentServerConnection = null;
        }

        this.connected = false;
        this.connecting = false;
        const scope = this;
        //remove handlers
        if (this.socket) {
            this.socket.onclose = function () {
                scope._logger && scope._logger.debug('Removed WS Closed!!!');
                this.working = false;
            };
            this.socket.onerror = function () {
            };
            this.socket.onmessage = function () {
            };
            this.socket.onopen = function () {
            };
            this.socket.close();
            this.socket = null;
        }
    }

    reconnectStart(interval = 1000) {
        if (this.logInStatus !== Transport.LOGGED_IN) return;

        this.disconnect();

        if (this.reconnectTimer) {
            return;
        }
        this.reconnectTimer = window.setTimeout(this.connect.bind(this), interval);
    }

    startWatchDog() {
        const scope = this;
        window.clearInterval(this.watchdog);
        this.watchdog = window.setInterval(function () {
            if (!scope.dataReceived && scope.connected) {
                scope._logger && scope._logger.debug('No heartbeats!!');
                scope.reconnectStart();
                return;
    }

            scope.dataReceived = false;
        }, 30000);
    }

    checkConnectionOnFocus() {
        if (this.isCheckConnectionRunning) return;

        if (!this.isWSClosedWithError) return;

        if (document.visibilityState !== "visible" || document.hidden) return;

        if (!this.working) return;

        if (
            (!this.connecting && !this.connected) ||
            !this.socket ||
            (this.socket && this.socket.readyState === WebSocket.CLOSED)
            ) {
            this.isCheckConnectionRunning = true;
            this._logger && this._logger.debug('Lost connection!!');
            store.dispatch(lostWSConnection());
            this.disconnect();
            this.connect();
            this.isCheckConnectionRunning = false;
        }
    }

    send(data) {
        if (!this.connected && this.throwRejectWhenNoConnectedSend) {
            this.onReject('No connection to the server');
        }

        if (!data.Login && ((this.socket && this.socket.readyState !== this.socket.OPEN) || this.logInStatus !== Transport.LOGGED_IN || !this.socket)) {
            return this.onReject('No connection to the server');
        }

        const json = JSON.stringify(data);
        
        try {
            this.socket.send(json);
        } catch (err) {
            this._logger && this._logger.debug('WS SEND Exception!!!');
        }
    }

    message(data) {
        const obj = JSON.parse(data);
        this.processMessage(obj);
    }

    error() {
        this._logger && this._logger.debug('Handle error');
        this.errorMessage = "Server connection problem";
        // Initialize reconnect
        this.reconnectStart(3000);
    }

    processMessage(jsonData) {
        if (this._logger && jsonData.TypeInfo !== "Quote"
            && jsonData.TypeInfo !== "Heartbeat") {
            // this._logger.debug(`msg: ${jsonData.TypeInfo} (${this._transportName})`);
        }

        this.processDataMessage(jsonData);
    }

    processDataMessage(jsonData) {
        this.onDataMessage(jsonData);

        // события выставлены в прорядке частотности
        switch (jsonData.TypeInfo) {
            case "Quote":
this.onQuote(jsonData);
                break;
            case "Heartbeat":
                break;
            case "AppMouseLocation":
                this.onAppMouseLocation(jsonData);
                break;
            case "AppMouseClick":
                this.onAppMouseClick(jsonData);
                break;
            case "InstrumentsList":
                this.onInstrumentsList(jsonData.ListInstruments);
                break;
            case "AccountsList":
                this.onAccountsList(jsonData.ListAccounts);
                break;
            case "AccountUpdate":
                this.onAccountUpdate(jsonData.ListAccounts);
                break;
            case "AccountAdd":
                this.onAccountAdd(jsonData.ListAccounts);
                break;
            case "OrdersList":
                this.onOrdersList(jsonData.ListOrders);
                break;
            case "PositionsList":
                this.onPositionsList(jsonData.ListPositions);
                break;
            case "Login":
            case "Relogin":
                this.logInStatus = Transport.LOGGED_IN;
                this.onLogin(jsonData);
                break;
            case "NewsList":
                this.onNewsList(jsonData.ListNews);
                break;
            case "NewsAdd":
                this.onNewsAdd(jsonData.ListNews);
                break;
            case "ActivityLog":
                this.onActivityLog(jsonData.ListData);
                break;
            case "ActivityLogAdd":
                this.onActivityLogUpdate(jsonData.ListData);
                break;
            case "PositionAdd":
                this.onPositionAdd(jsonData.ListPositions);
                break;
            case "PositionUpdate":
                this.onPositionUpdate(jsonData.ListPositions);
                break;
            case "PositionRemove":
                this.onPositionRemove(jsonData.ListPositions);
                break;
            case "OrderAdd":
                this.onOrderAdd(jsonData.ListOrders);
                break;
            case "OrderUpdate":
                this.onOrderUpdate(jsonData.ListOrders);
                break;
            case "OrderRemove":
                this.onOrderRemove(jsonData.ListOrders);
                break;
            case "HistoryBars":
                this.onHistoryBars(jsonData);
                break;
            case "HistoryQuotes":
                this.onHistoryQuotes(jsonData);
                break;
            case "AccountHistoryAdd":
                this.onAccountHistoryUpdate(jsonData.ListData);
                break;
            case "AccountHistory":
                this.onAccountHistory(jsonData.ListData);
                break;
            case "CommissionsList":
                this.onCommissionsList(jsonData.ListCommissions);
                break;
            case "SignalSubscriptions":
                this.onSignalSubscriptions(jsonData.ListSignalSubscriptions);
                break;
            case "SignalProviders":
                this.onSignalProviders(jsonData.ListSignalProviders);
                break;
            case "SignalHistory":
                this.onSignalHistory(jsonData.ListData);
                break;
            case "Conversion":
                this.onConversion(jsonData.Data);
                break;
            case "MarginModelList":
                this.onMarginModelList(jsonData.ListMarginModels);
                break;
            case "SwapsList":
                this.onSwapsList(jsonData.ListSwaps);
                break;
            case "CommissionPaymentsList":
                this.onCommissionPaymentList(jsonData.ListCommissionPayments);
                break;
            case "InstrumentGroupList":
                this.onInstrumentGroupList(jsonData.ListInstrumentGroups);
                break;
            case "TradeSessionList":
                this.onTradeSessionList(jsonData.ListTradeSessions);
                break;
            case "TradeSessionAddUpdate":
                this.onTradeSessionAddUpdate(jsonData.ListTradeSessions);
                break;
            case "TradeSessionRemove":
                this.onTradeSessionRemove(jsonData.ListTradeSessions);
                break;
            case "AppActionData":
                this.onAppActionData(jsonData.Data);
                break;
            case "AppActionState":
                this.onAppActionState(jsonData.Data);
                break;
            case "ComponentState":
                this.onComponentState(jsonData.Data);
                break;
            case "ComponentStore":
                this.onComponentStore(jsonData.Data);
                break;
            case "TrackingState":
                this.onSetTracking(jsonData.Data);
                break;
            case "TechBreak":
                this.onTechBreak(jsonData);
                break;
            case "Error":
                this._logger && this._logger.error("Error : " + jsonData.AdditionalData, jsonData);
                break;
            case "Reject":
                this.onReject(jsonData);
                this._logger && this._logger.error("Reject : " + jsonData.Reason, jsonData);
                break;
            case "RejectOrder":
                this.onRejectOrder(jsonData.Reason);
                this._logger && this._logger.error("Reject Order:" + jsonData.Reason, jsonData);
                break;
            case "RejectPosition":
                this.onRejectPosition(jsonData.Reason);
                this._logger && this._logger.error("Reject Position:" + jsonData.Reason, jsonData);
                break;
            case "RejectLogin":
                this.onRejectLogin(jsonData);
                this._logger && this._logger.error("Reject Login : " + jsonData.Reason, jsonData);
                break;
            case "RejectSignalSubscription": {
                this.onRejectSignalSubscription(jsonData);
                this._logger && this._logger.error("Reject Signal Subscription : " + jsonData.Reason, jsonData);
                break;
            }
            case "InterchangeList":
                this.onInterchangeList(jsonData.ListInterchanges);
                break;
            case "InterchangeAdd":
                this.onInterchangeAdd(jsonData.ListInterchanges);
                break;
            case "InterchangeRemove":
                this.onInterchangeRemove(jsonData.ListInterchanges);
                break;
            case "InterchangeDecode":
                this.onInterchangeDecode(jsonData.ListInterchanges);
                break;
            case "InterchangeExecute":
                this.onInterchangeExecute(jsonData.ListInterchanges);
                break;
            case "ScreenSharing":
                this.onScreenSharing(jsonData);
                break;
            case "UserNotification":
                this.onNotificationAdd(jsonData || {});
                break;
            default:
                this._logger && this._logger.error("Uncaught transport message: " + jsonData.TypeInfo, jsonData);
                break;
        }
    }

    onScreenSharing(jsonData) {
        const { Content, Data } = jsonData.Data;

        switch(Content) {
            case "ScreensharingRequest":
                this.onScreenSharingRequest(Data);
                break;

            case "ScreensharingAnswer":
                this.onScreenSharingAnswer(Data);
                break;
            
            case "ScreensharingEnd":
                this.onScreenSharingEnd(Data);
                break;

            case "ScreensharingStarted":
                this.onScreenSharingStarted(Data);
                break;
            case "ScreensharingCancel":
                this.onScreenSharingCancel(Data);
                break;

            default: 
                return;
        }
    }

    requestScreenSharingPermission(allow) {
        const request = { 
            RequestType: 'ScreenSharing',
            Data: {
                Content: 'ScreensharingPermission',
                Allow: allow,
            },
        };

        this.send(request);
    }

    requestScreenSharingOffer(data, connectionId) {
        const request = {
            RequestType: 'ScreenSharing',
            Data: {
                Content: 'ScreensharingOffer',
                Offer: data,
                ConnectionId: connectionId,
            },
        };

        this.send(request);
    }

    requestScreenSharingEnd(connectionId) {
        const request = {
            RequestType: 'ScreenSharing',
            Data: {
                Content: 'ScreensharingEnd',
                ConnectionId: connectionId,
            },
        };

        this.send(request);
    }

    requestScreenSharingReject(connectionId) {
        const request = {
            RequestType: 'ScreenSharing',
            Data: {
                Content: 'ScreensharingReject',
                ConnectionId: connectionId,
            },
        };

        this.send(request);
    }

    requestCreateInterchange(CurrencyFrom, CurrencyTo, UnitVolumeFrom, UnitVolumeTo, TwoFACode) {
        const request = {
            Interchange: 'Create',
            Args: {
                CurrencyFrom,
                CurrencyTo,
                UnitVolumeFrom,
                UnitVolumeTo,
                TwoFACode,
            },
        };
        this.send(request);
    }

    actionInterchange(Id, action, TwoFACode = '') {
        const request = {
            Interchange: action,
            Args: { Id, TwoFACode, },
        };
        this.send(request);
    }

    requestAuth2({ token, lang, socketId = null, channel = Transport.CHANNEL_TRADE, twoFACode = null, tracking = null }) {
        const cmd = {
            Login: {
                Channel: channel,
                Token: token,
                Language: lang,
                SocketId: socketId,
                TwoFACode: twoFACode,
                TrackedUser: tracking
            }
        };

        this.send(cmd);
        this.logInStatus = Transport.LOGGING_IN;
    }

    requestLogin({ login, password, lang, socketId = null, channel = Transport.CHANNEL_TRADE, twoFACode = null, tracking = null }) {
        const cmd = {
            Login: {
                Channel: channel,
                Name: login,
                Password: password,
                Language: lang,
                SocketId: socketId,
                TwoFACode: twoFACode,
                TrackedUser: tracking
            }
        };

        this.send(cmd);
        this.logInStatus = Transport.LOGGING_IN;
    }

    requestCancelOrder({ orderId, accountId }) {
        const cmd = {
            Order: "Cancel", //Place, Replace, Cancel
            OrderArgs: {
                OrderNumber: orderId,
                AccountNumber: accountId
            }
        };
        this.send(cmd);
    }

    requestClosePosition({ positionId, amount, accountId }) {
        const cmd = {
            Position: "Close",
            PositionArgs: {
                PositionNumber: positionId,
                Amount: amount,
                AccountNumber: accountId
            }
        };
        this.send(cmd);
    }

    requestCloseAllPositions({ symbolId, side, netting, accountNumber }) {
        const cmd = {
            Position: "CloseAll",
            PositionArgs: {
                AccountNumber: accountNumber,
                SymbolId: symbolId,
                BuySell: side, // может от�?ут�?твовать или быть еще и "Sell"
                Mutual: netting // false - Это просто закрытие. true - Netting
            }
        };
        this.send(cmd);
    }

    requestCancelAllOrders({ accountId, symbolId, side }) {
        const cmd = {
            Order: "CancelAll",
            OrderArgs: {
                AccountNumber: accountId,
                SymbolId: symbolId,
                BuySell: side
            }
        };
        this.send(cmd);
    }

    requestActivityLogHistory({ from = null, to = null, accounts = []  }) {
        const request = {
            Report: "ActivityLog",
            FromDate: from,
            ToDate: to,
            Accounts: accounts,
        };

        this.send(request);
    }

    requestAccountHistory({ from = null, to = null, accounts = [] }) {
        const request = {
            Report: "AccountHistory",
            FromDate: from,
            ToDate: to,
            Accounts: accounts,
        };

        this.send(request);
    }

    requestCreateOrder({
        type, accountNumber, receiveAccountNumber, symbolId,
        buySell, side, price, size, stopLoss, takeProfit,
        TPEnteredVolume, TPEnteredUnit, SLEnteredVolume, SLEnteredUnit,
        trailingStop, timeInForce, tradeMode, isPrice = true }) {

        const stopPrice = (type.toLowerCase() === "stop") ? price : 0;
        const request = {
            Order: "Place", //Place, Replace, Cancel
            OrderType: type.toLowerCase(), // @deprecated
            OrderArgs: {
                AccountNumber: accountNumber,
                AccountNumber2: receiveAccountNumber,
                SymbolId: symbolId,
                //OrderType: type.toLowerCase(), // support only in new WSS
                //BuySell: buySell, // string Buy Sell, support only in new WSS
                Operation: side, // int, @deprecated
                Price: price,   // double
                StopPrice: stopPrice,
                Amount: size,     // double
                SLOffset: stopLoss, // double
                TPOffset: takeProfit, // double
                TrailingStop: !!trailingStop,
                TrailingOffSet: trailingStop,
                ValuesArePrices: isPrice,
                TradeMode: tradeMode,
                TimeInForce: timeInForce || 10008, //int,
                TPEnteredVolume: Number(TPEnteredVolume),
                TPEnteredUnit: TPEnteredUnit,
                SLEnteredVolume: Number(SLEnteredVolume),
                SLEnteredUnit: SLEnteredUnit,
            }
        };

        this.send(request);
    }

    requestOrderTrailingStopSet({orderId, accountNumber, trailingStop}) {
        const request = {
            Order: "TrailingStopSet",
            OrderArgs: {
                OrderNumber: orderId,
                AccountNumber: accountNumber,
                TrailOffset: Math.abs(trailingStop)
            }
        };

        this.send(request);
    }

    requestOrderTrailingStopRemove({orderId, accountNumber}) {
        const request = {
            Order: "TrailingStopRemove",
            OrderArgs: {
                OrderNumber: orderId,
                AccountNumber: accountNumber,
            }
        };

        this.send(request);
    }

    requestReplaceOrder({ accountNumber, orderId, type, price, size, boundTo = 0, stopLoss = 0, takeProfit = 0,
        TPEnteredVolume, TPEnteredUnit, SLEnteredVolume, SLEnteredUnit, trailingStop = false }) {
        const request = {
            Order: "Replace",
            OrderType: type, // @deprecated
            OrderArgs: {
                OrderNumber: orderId,
                //OrderType: type, // support only in new WSS
                Price: price,   // double
                Amount: size,     // double
                SLOffset: stopLoss, // double
                TPOffset: takeProfit, // double
                TrailingStop: trailingStop,
                BoundTo: boundTo,
                AccountNumber: accountNumber,
                TPEnteredVolume: Number(TPEnteredVolume),
                TPEnteredUnit: TPEnteredUnit,
                SLEnteredVolume: Number(SLEnteredVolume),
                SLEnteredUnit: SLEnteredUnit,
            }
        };

        this.send(request);
    }

    requestPositionTrailingStopSet({orderId, accountNumber, trailingStop}) {
        const request = {
            Position: "TrailingStopSet",
            PositionArgs: {
                PositionNumber: orderId,
                AccountNumber: accountNumber,
                TrailOffset: Math.abs(trailingStop)
            }
        };

        this.send(request);
    }

    requestPositionTrailingStopRemove({orderId, accountNumber}) {
        const request = {
            Position: "TrailingStopRemove",
            PositionArgs: {
                PositionNumber: orderId,
                AccountNumber: accountNumber,
            }
        };

        this.send(request);
    }

    requestSetPositionStopLoss({ orderId, price, accountId, slInputValue, slUnit, isPrice = true }) {
        const request = {
            Position: "SetSL",
            PositionArgs: {
                PositionNumber: orderId,
                Price: +price,
                ValuesArePrices: isPrice,
                AccountNumber: accountId,
                SLEnteredVolume: Number(slInputValue),
                SLEnteredUnit: slUnit,
            }
        };

        this.send(request);
    }

    requestSetPositionTakeProfit({ orderId, price, accountId, tpInputValue, tpUnit, isPrice = true }) {
        const request = {
            Position: "SetTP",
            PositionArgs: {
                PositionNumber: orderId,
                Price: +price,
                ValuesArePrices: isPrice,
                AccountNumber: accountId,
                TPEnteredVolume: Number(tpInputValue),
                TPEnteredUnit: tpUnit,
            }
        };

        this.send(request);
    }

    requestCancelPositionStopLoss({ orderId, accountId }) {
        const request = {
            Position: "RemoveSL",
            PositionArgs: {
                PositionNumber: orderId,
                AccountNumber: accountId
            }
        };

        this.send(request);
    }

    requestCancelPositionTakeProfit({ orderId, accountId }) {
        const request = {
            Position: "RemoveTP",
            PositionArgs: {
                PositionNumber: orderId,
                AccountNumber: accountId
            }
        };

        this.send(request);
    }

    requestSetOrderSLTP({ orderId, accountId, tpInputValue, tpUnit, slInputValue, slUnit, stopLoss = '', takeProfit = '', isPrice = true }) {
        const request = {
            Order: "Place",
            OrderType: "SLTP", // @deprecated
            OrderArgs: {
                OrderNumber: orderId,
                //OrderType: "SLTP", // support only in new WSS
                SLOffset: stopLoss, // double
                TPOffset: takeProfit, // double
                ValuesArePrices: isPrice,
                AccountNumber: accountId,
                TPEnteredVolume: Number(tpInputValue),
                TPEnteredUnit: tpUnit,
                SLEnteredVolume: Number(slInputValue),
                SLEnteredUnit: slUnit,
            }
        };

        this.send(request);
    }

    requestCancelOrderStopLoss({ orderId, accountId }) {
        const request = {
            Order: "RemoveSL",
            OrderArgs: {
                OrderNumber: orderId,
                AccountNumber: accountId
            }
        };

        this.send(request);
    }

    requestCancelOrderTakeProfit({ orderId, accountId }) {
        const request = {
            Order: "RemoveTP",
            OrderArgs: {
                OrderNumber: orderId,
                AccountNumber: accountId
            }
        };

        this.send(request);
    }

    requestQuotesHistory({ requestId, symbolId, periodM, periodB, from, to }) {
        const request = {
            History: "Request",
            HistoryRequest: {
                SymbolId: symbolId,
                Ticker: "",
                Period: {
                    MainPeriod: periodM,
                    BasePeriod: periodB
                },
                StartTime: from,
                EndTime: to,
                RequestId: requestId
            }
        };

        this.send(request);
    }

    requestLogOut(socketId = null) {
        const request = {
            Logout: "Logout",
            SocketId: socketId
        };

        this.working = false;

        this.send(request);
        this.disconnect();
        this.logInStatus = Transport.NOT_LOG_IN;
    }

    requestGetStrategyProviders() {
        const request = {
            Signal: "Get"
        };
        this.send(request);
    }

    requestSetSignalAccount(model) {
        const request = {
            Signal: "Set",
            SetSignalAccountArgs: {
                AccountNumber: model.accountNumber,
                MinimumInvestment: model.minimumInvestment,
                MinScubscriptionDays: model.minScubscriptionDays,
                Description: JSON.stringify({
                    strategyName: model.strategyName,
                    description: model.strategyDescription,
                }),
                PhotoPreviewFileName: model.strategyPhotoFileName,
                PhotoPreviewFileData: base64.base64ArrayBuffer(model.strategyPhotoFileData),
                UserDescription: model.userDescription,
                PhotoFileName: model.userPhotoFileName,
                PhotoFileData: base64.base64ArrayBuffer(model.userPhotoFileData),
            },
        };

        this.send(request);
    }

    requestCancelSignalAccount(accountNumber) {
        const request = {
            Signal: "Cancel",
            SetSignalAccountArgs: {
                AccountNumber: accountNumber
            },
        };

        this.send(request);
    }

    requestGetAccountHistory(historyType, Id, from, to) {
        const request = {
            Report: historyType,
            FromDate: from,
            ToDate: to,
            Accounts: [Id]
        };
        this.send(request);
    }

    requestConversionBalance(firstCurrency, secondCurrency, sum) {
        const request = {
            Service: 'Conversion',
            ConversionArgs: {
                Currency_1: firstCurrency,
                Currency_2: secondCurrency,
                Sum: sum,
                LP: ''
            }
        };
        this.send(request);
    }

    requestRemoveSubscription(id) {
        let request = {
            SignalSubscription: "REMOVE",
            SignalSubscriptionArgs: {
                SetID: id
            }
        };
        this.send(request);
    }

    requestCreateSubscription(data) {
        let request = {
            SignalSubscription: "UPDATE",
            SignalSubscriptionArgs: data
        };
        this.send(request);
    }

    sendAppActionData(action, state) {
        let request = {
            RequestType: "AppActionData",
            Params: {
                TypeInfo: action,
                State: state
            }
        };

        this.send(request);
    }

    sendAppActionState(action, state) {
        const request = {
            RequestType: "AppActionState",
            Params: {
                Action: (action && action.type) ? action.type : "",
                ActionPayload: (action && action.payload) ? action.payload : "",
                //AppState: state
            }
        };

        this.send(request);
    }

    sendAppMouseClick(state, cursorData) {
        let clientWidth = window.innerWidth;
        let clientHeight = window.innerHeight;
        let request = {
            RequestType: "AppMouseClick",
            Params: {
                Cursor: {
                    Button: cursorData.button,
                    InnerWidth: clientWidth,
                    InnerHeight: clientHeight,
                    OffsetX: cursorData.offsetX,
                    OffsetY: cursorData.offsetY,
                    X: math.roundToPrecision(cursorData.clientX / clientWidth, 4),
                    Y: math.roundToPrecision(cursorData.clientY / clientHeight, 4),
                }
            }
        };

        this.send(request);
    }

    sendAppMouseLocation(state, cursorData) {
        let clientWidth = window.innerWidth;
        let clientHeight = window.innerHeight;
        let request = {
            RequestType: "AppMouseLocation",
            Params: {
                Cursor: {
                    Button: cursorData.button,
                    InnerWidth: clientWidth,
                    InnerHeight: clientHeight,
                    OffsetX: cursorData.offsetX,
                    OffsetY: cursorData.offsetY,
                    X: math.roundToPrecision(cursorData.clientX / clientWidth, 4),
                    Y: math.roundToPrecision(cursorData.clientY / clientHeight, 4),
                }
            }
        };

        this.send(request);
    }

    sendComponentState(name, state) {
        let request = {
            RequestType: "ComponentState",
            Params: {
                Name: name,
                State: state
            }
        };

        this.send(request);
    }

    sendComponentStore(typeInfo, state) {
        let request = {
            RequestType: "ComponentStore",
            Params: {
                TypeInfo: typeInfo,
                State: state
            }
        };

        this.send(request);
    }

    notificationRequest({fromDate, toDate}) {
        const request = {
            Notification: "Request",
            FromDate: fromDate,
            ToDate: toDate,
        }
        this.send(request);
    }

    updateNotification({Id, STATUS}) {
        const request = {
            UserNotification: "Update",
            Id,
            STATUS,
        }
        this.send(request);
    }

}
