import * as appActions from '../app';
import { transport, transportMedia } from '../../services';
import { getDisplayMedia, Stream } from '../../utils/mediaStream';
import validator from '../../utils/validator';

export const REQUEST = 'SCREEN_SHARING_REQUEST';
export const START = 'SCREEN_SHARING_START';
export const END = 'SCREEN_SHARING_END';

export function request({ ConnectionId, Name }) {
    return {
        type: REQUEST,
        payload: {
            connectionId: ConnectionId,
            connectedUserName: Name,
        }
    }
}

export function start() {
    return {
        type: START,
    }
}

export function end(connectionId) {
    return {
        type: END,
        payload: {
            connectionId,
        }
    }
}

export function started() {
    return (dispatch, getState) => {
        const isNowSharing = getState().sharing.screen.isSharing;
        if (isNowSharing) return;

        dispatch(appActions.showScreenSharingRequestMessage(false));
    }
}

export function doEnd(sendEndMessage = true) {
    return (dispatch, getState) => {
        const { isSharing: isNowSharing, connectionId } = getState().sharing.screen;

        dispatch(end(connectionId));
        dispatch(appActions.showScreenSharingRequestMessage(false));

        if (isNowSharing) {
            dispatch(appActions.showScreenSharingEndMessage(true));
        }

        if (sendEndMessage && !validator.isEmpty(connectionId)) {
            transport.requestScreenSharingEnd(connectionId);
        }

        transportMedia.disconnect();
    }
}

export function doSendPermission(allow) {
    return () => {
        transport.requestScreenSharingPermission(allow);
    }
}

export function doRequest(data) {
    return (dispatch, getState) => {
        const isNowSharing = getState().sharing.screen.isSharing;

        if (isNowSharing) {
            transport.requestScreenSharingReject(data.ConnectionId);
            return;
        }
        
        dispatch(request(data));
        dispatch(appActions.showScreenSharingRequestMessage(true));
    }
}

export function doDenied() {
    return (dispatch) => {
        dispatch(doSendPermission(false));
        dispatch(doEnd(false));
    }
}

export function doStart() {
    return (dispatch) => {
        dispatch(start());
        dispatch(appActions.showScreenSharingRequestMessage(false));
        dispatch(doSendPermission(true));
    }
}

export function doAnswer(data) {
    return () => {
        transportMedia.addSignal(data.Answer);
    }
}

export function doSharing() {
    return (dispatch, getState) => {

        getDisplayMedia()
            .then((stream) => {
                const mediaStream = new Stream(stream);

                const { connectionId } = getState().sharing.screen;

                if (validator.isEmpty(connectionId)) {
                    mediaStream.stop();
                    return;
                }

                dispatch(doStart());

                transportMedia.onConnected = () => {
                    transportMedia.sendScreenSharingStream(mediaStream);
                };
                transportMedia.onSignal = (data) => {
                    transport.requestScreenSharingOffer(data, connectionId);
                };
                transportMedia.onStopStream = () => {
                    dispatch(doEnd(true));
                };
                transportMedia.onError = () => {
                    dispatch(doEnd(false));
                };
                transportMedia.onClose = () => {
                    dispatch(doEnd(false));
                };
                transportMedia.connect();
            })
            .catch(() => {
                dispatch(doDenied());
            });
    }
}
