/**
 * Created by ebondarev
 */
import { Record, Set } from 'immutable';

import Types from '../../classes/types';
import { sort } from '../utils';
import * as actionTypes from '../../actions/trade/news';
import { NewsModel } from '../../models/news';

class News extends Record({
    list: new Set([]),
    status: actionTypes.NOT_FETCHED,
    sortAttribute: 'UTCDateTime',
    sortDirection: Types.SORT_DESC,
    lastUpdateAt: Date.now(), // обновлены данные в модели
    lastUpdateListAt: Date.now(), // обновлены данные в списке логов
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

function createModelFromData(data) {
    const model = new NewsModel(data);
    return model;
}

const initialState = new News();

export default function activityLog(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHED:
            const data = Object.values(action.payload).map((data) => {
                return createModelFromData(data);
            });

            state.concat({
                list: sort(new Set(data), state.sortAttribute, state.sortDirection),
            });

            return state.merge({
                status: actionTypes.FETCHED,
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        case actionTypes.ADD:
            const addModel = createModelFromData(action.payload);
            return state.merge({
                list: sort(state.list.add(addModel), state.sortAttribute, state.sortDirection),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now()
            });

        case actionTypes.CHANGE_SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, action.payload, state.sortDirection),
                sortAttribute: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.CHANGE_SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            return state.merge({
                list: sort(state.list, state.sortAttribute, action.payload),
                sortDirection: action.payload,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.CLEAR:
            return state.merge({
                status: actionTypes.NOT_FETCHED,
                list: state.list.clear(),
                lastUpdateAt: Date.now(),
                lastUpdateListAt: Date.now(),
            });

        default:
            return state;
    }
}