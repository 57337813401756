import BaseModel from './base';

class SwapModel extends BaseModel {
    Id;
    Long;
    Short;
    Currency;
    SymbolId;
    PaymentType;
    PeriodUnit;

    constructor(initData) {
        super();
        this.copyFrom(initData);
    }
}

export default SwapModel;