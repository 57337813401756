export const CREATE = 'TRADE_TABS_CREATE';
export const UPDATE = 'TRADE_TABS_UPDATE';
export const TRADE_TABS__REMOVE_ALL = 'TRADE_TABS__REMOVE_ALL';
export const TRADE_TABS__REMOVE_TAB = 'TRADE_TABS__REMOVE_TAB';

export function create(tabKey, formState) {
    return {
        type: CREATE,
        payload: [tabKey, formState],
    }
}

export function update(tabKey, formState) {
    return {
        type: UPDATE,
        payload: [tabKey, formState],
    }
}

export function remove(tabKey) {
    return {
        type: TRADE_TABS__REMOVE_TAB,
        payload: tabKey,
    }
}

export function removeAll() {
    return {
        type: TRADE_TABS__REMOVE_ALL,
    }
}